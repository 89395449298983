import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './onlayn-srovnomasi.css'

const OnlaynSrovnomasi = (props) => {
  return (
    <div className="onlayn-srovnomasi-container10">
      <Helmet>
        <title>onlayn-srovnomasi - Visametric</title>
        <meta property="og:title" content="onlayn-srovnomasi - Visametric" />
      </Helmet>
      <div
        data-thq="thq-burger-menu"
        className="onlayn-srovnomasi-burger-menu1"
      >
        <svg viewBox="0 0 1024 1024" className="onlayn-srovnomasi-icon10">
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div id="main" className="onlayn-srovnomasi-container11">
        <h1 className="onlayn-srovnomasi-text100">Онлайн-сўровнома</h1>
        <div className="onlayn-srovnomasi-container12">
          <Link
            to="/uzbekistan-germanyuz"
            className="onlayn-srovnomasi-navlink10"
          >
            <div className="onlayn-srovnomasi-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="onlayn-srovnomasi-icon12"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="onlayn-srovnomasi-text101">Бош саҳифа</h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="onlayn-srovnomasi-navlink11"
          >
            <div className="onlayn-srovnomasi-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="onlayn-srovnomasi-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="onlayn-srovnomasi-text102">Виза олиш</h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="onlayn-srovnomasi-navlink12"
          >
            <div className="onlayn-srovnomasi-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="onlayn-srovnomasi-icon16"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="onlayn-srovnomasi-text103">Онлайн-сўровнома</h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="onlayn-srovnomasi-container16">
        <div className="onlayn-srovnomasi-container17">
          <div className="onlayn-srovnomasi-container18">
            <span className="onlayn-srovnomasi-text104">Виза олиш</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="onlayn-srovnomasi-icon18"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="onlayn-srovnomasi-image10"
          />
          <div className="onlayn-srovnomasi-container19">
            <Link to="/shengen-vizasi" className="onlayn-srovnomasi-navlink13">
              <div className="onlayn-srovnomasi-container20">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="onlayn-srovnomasi-image11"
                />
                <span className="onlayn-srovnomasi-text105">Шенген визаси</span>
              </div>
            </Link>
            <Link to="/milliy-viza" className="onlayn-srovnomasi-navlink14">
              <div className="onlayn-srovnomasi-container21">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="onlayn-srovnomasi-image12"
                />
                <span className="onlayn-srovnomasi-text106">Миллий виза</span>
              </div>
            </Link>
            <Link
              to="/rasmiy-bayramlar-kuni"
              className="onlayn-srovnomasi-navlink15"
            >
              <div className="onlayn-srovnomasi-container22">
                <img
                  alt="image"
                  src="/rectangle%2030.svg"
                  className="onlayn-srovnomasi-image13"
                />
                <span className="onlayn-srovnomasi-text107">
                  Онлайн-сўровнома
                </span>
              </div>
            </Link>
            <Link
              to="/kerakli-uzhzhatlar"
              className="onlayn-srovnomasi-navlink16"
            >
              <div className="onlayn-srovnomasi-container23">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="onlayn-srovnomasi-image14"
                />
                <span className="onlayn-srovnomasi-text108">
                  Керакли ҳужжатлар
                </span>
              </div>
            </Link>
            <Link
              to="/uchrashuv-sanasini-va-vatini-belgilash"
              className="onlayn-srovnomasi-navlink17"
            >
              <div className="onlayn-srovnomasi-container24">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="onlayn-srovnomasi-image15"
                />
                <span className="onlayn-srovnomasi-text109">
                  Учрашув санасини ва вақтини белгилаш
                </span>
              </div>
            </Link>
            <Link to="/ariza-shakllari" className="onlayn-srovnomasi-navlink18">
              <div className="onlayn-srovnomasi-container25">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="onlayn-srovnomasi-image16"
                />
                <span className="onlayn-srovnomasi-text110">
                  Ариза шакллари
                </span>
              </div>
            </Link>
            <Link
              to="/ilovani-kuzatish"
              className="onlayn-srovnomasi-navlink19"
            >
              <div className="onlayn-srovnomasi-container26">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="onlayn-srovnomasi-image17"
                />
                <span className="onlayn-srovnomasi-text111">
                  Иловани кузатиш
                </span>
              </div>
            </Link>
          </div>
        </div>
        <div className="onlayn-srovnomasi-container27">
          <span className="onlayn-srovnomasi-text112">
            <span>Онлайн сўровномаси (Videx)</span>
            <br></br>
          </span>
          <span className="onlayn-srovnomasi-text115">
            <span>
              Онлайн сўровномасини қўйидаги кўрсатилган бандлардан фойдаланиб
              тўлдиринг ва уни имзоланг. Бу ерда хизмат кўрсатиш йўриқномасини
              VIDEX да топасиз.
            </span>
            <br></br>
            <br></br>
            <span>
              Тўлдирилган сўровномадаги ҳамма маълумотларни қайта текшириб
              кўринг ва 5-6-7- саҳифалардаги кўрсатилган маълумотларни
              ўзингизнинг имзойингиз билан тасдиқланг. Ушбу электрон иловани
              VisaMetric филиалларига бошқа керакли ҳужжатлар билан биргаликда
              келтиринг.
            </span>
            <br></br>
            <br></br>
            <span>
              Сўровномани онлайн кўринишини тўлдириб чиқа олмасангиз, қўйидаги
              тақдим қилинган бланкалардан фойдаланинг. Илтимос эътибор беринг,
              қўлда тўлдирилган сўровномалар ва топширилган ҳужжатлар
              VisaMetricда қайта кўриб чиқиш эвазига муддатини сўровномада
              ўзайтиради, чунки ҳамма ҳужжатлар қўлда етказиб берилади.
            </span>
            <br></br>
            <br></br>
            <span>
              Шенген визаси учун сўровномани тўлдириш хизматлари таклиф
              қилинади,ушбу хизматлар пулли ҳисобланиб VisaMetric қабулларида
              ушбу хизмат турларидан фойдаланишлари мумкин. Қабулхонанинг
              ходимлари ариза топширувчининг интернетдаги электрон почта орқали
              жўнатган маълумотларини киритади ва қўйидаги маълумотлар сўровнома
              ҳужжатларини тўлдиришда фойдаланилади. Қўйидаги маълумотлар
              аризачилар томонидан тақдим қилинган маълумотларга асосланган
              бўлади.
            </span>
            <br></br>
            <br></br>
            <span>
              Сўровномаларни тўлдириш хизмати ихтиёрий ва қабулхоналардаги виза
              учун ариза шакллари бўйича бериладиган бепул маслаҳатлар бунга
              кирмайди. У сўровномадаги маълумотларни тўлиқ киритилиши
              жавобгарлигини олмайди ва визанинг салбий қарорига таъсир
              ўтказмайди. Қабул марказлари визанинг ижобий қарорлари учун
              жавобгар эмас. Сўровномаларни тўлдириш хизмати фақатгина
              аризачилар томонидан қўлланилиши мумкин.
            </span>
          </span>
          <div className="onlayn-srovnomasi-container28">
            <button type="button" className="onlayn-srovnomasi-button button">
              <span className="onlayn-srovnomasi-text129">
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>Онлайн сўровномани тўлдириш (Videx)</span>
              </span>
            </button>
            <Link
              to="/ariza-shakllari"
              className="onlayn-srovnomasi-navlink20 button"
            >
              <span className="onlayn-srovnomasi-text132">
                {' '}
                Сўровномани қўлда тўлдириш
              </span>
            </Link>
            <a
              href="https://videx.diplo.de/videx/visum-erfassung/videx-kurzfristiger-aufenthalt"
              target="_blank"
              rel="noreferrer noopener"
              className="onlayn-srovnomasi-link1 button"
            >
              <span className="onlayn-srovnomasi-text133">
                {' '}
                Онлайн сўровнома Шенген (Videx)
              </span>
            </a>
            <a
              href="https://videx.diplo.de/videx/visum-erfassung/videx-langfristiger-aufenthalt"
              target="_blank"
              rel="noreferrer noopener"
              className="onlayn-srovnomasi-link2 button"
            >
              <span className="onlayn-srovnomasi-text134">
                {' '}
                Онлайн сўровнома Миллий (Videx)
              </span>
            </a>
          </div>
        </div>
      </div>
      <div id="header" className="onlayn-srovnomasi-container29">
        <Link to="/" className="onlayn-srovnomasi-navlink21">
          <div className="onlayn-srovnomasi-container30">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="onlayn-srovnomasi-image18"
            />
            <p className="onlayn-srovnomasi-text135">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="onlayn-srovnomasi-container31">
          <div className="onlayn-srovnomasi-container32">
            <span className="onlayn-srovnomasi-text138">
              <span>Apply for Visa to</span>
              <span className="onlayn-srovnomasi-text140"> Germany</span>
            </span>
            <span className="onlayn-srovnomasi-text141">
              <span className="onlayn-srovnomasi-text142">in Uzbekistan</span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="onlayn-srovnomasi-image19"
          />
          <span className="onlayn-srovnomasi-text173">|</span>
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header
        data-thq="thq-navbar"
        className="onlayn-srovnomasi-navbar-interactive"
      >
        <div
          data-thq="thq-navbar-nav"
          className="onlayn-srovnomasi-desktop-menu"
        >
          <div className="onlayn-srovnomasi-container33">
            <nav className="onlayn-srovnomasi-links1">
              <div
                data-thq="thq-dropdown"
                className="onlayn-srovnomasi-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="onlayn-srovnomasi-dropdown-toggle10"
                  >
                    <span className="onlayn-srovnomasi-text174">
                      Бош сахифа
                    </span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="onlayn-srovnomasi-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="onlayn-srovnomasi-dropdown-toggle11"
                >
                  <span className="onlayn-srovnomasi-text175">Умумий</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="onlayn-srovnomasi-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="onlayn-srovnomasi-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle12"
                      >
                        <span className="onlayn-srovnomasi-text176 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle13"
                      >
                        <span className="onlayn-srovnomasi-text179 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle14"
                      >
                        <span className="onlayn-srovnomasi-text180 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle15"
                      >
                        <span className="onlayn-srovnomasi-text181 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle16"
                      >
                        <span className="onlayn-srovnomasi-text186 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle17"
                      >
                        <span className="onlayn-srovnomasi-text187 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle18"
                      >
                        <span className="onlayn-srovnomasi-text188 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle19"
                      >
                        <span className="onlayn-srovnomasi-text192 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="onlayn-srovnomasi-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="onlayn-srovnomasi-dropdown-toggle20"
                >
                  <span className="onlayn-srovnomasi-text193">Виза олиш</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="onlayn-srovnomasi-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="onlayn-srovnomasi-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle21"
                      >
                        <span className="onlayn-srovnomasi-text194">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle22"
                      >
                        <span className="onlayn-srovnomasi-text195">
                          Миллий виза
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle23"
                      >
                        <span className="onlayn-srovnomasi-text196">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle24"
                      >
                        <span className="onlayn-srovnomasi-text197">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle25"
                      >
                        <span className="onlayn-srovnomasi-text200">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle26"
                      >
                        <span className="onlayn-srovnomasi-text204">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle27"
                      >
                        <span className="onlayn-srovnomasi-text205">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="onlayn-srovnomasi-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="onlayn-srovnomasi-dropdown-toggle28"
                >
                  <span className="onlayn-srovnomasi-text206">
                    Кушимча хизматлар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="onlayn-srovnomasi-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="onlayn-srovnomasi-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle29"
                      >
                        <span className="onlayn-srovnomasi-text207">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle30"
                      >
                        <span className="onlayn-srovnomasi-text208">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle31"
                      >
                        <span className="onlayn-srovnomasi-text212">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle32"
                      >
                        <span className="onlayn-srovnomasi-text215">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle33"
                      >
                        <span className="onlayn-srovnomasi-text216">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle34"
                      >
                        <span className="onlayn-srovnomasi-text220">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle35"
                      >
                        <span className="onlayn-srovnomasi-text221">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="onlayn-srovnomasi-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="onlayn-srovnomasi-dropdown-toggle38"
                  >
                    <span className="onlayn-srovnomasi-text224">FAQ</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="onlayn-srovnomasi-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="onlayn-srovnomasi-dropdown-toggle39"
                >
                  <span className="onlayn-srovnomasi-text225">Алокалар</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="onlayn-srovnomasi-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="onlayn-srovnomasi-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle40"
                      >
                        <span className="onlayn-srovnomasi-text226">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle41"
                      >
                        <span className="onlayn-srovnomasi-text227">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle42"
                      >
                        <span className="onlayn-srovnomasi-text228">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="onlayn-srovnomasi-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="onlayn-srovnomasi-dropdown-toggle43"
                      >
                        <span className="onlayn-srovnomasi-text229">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="onlayn-srovnomasi-container34">
          <input
            type="text"
            placeholder="Кидирмок"
            className="onlayn-srovnomasi-textinput1 input"
          />
          <div className="onlayn-srovnomasi-container35">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="onlayn-srovnomasi-icon20"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="onlayn-srovnomasi-container36">
            <span className="onlayn-srovnomasi-text232">A+</span>
            <span className="onlayn-srovnomasi-text233">A-</span>
          </div>
        </div>
        <div
          data-thq="thq-burger-menu"
          className="onlayn-srovnomasi-burger-menu2"
        >
          <svg viewBox="0 0 1024 1024" className="onlayn-srovnomasi-icon22">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div
          data-thq="thq-mobile-menu"
          className="onlayn-srovnomasi-mobile-menu"
        >
          <div className="onlayn-srovnomasi-nav">
            <div className="onlayn-srovnomasi-container37">
              <div className="onlayn-srovnomasi-container38">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="onlayn-srovnomasi-textinput2 input"
                />
                <div className="onlayn-srovnomasi-container39">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="onlayn-srovnomasi-icon24"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="onlayn-srovnomasi-container40">
                  <span className="onlayn-srovnomasi-text234">A+</span>
                  <span className="onlayn-srovnomasi-text235">A-</span>
                </div>
              </div>
              <div
                data-thq="thq-close-menu"
                className="onlayn-srovnomasi-close-menu"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="onlayn-srovnomasi-icon26"
                >
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="onlayn-srovnomasi-links2">
            <div
              data-thq="thq-dropdown"
              className="onlayn-srovnomasi-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="onlayn-srovnomasi-dropdown-toggle44"
                >
                  <span className="onlayn-srovnomasi-text236">Бош сахифа</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="onlayn-srovnomasi-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="onlayn-srovnomasi-dropdown-toggle45"
              >
                <span className="onlayn-srovnomasi-text237">Умумий</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="onlayn-srovnomasi-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="onlayn-srovnomasi-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="onlayn-srovnomasi-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle46"
                    >
                      <span className="onlayn-srovnomasi-text238">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle47"
                    >
                      <span className="onlayn-srovnomasi-text241">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle48"
                    >
                      <span className="onlayn-srovnomasi-text242">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle49"
                    >
                      <span className="onlayn-srovnomasi-text243">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle50"
                    >
                      <span className="onlayn-srovnomasi-text248">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle51"
                    >
                      <span className="onlayn-srovnomasi-text249">
                        Тулов Шартлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle52"
                    >
                      <span className="onlayn-srovnomasi-text250">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle53"
                    >
                      <span className="onlayn-srovnomasi-text253">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="onlayn-srovnomasi-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="onlayn-srovnomasi-dropdown-toggle54"
              >
                <span className="onlayn-srovnomasi-text254">Виза олиш</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="onlayn-srovnomasi-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="onlayn-srovnomasi-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="onlayn-srovnomasi-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle55"
                    >
                      <span className="onlayn-srovnomasi-text255">
                        Шенген визаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle56"
                    >
                      <span className="onlayn-srovnomasi-text256">
                        Миллий виза
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle57"
                    >
                      <span className="onlayn-srovnomasi-text257">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle58"
                    >
                      <span className="onlayn-srovnomasi-text258">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle59"
                    >
                      <span className="onlayn-srovnomasi-text261">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle60"
                    >
                      <span className="onlayn-srovnomasi-text265">
                        Ариза шакллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle61"
                    >
                      <span className="onlayn-srovnomasi-text266">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="onlayn-srovnomasi-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="onlayn-srovnomasi-dropdown-toggle62"
              >
                <span className="onlayn-srovnomasi-text267">
                  Кушимча хизматлар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="onlayn-srovnomasi-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="onlayn-srovnomasi-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="onlayn-srovnomasi-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle63"
                    >
                      <span className="onlayn-srovnomasi-text268">
                        VIP - хизмати
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle64"
                    >
                      <span className="onlayn-srovnomasi-text269">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle65"
                    >
                      <span className="onlayn-srovnomasi-text270">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle66"
                    >
                      <span className="onlayn-srovnomasi-text271">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle67"
                    >
                      <span className="onlayn-srovnomasi-text272">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle68"
                    >
                      <span className="onlayn-srovnomasi-text275">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle69"
                    >
                      <span className="onlayn-srovnomasi-text278">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle70"
                    >
                      <span className="onlayn-srovnomasi-text279">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle71"
                    >
                      <span className="onlayn-srovnomasi-text280">
                        Prime Тиме
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="onlayn-srovnomasi-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="onlayn-srovnomasi-dropdown-toggle72"
                >
                  <span className="onlayn-srovnomasi-text281">FAQ</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="onlayn-srovnomasi-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="onlayn-srovnomasi-dropdown-toggle73"
              >
                <span className="onlayn-srovnomasi-text282">Алокалар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="onlayn-srovnomasi-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="onlayn-srovnomasi-icon34"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="onlayn-srovnomasi-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle74"
                    >
                      <span className="onlayn-srovnomasi-text283">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle75"
                    >
                      <span className="onlayn-srovnomasi-text284">
                        Алока маркази
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle76"
                    >
                      <span className="onlayn-srovnomasi-text285">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="onlayn-srovnomasi-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="onlayn-srovnomasi-dropdown-toggle77"
                    >
                      <span className="onlayn-srovnomasi-text286">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="onlayn-srovnomasi-container41">
        <div className="onlayn-srovnomasi-container42">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="onlayn-srovnomasi-image21"
          />
          <span className="onlayn-srovnomasi-text289">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="onlayn-srovnomasi-container43">
          <div className="onlayn-srovnomasi-container44">
            <span className="onlayn-srovnomasi-text290">
              Ходимларни бошқариш
            </span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="onlayn-srovnomasi-image22"
            />
          </div>
          <div className="onlayn-srovnomasi-container45">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="onlayn-srovnomasi-navlink82"
            >
              <div className="onlayn-srovnomasi-container46">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="onlayn-srovnomasi-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="onlayn-srovnomasi-text291">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="onlayn-srovnomasi-navlink83"
            >
              <div className="onlayn-srovnomasi-container47">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="onlayn-srovnomasi-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="onlayn-srovnomasi-text292">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="onlayn-srovnomasi-navlink84"
            >
              <div className="onlayn-srovnomasi-container48">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="onlayn-srovnomasi-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="onlayn-srovnomasi-text296">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="onlayn-srovnomasi-navlink85"
            >
              <div className="onlayn-srovnomasi-container49">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="onlayn-srovnomasi-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="onlayn-srovnomasi-text300">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="onlayn-srovnomasi-navlink86"
            >
              <div className="onlayn-srovnomasi-container50">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="onlayn-srovnomasi-icon44"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="onlayn-srovnomasi-text304">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="onlayn-srovnomasi-container51">
        <div className="onlayn-srovnomasi-container52">
          <span className="onlayn-srovnomasi-text308">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="onlayn-srovnomasi-container53">
            <Link
              to="/visametric-gurukhi"
              className="onlayn-srovnomasi-navlink87"
            >
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="onlayn-srovnomasi-navlink88"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/w3c-logo2-200h.png"
          className="onlayn-srovnomasi-image23"
        />
        <a href="#main" className="onlayn-srovnomasi-link3">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="onlayn-srovnomasi-image24"
          />
        </a>
      </div>
    </div>
  )
}

export default OnlaynSrovnomasi
