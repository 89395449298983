import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './ariza-topshirishdan-snggi-olat.css'

const ArizaTopshirishdanSnggiOlat = (props) => {
  return (
    <div className="ariza-topshirishdan-snggi-olat-container10">
      <Helmet>
        <title>ariza-topshirishdan-snggi-olat - Visametric</title>
        <meta
          property="og:title"
          content="ariza-topshirishdan-snggi-olat - Visametric"
        />
      </Helmet>
      <div
        data-thq="thq-burger-menu"
        className="ariza-topshirishdan-snggi-olat-burger-menu1"
      >
        <svg
          viewBox="0 0 1024 1024"
          className="ariza-topshirishdan-snggi-olat-icon10"
        >
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div id="main" className="ariza-topshirishdan-snggi-olat-container11">
        <h1 className="ariza-topshirishdan-snggi-olat-text100">
          Ариза топширишдан сўнгги ҳолат
        </h1>
        <div className="ariza-topshirishdan-snggi-olat-container12">
          <Link
            to="/uzbekistan-germanyuz"
            className="ariza-topshirishdan-snggi-olat-navlink10"
          >
            <div className="ariza-topshirishdan-snggi-olat-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="ariza-topshirishdan-snggi-olat-icon12"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="ariza-topshirishdan-snggi-olat-text101">
                Бош саҳифа
              </h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="ariza-topshirishdan-snggi-olat-navlink11"
          >
            <div className="ariza-topshirishdan-snggi-olat-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="ariza-topshirishdan-snggi-olat-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="ariza-topshirishdan-snggi-olat-text102">FAQ</h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="ariza-topshirishdan-snggi-olat-navlink12"
          >
            <div className="ariza-topshirishdan-snggi-olat-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="ariza-topshirishdan-snggi-olat-icon16"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="ariza-topshirishdan-snggi-olat-text103">
                Ариза топширишдан сўнгги ҳолат
              </h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="ariza-topshirishdan-snggi-olat-container16">
        <div className="ariza-topshirishdan-snggi-olat-container17">
          <div className="ariza-topshirishdan-snggi-olat-container18">
            <span className="ariza-topshirishdan-snggi-olat-text104">FAQ</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="ariza-topshirishdan-snggi-olat-icon18"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="ariza-topshirishdan-snggi-olat-image10"
          />
          <div className="ariza-topshirishdan-snggi-olat-container19">
            <Link
              to="/umumiy-malumot"
              className="ariza-topshirishdan-snggi-olat-navlink13"
            >
              <div className="ariza-topshirishdan-snggi-olat-container20">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-topshirishdan-snggi-olat-image11"
                />
                <span className="ariza-topshirishdan-snggi-olat-text105">
                  Умумий маълумот
                </span>
              </div>
            </Link>
            <Link
              to="/ariza-oldin"
              className="ariza-topshirishdan-snggi-olat-navlink14"
            >
              <div className="ariza-topshirishdan-snggi-olat-container21">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-topshirishdan-snggi-olat-image12"
                />
                <span className="ariza-topshirishdan-snggi-olat-text106">
                  Ариза олдин
                </span>
              </div>
            </Link>
            <Link
              to="/ariza-topshirish-zharaeni"
              className="ariza-topshirishdan-snggi-olat-navlink15"
            >
              <div className="ariza-topshirishdan-snggi-olat-container22">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-topshirishdan-snggi-olat-image13"
                />
                <span className="ariza-topshirishdan-snggi-olat-text107">
                  Ариза топшириш жараёни
                </span>
              </div>
            </Link>
            <Link
              to="/uzhzhatlar"
              className="ariza-topshirishdan-snggi-olat-navlink16"
            >
              <div className="ariza-topshirishdan-snggi-olat-container23">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-topshirishdan-snggi-olat-image14"
                />
                <span className="ariza-topshirishdan-snggi-olat-text108">
                  Ҳужжатлар
                </span>
              </div>
            </Link>
            <Link
              to="/shimcha-khizmatlar"
              className="ariza-topshirishdan-snggi-olat-navlink17"
            >
              <div className="ariza-topshirishdan-snggi-olat-container24">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-topshirishdan-snggi-olat-image15"
                />
                <span className="ariza-topshirishdan-snggi-olat-text109">
                  Қўшимча хизматлар
                </span>
              </div>
            </Link>
            <Link
              to="/ariza-topshirishdan-snggi-olat"
              className="ariza-topshirishdan-snggi-olat-navlink18"
            >
              <div className="ariza-topshirishdan-snggi-olat-container25">
                <img
                  alt="image"
                  src="/rectangle%2030.svg"
                  className="ariza-topshirishdan-snggi-olat-image16"
                />
                <span className="ariza-topshirishdan-snggi-olat-text110">
                  Ариза топширишдан сўнгги ҳолат
                </span>
              </div>
            </Link>
          </div>
        </div>
        <div className="ariza-topshirishdan-snggi-olat-container26">
          <span className="ariza-topshirishdan-snggi-olat-text111">
            1. Узбекистон / FAQ / Ариза топширишдан сўнгги ҳолат
          </span>
          <span className="ariza-topshirishdan-snggi-olat-text112">
            <span className="ariza-topshirishdan-snggi-olat-text113">
              Бошқа Шенген давлатларига немис визаси билан ташриф буюришим
              мумкинми?
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text114"></br>
            <br className="ariza-topshirishdan-snggi-olat-text115"></br>
            <span>
              Ҳа, виза муддати давомида Шенген визаси барча Шенген давлатларида
              бўлиш имконини беради. Бошқа Шенген мамлакатларига саёҳат қилишни
              истасангиз, энг узоқ вақт қайси давлатда бўлишингизни аниқлаб ўша
              мамлакат визасига ариза топширишингиз керак. Шенген ҳамкорлик
              ташкилоти доирасида Тошкентдаги Германия Элчихонаси - Германия,
              Финландия ва Швеция давлатларига виза мурожаатларини кўриб чиқади.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text117"></br>
            <br className="ariza-topshirishdan-snggi-olat-text118"></br>
            <br className="ariza-topshirishdan-snggi-olat-text119"></br>
            <span className="ariza-topshirishdan-snggi-olat-text120">
              Виза кодексида кўрсатилган қўшимча 15 кунлик визани қандай олишим
              мумкин?
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text121"></br>
            <br className="ariza-topshirishdan-snggi-olat-text122"></br>
            <span>
              Виза кодексининг 24-моддаси 1-бандига асосан, тақдим қилинган
              тиббий суғурта муддатига асосан, виза амал қилиш муддати қўшимча
              15 кунлик даврни ўз ичига олади. У фақат бир марталик визалар учун
              амал қилади: Масалан:
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text124"></br>
            <br className="ariza-topshirishdan-snggi-olat-text125"></br>
            <span>
              Сиз 05.06.2017 дан 15.06.2017 гача (11 кун) бир марталик кириш
              саёҳатини режалаштирмоқдасиз. Сиз 2017 йил 5 июндан дан 30 июнгача
              бўлган 26 кундан ортиқ тиббий суғурта расмийлаштиришингиз лозим.
              Кейин элчихона сизга бир марталик кириш яъни 26 кунлик 05.06.2017
              дан 30.06.2017 гача бўлган визани тақдим қилади. Шундай қилиб,
              саёҳатингизни шу даврга режалаштиришингиз мумкин.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text127"></br>
            <br className="ariza-topshirishdan-snggi-olat-text128"></br>
            <span>
              Шуни эътиборга олингки, тақдим этилган тиббий суғуртанинг амал
              қилиш муддатига асосан бир марталик кириш виза берилади.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text130"></br>
            <br className="ariza-topshirishdan-snggi-olat-text131"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text133"></br>
            <span className="ariza-topshirishdan-snggi-olat-text134">
              Менинг аризам рад қилинди, ҳозир нима қилишим мумкин?
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text135"></br>
            <br className="ariza-topshirishdan-snggi-olat-text136"></br>
            <span>
              Агар сиз элчихона қарорини нотўғри деб ҳисобласангиз, фикрингизни
              ёзма равишда тушунтириб беришингиз ва ҳужжатларингизни қайта кўриб
              чиқилишини сўрашингиз мумкин.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text138"></br>
            <br className="ariza-topshirishdan-snggi-olat-text139"></br>
            <span>
              Паспортингизни қабул қилиб олганингиздан сўнг, элчихона томонидан
              аризангиз рад этилиш сабаби кўрсатилган қисқача бир неча сўз
              орқали ифодаланган ёзма тушунтириш хатини оласиз. Илтимос, виза
              рад қилинишига жавобан аппеляция орқали кўриб чиқилишини сўранг.
              Апелляцияни амалга оширилиши бир неча ҳафтага чўзилиши мумкин.
              Элчихона Сизнинг апелляциянгизга жавоб қайтаради ва аризани қайта
              кўриб чиқади.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text141"></br>
            <br className="ariza-topshirishdan-snggi-olat-text142"></br>
            <span>
              Апелляция Немис тилида ёзилган бўлиши зарур. Апелляция асосида
              топширилган ҳужжатлар таржима қилинган бўлиши керак.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text144"></br>
            <br className="ariza-topshirishdan-snggi-olat-text145"></br>
            <span>
              Агар элчихона Сизнинг ҳужжатларингизни маъқул деб топса, Сизга
              виза берилиши мумкин.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text147"></br>
            <br className="ariza-topshirishdan-snggi-olat-text148"></br>
            <span>
              Акс ҳолда, батафсил ёзма тушунтириш хатини почта орқали оласиз.
              Жавобдан қониқмасангиз, бир ой ичида Берлиндаги Маъмурий судга
              даъво киритишингиз мумкин. Ҳар қандай ҳолатда ҳам тушунтириш
              олишни кутинг.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text150"></br>
            <br className="ariza-topshirishdan-snggi-olat-text151"></br>
            <span>
              Бошқа алтернатив вариант сифатида янги ариза топширишингиз ва
              олинги рад этилиш сабабларига эътибор қаратиб, ёзма равишда буни
              тушунтиришингиз мумкин.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text153"></br>
            <br className="ariza-topshirishdan-snggi-olat-text154"></br>
            <br className="ariza-topshirishdan-snggi-olat-text155"></br>
            <span className="ariza-topshirishdan-snggi-olat-text156">
              Апелляция берилгандан кейинги қайта кўриб чиқиш қанча вақтни
              олади?
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text157"></br>
            <br className="ariza-topshirishdan-snggi-olat-text158"></br>
            <span>
              Апелляция мурожаатлар сана бўйича кўриб чиқилади. Виза бўлими иш
              ҳажмига кўра аризангиз кўриб чиқилиши бир неча ҳафтадан, бир неча
              ойгача чўзилиши мумкин. Элчихонада аппеляция мурожаатларини кўриб
              чиқиш учун аниқ муддатлар мавжуд эмас.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text160"></br>
            <br className="ariza-topshirishdan-snggi-olat-text161"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text163"></br>
            <span className="ariza-topshirishdan-snggi-olat-text164">
              Менинг аризам рад этилди, бунга Германиядаги қабул қилувчи томон
              нима қилиб билади?
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text165"></br>
            <br className="ariza-topshirishdan-snggi-olat-text166"></br>
            <span>
              Сиз, ҳамда қабул қилувчи томон элчихонага раддияни
              тушунтириш/қайта кўриб чиқиш бўйича сўровнома юборишингиз мумкин.
              Бу вазиятда, паспорт олишдаги юқорида келтириб ўтилган сабабларни
              ҳисобга олишингиз керак бўлади. Таклиф қилувчи томон Сиз
              томонингиздан имзоланган, қонуний тасдиқланган ҳужжатни тақдим
              этиши керак, бу Сиз томонингиздан виза олиш жараёни тўғрисидаги
              маълумотни олиш имконини беради.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text168"></br>
            <br className="ariza-topshirishdan-snggi-olat-text169"></br>
            <span>
              Агар элчихона Сизни таклиф қилувчи шахснинг тушунтириш хатидан
              қониқмаган тақдирда, таклиф қилувчи шахс почта орқали элчихонанинг
              батафсил тушунтирилган ёзма жавоб хатини олади. Ушбу ёзма
              тушунтириш хати Берлин Маъмурий судига тақдим қилиниши мумкин.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text171"></br>
            <br className="ariza-topshirishdan-snggi-olat-text172"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text174"></br>
            <span className="ariza-topshirishdan-snggi-olat-text175">
              Рад этилиши сабабини тушунаман, лекин мен барибир Германияга
              бормоқчиман. Қайта ариза топширишим мумкинми?
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text176"></br>
            <br className="ariza-topshirishdan-snggi-olat-text177"></br>
            <span>
              Ҳар доим виза олишнинг иккинчи аризасини топширишингиз мумкин.
              Қабул санасини аниқлаш ва рўйхатга киритилган барча ҳужжатларни
              тайёрлаш. Аппеляция берган бўлсангиз, аппеляция аризангизнинг
              қайта кўриб чиқилиши янги ариза топширилишидан сўнг тўхтатилади.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text179"></br>
            <br className="ariza-topshirishdan-snggi-olat-text180"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text182"></br>
            <span className="ariza-topshirishdan-snggi-olat-text183">
              Виза олиш учун топширган аризамга рад жавобини олганман. Паспортда
              фақат элчихона муҳри бор. Бу нимани англатади?
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text184"></br>
            <br className="ariza-topshirishdan-snggi-olat-text185"></br>
            <span>
              Паспортдаги муҳр элчихонадаги визага ариза берганлигингизни
              англатади. Муҳр кейинги аризангизга тўсқинлик қила олмайди.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text187"></br>
            <br className="ariza-topshirishdan-snggi-olat-text188"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text190"></br>
            <span className="ariza-topshirishdan-snggi-olat-text191">
              Менинг қўлимда расмий таклифнома мавжуд, таклифномада барча
              молиявий харажатларим қопланиши кўрсатилган. Шунга қарамасдан,
              қайтиб келишимга шубҳа туфайли виза олишга рад жавоби берилди.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text192"></br>
            <br className="ariza-topshirishdan-snggi-olat-text193"></br>
            <span>
              Лекин мен, расмий таклифномага кўра бу шахс Германияни тарк
              этишига кафолат бераман.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text195"></br>
            <br className="ariza-topshirishdan-snggi-olat-text196"></br>
            <span>
              Расмий таклифнома фақатгина аризачининг Германияда молиявий кўллаб
              қувватланишини кафолатлайди. Ариза берувчининг қайтиб келишини
              кафолатлаш мумкин эмас. Бу масала фақатгина аризачининг шахси
              тўғрисидаги маълумотлар текширилгандан сўнг ҳал қилиниши мумкин.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text198"></br>
            <br className="ariza-topshirishdan-snggi-olat-text199"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text201"></br>
            <span className="ariza-topshirishdan-snggi-olat-text202">
              Виза рад этилганда 80 евро миқдоридаги пул қайтариладими?
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text203"></br>
            <br className="ariza-topshirishdan-snggi-olat-text204"></br>
            <span>
              Виза берилиши қарорига боғлиқ бўлмаган ушбу виза йиғими тўловлари
              давлат аппаратини сақлаш харажатлари нормасига киради. Агар виза
              аризаси рад этилса, тўловлар қайтарилмайди.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text206"></br>
            <br className="ariza-topshirishdan-snggi-olat-text207"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text209"></br>
            <span className="ariza-topshirishdan-snggi-olat-text210">
              Аризам тезроқ кўриб чиқилиши учун кўпроқ миқдордаги тўловни амалга
              оширишим мумкинми?
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text211"></br>
            <br className="ariza-topshirishdan-snggi-olat-text212"></br>
            <span>
              Йўқ, бунинг иложи йўқ. Белгиланган қонунга кўра, VisaMetric
              кўрсатилгандан ортиқча тўловларни қабул қилолмайди.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text214"></br>
            <br className="ariza-topshirishdan-snggi-olat-text215"></br>
            <span>
              Қўшимча тўловлар фақат белгиланган қўшимча хизматлар учун амалга
              оширилади.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text217"></br>
            <br className="ariza-topshirishdan-snggi-olat-text218"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text220"></br>
            <span className="ariza-topshirishdan-snggi-olat-text221">
              Виза олишим имконини қандай аниқлайман?
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text222"></br>
            <br className="ariza-topshirishdan-snggi-olat-text223"></br>
            <span>
              Бунга кафолат йўқ. VisaMetric нинг зарурий ҳамма ҳужжатларини
              тақдим қилсангиз ҳам, аризангиз қаноатлантирилмаслиги эҳтимоли
              бор. Германия элчихонасидаги қарор айнан бир ҳолатга асосан қабул
              қилинади, шунинг учун унга олдиндан таъсир ўтказиб бўлмайди.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text225"></br>
            <br className="ariza-topshirishdan-snggi-olat-text226"></br>
            <span>
              Элчихона вакили инструкцияда кўрсатилмаган қўшимча ҳужжатларни
              тақдим этишимни сўради.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text228"></br>
            <br className="ariza-topshirishdan-snggi-olat-text229"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text231"></br>
            <span className="ariza-topshirishdan-snggi-olat-text232">
              Нима учун бу ҳужжатларни тақдим этишим шарт ва нимага ариза
              топшириш жараёнида тўғридан тўғри огоҳлантирилмаганман?
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text233"></br>
            <br className="ariza-topshirishdan-snggi-olat-text234"></br>
            <span>
              Баъзида элчихона тақдим этилган ҳужжатлар асосида қарор қабул
              қилолмаса, қўшимча ҳужжатларни сўраш ҳуқуқига эга. Бу ҳужжатларни
              сўраш қарори аризани қабул қилган ходимга боғлиқ эмас. Бошқача
              қилиб айтганда, бу ҳақида хабардор қилинган бўлар эдингиз.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text236"></br>
            <br className="ariza-topshirishdan-snggi-olat-text237"></br>
            <br className="ariza-topshirishdan-snggi-olat-text238"></br>
            <span className="ariza-topshirishdan-snggi-olat-text239">
              Визамда хатолик топдим. Нима килишим керак бўлади?
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text240"></br>
            <br className="ariza-topshirishdan-snggi-olat-text241"></br>
            <span>Паспортни олган заҳоти визани текширинг, хато ва</span>
            <br className="ariza-topshirishdan-snggi-olat-text243"></br>
            <br className="ariza-topshirishdan-snggi-olat-text244"></br>
            <span>камчиликлар аниқланганда хабар беринг.</span>
            <br className="ariza-topshirishdan-snggi-olat-text246"></br>
            <br className="ariza-topshirishdan-snggi-olat-text247"></br>
            <span>
              Кеч аниқланган хатоликни ўзгартиришнинг имкони йук ёки қўшимча
              тўлов эвазига ўзгартириш мумкин бўлади.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text249"></br>
            <br className="ariza-topshirishdan-snggi-olat-text250"></br>
            <span>
              Агар визангизда хатолик аниқланса илтимос, VisaMetric офисига
              мурожат қилинг.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text252"></br>
            <br className="ariza-topshirishdan-snggi-olat-text253"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text255"></br>
            <span className="ariza-topshirishdan-snggi-olat-text256">
              Йиллик виза олдим лекин кўрсатилган кунларга – “90” қўйилган ва
              кириш маълумотида “MULT” қўйилган. Бу нимани англатади?
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text257"></br>
            <br className="ariza-topshirishdan-snggi-olat-text258"></br>
            <span>
              Бу виза Шенген давлатлари зонасида йиллик олти ой давомида 90 кун
              яшашингиз мумкинлигини билдиради. Шу билан бир вақтда, бу
              давлатларга бир мартадан ортиқ кириб чиқишингиз мумкин. Ярим
              йилликнинг тегишли даври биринчи кириш кунингиздан бошланади. Олти
              ойда ишлатмаган кунларингизни кейинги ярим йилга ўтказа олмайсиз,
              90 кунни сарфлаганингиздан сўнг давлатни тарк этасиз ва янги ярим
              йиллик бошланмасидан қайта олмайсиз.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text260"></br>
            <br className="ariza-topshirishdan-snggi-olat-text261"></br>
            <span>
              Қолган яшаш даврингиз ҳақида амин бўлмасангиз, ахборот бўлимига
              паспортингиз билан мурожаат қилишингиз зарур.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text263"></br>
            <br className="ariza-topshirishdan-snggi-olat-text264"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text266"></br>
            <span className="ariza-topshirishdan-snggi-olat-text267">
              Мен (масалан 01.05 дан 01.06 гача) виза олдим. Визада кўрсатилган
              кунлар миқдори-&quot;15&quot;, мамлакатга киришлар сонида эса
              &quot;01&quot; қўйилган. Бу нимани англатади?
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text268"></br>
            <br className="ariza-topshirishdan-snggi-olat-text269"></br>
            <span>
              Бу визадан фойдаланиб, Шенген давлатлари ҳудудига фақат бир марта
              кириб, 01.05 дан 01.06 гача бўлган муддат оралиғида Германияда 15
              кунга қолишингиз мумкинлигини англатади.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text271"></br>
            <br className="ariza-topshirishdan-snggi-olat-text272"></br>
            <span>Мен мамлакатга бир марталик кириш учун виза олганман.</span>
            <br className="ariza-topshirishdan-snggi-olat-text274"></br>
            <br className="ariza-topshirishdan-snggi-olat-text275"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text277"></br>
            <span className="ariza-topshirishdan-snggi-olat-text278">
              Шенген давлатлари ҳудудида саёҳат қилсам бўладими, ёки Шенген
              ҳудудининг фақат бир давлатида қолишим керакми?
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text279"></br>
            <br className="ariza-topshirishdan-snggi-olat-text280"></br>
            <span>
              Бир марталик кириш учун виза фақат Шенген ҳудудидаги биринчи
              ташрифга тааллуқлидир. Германиядан чиқиб бошқа Шенген давлати
              ҳудудига кириб, яна қайтиб Германияга келсангиз, бундай ҳолат
              Шенген ҳудудидан чиқган деб ҳисобланмайди.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text282"></br>
            <br className="ariza-topshirishdan-snggi-olat-text283"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text285"></br>
            <span className="ariza-topshirishdan-snggi-olat-text286">
              Мен йиллик визани олдим ва биринчи ярим йилликнинг 50 кунини
              хизмат сафари бўйича Германияда ўтказдим. Меҳнат таътили даврининг
              14 кунини Испанияда ўтказсам бўладими?
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text287"></br>
            <br className="ariza-topshirishdan-snggi-olat-text288"></br>
            <span>
              Шенген визаси бутун Шенген ҳудуди давлатлари учун амал қилади.
              Лекин Сиз кўпроқ вақтингизни Сизга виза берилган Шенген давлатида
              сарфлашингиз керак, қолган вақт исталган Шенген давлатларида
              сарфланиши мумкин.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text290"></br>
            <br className="ariza-topshirishdan-snggi-olat-text291"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text293"></br>
            <span className="ariza-topshirishdan-snggi-olat-text294">
              Мен Германия элчихонаси томонидан 15 кунлик Шенген визасини олдим
              ва шундан 10 кунимни Германияда ўтказмоқчи эдим. Шунингдек, қолган
              5 кунни Италияда ўтказмоқчиман. Бунинг иложи борми?
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text295"></br>
            <br className="ariza-topshirishdan-snggi-olat-text296"></br>
            <span>
              Умуман олганда, ҳа. Асосий борадиган жойингиз Германия бўлишига
              қарамасдан, биринчи кириш жойингиз бошқа Шенген давлатларида
              амалга оширилиши мумкин. Бироқ, шубҳа уйғонганда биринчи кириш
              давлатининг чегара бошқармалари кейинчалик Сизга виза берган
              давлатга ўтиб кетишингизни тасдиқлашингизни сўрашади. Буни
              исботлаб беролмасангиз, чегарани кесиб ўтишингизга рухсат
              берилмаслиги мумкин.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text298"></br>
            <br className="ariza-topshirishdan-snggi-olat-text299"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text301"></br>
            <span className="ariza-topshirishdan-snggi-olat-text302">
              Мен 15 кунлик Шенген визасини олдим ва ҳозир Германиядаман. Бу
              ерда узоқроқ муддатга қолсам бўладими? Бунинг учун нима қилишим
              зарур?
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text303"></br>
            <br className="ariza-topshirishdan-snggi-olat-text304"></br>
            <span>
              Зудлик билан тегишли чет эл фуқаролари билан ишлаш органига
              мурожаат қилиб виза муддатини узайтиришни сўранг. Муддати ўтган
              резидентлик карточкаси ёки муддати ўтган виза Германияда яшаш
              ҳуқуқини суистеъмол қилишдир ва натижада жарима, ҳатто киришга
              таъқиққа айланиши мумкин.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text306"></br>
            <br className="ariza-topshirishdan-snggi-olat-text307"></br>
            <span>
              Бундан ташқари, сизнинг визангиз Германия элчихонаси томонидан
              берилмаган бўлса ёки Шенген давлати ҳудудида яшамаётган бўлсангиз,
              тегишли чет эл фуқаролари билан ишлаш органига мурожаат қилишингиз
              зарур.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text309"></br>
            <br className="ariza-topshirishdan-snggi-olat-text310"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text312"></br>
            <span className="ariza-topshirishdan-snggi-olat-text313">
              Хозиргача амалдаги Шенген визасига эгаман. Лекин паспортимда бўш
              варақлар йўқ ва мен янги паспорт учун ариза беришим керак, визамга
              нима бўлади?
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text314"></br>
            <br className="ariza-topshirishdan-snggi-olat-text315"></br>
            <span>
              Паспортингизни бўш варақлари тугаган бўлса ҳам, ҳозирги
              паспортдаги визангиз яроқли ҳисобланади. Шунингдек, сиз 2 та
              паспорт билан муаммосиз саёҳат қилишингиз мумкин. Акс ҳолда,
              Visametric виза маркази офисларига янги ва эски паспортларингиз
              билан мурожаат қилишингиз мумкин.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text317"></br>
            <br className="ariza-topshirishdan-snggi-olat-text318"></br>
            <br className="ariza-topshirishdan-snggi-olat-text319"></br>
            <span className="ariza-topshirishdan-snggi-olat-text320">
              Нима учун менинг виза аризамга рад жавоби берилди?
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text321"></br>
            <br className="ariza-topshirishdan-snggi-olat-text322"></br>
            <span>
              Сизнинг аризангиз рад этилишининг сабаблари кўрсатилган мактуб
              конверт ичида паспортингиз билан бириктирилган бўлади. Сизнинг
              виза аризангиз, барча тегишли ҳужжатларни тақдим этган бўлсангиз
              ҳам рад этилиши мумкин. Виза олиш учун барча керакли ҳужжатларнинг
              тақдим этилганлиги, виза берилишини кафолатламайди.
            </span>
            <br className="ariza-topshirishdan-snggi-olat-text324"></br>
            <br className="ariza-topshirishdan-snggi-olat-text325"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
        </div>
      </div>
      <div id="header" className="ariza-topshirishdan-snggi-olat-container27">
        <Link to="/" className="ariza-topshirishdan-snggi-olat-navlink19">
          <div className="ariza-topshirishdan-snggi-olat-container28">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="ariza-topshirishdan-snggi-olat-image17"
            />
            <p className="ariza-topshirishdan-snggi-olat-text327">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="ariza-topshirishdan-snggi-olat-container29">
          <div className="ariza-topshirishdan-snggi-olat-container30">
            <span className="ariza-topshirishdan-snggi-olat-text330">
              <span>Apply for Visa to</span>
              <span className="ariza-topshirishdan-snggi-olat-text332">
                {' '}
                Germany
              </span>
            </span>
            <span className="ariza-topshirishdan-snggi-olat-text333">
              <span className="ariza-topshirishdan-snggi-olat-text334">
                in Uzbekistan
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="ariza-topshirishdan-snggi-olat-image18"
          />
          <span className="ariza-topshirishdan-snggi-olat-text365">|</span>
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header
        data-thq="thq-navbar"
        className="ariza-topshirishdan-snggi-olat-navbar-interactive"
      >
        <div
          data-thq="thq-navbar-nav"
          className="ariza-topshirishdan-snggi-olat-desktop-menu"
        >
          <div className="ariza-topshirishdan-snggi-olat-container31">
            <nav className="ariza-topshirishdan-snggi-olat-links1">
              <div
                data-thq="thq-dropdown"
                className="ariza-topshirishdan-snggi-olat-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="ariza-topshirishdan-snggi-olat-dropdown-toggle10"
                  >
                    <span className="ariza-topshirishdan-snggi-olat-text366">
                      Бош сахифа
                    </span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ariza-topshirishdan-snggi-olat-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-topshirishdan-snggi-olat-dropdown-toggle11"
                >
                  <span className="ariza-topshirishdan-snggi-olat-text367">
                    Умумий
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="ariza-topshirishdan-snggi-olat-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="ariza-topshirishdan-snggi-olat-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle12"
                      >
                        <span className="ariza-topshirishdan-snggi-olat-text368 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle13"
                      >
                        <span className="ariza-topshirishdan-snggi-olat-text371 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle14"
                      >
                        <span className="ariza-topshirishdan-snggi-olat-text372 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle15"
                      >
                        <span className="ariza-topshirishdan-snggi-olat-text373 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle16"
                      >
                        <span className="ariza-topshirishdan-snggi-olat-text378 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle17"
                      >
                        <span className="ariza-topshirishdan-snggi-olat-text379 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle18"
                      >
                        <span className="ariza-topshirishdan-snggi-olat-text380 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle19"
                      >
                        <span className="ariza-topshirishdan-snggi-olat-text384 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ariza-topshirishdan-snggi-olat-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-topshirishdan-snggi-olat-dropdown-toggle20"
                >
                  <span className="ariza-topshirishdan-snggi-olat-text385">
                    Виза олиш
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="ariza-topshirishdan-snggi-olat-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="ariza-topshirishdan-snggi-olat-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle21"
                      >
                        <span className="ariza-topshirishdan-snggi-olat-text386">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle22"
                      >
                        <span className="ariza-topshirishdan-snggi-olat-text387">
                          Миллий виза
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle23"
                      >
                        <span className="ariza-topshirishdan-snggi-olat-text388">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle24"
                      >
                        <span className="ariza-topshirishdan-snggi-olat-text389">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle25"
                      >
                        <span className="ariza-topshirishdan-snggi-olat-text392">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle26"
                      >
                        <span className="ariza-topshirishdan-snggi-olat-text396">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle27"
                      >
                        <span className="ariza-topshirishdan-snggi-olat-text397">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ariza-topshirishdan-snggi-olat-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-topshirishdan-snggi-olat-dropdown-toggle28"
                >
                  <span className="ariza-topshirishdan-snggi-olat-text398">
                    Кушимча хизматлар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="ariza-topshirishdan-snggi-olat-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="ariza-topshirishdan-snggi-olat-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle29"
                      >
                        <span className="ariza-topshirishdan-snggi-olat-text399">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle30"
                      >
                        <span className="ariza-topshirishdan-snggi-olat-text400">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle31"
                      >
                        <span className="ariza-topshirishdan-snggi-olat-text404">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle32"
                      >
                        <span className="ariza-topshirishdan-snggi-olat-text407">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle33"
                      >
                        <span className="ariza-topshirishdan-snggi-olat-text408">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle34"
                      >
                        <span className="ariza-topshirishdan-snggi-olat-text412">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle35"
                      >
                        <span className="ariza-topshirishdan-snggi-olat-text413">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ariza-topshirishdan-snggi-olat-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="ariza-topshirishdan-snggi-olat-dropdown-toggle38"
                  >
                    <span className="ariza-topshirishdan-snggi-olat-text416">
                      FAQ
                    </span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ariza-topshirishdan-snggi-olat-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-topshirishdan-snggi-olat-dropdown-toggle39"
                >
                  <span className="ariza-topshirishdan-snggi-olat-text417">
                    Алокалар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="ariza-topshirishdan-snggi-olat-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="ariza-topshirishdan-snggi-olat-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle40"
                      >
                        <span className="ariza-topshirishdan-snggi-olat-text418">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle41"
                      >
                        <span className="ariza-topshirishdan-snggi-olat-text419">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle42"
                      >
                        <span className="ariza-topshirishdan-snggi-olat-text420">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirishdan-snggi-olat-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirishdan-snggi-olat-dropdown-toggle43"
                      >
                        <span className="ariza-topshirishdan-snggi-olat-text421">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="ariza-topshirishdan-snggi-olat-container32">
          <input
            type="text"
            placeholder="Кидирмок"
            className="ariza-topshirishdan-snggi-olat-textinput1 input"
          />
          <div className="ariza-topshirishdan-snggi-olat-container33">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="ariza-topshirishdan-snggi-olat-icon20"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="ariza-topshirishdan-snggi-olat-container34">
            <span className="ariza-topshirishdan-snggi-olat-text424">A+</span>
            <span className="ariza-topshirishdan-snggi-olat-text425">A-</span>
          </div>
        </div>
        <div
          data-thq="thq-burger-menu"
          className="ariza-topshirishdan-snggi-olat-burger-menu2"
        >
          <svg
            viewBox="0 0 1024 1024"
            className="ariza-topshirishdan-snggi-olat-icon22"
          >
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div
          data-thq="thq-mobile-menu"
          className="ariza-topshirishdan-snggi-olat-mobile-menu"
        >
          <div className="ariza-topshirishdan-snggi-olat-nav">
            <div className="ariza-topshirishdan-snggi-olat-container35">
              <div className="ariza-topshirishdan-snggi-olat-container36">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="ariza-topshirishdan-snggi-olat-textinput2 input"
                />
                <div className="ariza-topshirishdan-snggi-olat-container37">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="ariza-topshirishdan-snggi-olat-icon24"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="ariza-topshirishdan-snggi-olat-container38">
                  <span className="ariza-topshirishdan-snggi-olat-text426">
                    A+
                  </span>
                  <span className="ariza-topshirishdan-snggi-olat-text427">
                    A-
                  </span>
                </div>
              </div>
              <div
                data-thq="thq-close-menu"
                className="ariza-topshirishdan-snggi-olat-close-menu"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="ariza-topshirishdan-snggi-olat-icon26"
                >
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="ariza-topshirishdan-snggi-olat-links2">
            <div
              data-thq="thq-dropdown"
              className="ariza-topshirishdan-snggi-olat-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-topshirishdan-snggi-olat-dropdown-toggle44"
                >
                  <span className="ariza-topshirishdan-snggi-olat-text428">
                    Бош сахифа
                  </span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ariza-topshirishdan-snggi-olat-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="ariza-topshirishdan-snggi-olat-dropdown-toggle45"
              >
                <span className="ariza-topshirishdan-snggi-olat-text429">
                  Умумий
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="ariza-topshirishdan-snggi-olat-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="ariza-topshirishdan-snggi-olat-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="ariza-topshirishdan-snggi-olat-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle46"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text430">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle47"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text433">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle48"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text434">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle49"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text435">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle50"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text440">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle51"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text441">
                        Тулов Шартлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle52"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text442">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle53"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text445">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ariza-topshirishdan-snggi-olat-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="ariza-topshirishdan-snggi-olat-dropdown-toggle54"
              >
                <span className="ariza-topshirishdan-snggi-olat-text446">
                  Виза олиш
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="ariza-topshirishdan-snggi-olat-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="ariza-topshirishdan-snggi-olat-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="ariza-topshirishdan-snggi-olat-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle55"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text447">
                        Шенген визаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle56"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text448">
                        Миллий виза
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle57"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text449">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle58"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text450">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle59"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text453">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle60"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text457">
                        Ариза шакллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle61"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text458">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ariza-topshirishdan-snggi-olat-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="ariza-topshirishdan-snggi-olat-dropdown-toggle62"
              >
                <span className="ariza-topshirishdan-snggi-olat-text459">
                  Кушимча хизматлар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="ariza-topshirishdan-snggi-olat-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="ariza-topshirishdan-snggi-olat-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="ariza-topshirishdan-snggi-olat-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle63"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text460">
                        VIP - хизмати
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle64"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text461">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle65"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text462">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle66"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text463">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle67"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text464">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle68"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text467">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle69"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text470">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle70"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text471">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle71"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text472">
                        Prime Тиме
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ariza-topshirishdan-snggi-olat-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-topshirishdan-snggi-olat-dropdown-toggle72"
                >
                  <span className="ariza-topshirishdan-snggi-olat-text473">
                    FAQ
                  </span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ariza-topshirishdan-snggi-olat-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="ariza-topshirishdan-snggi-olat-dropdown-toggle73"
              >
                <span className="ariza-topshirishdan-snggi-olat-text474">
                  Алокалар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="ariza-topshirishdan-snggi-olat-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="ariza-topshirishdan-snggi-olat-icon34"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="ariza-topshirishdan-snggi-olat-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle74"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text475">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle75"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text476">
                        Алока маркази
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle76"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text477">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirishdan-snggi-olat-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirishdan-snggi-olat-dropdown-toggle77"
                    >
                      <span className="ariza-topshirishdan-snggi-olat-text478">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="ariza-topshirishdan-snggi-olat-container39">
        <div className="ariza-topshirishdan-snggi-olat-container40">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="ariza-topshirishdan-snggi-olat-image20"
          />
          <span className="ariza-topshirishdan-snggi-olat-text481">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="ariza-topshirishdan-snggi-olat-container41">
          <div className="ariza-topshirishdan-snggi-olat-container42">
            <span className="ariza-topshirishdan-snggi-olat-text482">
              Ходимларни бошқариш
            </span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="ariza-topshirishdan-snggi-olat-image21"
            />
          </div>
          <div className="ariza-topshirishdan-snggi-olat-container43">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="ariza-topshirishdan-snggi-olat-navlink80"
            >
              <div className="ariza-topshirishdan-snggi-olat-container44">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ariza-topshirishdan-snggi-olat-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ariza-topshirishdan-snggi-olat-text483">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="ariza-topshirishdan-snggi-olat-navlink81"
            >
              <div className="ariza-topshirishdan-snggi-olat-container45">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ariza-topshirishdan-snggi-olat-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ariza-topshirishdan-snggi-olat-text484">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="ariza-topshirishdan-snggi-olat-navlink82"
            >
              <div className="ariza-topshirishdan-snggi-olat-container46">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ariza-topshirishdan-snggi-olat-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ariza-topshirishdan-snggi-olat-text488">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="ariza-topshirishdan-snggi-olat-navlink83"
            >
              <div className="ariza-topshirishdan-snggi-olat-container47">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ariza-topshirishdan-snggi-olat-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ariza-topshirishdan-snggi-olat-text492">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="ariza-topshirishdan-snggi-olat-navlink84"
            >
              <div className="ariza-topshirishdan-snggi-olat-container48">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ariza-topshirishdan-snggi-olat-icon44"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ariza-topshirishdan-snggi-olat-text496">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="ariza-topshirishdan-snggi-olat-container49">
        <div className="ariza-topshirishdan-snggi-olat-container50">
          <span className="ariza-topshirishdan-snggi-olat-text500">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="ariza-topshirishdan-snggi-olat-container51">
            <Link
              to="/visametric-gurukhi"
              className="ariza-topshirishdan-snggi-olat-navlink85"
            >
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="ariza-topshirishdan-snggi-olat-navlink86"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/w3c-logo2-200h.png"
          className="ariza-topshirishdan-snggi-olat-image22"
        />
        <a href="#main" className="ariza-topshirishdan-snggi-olat-link">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="ariza-topshirishdan-snggi-olat-image23"
          />
        </a>
      </div>
    </div>
  )
}

export default ArizaTopshirishdanSnggiOlat
