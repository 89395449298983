import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './khuzhzhatlar-tarzhimasi.css'

const KhuzhzhatlarTarzhimasi = (props) => {
  return (
    <div className="khuzhzhatlar-tarzhimasi-container10">
      <Helmet>
        <title>khuzhzhatlar-tarzhimasi - Visametric</title>
        <meta
          property="og:title"
          content="khuzhzhatlar-tarzhimasi - Visametric"
        />
      </Helmet>
      <div
        data-thq="thq-burger-menu"
        className="khuzhzhatlar-tarzhimasi-burger-menu1"
      >
        <svg viewBox="0 0 1024 1024" className="khuzhzhatlar-tarzhimasi-icon10">
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div id="main" className="khuzhzhatlar-tarzhimasi-container11">
        <h1 className="khuzhzhatlar-tarzhimasi-text100">Хужжатлар таржимаси</h1>
        <div className="khuzhzhatlar-tarzhimasi-container12">
          <Link
            to="/uzbekistan-germanyuz"
            className="khuzhzhatlar-tarzhimasi-navlink10"
          >
            <div className="khuzhzhatlar-tarzhimasi-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="khuzhzhatlar-tarzhimasi-icon12"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="khuzhzhatlar-tarzhimasi-text101">Бош саҳифа</h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="khuzhzhatlar-tarzhimasi-navlink11"
          >
            <div className="khuzhzhatlar-tarzhimasi-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="khuzhzhatlar-tarzhimasi-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="khuzhzhatlar-tarzhimasi-text102">
                Қўшимча хизматлар
              </h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="khuzhzhatlar-tarzhimasi-navlink12"
          >
            <div className="khuzhzhatlar-tarzhimasi-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="khuzhzhatlar-tarzhimasi-icon16"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="khuzhzhatlar-tarzhimasi-text103">
                Хужжатлар таржимаси
              </h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="khuzhzhatlar-tarzhimasi-container16">
        <div className="khuzhzhatlar-tarzhimasi-container17">
          <div className="khuzhzhatlar-tarzhimasi-container18">
            <span className="khuzhzhatlar-tarzhimasi-text104">
              Қўшимча хизматлар
            </span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="khuzhzhatlar-tarzhimasi-icon18"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="khuzhzhatlar-tarzhimasi-image10"
          />
          <div className="khuzhzhatlar-tarzhimasi-container19">
            <Link
              to="/vip-khizmati"
              className="khuzhzhatlar-tarzhimasi-navlink13"
            >
              <div className="khuzhzhatlar-tarzhimasi-container20">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="khuzhzhatlar-tarzhimasi-image11"
                />
                <span className="khuzhzhatlar-tarzhimasi-text105">
                  VIP - хизмати
                </span>
              </div>
            </Link>
            <Link
              to="/ariza-topshirish-uchun-srovnoma-tldirish"
              className="khuzhzhatlar-tarzhimasi-navlink14"
            >
              <div className="khuzhzhatlar-tarzhimasi-container21">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="khuzhzhatlar-tarzhimasi-image12"
                />
                <span className="khuzhzhatlar-tarzhimasi-text106">
                  Ариза топшириш учун сўровнома тўлдириш
                </span>
              </div>
            </Link>
            <Link
              to="/biometrik-fotosura"
              className="khuzhzhatlar-tarzhimasi-navlink15"
            >
              <div className="khuzhzhatlar-tarzhimasi-container22">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="khuzhzhatlar-tarzhimasi-image13"
                />
                <span className="khuzhzhatlar-tarzhimasi-text107">
                  Биометрик фотосура
                </span>
              </div>
            </Link>
            <Link
              to="/foto-nuskha-faks-chop-etish-khizmatlari"
              className="khuzhzhatlar-tarzhimasi-navlink16"
            >
              <div className="khuzhzhatlar-tarzhimasi-container23">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="khuzhzhatlar-tarzhimasi-image14"
                />
                <span className="khuzhzhatlar-tarzhimasi-text108">
                  Фото нусха, факс, чоп этиш хизматлари
                </span>
              </div>
            </Link>
            <Link
              to="/kurerlik-khizmati-orali-etkazib-berish"
              className="khuzhzhatlar-tarzhimasi-navlink17"
            >
              <div className="khuzhzhatlar-tarzhimasi-container24">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="khuzhzhatlar-tarzhimasi-image15"
                />
                <span className="khuzhzhatlar-tarzhimasi-text109">
                  Курьерлик хизмати орқали етказиб бериш
                </span>
              </div>
            </Link>
            <Link
              to="/khabardor-ilish-khizmati"
              className="khuzhzhatlar-tarzhimasi-navlink18"
            >
              <div className="khuzhzhatlar-tarzhimasi-container25">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="khuzhzhatlar-tarzhimasi-image16"
                />
                <span className="khuzhzhatlar-tarzhimasi-text110">
                  Хабардор қилиш хизмати
                </span>
              </div>
            </Link>
            <Link
              to="/khuzhzhatlar-tarzhimasi"
              className="khuzhzhatlar-tarzhimasi-navlink19"
            >
              <div className="khuzhzhatlar-tarzhimasi-container26">
                <img
                  alt="image"
                  src="/rectangle%2030.svg"
                  className="khuzhzhatlar-tarzhimasi-image17"
                />
                <span className="khuzhzhatlar-tarzhimasi-text111">
                  Хужжатлар таржимаси
                </span>
              </div>
            </Link>
            <Link
              to="/khalaro-tibbiy-suurta"
              className="khuzhzhatlar-tarzhimasi-navlink20"
            >
              <div className="khuzhzhatlar-tarzhimasi-container27">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="khuzhzhatlar-tarzhimasi-image18"
                />
                <span className="khuzhzhatlar-tarzhimasi-text112">
                  Халқаро тиббий суғурта
                </span>
              </div>
            </Link>
            <Link
              to="/prime-time"
              className="khuzhzhatlar-tarzhimasi-navlink21"
            >
              <div className="khuzhzhatlar-tarzhimasi-container28">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="khuzhzhatlar-tarzhimasi-image19"
                />
                <span className="khuzhzhatlar-tarzhimasi-text113">
                  Prime Тиме
                </span>
              </div>
            </Link>
          </div>
        </div>
        <div className="khuzhzhatlar-tarzhimasi-container29">
          <span className="khuzhzhatlar-tarzhimasi-text114">
            <span className="khuzhzhatlar-tarzhimasi-text115">
              Хужжатлар таржимаси ( бир саҳифа 10 евро )
            </span>
            <br className="khuzhzhatlar-tarzhimasi-text116"></br>
            <br className="khuzhzhatlar-tarzhimasi-text117"></br>
            <span>
              Сиз қабулхонамизда ҳужжатларингизни немис тилига таржима
              қилишингиз мумкин.
            </span>
            <br className="khuzhzhatlar-tarzhimasi-text119"></br>
          </span>
        </div>
      </div>
      <div id="header" className="khuzhzhatlar-tarzhimasi-container30">
        <Link to="/" className="khuzhzhatlar-tarzhimasi-navlink22">
          <div className="khuzhzhatlar-tarzhimasi-container31">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="khuzhzhatlar-tarzhimasi-image20"
            />
            <p className="khuzhzhatlar-tarzhimasi-text120">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="khuzhzhatlar-tarzhimasi-container32">
          <div className="khuzhzhatlar-tarzhimasi-container33">
            <span className="khuzhzhatlar-tarzhimasi-text123">
              <span>Apply for Visa to</span>
              <span className="khuzhzhatlar-tarzhimasi-text125"> Germany</span>
            </span>
            <span className="khuzhzhatlar-tarzhimasi-text126">
              <span className="khuzhzhatlar-tarzhimasi-text127">
                in Uzbekistan
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="khuzhzhatlar-tarzhimasi-image21"
          />
          <span className="khuzhzhatlar-tarzhimasi-text158">|</span>
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header
        data-thq="thq-navbar"
        className="khuzhzhatlar-tarzhimasi-navbar-interactive"
      >
        <div
          data-thq="thq-navbar-nav"
          className="khuzhzhatlar-tarzhimasi-desktop-menu"
        >
          <div className="khuzhzhatlar-tarzhimasi-container34">
            <nav className="khuzhzhatlar-tarzhimasi-links1">
              <div
                data-thq="thq-dropdown"
                className="khuzhzhatlar-tarzhimasi-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="khuzhzhatlar-tarzhimasi-dropdown-toggle10"
                  >
                    <span className="khuzhzhatlar-tarzhimasi-text159">
                      Бош сахифа
                    </span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="khuzhzhatlar-tarzhimasi-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="khuzhzhatlar-tarzhimasi-dropdown-toggle11"
                >
                  <span className="khuzhzhatlar-tarzhimasi-text160">
                    Умумий
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="khuzhzhatlar-tarzhimasi-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="khuzhzhatlar-tarzhimasi-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle12"
                      >
                        <span className="khuzhzhatlar-tarzhimasi-text161 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle13"
                      >
                        <span className="khuzhzhatlar-tarzhimasi-text164 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle14"
                      >
                        <span className="khuzhzhatlar-tarzhimasi-text165 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle15"
                      >
                        <span className="khuzhzhatlar-tarzhimasi-text166 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle16"
                      >
                        <span className="khuzhzhatlar-tarzhimasi-text171 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle17"
                      >
                        <span className="khuzhzhatlar-tarzhimasi-text172 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle18"
                      >
                        <span className="khuzhzhatlar-tarzhimasi-text173 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle19"
                      >
                        <span className="khuzhzhatlar-tarzhimasi-text177 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="khuzhzhatlar-tarzhimasi-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="khuzhzhatlar-tarzhimasi-dropdown-toggle20"
                >
                  <span className="khuzhzhatlar-tarzhimasi-text178">
                    Виза олиш
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="khuzhzhatlar-tarzhimasi-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="khuzhzhatlar-tarzhimasi-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle21"
                      >
                        <span className="khuzhzhatlar-tarzhimasi-text179">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle22"
                      >
                        <span className="khuzhzhatlar-tarzhimasi-text180">
                          Миллий виза
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle23"
                      >
                        <span className="khuzhzhatlar-tarzhimasi-text181">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle24"
                      >
                        <span className="khuzhzhatlar-tarzhimasi-text182">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle25"
                      >
                        <span className="khuzhzhatlar-tarzhimasi-text185">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle26"
                      >
                        <span className="khuzhzhatlar-tarzhimasi-text189">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle27"
                      >
                        <span className="khuzhzhatlar-tarzhimasi-text190">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="khuzhzhatlar-tarzhimasi-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="khuzhzhatlar-tarzhimasi-dropdown-toggle28"
                >
                  <span className="khuzhzhatlar-tarzhimasi-text191">
                    Кушимча хизматлар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="khuzhzhatlar-tarzhimasi-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="khuzhzhatlar-tarzhimasi-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle29"
                      >
                        <span className="khuzhzhatlar-tarzhimasi-text192">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle30"
                      >
                        <span className="khuzhzhatlar-tarzhimasi-text193">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle31"
                      >
                        <span className="khuzhzhatlar-tarzhimasi-text197">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle32"
                      >
                        <span className="khuzhzhatlar-tarzhimasi-text200">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle33"
                      >
                        <span className="khuzhzhatlar-tarzhimasi-text201">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle34"
                      >
                        <span className="khuzhzhatlar-tarzhimasi-text205">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle35"
                      >
                        <span className="khuzhzhatlar-tarzhimasi-text206">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="khuzhzhatlar-tarzhimasi-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="khuzhzhatlar-tarzhimasi-dropdown-toggle38"
                  >
                    <span className="khuzhzhatlar-tarzhimasi-text209">FAQ</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="khuzhzhatlar-tarzhimasi-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="khuzhzhatlar-tarzhimasi-dropdown-toggle39"
                >
                  <span className="khuzhzhatlar-tarzhimasi-text210">
                    Алокалар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="khuzhzhatlar-tarzhimasi-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="khuzhzhatlar-tarzhimasi-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle40"
                      >
                        <span className="khuzhzhatlar-tarzhimasi-text211">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle41"
                      >
                        <span className="khuzhzhatlar-tarzhimasi-text212">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle42"
                      >
                        <span className="khuzhzhatlar-tarzhimasi-text213">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="khuzhzhatlar-tarzhimasi-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="khuzhzhatlar-tarzhimasi-dropdown-toggle43"
                      >
                        <span className="khuzhzhatlar-tarzhimasi-text214">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="khuzhzhatlar-tarzhimasi-container35">
          <input
            type="text"
            placeholder="Кидирмок"
            className="khuzhzhatlar-tarzhimasi-textinput1 input"
          />
          <div className="khuzhzhatlar-tarzhimasi-container36">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="khuzhzhatlar-tarzhimasi-icon20"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="khuzhzhatlar-tarzhimasi-container37">
            <span className="khuzhzhatlar-tarzhimasi-text217">A+</span>
            <span className="khuzhzhatlar-tarzhimasi-text218">A-</span>
          </div>
        </div>
        <div
          data-thq="thq-burger-menu"
          className="khuzhzhatlar-tarzhimasi-burger-menu2"
        >
          <svg
            viewBox="0 0 1024 1024"
            className="khuzhzhatlar-tarzhimasi-icon22"
          >
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div
          data-thq="thq-mobile-menu"
          className="khuzhzhatlar-tarzhimasi-mobile-menu"
        >
          <div className="khuzhzhatlar-tarzhimasi-nav">
            <div className="khuzhzhatlar-tarzhimasi-container38">
              <div className="khuzhzhatlar-tarzhimasi-container39">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="khuzhzhatlar-tarzhimasi-textinput2 input"
                />
                <div className="khuzhzhatlar-tarzhimasi-container40">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="khuzhzhatlar-tarzhimasi-icon24"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="khuzhzhatlar-tarzhimasi-container41">
                  <span className="khuzhzhatlar-tarzhimasi-text219">A+</span>
                  <span className="khuzhzhatlar-tarzhimasi-text220">A-</span>
                </div>
              </div>
              <div
                data-thq="thq-close-menu"
                className="khuzhzhatlar-tarzhimasi-close-menu"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="khuzhzhatlar-tarzhimasi-icon26"
                >
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="khuzhzhatlar-tarzhimasi-links2">
            <div
              data-thq="thq-dropdown"
              className="khuzhzhatlar-tarzhimasi-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="khuzhzhatlar-tarzhimasi-dropdown-toggle44"
                >
                  <span className="khuzhzhatlar-tarzhimasi-text221">
                    Бош сахифа
                  </span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="khuzhzhatlar-tarzhimasi-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="khuzhzhatlar-tarzhimasi-dropdown-toggle45"
              >
                <span className="khuzhzhatlar-tarzhimasi-text222">Умумий</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="khuzhzhatlar-tarzhimasi-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="khuzhzhatlar-tarzhimasi-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="khuzhzhatlar-tarzhimasi-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle46"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text223">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle47"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text226">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle48"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text227">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle49"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text228">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle50"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text233">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle51"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text234">
                        Тулов Шартлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle52"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text235">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle53"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text238">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="khuzhzhatlar-tarzhimasi-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="khuzhzhatlar-tarzhimasi-dropdown-toggle54"
              >
                <span className="khuzhzhatlar-tarzhimasi-text239">
                  Виза олиш
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="khuzhzhatlar-tarzhimasi-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="khuzhzhatlar-tarzhimasi-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="khuzhzhatlar-tarzhimasi-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle55"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text240">
                        Шенген визаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle56"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text241">
                        Миллий виза
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle57"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text242">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle58"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text243">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle59"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text246">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle60"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text250">
                        Ариза шакллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle61"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text251">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="khuzhzhatlar-tarzhimasi-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="khuzhzhatlar-tarzhimasi-dropdown-toggle62"
              >
                <span className="khuzhzhatlar-tarzhimasi-text252">
                  Кушимча хизматлар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="khuzhzhatlar-tarzhimasi-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="khuzhzhatlar-tarzhimasi-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="khuzhzhatlar-tarzhimasi-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle63"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text253">
                        VIP - хизмати
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle64"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text254">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle65"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text255">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle66"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text256">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle67"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text257">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle68"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text260">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle69"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text263">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle70"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text264">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle71"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text265">
                        Prime Тиме
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="khuzhzhatlar-tarzhimasi-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="khuzhzhatlar-tarzhimasi-dropdown-toggle72"
                >
                  <span className="khuzhzhatlar-tarzhimasi-text266">FAQ</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="khuzhzhatlar-tarzhimasi-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="khuzhzhatlar-tarzhimasi-dropdown-toggle73"
              >
                <span className="khuzhzhatlar-tarzhimasi-text267">
                  Алокалар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="khuzhzhatlar-tarzhimasi-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="khuzhzhatlar-tarzhimasi-icon34"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="khuzhzhatlar-tarzhimasi-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle74"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text268">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle75"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text269">
                        Алока маркази
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle76"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text270">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="khuzhzhatlar-tarzhimasi-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="khuzhzhatlar-tarzhimasi-dropdown-toggle77"
                    >
                      <span className="khuzhzhatlar-tarzhimasi-text271">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="khuzhzhatlar-tarzhimasi-container42">
        <div className="khuzhzhatlar-tarzhimasi-container43">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="khuzhzhatlar-tarzhimasi-image23"
          />
          <span className="khuzhzhatlar-tarzhimasi-text274">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="khuzhzhatlar-tarzhimasi-container44">
          <div className="khuzhzhatlar-tarzhimasi-container45">
            <span className="khuzhzhatlar-tarzhimasi-text275">
              Ходимларни бошқариш
            </span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="khuzhzhatlar-tarzhimasi-image24"
            />
          </div>
          <div className="khuzhzhatlar-tarzhimasi-container46">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="khuzhzhatlar-tarzhimasi-navlink83"
            >
              <div className="khuzhzhatlar-tarzhimasi-container47">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="khuzhzhatlar-tarzhimasi-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="khuzhzhatlar-tarzhimasi-text276">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="khuzhzhatlar-tarzhimasi-navlink84"
            >
              <div className="khuzhzhatlar-tarzhimasi-container48">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="khuzhzhatlar-tarzhimasi-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="khuzhzhatlar-tarzhimasi-text277">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="khuzhzhatlar-tarzhimasi-navlink85"
            >
              <div className="khuzhzhatlar-tarzhimasi-container49">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="khuzhzhatlar-tarzhimasi-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="khuzhzhatlar-tarzhimasi-text281">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="khuzhzhatlar-tarzhimasi-navlink86"
            >
              <div className="khuzhzhatlar-tarzhimasi-container50">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="khuzhzhatlar-tarzhimasi-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="khuzhzhatlar-tarzhimasi-text285">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="khuzhzhatlar-tarzhimasi-navlink87"
            >
              <div className="khuzhzhatlar-tarzhimasi-container51">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="khuzhzhatlar-tarzhimasi-icon44"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="khuzhzhatlar-tarzhimasi-text289">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="khuzhzhatlar-tarzhimasi-container52">
        <div className="khuzhzhatlar-tarzhimasi-container53">
          <span className="khuzhzhatlar-tarzhimasi-text293">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="khuzhzhatlar-tarzhimasi-container54">
            <Link
              to="/visametric-gurukhi"
              className="khuzhzhatlar-tarzhimasi-navlink88"
            >
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="khuzhzhatlar-tarzhimasi-navlink89"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/w3c-logo2-200h.png"
          className="khuzhzhatlar-tarzhimasi-image25"
        />
        <a href="#main" className="khuzhzhatlar-tarzhimasi-link">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="khuzhzhatlar-tarzhimasi-image26"
          />
        </a>
      </div>
    </div>
  )
}

export default KhuzhzhatlarTarzhimasi
