import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './tekhnika-khavfsizligi-krsatmalari.css'

const TekhnikaKhavfsizligiKrsatmalari = (props) => {
  return (
    <div className="tekhnika-khavfsizligi-krsatmalari-container10">
      <Helmet>
        <title>tekhnika-khavfsizligi-krsatmalari - Visametric</title>
        <meta
          property="og:title"
          content="tekhnika-khavfsizligi-krsatmalari - Visametric"
        />
      </Helmet>
      <div
        data-thq="thq-burger-menu"
        className="tekhnika-khavfsizligi-krsatmalari-burger-menu1"
      >
        <svg
          viewBox="0 0 1024 1024"
          className="tekhnika-khavfsizligi-krsatmalari-icon10"
        >
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div id="main" className="tekhnika-khavfsizligi-krsatmalari-container11">
        <h1 className="tekhnika-khavfsizligi-krsatmalari-text100">
          Техника хавфсизлиги кўрсатмалари
        </h1>
        <div className="tekhnika-khavfsizligi-krsatmalari-container12">
          <Link
            to="/uzbekistan-germanyuz"
            className="tekhnika-khavfsizligi-krsatmalari-navlink10"
          >
            <div className="tekhnika-khavfsizligi-krsatmalari-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="tekhnika-khavfsizligi-krsatmalari-icon12"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="tekhnika-khavfsizligi-krsatmalari-text101">
                Бош саҳифа
              </h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="tekhnika-khavfsizligi-krsatmalari-navlink11"
          >
            <div className="tekhnika-khavfsizligi-krsatmalari-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="tekhnika-khavfsizligi-krsatmalari-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="tekhnika-khavfsizligi-krsatmalari-text102">
                Умумий
              </h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="tekhnika-khavfsizligi-krsatmalari-navlink12"
          >
            <div className="tekhnika-khavfsizligi-krsatmalari-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="tekhnika-khavfsizligi-krsatmalari-icon16"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="tekhnika-khavfsizligi-krsatmalari-text103">
                Техника хавфсизлиги кўрсатмалари
              </h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="tekhnika-khavfsizligi-krsatmalari-container16">
        <div className="tekhnika-khavfsizligi-krsatmalari-container17">
          <div className="tekhnika-khavfsizligi-krsatmalari-container18">
            <span className="tekhnika-khavfsizligi-krsatmalari-text104">
              Умумий
            </span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="tekhnika-khavfsizligi-krsatmalari-icon18"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="tekhnika-khavfsizligi-krsatmalari-image10"
          />
          <div className="tekhnika-khavfsizligi-krsatmalari-container19">
            <Link
              to="/submission-collection"
              className="tekhnika-khavfsizligi-krsatmalari-navlink13"
            >
              <div className="tekhnika-khavfsizligi-krsatmalari-container20">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="tekhnika-khavfsizligi-krsatmalari-image11"
                />
                <span className="tekhnika-khavfsizligi-krsatmalari-text105">
                  Ҳужжатларни топшириш/Ҳужжатларни олиш
                </span>
              </div>
            </Link>
            <Link
              to="/tegishli-malumotlar"
              className="tekhnika-khavfsizligi-krsatmalari-navlink14"
            >
              <div className="tekhnika-khavfsizligi-krsatmalari-container21">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="tekhnika-khavfsizligi-krsatmalari-image12"
                />
                <span className="tekhnika-khavfsizligi-krsatmalari-text106">
                  Тегишли маълумотлар
                </span>
              </div>
            </Link>
            <Link
              to="/rasmiy-bayramlar-kuni"
              className="tekhnika-khavfsizligi-krsatmalari-navlink15"
            >
              <div className="tekhnika-khavfsizligi-krsatmalari-container22">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="tekhnika-khavfsizligi-krsatmalari-image13"
                />
                <span className="tekhnika-khavfsizligi-krsatmalari-text107">
                  Расмий байрамлар куни
                </span>
              </div>
            </Link>
            <Link
              to="/arizalarni-krib-chiish-muddati"
              className="tekhnika-khavfsizligi-krsatmalari-navlink16"
            >
              <div className="tekhnika-khavfsizligi-krsatmalari-container23">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="tekhnika-khavfsizligi-krsatmalari-image14"
                />
                <span className="tekhnika-khavfsizligi-krsatmalari-text108">
                  Аризаларни кўриб чиқиш муддати
                </span>
              </div>
            </Link>
            <Link
              to="/biometrik-malumotlarini"
              className="tekhnika-khavfsizligi-krsatmalari-navlink17"
            >
              <div className="tekhnika-khavfsizligi-krsatmalari-container24">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="tekhnika-khavfsizligi-krsatmalari-image15"
                />
                <span className="tekhnika-khavfsizligi-krsatmalari-text109">
                  биометрик маълумотларини
                </span>
              </div>
            </Link>
            <Link
              to="/tlov-shartlari"
              className="tekhnika-khavfsizligi-krsatmalari-navlink18"
            >
              <div className="tekhnika-khavfsizligi-krsatmalari-container25">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="tekhnika-khavfsizligi-krsatmalari-image16"
                />
                <span className="tekhnika-khavfsizligi-krsatmalari-text110">
                  Тўлов Шартлари
                </span>
              </div>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="tekhnika-khavfsizligi-krsatmalari-navlink19"
            >
              <div className="tekhnika-khavfsizligi-krsatmalari-container26">
                <img
                  alt="image"
                  src="/rectangle%2030.svg"
                  className="tekhnika-khavfsizligi-krsatmalari-image17"
                />
                <span className="tekhnika-khavfsizligi-krsatmalari-text111">
                  Техника хавфсизлиги кўрсатмалари
                </span>
              </div>
            </Link>
            <Link
              to="/foydali-avolalar-uz"
              className="tekhnika-khavfsizligi-krsatmalari-navlink20"
            >
              <div className="tekhnika-khavfsizligi-krsatmalari-container27">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="tekhnika-khavfsizligi-krsatmalari-image18"
                />
                <span className="tekhnika-khavfsizligi-krsatmalari-text112">
                  Фойдали ҳаволалар
                </span>
              </div>
            </Link>
          </div>
        </div>
        <div className="tekhnika-khavfsizligi-krsatmalari-container28">
          <span className="tekhnika-khavfsizligi-krsatmalari-text113">
            <span className="tekhnika-khavfsizligi-krsatmalari-text114">
              Техника хавфсизлиги кўрсатмалари.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <span>
              VisaMetric қабул пунктига келувчилар қуйидаги техник хавфсизлиги
              низомларига риоя қилишлари зарур:
            </span>
            <br></br>
            <br></br>
            <span>
              •
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              Германия ваколатхоналари хавфсизлик қоидаларига асосан қуйидаги
              мослама ва материаллардан фойдаланиш таъқиқланган.
            </span>
            <br></br>
            <br></br>
            <span>
              •
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              Смартфонлар, рақамли камералар ва бошқа фото- ёки овоз ёзиш
              мосламалари.
            </span>
            <br></br>
            <br></br>
            <span>
              •
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              Қурол ёки унга ўхшаш жисмлар, ҳар қандай тез аланга олувчи жисмлар
              (mp3, ноутбук, аудиомагнитофон батареялари билан ёки аналог
              тизимли)
            </span>
            <br></br>
            <span>
              Ёнувчан (гугурт, ёндиргич,ёқилғининг тез ёнувчан турлари) Ва
              кесувчи жисмлар (қайчи, йўнғич, файллар ва ҳоказо)
            </span>
            <br></br>
            <br></br>
            <span>
              •
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>Ҳар қандай қурол ёки қуролга ўхшаш объектлар</span>
            <br></br>
            <br></br>
            <span>• Уй ҳайвонлари (аллергик хавф туфайли)</span>
            <br></br>
            <br></br>
            <span>• Муҳрланган конверт ёки жўнатмалар</span>
            <br></br>
            <br></br>
            <span>• Озиқ овқат ёки ичимликлар</span>
            <br></br>
            <br></br>
            <span>
              •
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              Қўл юкидан катта сумкалар, рюкзаклар и чемоданлар (фақат ариза
              топшириш учун зарур ҳужжатлар папкасини келтиришингиз мумкин)
            </span>
            <br></br>
            <br></br>
            <span>
              * Юқоридаги рўйхат номланган элементлар билан чегараланмайди.
            </span>
            <br></br>
            <br></br>
            <span>
              •
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              Ходим ва қоровуллар ҳар хил предметлик гумондор шахсларга ман
              қилиш ҳуқуқига эгадирлар. Ариза топшириш жараёнининг хоҳлаган
              пайтида хавфсизлик хизмати ходими ариза топширувчининг сумкасини
              текшириш ҳуқуқига эга
            </span>
            <br></br>
            <br></br>
            <span>
              • Хавфсизлик юзасидан ариза топшириш жараёнида ариза топширувчи
              дўстлари, қариндошлари ёки ҳамкасблари билан бирга бўлиши
              ножоиздир. (Фақатгина ариза топширувчилар ва уларнинг
              вакилларигина VisaMetric филиалларига кириши мумкин). Жисмоний
              камчилик ёки саводсиз одам билан бирга фақатгина бир киши бирга
              бўлиши мумкин.
            </span>
            <br></br>
            <br></br>
            <span>
              • Ариза берувчи шахслар филиалларимизда фақатгина умумий
              майдонларда бўлишлари мумкин (иш жойлари, ҳожатхона, ва ҳ.к да
              эмас). Юқорида кўрсатиб ўтилган предметларни сақлаш жойи
              VisaMetric да мавжуд эмас. Шунинг учун ариза топширувчилардан ушбу
              предметларини келишдан олдин бошқа жойда қолдиришларини сўраймиз.
            </span>
            <br></br>
            <br></br>
            <span>
              •
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              Хавфсизлик сиёсатимизга биноан VisaMetric филиаллари суткасига 24
              соат камералар назорати остида бўлади.
            </span>
            <br></br>
          </span>
          <span className="tekhnika-khavfsizligi-krsatmalari-text168">
            <span className="tekhnika-khavfsizligi-krsatmalari-text169">
              Шахсий маълумотлар даҳлсизлиги ва ҳимоя тизими
            </span>
            <br></br>
            <br></br>
            <br></br>
            <span>
              Ушбу шахсий маълумотларнинг маҳфийлигини ва ҳимоясини («Директив»)
              ўз ичига олади.
            </span>
            <br></br>
            <br></br>
            <span>
              •
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              Visa Metric веб-сайти орқали олинган шахсий маълумотлар турлари ,
              келгусидаги мобил интернет иловалар ва бошқа воситалар орқали олиш
              имкониятига эга бўласиз.
            </span>
            <br></br>
            <br></br>
            <span>
              •
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              Ушбу шахсий маълумотлар қандай ва қанақа мақсадда қўлланилганлиги,
            </span>
            <br></br>
            <br></br>
            <span>
              • VisaMetric ушбу шахсий маълумотларингизни улашиш имкониятига
              эгалиги.
            </span>
            <br></br>
            <br></br>
            <span>
              • Шахсий маълумотларнинг хафсизлиги борасида қандай чора-тадбирлар
              кўрилаётганлиги.
            </span>
            <br></br>
            <br></br>
            <span>
              • VisaMetric даги шахсий маълумотларга бўлган хуқуқингиз ва ушбу
              хуқуқлардан қандай фойдаланишингиз.
            </span>
            <br></br>
            <br></br>
            <span>
              • Сизнинг шахсий маълумотларингиз қанча муддатгача сақланиши.
            </span>
            <br></br>
            <br></br>
            <span>• Махфийлик маълумотлари.</span>
            <br></br>
            <br></br>
            <br></br>
            <span>
              VISAMETRIC GROUP сизнинг шахсий маълумотларингизни олдиндан
              розиликсиз ёки суд қарорисиз учинчи шахсларга кўрсатиш,ижарага
              бериш ёки сотиш учун тақдим қилишга кафолат бермайди. Сизнинг
              шахсий маълумотларингиз жуда эҳтиёткорлик билан сақланади.
            </span>
            <br></br>
            <br></br>
            <span className="tekhnika-khavfsizligi-krsatmalari-text203">
              Маълумотларни ҳимоя қилиш
            </span>
            <br></br>
            <br></br>
            <span>
              VisaMetric Group сизнинг шахсий маълумотларингизни олдиндан
              розиликсиз ёки суд қарорисиз учунчи шахсларга хар қандай шахсий
              маълумотни тақдим этиш,намойиш қилиш, ижарага бериш ёки сотишни
              кафолатламайди.
            </span>
            <br></br>
            <br></br>
            <span>
              Шахсий маълумотларни ҳимоя қилиш тўғрисидаги маълумотлар
              VisaMetric сифатида биз шахсий маълумотларингиз хафсизлиги нуқтаи
              назаридан ишончингизни таминлаймиз.
            </span>
            <br></br>
            <br></br>
            <span>
              Қонун ҳужжатларида белгиланганидек, маълумотларни текширувчи
              сифатида бизнинг хуқуқий мажбуриятларимизга тўлиқ мос келади,
              шахсий маълумотларингизни қуйида кўрсатилган тартибда ва низомда
              қайта ишлашимиз мумкин.
            </span>
            <br></br>
            <br></br>
            <span className="tekhnika-khavfsizligi-krsatmalari-text215">
              1. Шахсий маълумотларни қайта ишлаш ва тўплаш.
            </span>
            <br></br>
            <br></br>
            <span>
              Сизнинг шахсий маълумотларингиз VisaMetric хизматлари ёки бизнес
              операцияларига боғлиқ равишда ўзгариши мумкин, улар VisaMetric
              бўлинмалари ва филиаллари, вебсайти ижтимоий тармоқлар мобил
              иловалар ва шунга ўхшаш воситалар орқали автоматик ёки
              автоматлашган жараёнлар орқали амалга оширилиши мумкин.
            </span>
            <br></br>
            <br></br>
            <span>
              VisaMetric хизматларидан фойдаланганда сизнинг шахсий
              маълумотларингиз тўпланиши, янгиланиши ва қайта ишланиши мумкин.
            </span>
            <br></br>
            <br></br>
            <span>
              Бундан ташқари, веб-сайтимиз орқали VisaMetric хизматларини
              кўзатиш ёки VisaMetric офисларига ташриф буюриш ёки VisaMetric
              томонидан ташкилаштирилган тадбирларда иштирок этиш учун бизнинг
              Call Center дан ёки веб сайтимиздан фойдалансангиз, сизнинг шахсий
              маълумотларингиз қайта ишланиши мумкин.
            </span>
            <br></br>
            <br></br>
            <span>
              Сиз тўпланган шахсий маълумотларингиз жараённинг бир қисми
              сифатида кўриб чиқилади, шунинг учун VisaMetric томонидан таклиф
              этилган хизматлардан фойдаланишингиз учун қуйидаги жараёнларни
              амалга оширади, VisaMetric хизматлари сизнинг эҳтиёжларингизни
              қондириш учун хизматларни таклиф қилади. VisaMetric бизнес ва
              юридик шахсларнинг юридик ва бизнес хавфсизлигини таъминлаш;
              VisaMetric бизнес ва операцион стратегияларини белгилаш ва амалга
              ошириш ҳамда кадрлар сиёсатини йўлга қўйишни таъминлашдир.
            </span>
            <br></br>
            <br></br>
            <span className="tekhnika-khavfsizligi-krsatmalari-text230">
              2. Шахсий маълумотларни тўплаш ва қайта ишлаш. «Шахсий
              маълумотлар» атамаси.
            </span>
            <br></br>
            <br></br>
            <span>
              Ушбу Директивда фойдаланилган «Шахсий маълумотлар» атамаси,
              масалан қўйидагилар исм, фамилия, туғилган санаси, фуқаролиги,
              паспортнинг амал қилиш муддати, паспорт серия рақами, тарк этиш
              санаси, қайтиб келиш санаси, телефон рақами, мобил телефон рақами,
              электрон манзили, яшаш манзили ва бошқалар.
            </span>
            <br></br>
            <br></br>
            <span>
              VisaMetric офисининг тегишли давлат элчихоналари ва
              консулликларига ариза ва сўровлар асосида қўшимча маълумотлар
              тақдим этиш хуқуқи химояланган.
            </span>
            <br></br>
            <br></br>
            <span className="tekhnika-khavfsizligi-krsatmalari-text239">
              3. Кимга ва қандай мақсадларда қайта кўриб чиқилган шахсий
              маълумотлар тақдим қилиниши мумкин.
            </span>
            <br></br>
            <br></br>
            <span>
              Сизнинг шахсий маълумотларингиз бизнинг бизнес
              ҳамкорларимизга,етқазиб берувчиларга,агентликларга,VisaMetric
              агентларига,ходимларга,давлат идоралари ва муассасаларига шахсий
              маълумотларни қайта қўриб чиқиш шартларига мувофиқ равишда
              топширилиши мумкин,шунинг учун VisaMetric томонидан таклиф
              қилинган хизмат турларидан фойдаланишингиз учун керакли
              жараёнларни амалга ошириши мумкин,VisaMetric хизмат турлари
              сизнинг талабларингизни қондириш учун таклиф қилинади. Шундай
              қилиб, VisaMetric бизнес ва юридик органларининг ва компания
              акциядорларининг, жамоат ташкилотларининг хуқуқи ва бизнес
              хавфсизлиги таъминланади. Ушбу шахсий маълумотлар маълум мақсад ва
              амалларни бажариш учунгина юқоридаги органларга етқазиб берилиши
              мумкин.
            </span>
            <br></br>
            <br></br>
            <span className="tekhnika-khavfsizligi-krsatmalari-text245">
              4. Шахсий маълумотларни тўплаш тартиби ва хуқуқий асослари.
            </span>
            <br></br>
            <br></br>
            <span>
              Сизнинг шахсий маълумотларингиз юқорида кўрсатилган мақсадларга
              мувофиқ оғзаки, ёзма ёки электрон тарзда тўплади, VisaMetric ўз
              хизматларини хуқуқий асосларга мувофиқ ўрнатилган тартибда таклиф
              қилади. VisaMetric шартномага асосан ўз мажбуриятларига кўра
              жавобгар хисобланади ва қонунда кўрсатилганидек тўлиқ бажарилади.
              Юқоридаги мақсадларга мувофиқ топланган шахсий маълумотлар қайта
              ишланиши ёки текширувларни амалга ошириш учун топширилиши мумкин.
            </span>
            <br></br>
            <br></br>
            <span className="tekhnika-khavfsizligi-krsatmalari-text251">
              5. Шахсий маълумотларни сақлаш.
            </span>
            <br className="tekhnika-khavfsizligi-krsatmalari-text252"></br>
            <br></br>
            <span>
              VisaMetric филиаллари, веб-сайти, ижтимои тармоқлар, мобил алоқа
              иловалари орқали мурожаат этувчилар томонидан тўпланга, оғзаки,
              ёзма ёки электрон шаклидаги шахсий маълумотлар фақатгина тегишли
              қонун-қоидалар билан белгиланган бўлиб, шахсий маълумотларни қайта
              ишлаш учун зарур бўлган муддатни таъминлайди. Сақланган
              маълумотлар ўчириб юборилади, бекор қилинади ёки маълумотларни
              қайта ишлаш жараёни тугаллагандан сўнг анонимлаштирилади.
            </span>
            <br></br>
            <br></br>
            <span className="tekhnika-khavfsizligi-krsatmalari-text257">
              6. Шахсий маълумотларни ҳимоя қилиш чоралари.
            </span>
            <br></br>
            <br></br>
            <span>
              VisaMetric ариза берувчилар ва шахсий маълумотларни қайта ишланган
              барча жисмоний шахсларга тегишли бўлган шахсий маълумотларни
              рухсатсиз фойдаланишга қарши барча зарур техник ва маъмурий
              чораларни ўз зиммасига олади, маълумотларни йўқотиш, нотўғри
              фойдаланиш, ошкор қилиш, ўзгартириш билан боғлиқ равишда маъмурий
              чоралар қабул қилишга мажбурдир.
            </span>
            <br></br>
            <br></br>
            <span>
              VisaMetric шахсий маълумотларни ҳимоя қилиш учун барча керакли
              чора тадбирларни ўз зиммасига олсада, фавқулотда вазиятларда мисол
              учун маълумотлар сақлаш тизимида носозликлар келиб чиқиши,
              фирибгарлар ҳужуми, зилзилалар, сув тошқинлари, ёнғин ва х.к
              лардан, Серверга жиддий зарар етқазилганда ёки форс — мажор
              ҳолатларида сервер тизимида маълумотлар ҳимоя қилишни ўз зиммасига
              олмайди.
            </span>
            <br></br>
            <br></br>
            <span className="tekhnika-khavfsizligi-krsatmalari-text266">
              7. VisaMetric-айни пайтда шахсий маълумотларингизни аниқлигини ва
              жорий холатини сақлашнишини таъминлайди.
            </span>
            <br></br>
            <br></br>
            <span>
              VisaMetric компаниясига ва филиалларига тақдим қилинган шахсий
              маълумотларнинг жавобгарлигини, веб — сайт ва ижтимоий
              тармоқлардаги, мобил иловалар орқали ва шунга ўхшаш манбалар
              орқали ёки оғзаки, ёзма ва электрон шаклда тақдим қилинган шахсий
              маълумотларингизни нотўғри ёки ўз вақтида бажарилмаганлигини ариза
              асосида VisaMetric қайта кўриб чиқиши мумкин.
            </span>
            <br></br>
            <br></br>
            <span className="tekhnika-khavfsizligi-krsatmalari-text272">
              8. Шахсий маълумотларнинг махфийлиги ва ҳимояси бўйича Директивга
              ўзгартиришлар киритилган.
            </span>
            <br></br>
            <br></br>
            <span>
              VisaMetricнинг ушбу сиёсатга ўзгартириш хуқуқи хар доим
              ҳимояланган. VisaMetric сиёсатида ўзгартириш киритилгандан сўнг
              VisaMetric веб-сайтида эълон қилингандан сўнг дархол кучга киради.
              Шу сабабли, аризачиларнинг шахсий маълумотлари қабул қилингандан
              сўнг хар бир шахс ушбу сиёсатни ўзгартиришлар киритилганлигини
              мунтазам равишда кўзатиб бораши тахмин қилинади.
            </span>
            <br></br>
            <br></br>
            <span className="tekhnika-khavfsizligi-krsatmalari-text278">
              9. Амалдаги қонун, ваколатли суд ва хуқуқни мухофаза қилиш
              органлари.
            </span>
            <br></br>
            <br></br>
            <span>
              Ўзбекистон суд органлари ва ижро этувчи органлар Директивни амалга
              ошириш билан боғлиқ бўлган низоларни хал этишга хақлидир.
            </span>
            <br></br>
            <br></br>
            <span className="tekhnika-khavfsizligi-krsatmalari-text284">
              VISAMETRIC GROUP
            </span>
            <br></br>
          </span>
        </div>
      </div>
      <div
        id="header"
        className="tekhnika-khavfsizligi-krsatmalari-container29"
      >
        <Link to="/" className="tekhnika-khavfsizligi-krsatmalari-navlink21">
          <div className="tekhnika-khavfsizligi-krsatmalari-container30">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="tekhnika-khavfsizligi-krsatmalari-image19"
            />
            <p className="tekhnika-khavfsizligi-krsatmalari-text286">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="tekhnika-khavfsizligi-krsatmalari-container31">
          <div className="tekhnika-khavfsizligi-krsatmalari-container32">
            <span className="tekhnika-khavfsizligi-krsatmalari-text289">
              <span>Apply for Visa to</span>
              <span className="tekhnika-khavfsizligi-krsatmalari-text291">
                {' '}
                Germany
              </span>
            </span>
            <span className="tekhnika-khavfsizligi-krsatmalari-text292">
              <span className="tekhnika-khavfsizligi-krsatmalari-text293">
                in Uzbekistan
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="tekhnika-khavfsizligi-krsatmalari-image20"
          />
          <span className="tekhnika-khavfsizligi-krsatmalari-text324">|</span>
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header
        data-thq="thq-navbar"
        className="tekhnika-khavfsizligi-krsatmalari-navbar-interactive"
      >
        <div
          data-thq="thq-navbar-nav"
          className="tekhnika-khavfsizligi-krsatmalari-desktop-menu"
        >
          <div className="tekhnika-khavfsizligi-krsatmalari-container33">
            <nav className="tekhnika-khavfsizligi-krsatmalari-links1">
              <div
                data-thq="thq-dropdown"
                className="tekhnika-khavfsizligi-krsatmalari-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle10"
                  >
                    <span className="tekhnika-khavfsizligi-krsatmalari-text325">
                      Бош сахифа
                    </span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="tekhnika-khavfsizligi-krsatmalari-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle11"
                >
                  <span className="tekhnika-khavfsizligi-krsatmalari-text326">
                    Умумий
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle12"
                      >
                        <span className="tekhnika-khavfsizligi-krsatmalari-text327 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle13"
                      >
                        <span className="tekhnika-khavfsizligi-krsatmalari-text330 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle14"
                      >
                        <span className="tekhnika-khavfsizligi-krsatmalari-text331 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle15"
                      >
                        <span className="tekhnika-khavfsizligi-krsatmalari-text332 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle16"
                      >
                        <span className="tekhnika-khavfsizligi-krsatmalari-text337 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle17"
                      >
                        <span className="tekhnika-khavfsizligi-krsatmalari-text338 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle18"
                      >
                        <span className="tekhnika-khavfsizligi-krsatmalari-text339 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle19"
                      >
                        <span className="tekhnika-khavfsizligi-krsatmalari-text343 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="tekhnika-khavfsizligi-krsatmalari-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle20"
                >
                  <span className="tekhnika-khavfsizligi-krsatmalari-text344">
                    Виза олиш
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle21"
                      >
                        <span className="tekhnika-khavfsizligi-krsatmalari-text345">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle22"
                      >
                        <span className="tekhnika-khavfsizligi-krsatmalari-text346">
                          Миллий виза
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle23"
                      >
                        <span className="tekhnika-khavfsizligi-krsatmalari-text347">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle24"
                      >
                        <span className="tekhnika-khavfsizligi-krsatmalari-text348">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle25"
                      >
                        <span className="tekhnika-khavfsizligi-krsatmalari-text351">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle26"
                      >
                        <span className="tekhnika-khavfsizligi-krsatmalari-text355">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle27"
                      >
                        <span className="tekhnika-khavfsizligi-krsatmalari-text356">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="tekhnika-khavfsizligi-krsatmalari-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle28"
                >
                  <span className="tekhnika-khavfsizligi-krsatmalari-text357">
                    Кушимча хизматлар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle29"
                      >
                        <span className="tekhnika-khavfsizligi-krsatmalari-text358">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle30"
                      >
                        <span className="tekhnika-khavfsizligi-krsatmalari-text359">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle31"
                      >
                        <span className="tekhnika-khavfsizligi-krsatmalari-text363">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle32"
                      >
                        <span className="tekhnika-khavfsizligi-krsatmalari-text366">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle33"
                      >
                        <span className="tekhnika-khavfsizligi-krsatmalari-text367">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle34"
                      >
                        <span className="tekhnika-khavfsizligi-krsatmalari-text371">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle35"
                      >
                        <span className="tekhnika-khavfsizligi-krsatmalari-text372">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="tekhnika-khavfsizligi-krsatmalari-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle38"
                  >
                    <span className="tekhnika-khavfsizligi-krsatmalari-text375">
                      FAQ
                    </span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="tekhnika-khavfsizligi-krsatmalari-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle39"
                >
                  <span className="tekhnika-khavfsizligi-krsatmalari-text376">
                    Алокалар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle40"
                      >
                        <span className="tekhnika-khavfsizligi-krsatmalari-text377">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle41"
                      >
                        <span className="tekhnika-khavfsizligi-krsatmalari-text378">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle42"
                      >
                        <span className="tekhnika-khavfsizligi-krsatmalari-text379">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tekhnika-khavfsizligi-krsatmalari-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle43"
                      >
                        <span className="tekhnika-khavfsizligi-krsatmalari-text380">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="tekhnika-khavfsizligi-krsatmalari-container34">
          <input
            type="text"
            placeholder="Кидирмок"
            className="tekhnika-khavfsizligi-krsatmalari-textinput1 input"
          />
          <div className="tekhnika-khavfsizligi-krsatmalari-container35">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="tekhnika-khavfsizligi-krsatmalari-icon20"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="tekhnika-khavfsizligi-krsatmalari-container36">
            <span className="tekhnika-khavfsizligi-krsatmalari-text383">
              A+
            </span>
            <span className="tekhnika-khavfsizligi-krsatmalari-text384">
              A-
            </span>
          </div>
        </div>
        <div
          data-thq="thq-burger-menu"
          className="tekhnika-khavfsizligi-krsatmalari-burger-menu2"
        >
          <svg
            viewBox="0 0 1024 1024"
            className="tekhnika-khavfsizligi-krsatmalari-icon22"
          >
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div
          data-thq="thq-mobile-menu"
          className="tekhnika-khavfsizligi-krsatmalari-mobile-menu"
        >
          <div className="tekhnika-khavfsizligi-krsatmalari-nav">
            <div className="tekhnika-khavfsizligi-krsatmalari-container37">
              <div className="tekhnika-khavfsizligi-krsatmalari-container38">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="tekhnika-khavfsizligi-krsatmalari-textinput2 input"
                />
                <div className="tekhnika-khavfsizligi-krsatmalari-container39">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="tekhnika-khavfsizligi-krsatmalari-icon24"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="tekhnika-khavfsizligi-krsatmalari-container40">
                  <span className="tekhnika-khavfsizligi-krsatmalari-text385">
                    A+
                  </span>
                  <span className="tekhnika-khavfsizligi-krsatmalari-text386">
                    A-
                  </span>
                </div>
              </div>
              <div
                data-thq="thq-close-menu"
                className="tekhnika-khavfsizligi-krsatmalari-close-menu"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="tekhnika-khavfsizligi-krsatmalari-icon26"
                >
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="tekhnika-khavfsizligi-krsatmalari-links2">
            <div
              data-thq="thq-dropdown"
              className="tekhnika-khavfsizligi-krsatmalari-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle44"
                >
                  <span className="tekhnika-khavfsizligi-krsatmalari-text387">
                    Бош сахифа
                  </span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="tekhnika-khavfsizligi-krsatmalari-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle45"
              >
                <span className="tekhnika-khavfsizligi-krsatmalari-text388">
                  Умумий
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="tekhnika-khavfsizligi-krsatmalari-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="tekhnika-khavfsizligi-krsatmalari-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle46"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text389">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle47"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text392">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle48"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text393">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle49"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text394">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle50"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text399">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle51"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text400">
                        Тулов Шартлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle52"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text401">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle53"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text404">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="tekhnika-khavfsizligi-krsatmalari-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle54"
              >
                <span className="tekhnika-khavfsizligi-krsatmalari-text405">
                  Виза олиш
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="tekhnika-khavfsizligi-krsatmalari-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="tekhnika-khavfsizligi-krsatmalari-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle55"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text406">
                        Шенген визаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle56"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text407">
                        Миллий виза
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle57"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text408">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle58"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text409">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle59"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text412">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle60"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text416">
                        Ариза шакллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle61"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text417">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="tekhnika-khavfsizligi-krsatmalari-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle62"
              >
                <span className="tekhnika-khavfsizligi-krsatmalari-text418">
                  Кушимча хизматлар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="tekhnika-khavfsizligi-krsatmalari-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="tekhnika-khavfsizligi-krsatmalari-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle63"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text419">
                        VIP - хизмати
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle64"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text420">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle65"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text421">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle66"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text422">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle67"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text423">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle68"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text426">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle69"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text429">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle70"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text430">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle71"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text431">
                        Prime Тиме
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="tekhnika-khavfsizligi-krsatmalari-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle72"
                >
                  <span className="tekhnika-khavfsizligi-krsatmalari-text432">
                    FAQ
                  </span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="tekhnika-khavfsizligi-krsatmalari-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle73"
              >
                <span className="tekhnika-khavfsizligi-krsatmalari-text433">
                  Алокалар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="tekhnika-khavfsizligi-krsatmalari-icon34"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="tekhnika-khavfsizligi-krsatmalari-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle74"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text434">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle75"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text435">
                        Алока маркази
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle76"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text436">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tekhnika-khavfsizligi-krsatmalari-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tekhnika-khavfsizligi-krsatmalari-dropdown-toggle77"
                    >
                      <span className="tekhnika-khavfsizligi-krsatmalari-text437">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="tekhnika-khavfsizligi-krsatmalari-container41">
        <div className="tekhnika-khavfsizligi-krsatmalari-container42">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="tekhnika-khavfsizligi-krsatmalari-image22"
          />
          <span className="tekhnika-khavfsizligi-krsatmalari-text440">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="tekhnika-khavfsizligi-krsatmalari-container43">
          <div className="tekhnika-khavfsizligi-krsatmalari-container44">
            <span className="tekhnika-khavfsizligi-krsatmalari-text441">
              Ходимларни бошқариш
            </span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="tekhnika-khavfsizligi-krsatmalari-image23"
            />
          </div>
          <div className="tekhnika-khavfsizligi-krsatmalari-container45">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="tekhnika-khavfsizligi-krsatmalari-navlink82"
            >
              <div className="tekhnika-khavfsizligi-krsatmalari-container46">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="tekhnika-khavfsizligi-krsatmalari-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="tekhnika-khavfsizligi-krsatmalari-text442">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="tekhnika-khavfsizligi-krsatmalari-navlink83"
            >
              <div className="tekhnika-khavfsizligi-krsatmalari-container47">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="tekhnika-khavfsizligi-krsatmalari-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="tekhnika-khavfsizligi-krsatmalari-text443">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="tekhnika-khavfsizligi-krsatmalari-navlink84"
            >
              <div className="tekhnika-khavfsizligi-krsatmalari-container48">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="tekhnika-khavfsizligi-krsatmalari-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="tekhnika-khavfsizligi-krsatmalari-text447">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="tekhnika-khavfsizligi-krsatmalari-navlink85"
            >
              <div className="tekhnika-khavfsizligi-krsatmalari-container49">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="tekhnika-khavfsizligi-krsatmalari-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="tekhnika-khavfsizligi-krsatmalari-text451">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="tekhnika-khavfsizligi-krsatmalari-navlink86"
            >
              <div className="tekhnika-khavfsizligi-krsatmalari-container50">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="tekhnika-khavfsizligi-krsatmalari-icon44"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="tekhnika-khavfsizligi-krsatmalari-text455">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="tekhnika-khavfsizligi-krsatmalari-container51">
        <div className="tekhnika-khavfsizligi-krsatmalari-container52">
          <span className="tekhnika-khavfsizligi-krsatmalari-text459">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="tekhnika-khavfsizligi-krsatmalari-container53">
            <Link
              to="/visametric-gurukhi"
              className="tekhnika-khavfsizligi-krsatmalari-navlink87"
            >
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="tekhnika-khavfsizligi-krsatmalari-navlink88"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/w3c-logo2-200h.png"
          className="tekhnika-khavfsizligi-krsatmalari-image24"
        />
        <a href="#main" className="tekhnika-khavfsizligi-krsatmalari-link">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="tekhnika-khavfsizligi-krsatmalari-image25"
          />
        </a>
      </div>
    </div>
  )
}

export default TekhnikaKhavfsizligiKrsatmalari
