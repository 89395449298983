import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './biometrik-fotosura.css'

const BiometrikFotosura = (props) => {
  return (
    <div className="biometrik-fotosura-container10">
      <Helmet>
        <title>biometrik-fotosura - Visametric</title>
        <meta property="og:title" content="biometrik-fotosura - Visametric" />
      </Helmet>
      <div
        data-thq="thq-burger-menu"
        className="biometrik-fotosura-burger-menu1"
      >
        <svg viewBox="0 0 1024 1024" className="biometrik-fotosura-icon10">
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div id="main" className="biometrik-fotosura-container11">
        <h1 className="biometrik-fotosura-text100">Биометрик фотосура</h1>
        <div className="biometrik-fotosura-container12">
          <Link
            to="/uzbekistan-germanyuz"
            className="biometrik-fotosura-navlink10"
          >
            <div className="biometrik-fotosura-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="biometrik-fotosura-icon12"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="biometrik-fotosura-text101">Бош саҳифа</h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="biometrik-fotosura-navlink11"
          >
            <div className="biometrik-fotosura-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="biometrik-fotosura-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="biometrik-fotosura-text102">Қўшимча хизматлар</h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="biometrik-fotosura-navlink12"
          >
            <div className="biometrik-fotosura-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="biometrik-fotosura-icon16"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="biometrik-fotosura-text103">Биометрик фотосура</h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="biometrik-fotosura-container16">
        <div className="biometrik-fotosura-container17">
          <div className="biometrik-fotosura-container18">
            <span className="biometrik-fotosura-text104">
              Қўшимча хизматлар
            </span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="biometrik-fotosura-icon18"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="biometrik-fotosura-image10"
          />
          <div className="biometrik-fotosura-container19">
            <Link to="/vip-khizmati" className="biometrik-fotosura-navlink13">
              <div className="biometrik-fotosura-container20">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="biometrik-fotosura-image11"
                />
                <span className="biometrik-fotosura-text105">
                  VIP - хизмати
                </span>
              </div>
            </Link>
            <Link
              to="/ariza-topshirish-uchun-srovnoma-tldirish"
              className="biometrik-fotosura-navlink14"
            >
              <div className="biometrik-fotosura-container21">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="biometrik-fotosura-image12"
                />
                <span className="biometrik-fotosura-text106">
                  Ариза топшириш учун сўровнома тўлдириш
                </span>
              </div>
            </Link>
            <Link
              to="/biometrik-fotosura"
              className="biometrik-fotosura-navlink15"
            >
              <div className="biometrik-fotosura-container22">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="biometrik-fotosura-image13"
                />
                <span className="biometrik-fotosura-text107">
                  Биометрик фотосура
                </span>
              </div>
            </Link>
            <Link
              to="/foto-nuskha-faks-chop-etish-khizmatlari"
              className="biometrik-fotosura-navlink16"
            >
              <div className="biometrik-fotosura-container23">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="biometrik-fotosura-image14"
                />
                <span className="biometrik-fotosura-text108">
                  Фото нусха, факс, чоп этиш хизматлари
                </span>
              </div>
            </Link>
            <Link
              to="/kurerlik-khizmati-orali-etkazib-berish"
              className="biometrik-fotosura-navlink17"
            >
              <div className="biometrik-fotosura-container24">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="biometrik-fotosura-image15"
                />
                <span className="biometrik-fotosura-text109">
                  Курьерлик хизмати орқали етказиб бериш
                </span>
              </div>
            </Link>
            <Link
              to="/khabardor-ilish-khizmati"
              className="biometrik-fotosura-navlink18"
            >
              <div className="biometrik-fotosura-container25">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="biometrik-fotosura-image16"
                />
                <span className="biometrik-fotosura-text110">
                  Хабардор қилиш хизмати
                </span>
              </div>
            </Link>
            <Link
              to="/khuzhzhatlar-tarzhimasi"
              className="biometrik-fotosura-navlink19"
            >
              <div className="biometrik-fotosura-container26">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="biometrik-fotosura-image17"
                />
                <span className="biometrik-fotosura-text111">
                  Хужжатлар таржимаси
                </span>
              </div>
            </Link>
            <Link
              to="/khalaro-tibbiy-suurta"
              className="biometrik-fotosura-navlink20"
            >
              <div className="biometrik-fotosura-container27">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="biometrik-fotosura-image18"
                />
                <span className="biometrik-fotosura-text112">
                  Халқаро тиббий суғурта
                </span>
              </div>
            </Link>
            <Link to="/prime-time" className="biometrik-fotosura-navlink21">
              <div className="biometrik-fotosura-container28">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="biometrik-fotosura-image19"
                />
                <span className="biometrik-fotosura-text113">Prime Тиме</span>
              </div>
            </Link>
          </div>
        </div>
        <div className="biometrik-fotosura-container29">
          <span className="biometrik-fotosura-text114">
            <span className="biometrik-fotosura-text115">
              Биометрик фотосурат (бир киши учун 6 евро)
            </span>
            <br></br>
            <br></br>
            <span>
              Зарур биометрик фотосуратларни VisaMetric қабул марказларида
              тегишли дипломатик ҳукумат қоидаларига асосан тайёрлашингиз
              мумкин.
            </span>
          </span>
        </div>
      </div>
      <div id="header" className="biometrik-fotosura-container30">
        <Link to="/" className="biometrik-fotosura-navlink22">
          <div className="biometrik-fotosura-container31">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="biometrik-fotosura-image20"
            />
            <p className="biometrik-fotosura-text119">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="biometrik-fotosura-container32">
          <div className="biometrik-fotosura-container33">
            <span className="biometrik-fotosura-text122">
              <span>Apply for Visa to</span>
              <span className="biometrik-fotosura-text124"> Germany</span>
            </span>
            <span className="biometrik-fotosura-text125">
              <span className="biometrik-fotosura-text126">in Uzbekistan</span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="biometrik-fotosura-image21"
          />
          <span className="biometrik-fotosura-text157">|</span>
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header
        data-thq="thq-navbar"
        className="biometrik-fotosura-navbar-interactive"
      >
        <div
          data-thq="thq-navbar-nav"
          className="biometrik-fotosura-desktop-menu"
        >
          <div className="biometrik-fotosura-container34">
            <nav className="biometrik-fotosura-links1">
              <div
                data-thq="thq-dropdown"
                className="biometrik-fotosura-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="biometrik-fotosura-dropdown-toggle10"
                  >
                    <span className="biometrik-fotosura-text158">
                      Бош сахифа
                    </span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="biometrik-fotosura-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="biometrik-fotosura-dropdown-toggle11"
                >
                  <span className="biometrik-fotosura-text159">Умумий</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="biometrik-fotosura-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="biometrik-fotosura-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle12"
                      >
                        <span className="biometrik-fotosura-text160 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle13"
                      >
                        <span className="biometrik-fotosura-text163 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle14"
                      >
                        <span className="biometrik-fotosura-text164 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle15"
                      >
                        <span className="biometrik-fotosura-text165 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle16"
                      >
                        <span className="biometrik-fotosura-text170 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle17"
                      >
                        <span className="biometrik-fotosura-text171 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle18"
                      >
                        <span className="biometrik-fotosura-text172 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle19"
                      >
                        <span className="biometrik-fotosura-text176 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="biometrik-fotosura-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="biometrik-fotosura-dropdown-toggle20"
                >
                  <span className="biometrik-fotosura-text177">Виза олиш</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="biometrik-fotosura-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="biometrik-fotosura-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle21"
                      >
                        <span className="biometrik-fotosura-text178">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle22"
                      >
                        <span className="biometrik-fotosura-text179">
                          Миллий виза
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle23"
                      >
                        <span className="biometrik-fotosura-text180">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle24"
                      >
                        <span className="biometrik-fotosura-text181">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle25"
                      >
                        <span className="biometrik-fotosura-text184">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle26"
                      >
                        <span className="biometrik-fotosura-text188">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle27"
                      >
                        <span className="biometrik-fotosura-text189">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="biometrik-fotosura-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="biometrik-fotosura-dropdown-toggle28"
                >
                  <span className="biometrik-fotosura-text190">
                    Кушимча хизматлар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="biometrik-fotosura-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="biometrik-fotosura-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle29"
                      >
                        <span className="biometrik-fotosura-text191">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle30"
                      >
                        <span className="biometrik-fotosura-text192">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle31"
                      >
                        <span className="biometrik-fotosura-text196">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle32"
                      >
                        <span className="biometrik-fotosura-text199">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle33"
                      >
                        <span className="biometrik-fotosura-text200">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle34"
                      >
                        <span className="biometrik-fotosura-text204">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle35"
                      >
                        <span className="biometrik-fotosura-text205">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="biometrik-fotosura-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="biometrik-fotosura-dropdown-toggle38"
                  >
                    <span className="biometrik-fotosura-text208">FAQ</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="biometrik-fotosura-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="biometrik-fotosura-dropdown-toggle39"
                >
                  <span className="biometrik-fotosura-text209">Алокалар</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="biometrik-fotosura-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="biometrik-fotosura-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle40"
                      >
                        <span className="biometrik-fotosura-text210">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle41"
                      >
                        <span className="biometrik-fotosura-text211">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle42"
                      >
                        <span className="biometrik-fotosura-text212">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-fotosura-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-fotosura-dropdown-toggle43"
                      >
                        <span className="biometrik-fotosura-text213">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="biometrik-fotosura-container35">
          <input
            type="text"
            placeholder="Кидирмок"
            className="biometrik-fotosura-textinput1 input"
          />
          <div className="biometrik-fotosura-container36">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="biometrik-fotosura-icon20"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="biometrik-fotosura-container37">
            <span className="biometrik-fotosura-text216">A+</span>
            <span className="biometrik-fotosura-text217">A-</span>
          </div>
        </div>
        <div
          data-thq="thq-burger-menu"
          className="biometrik-fotosura-burger-menu2"
        >
          <svg viewBox="0 0 1024 1024" className="biometrik-fotosura-icon22">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div
          data-thq="thq-mobile-menu"
          className="biometrik-fotosura-mobile-menu"
        >
          <div className="biometrik-fotosura-nav">
            <div className="biometrik-fotosura-container38">
              <div className="biometrik-fotosura-container39">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="biometrik-fotosura-textinput2 input"
                />
                <div className="biometrik-fotosura-container40">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="biometrik-fotosura-icon24"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="biometrik-fotosura-container41">
                  <span className="biometrik-fotosura-text218">A+</span>
                  <span className="biometrik-fotosura-text219">A-</span>
                </div>
              </div>
              <div
                data-thq="thq-close-menu"
                className="biometrik-fotosura-close-menu"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="biometrik-fotosura-icon26"
                >
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="biometrik-fotosura-links2">
            <div
              data-thq="thq-dropdown"
              className="biometrik-fotosura-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="biometrik-fotosura-dropdown-toggle44"
                >
                  <span className="biometrik-fotosura-text220">Бош сахифа</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="biometrik-fotosura-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="biometrik-fotosura-dropdown-toggle45"
              >
                <span className="biometrik-fotosura-text221">Умумий</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="biometrik-fotosura-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="biometrik-fotosura-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="biometrik-fotosura-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle46"
                    >
                      <span className="biometrik-fotosura-text222">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle47"
                    >
                      <span className="biometrik-fotosura-text225">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle48"
                    >
                      <span className="biometrik-fotosura-text226">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle49"
                    >
                      <span className="biometrik-fotosura-text227">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle50"
                    >
                      <span className="biometrik-fotosura-text232">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle51"
                    >
                      <span className="biometrik-fotosura-text233">
                        Тулов Шартлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle52"
                    >
                      <span className="biometrik-fotosura-text234">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle53"
                    >
                      <span className="biometrik-fotosura-text237">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="biometrik-fotosura-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="biometrik-fotosura-dropdown-toggle54"
              >
                <span className="biometrik-fotosura-text238">Виза олиш</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="biometrik-fotosura-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="biometrik-fotosura-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="biometrik-fotosura-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle55"
                    >
                      <span className="biometrik-fotosura-text239">
                        Шенген визаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle56"
                    >
                      <span className="biometrik-fotosura-text240">
                        Миллий виза
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle57"
                    >
                      <span className="biometrik-fotosura-text241">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle58"
                    >
                      <span className="biometrik-fotosura-text242">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle59"
                    >
                      <span className="biometrik-fotosura-text245">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle60"
                    >
                      <span className="biometrik-fotosura-text249">
                        Ариза шакллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle61"
                    >
                      <span className="biometrik-fotosura-text250">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="biometrik-fotosura-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="biometrik-fotosura-dropdown-toggle62"
              >
                <span className="biometrik-fotosura-text251">
                  Кушимча хизматлар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="biometrik-fotosura-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="biometrik-fotosura-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="biometrik-fotosura-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle63"
                    >
                      <span className="biometrik-fotosura-text252">
                        VIP - хизмати
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle64"
                    >
                      <span className="biometrik-fotosura-text253">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle65"
                    >
                      <span className="biometrik-fotosura-text254">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle66"
                    >
                      <span className="biometrik-fotosura-text255">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle67"
                    >
                      <span className="biometrik-fotosura-text256">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle68"
                    >
                      <span className="biometrik-fotosura-text259">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle69"
                    >
                      <span className="biometrik-fotosura-text262">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle70"
                    >
                      <span className="biometrik-fotosura-text263">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle71"
                    >
                      <span className="biometrik-fotosura-text264">
                        Prime Тиме
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="biometrik-fotosura-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="biometrik-fotosura-dropdown-toggle72"
                >
                  <span className="biometrik-fotosura-text265">FAQ</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="biometrik-fotosura-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="biometrik-fotosura-dropdown-toggle73"
              >
                <span className="biometrik-fotosura-text266">Алокалар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="biometrik-fotosura-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="biometrik-fotosura-icon34"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="biometrik-fotosura-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle74"
                    >
                      <span className="biometrik-fotosura-text267">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle75"
                    >
                      <span className="biometrik-fotosura-text268">
                        Алока маркази
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle76"
                    >
                      <span className="biometrik-fotosura-text269">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-fotosura-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-fotosura-dropdown-toggle77"
                    >
                      <span className="biometrik-fotosura-text270">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="biometrik-fotosura-container42">
        <div className="biometrik-fotosura-container43">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="biometrik-fotosura-image23"
          />
          <span className="biometrik-fotosura-text273">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="biometrik-fotosura-container44">
          <div className="biometrik-fotosura-container45">
            <span className="biometrik-fotosura-text274">
              Ходимларни бошқариш
            </span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="biometrik-fotosura-image24"
            />
          </div>
          <div className="biometrik-fotosura-container46">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="biometrik-fotosura-navlink83"
            >
              <div className="biometrik-fotosura-container47">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="biometrik-fotosura-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="biometrik-fotosura-text275">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="biometrik-fotosura-navlink84"
            >
              <div className="biometrik-fotosura-container48">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="biometrik-fotosura-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="biometrik-fotosura-text276">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="biometrik-fotosura-navlink85"
            >
              <div className="biometrik-fotosura-container49">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="biometrik-fotosura-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="biometrik-fotosura-text280">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="biometrik-fotosura-navlink86"
            >
              <div className="biometrik-fotosura-container50">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="biometrik-fotosura-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="biometrik-fotosura-text284">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="biometrik-fotosura-navlink87"
            >
              <div className="biometrik-fotosura-container51">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="biometrik-fotosura-icon44"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="biometrik-fotosura-text288">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="biometrik-fotosura-container52">
        <div className="biometrik-fotosura-container53">
          <span className="biometrik-fotosura-text292">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="biometrik-fotosura-container54">
            <Link
              to="/visametric-gurukhi"
              className="biometrik-fotosura-navlink88"
            >
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="biometrik-fotosura-navlink89"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/w3c-logo2-200h.png"
          className="biometrik-fotosura-image25"
        />
        <a href="#main" className="biometrik-fotosura-link">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="biometrik-fotosura-image26"
          />
        </a>
      </div>
    </div>
  )
}

export default BiometrikFotosura
