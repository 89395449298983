import React from 'react'

import { Helmet } from 'react-helmet'

import './ish-taminoti-byicha-srovnoma.css'

const IshTaminotiByichaSrovnoma = (props) => {
  return (
    <div className="ish-taminoti-byicha-srovnoma-container">
      <Helmet>
        <title>ish-taminoti-byicha-srovnoma - Visametric</title>
        <meta
          property="og:title"
          content="ish-taminoti-byicha-srovnoma - Visametric"
        />
      </Helmet>
    </div>
  )
}

export default IshTaminotiByichaSrovnoma
