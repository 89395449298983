import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './shimcha-khizmatlar.css'

const ShimchaKhizmatlar = (props) => {
  return (
    <div className="shimcha-khizmatlar-container10">
      <Helmet>
        <title>shimcha-khizmatlar - Visametric</title>
        <meta property="og:title" content="shimcha-khizmatlar - Visametric" />
      </Helmet>
      <div
        data-thq="thq-burger-menu"
        className="shimcha-khizmatlar-burger-menu1"
      >
        <svg viewBox="0 0 1024 1024" className="shimcha-khizmatlar-icon10">
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div id="main" className="shimcha-khizmatlar-container11">
        <h1 className="shimcha-khizmatlar-text100">Қўшимча хизматлар</h1>
        <div className="shimcha-khizmatlar-container12">
          <Link
            to="/uzbekistan-germanyuz"
            className="shimcha-khizmatlar-navlink10"
          >
            <div className="shimcha-khizmatlar-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="shimcha-khizmatlar-icon12"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="shimcha-khizmatlar-text101">Бош саҳифа</h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="shimcha-khizmatlar-navlink11"
          >
            <div className="shimcha-khizmatlar-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="shimcha-khizmatlar-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="shimcha-khizmatlar-text102">FAQ</h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="shimcha-khizmatlar-navlink12"
          >
            <div className="shimcha-khizmatlar-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="shimcha-khizmatlar-icon16"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="shimcha-khizmatlar-text103">Қўшимча хизматлар</h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="shimcha-khizmatlar-container16">
        <div className="shimcha-khizmatlar-container17">
          <div className="shimcha-khizmatlar-container18">
            <span className="shimcha-khizmatlar-text104">FAQ</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="shimcha-khizmatlar-icon18"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="shimcha-khizmatlar-image10"
          />
          <div className="shimcha-khizmatlar-container19">
            <Link to="/umumiy-malumot" className="shimcha-khizmatlar-navlink13">
              <div className="shimcha-khizmatlar-container20">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="shimcha-khizmatlar-image11"
                />
                <span className="shimcha-khizmatlar-text105">
                  Умумий маълумот
                </span>
              </div>
            </Link>
            <Link to="/ariza-oldin" className="shimcha-khizmatlar-navlink14">
              <div className="shimcha-khizmatlar-container21">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="shimcha-khizmatlar-image12"
                />
                <span className="shimcha-khizmatlar-text106">Ариза олдин</span>
              </div>
            </Link>
            <Link
              to="/ariza-topshirish-zharaeni"
              className="shimcha-khizmatlar-navlink15"
            >
              <div className="shimcha-khizmatlar-container22">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="shimcha-khizmatlar-image13"
                />
                <span className="shimcha-khizmatlar-text107">
                  Ариза топшириш жараёни
                </span>
              </div>
            </Link>
            <Link to="/uzhzhatlar" className="shimcha-khizmatlar-navlink16">
              <div className="shimcha-khizmatlar-container23">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="shimcha-khizmatlar-image14"
                />
                <span className="shimcha-khizmatlar-text108">Ҳужжатлар</span>
              </div>
            </Link>
            <Link
              to="/shimcha-khizmatlar"
              className="shimcha-khizmatlar-navlink17"
            >
              <div className="shimcha-khizmatlar-container24">
                <img
                  alt="image"
                  src="/rectangle%2030.svg"
                  className="shimcha-khizmatlar-image15"
                />
                <span className="shimcha-khizmatlar-text109">
                  Қўшимча хизматлар
                </span>
              </div>
            </Link>
            <Link
              to="/ariza-topshirishdan-snggi-olat"
              className="shimcha-khizmatlar-navlink18"
            >
              <div className="shimcha-khizmatlar-container25">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="shimcha-khizmatlar-image16"
                />
                <span className="shimcha-khizmatlar-text110">
                  Ариза топширишдан сўнгги ҳолат
                </span>
              </div>
            </Link>
          </div>
        </div>
        <div className="shimcha-khizmatlar-container26">
          <span className="shimcha-khizmatlar-text111">
            1. Узбекистон / FAQ / Қўшимча хизматлар
          </span>
          <span className="shimcha-khizmatlar-text112">
            <span className="shimcha-khizmatlar-text113">
              Мен ўз аризамни почта хизмати орқали юборишим мумкинми?
            </span>
            <br className="shimcha-khizmatlar-text114"></br>
            <br className="shimcha-khizmatlar-text115"></br>
            <span>
              Йўқ, почта хизмати орқали юборилган аризалар қабул қилинмайди. Ҳар
              бир аризачи шахсан ёки ваколатга эга ишочли шахс орқали VisaMetric
              офисига мурожаат қилиши лозим.
            </span>
            <br className="shimcha-khizmatlar-text117"></br>
            <br className="shimcha-khizmatlar-text118"></br>
            <br className="shimcha-khizmatlar-text119"></br>
            <span className="shimcha-khizmatlar-text120">
              Тиббий суғурта муддати виза амал қилинадиган муддат билан бир хил
              бўлиши керакми?
            </span>
            <br className="shimcha-khizmatlar-text121"></br>
            <br className="shimcha-khizmatlar-text122"></br>
            <span>
              Тиббий суғурта муддати Шенген зонасидаги саёҳатингиз даврини
              қоплаши лозим.
            </span>
            <br className="shimcha-khizmatlar-text124"></br>
            <br className="shimcha-khizmatlar-text125"></br>
            <br className="shimcha-khizmatlar-text126"></br>
            <span className="shimcha-khizmatlar-text127">
              Тайёр бўлган паспортимни қайси вақт олишим мумкин?
            </span>
            <br className="shimcha-khizmatlar-text128"></br>
            <br className="shimcha-khizmatlar-text129"></br>
            <span>
              Тайёр бўлган паспортларни барча VisaMetric офисларида иш кунлари
              соат 9:00 дан 18:00 га қадар қабул қилиб олиш мумкин.
            </span>
            <br className="shimcha-khizmatlar-text131"></br>
            <br className="shimcha-khizmatlar-text132"></br>
            <br className="shimcha-khizmatlar-text133"></br>
            <span className="shimcha-khizmatlar-text134">
              Ҳужжатларим қаердалигини кузатишим мумкинми?
            </span>
            <br className="shimcha-khizmatlar-text135"></br>
            <br className="shimcha-khizmatlar-text136"></br>
            <span>
              Аризангизни тақдим этганингизда сизга берилган шартномадаги штрих
              коддан фойдаланиб, ўз ҳужжатларингизни бизнинг сайтимиз орқали
              кузатиш имкониятига эгасиз.
            </span>
            <br className="shimcha-khizmatlar-text138"></br>
            <br className="shimcha-khizmatlar-text139"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="shimcha-khizmatlar-text141"></br>
            <span className="shimcha-khizmatlar-text142">
              Бошка киши менинг паспортимни олиши мумкинми?
            </span>
            <br className="shimcha-khizmatlar-text143"></br>
            <br className="shimcha-khizmatlar-text144"></br>
            <span>
              Паспортларни бошқа шахсларга бериш мумкин эмас. Паспортни шахсан
              аризачининг ўзи ёки нотариал тасдиқланган ишончномага эга бўлган
              бошқа шахс, шартнома нусхасини, шахсини тасдиқловчи ҳужжатини ва
              нотариал тасдиқланган ишончноманинг асл нусхасини тақдим этиш
              орқали паспортни олиши мумкин.
            </span>
            <br className="shimcha-khizmatlar-text146"></br>
            <br className="shimcha-khizmatlar-text147"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="shimcha-khizmatlar-text149"></br>
            <span className="shimcha-khizmatlar-text150">
              Visametric томонидан тақдим этиладиган қўшимча хизматлардан
              фойдаланишим керакми?
            </span>
            <br className="shimcha-khizmatlar-text151"></br>
            <br className="shimcha-khizmatlar-text152"></br>
            <span>
              Барча қўшимча хизматларимиз ихтиёрий ва виза олиш жараёни ёки
              натижасига таъсир қилмайди.
            </span>
          </span>
        </div>
      </div>
      <div id="header" className="shimcha-khizmatlar-container27">
        <Link to="/" className="shimcha-khizmatlar-navlink19">
          <div className="shimcha-khizmatlar-container28">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="shimcha-khizmatlar-image17"
            />
            <p className="shimcha-khizmatlar-text154">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="shimcha-khizmatlar-container29">
          <div className="shimcha-khizmatlar-container30">
            <span className="shimcha-khizmatlar-text157">
              <span>Apply for Visa to</span>
              <span className="shimcha-khizmatlar-text159"> Germany</span>
            </span>
            <span className="shimcha-khizmatlar-text160">
              <span className="shimcha-khizmatlar-text161">in Uzbekistan</span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="shimcha-khizmatlar-image18"
          />
          <span className="shimcha-khizmatlar-text192">|</span>
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header
        data-thq="thq-navbar"
        className="shimcha-khizmatlar-navbar-interactive"
      >
        <div
          data-thq="thq-navbar-nav"
          className="shimcha-khizmatlar-desktop-menu"
        >
          <div className="shimcha-khizmatlar-container31">
            <nav className="shimcha-khizmatlar-links1">
              <div
                data-thq="thq-dropdown"
                className="shimcha-khizmatlar-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="shimcha-khizmatlar-dropdown-toggle10"
                  >
                    <span className="shimcha-khizmatlar-text193">
                      Бош сахифа
                    </span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="shimcha-khizmatlar-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="shimcha-khizmatlar-dropdown-toggle11"
                >
                  <span className="shimcha-khizmatlar-text194">Умумий</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="shimcha-khizmatlar-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="shimcha-khizmatlar-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle12"
                      >
                        <span className="shimcha-khizmatlar-text195 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle13"
                      >
                        <span className="shimcha-khizmatlar-text198 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle14"
                      >
                        <span className="shimcha-khizmatlar-text199 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle15"
                      >
                        <span className="shimcha-khizmatlar-text200 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle16"
                      >
                        <span className="shimcha-khizmatlar-text205 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle17"
                      >
                        <span className="shimcha-khizmatlar-text206 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle18"
                      >
                        <span className="shimcha-khizmatlar-text207 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle19"
                      >
                        <span className="shimcha-khizmatlar-text211 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="shimcha-khizmatlar-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="shimcha-khizmatlar-dropdown-toggle20"
                >
                  <span className="shimcha-khizmatlar-text212">Виза олиш</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="shimcha-khizmatlar-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="shimcha-khizmatlar-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle21"
                      >
                        <span className="shimcha-khizmatlar-text213">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle22"
                      >
                        <span className="shimcha-khizmatlar-text214">
                          Миллий виза
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle23"
                      >
                        <span className="shimcha-khizmatlar-text215">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle24"
                      >
                        <span className="shimcha-khizmatlar-text216">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle25"
                      >
                        <span className="shimcha-khizmatlar-text219">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle26"
                      >
                        <span className="shimcha-khizmatlar-text223">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle27"
                      >
                        <span className="shimcha-khizmatlar-text224">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="shimcha-khizmatlar-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="shimcha-khizmatlar-dropdown-toggle28"
                >
                  <span className="shimcha-khizmatlar-text225">
                    Кушимча хизматлар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="shimcha-khizmatlar-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="shimcha-khizmatlar-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle29"
                      >
                        <span className="shimcha-khizmatlar-text226">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle30"
                      >
                        <span className="shimcha-khizmatlar-text227">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle31"
                      >
                        <span className="shimcha-khizmatlar-text231">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle32"
                      >
                        <span className="shimcha-khizmatlar-text234">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle33"
                      >
                        <span className="shimcha-khizmatlar-text235">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle34"
                      >
                        <span className="shimcha-khizmatlar-text239">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle35"
                      >
                        <span className="shimcha-khizmatlar-text240">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="shimcha-khizmatlar-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="shimcha-khizmatlar-dropdown-toggle38"
                  >
                    <span className="shimcha-khizmatlar-text243">FAQ</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="shimcha-khizmatlar-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="shimcha-khizmatlar-dropdown-toggle39"
                >
                  <span className="shimcha-khizmatlar-text244">Алокалар</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="shimcha-khizmatlar-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="shimcha-khizmatlar-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle40"
                      >
                        <span className="shimcha-khizmatlar-text245">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle41"
                      >
                        <span className="shimcha-khizmatlar-text246">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle42"
                      >
                        <span className="shimcha-khizmatlar-text247">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shimcha-khizmatlar-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shimcha-khizmatlar-dropdown-toggle43"
                      >
                        <span className="shimcha-khizmatlar-text248">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="shimcha-khizmatlar-container32">
          <input
            type="text"
            placeholder="Кидирмок"
            className="shimcha-khizmatlar-textinput1 input"
          />
          <div className="shimcha-khizmatlar-container33">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="shimcha-khizmatlar-icon20"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="shimcha-khizmatlar-container34">
            <span className="shimcha-khizmatlar-text251">A+</span>
            <span className="shimcha-khizmatlar-text252">A-</span>
          </div>
        </div>
        <div
          data-thq="thq-burger-menu"
          className="shimcha-khizmatlar-burger-menu2"
        >
          <svg viewBox="0 0 1024 1024" className="shimcha-khizmatlar-icon22">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div
          data-thq="thq-mobile-menu"
          className="shimcha-khizmatlar-mobile-menu"
        >
          <div className="shimcha-khizmatlar-nav">
            <div className="shimcha-khizmatlar-container35">
              <div className="shimcha-khizmatlar-container36">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="shimcha-khizmatlar-textinput2 input"
                />
                <div className="shimcha-khizmatlar-container37">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="shimcha-khizmatlar-icon24"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="shimcha-khizmatlar-container38">
                  <span className="shimcha-khizmatlar-text253">A+</span>
                  <span className="shimcha-khizmatlar-text254">A-</span>
                </div>
              </div>
              <div
                data-thq="thq-close-menu"
                className="shimcha-khizmatlar-close-menu"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="shimcha-khizmatlar-icon26"
                >
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="shimcha-khizmatlar-links2">
            <div
              data-thq="thq-dropdown"
              className="shimcha-khizmatlar-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="shimcha-khizmatlar-dropdown-toggle44"
                >
                  <span className="shimcha-khizmatlar-text255">Бош сахифа</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="shimcha-khizmatlar-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="shimcha-khizmatlar-dropdown-toggle45"
              >
                <span className="shimcha-khizmatlar-text256">Умумий</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="shimcha-khizmatlar-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="shimcha-khizmatlar-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="shimcha-khizmatlar-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle46"
                    >
                      <span className="shimcha-khizmatlar-text257">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle47"
                    >
                      <span className="shimcha-khizmatlar-text260">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle48"
                    >
                      <span className="shimcha-khizmatlar-text261">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle49"
                    >
                      <span className="shimcha-khizmatlar-text262">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle50"
                    >
                      <span className="shimcha-khizmatlar-text267">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle51"
                    >
                      <span className="shimcha-khizmatlar-text268">
                        Тулов Шартлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle52"
                    >
                      <span className="shimcha-khizmatlar-text269">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle53"
                    >
                      <span className="shimcha-khizmatlar-text272">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="shimcha-khizmatlar-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="shimcha-khizmatlar-dropdown-toggle54"
              >
                <span className="shimcha-khizmatlar-text273">Виза олиш</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="shimcha-khizmatlar-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="shimcha-khizmatlar-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="shimcha-khizmatlar-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle55"
                    >
                      <span className="shimcha-khizmatlar-text274">
                        Шенген визаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle56"
                    >
                      <span className="shimcha-khizmatlar-text275">
                        Миллий виза
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle57"
                    >
                      <span className="shimcha-khizmatlar-text276">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle58"
                    >
                      <span className="shimcha-khizmatlar-text277">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle59"
                    >
                      <span className="shimcha-khizmatlar-text280">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle60"
                    >
                      <span className="shimcha-khizmatlar-text284">
                        Ариза шакллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle61"
                    >
                      <span className="shimcha-khizmatlar-text285">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="shimcha-khizmatlar-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="shimcha-khizmatlar-dropdown-toggle62"
              >
                <span className="shimcha-khizmatlar-text286">
                  Кушимча хизматлар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="shimcha-khizmatlar-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="shimcha-khizmatlar-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="shimcha-khizmatlar-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle63"
                    >
                      <span className="shimcha-khizmatlar-text287">
                        VIP - хизмати
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle64"
                    >
                      <span className="shimcha-khizmatlar-text288">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle65"
                    >
                      <span className="shimcha-khizmatlar-text289">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle66"
                    >
                      <span className="shimcha-khizmatlar-text290">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle67"
                    >
                      <span className="shimcha-khizmatlar-text291">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle68"
                    >
                      <span className="shimcha-khizmatlar-text294">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle69"
                    >
                      <span className="shimcha-khizmatlar-text297">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle70"
                    >
                      <span className="shimcha-khizmatlar-text298">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle71"
                    >
                      <span className="shimcha-khizmatlar-text299">
                        Prime Тиме
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="shimcha-khizmatlar-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="shimcha-khizmatlar-dropdown-toggle72"
                >
                  <span className="shimcha-khizmatlar-text300">FAQ</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="shimcha-khizmatlar-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="shimcha-khizmatlar-dropdown-toggle73"
              >
                <span className="shimcha-khizmatlar-text301">Алокалар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="shimcha-khizmatlar-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="shimcha-khizmatlar-icon34"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="shimcha-khizmatlar-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle74"
                    >
                      <span className="shimcha-khizmatlar-text302">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle75"
                    >
                      <span className="shimcha-khizmatlar-text303">
                        Алока маркази
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle76"
                    >
                      <span className="shimcha-khizmatlar-text304">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shimcha-khizmatlar-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shimcha-khizmatlar-dropdown-toggle77"
                    >
                      <span className="shimcha-khizmatlar-text305">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="shimcha-khizmatlar-container39">
        <div className="shimcha-khizmatlar-container40">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="shimcha-khizmatlar-image20"
          />
          <span className="shimcha-khizmatlar-text308">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="shimcha-khizmatlar-container41">
          <div className="shimcha-khizmatlar-container42">
            <span className="shimcha-khizmatlar-text309">
              Ходимларни бошқариш
            </span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="shimcha-khizmatlar-image21"
            />
          </div>
          <div className="shimcha-khizmatlar-container43">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="shimcha-khizmatlar-navlink80"
            >
              <div className="shimcha-khizmatlar-container44">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="shimcha-khizmatlar-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="shimcha-khizmatlar-text310">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="shimcha-khizmatlar-navlink81"
            >
              <div className="shimcha-khizmatlar-container45">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="shimcha-khizmatlar-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="shimcha-khizmatlar-text311">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="shimcha-khizmatlar-navlink82"
            >
              <div className="shimcha-khizmatlar-container46">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="shimcha-khizmatlar-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="shimcha-khizmatlar-text315">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="shimcha-khizmatlar-navlink83"
            >
              <div className="shimcha-khizmatlar-container47">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="shimcha-khizmatlar-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="shimcha-khizmatlar-text319">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="shimcha-khizmatlar-navlink84"
            >
              <div className="shimcha-khizmatlar-container48">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="shimcha-khizmatlar-icon44"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="shimcha-khizmatlar-text323">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="shimcha-khizmatlar-container49">
        <div className="shimcha-khizmatlar-container50">
          <span className="shimcha-khizmatlar-text327">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="shimcha-khizmatlar-container51">
            <Link
              to="/visametric-gurukhi"
              className="shimcha-khizmatlar-navlink85"
            >
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="shimcha-khizmatlar-navlink86"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/w3c-logo2-200h.png"
          className="shimcha-khizmatlar-image22"
        />
        <a href="#main" className="shimcha-khizmatlar-link">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="shimcha-khizmatlar-image23"
          />
        </a>
      </div>
    </div>
  )
}

export default ShimchaKhizmatlar
