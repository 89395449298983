import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './contact-form.css'

const ContactForm = (props) => {
  return (
    <div className="contact-form-container10">
      <Helmet>
        <title>contact-form - Visametric</title>
        <meta property="og:title" content="contact-form - Visametric" />
      </Helmet>
      <div data-thq="thq-burger-menu" className="contact-form-burger-menu1">
        <svg viewBox="0 0 1024 1024" className="contact-form-icon10">
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div id="main" className="contact-form-container11">
        <h1 className="contact-form-text100">Қайта алока шакли</h1>
        <div className="contact-form-container12">
          <Link to="/uzbekistan-germanyuz" className="contact-form-navlink10">
            <div className="contact-form-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="contact-form-icon12"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="contact-form-text101">Бош саҳифа</h1>
            </div>
          </Link>
          <Link to="/uzbekistan-germanyuz" className="contact-form-navlink11">
            <div className="contact-form-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="contact-form-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="contact-form-text102">Алоқалар</h1>
            </div>
          </Link>
          <Link to="/uzbekistan-germanyuz" className="contact-form-navlink12">
            <div className="contact-form-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="contact-form-icon16"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="contact-form-text103">Қайта алока шакли</h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="contact-form-container16">
        <div className="contact-form-container17">
          <div className="contact-form-container18">
            <span className="contact-form-text104">Алоқалар</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="contact-form-icon18"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="contact-form-image10"
          />
          <div className="contact-form-container19">
            <Link to="/office" className="contact-form-navlink13">
              <div className="contact-form-container20">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="contact-form-image11"
                />
                <span className="contact-form-text105">
                  VisaMetric филиаллари
                </span>
              </div>
            </Link>
            <Link to="/callcenter" className="contact-form-navlink14">
              <div className="contact-form-container21">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="contact-form-image12"
                />
                <span className="contact-form-text106">Алоқа маркази</span>
              </div>
            </Link>
            <Link to="/contact" className="contact-form-navlink15">
              <div className="contact-form-container22">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="contact-form-image13"
                />
                <span className="contact-form-text107">Муштарий Анкетаси</span>
              </div>
            </Link>
            <Link to="/contact-form" className="contact-form-navlink16">
              <div className="contact-form-container23">
                <img
                  alt="image"
                  src="/rectangle%2030.svg"
                  className="contact-form-image14"
                />
                <span className="contact-form-text108">Қайта алока шакли</span>
              </div>
            </Link>
          </div>
        </div>
        <div className="contact-form-container24">
          <span className="contact-form-text109">
            <span>Қайта алока шакли</span>
            <br className="contact-form-text111"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
          <div className="contact-form-container25">
            <div className="contact-form-container26">
              <span className="contact-form-text113">
                <span>Исм</span>
                <br></br>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <input
                type="text"
                placeholder="Исм"
                className="contact-form-textinput10 input"
              />
            </div>
            <div className="contact-form-container27">
              <span className="contact-form-text117">
                <span>Фамилия</span>
                <br></br>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <input
                type="text"
                placeholder="Фамилия"
                className="contact-form-textinput11 input"
              />
            </div>
            <div className="contact-form-container28">
              <span className="contact-form-text121">
                <span>E-почта</span>
                <br></br>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <input
                type="text"
                placeholder="E-почта"
                className="contact-form-textinput12 input"
              />
            </div>
            <div className="contact-form-container29">
              <span className="contact-form-text125">
                <span>Телефон</span>
                <br></br>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <input
                type="text"
                placeholder="Телефон"
                className="contact-form-textinput13 input"
              />
            </div>
            <div className="contact-form-container30">
              <span className="contact-form-text129">
                <span>Илтимос, мурожаат мавзусини танланг</span>
                <br></br>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <select className="contact-form-select1">
                <option value="Option 1">Илтимос танланг</option>
                <option value="Option 2">Визага мурожаат килиш жараёни</option>
                <option value="Option 3">Кунтирок маркази</option>
                <option value="Option 3">Бизнинг ходимлар</option>
                <option value="Option 3">хужжатлар</option>
                <option value="Option 3">Кушимча хизматлар</option>
                <option value="Option 3">Хужжатлар топширилгандан сунг</option>
                <option value="Option 3">Бошкалар</option>
              </select>
            </div>
            <div className="contact-form-container31">
              <span className="contact-form-text133">
                <span>Шахар</span>
                <br></br>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <input
                type="text"
                placeholder="Шахар"
                className="contact-form-textinput14 input"
              />
            </div>
            <div className="contact-form-container32">
              <span className="contact-form-text137">
                <span>
                  Залиште ваші питання та пропозиції стосовно роботи Visametric
                  тут.
                </span>
                <br></br>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <input
                type="text"
                placeholder="Залиште ваші питання та пропозиції стосовно роботи Visametric тут."
                className="contact-form-textinput15 input"
              />
            </div>
            <a
              href="http://web.test.visametric.com/storage/images/pages/files2/5ea882650a548-Security-and-Privacy-Policy---General-uz-28042020.pdf"
              target="_blank"
              rel="noreferrer noopener"
              className="contact-form-link1"
            >
              <span>
                ✔ Хавфсизлик ва маълумотларни ҳимоя қилиш сиёсати шартларини
                ўқидим ва қабул қилдим
              </span>
              <br></br>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </a>
            <div className="contact-form-container33">
              <a
                href="http://web.test.visametric.com/storage/images/pages/files2/5ea882650a548-Security-and-Privacy-Policy---General-uz-28042020.pdf"
                target="_blank"
                rel="noreferrer noopener"
                className="contact-form-link2"
              >
                <span>(PDF, DOC)</span>
                <br></br>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </a>
              <input
                type="file"
                placeholder="Шахар"
                className="contact-form-textinput16 input"
              />
            </div>
            <img
              alt="CAPTCHA Resmi"
              src="/external/captcha%20resmi-ssad-900w.png"
              className="contact-form-image15"
            />
            <input
              type="text"
              placeholder="CONFIRM CODE"
              className="contact-form-textinput17 input"
            />
            <button type="submit" className="contact-form-button button">
              <span className="contact-form-text147">
                {' '}
                Жўнатиш
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </button>
          </div>
        </div>
      </div>
      <div id="header" className="contact-form-container34">
        <Link to="/" className="contact-form-navlink17">
          <div className="contact-form-container35">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="contact-form-image16"
            />
            <p className="contact-form-text148">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="contact-form-container36">
          <div className="contact-form-container37">
            <span className="contact-form-text151">
              <span>Apply for Visa to</span>
              <span className="contact-form-text153"> Germany</span>
            </span>
            <span className="contact-form-text154">
              <span className="contact-form-text155">in Uzbekistan</span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="contact-form-image17"
          />
          <span className="contact-form-text186">|</span>
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header data-thq="thq-navbar" className="contact-form-navbar-interactive">
        <div data-thq="thq-navbar-nav" className="contact-form-desktop-menu">
          <div className="contact-form-container38">
            <nav className="contact-form-links1">
              <div
                data-thq="thq-dropdown"
                className="contact-form-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="contact-form-dropdown-toggle10"
                  >
                    <span className="contact-form-text187">Бош сахифа</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="contact-form-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="contact-form-dropdown-toggle11"
                >
                  <span className="contact-form-text188">Умумий</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="contact-form-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="contact-form-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle12"
                      >
                        <span className="contact-form-text189 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle13"
                      >
                        <span className="contact-form-text192 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle14"
                      >
                        <span className="contact-form-text193 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle15"
                      >
                        <span className="contact-form-text194 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle16"
                      >
                        <span className="contact-form-text199 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle17"
                      >
                        <span className="contact-form-text200 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle18"
                      >
                        <span className="contact-form-text201 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle19"
                      >
                        <span className="contact-form-text205 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="contact-form-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="contact-form-dropdown-toggle20"
                >
                  <span className="contact-form-text206">Виза олиш</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="contact-form-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="contact-form-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle21"
                      >
                        <span className="contact-form-text207">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle22"
                      >
                        <span className="contact-form-text208">
                          Миллий виза
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle23"
                      >
                        <span className="contact-form-text209">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle24"
                      >
                        <span className="contact-form-text210">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle25"
                      >
                        <span className="contact-form-text213">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle26"
                      >
                        <span className="contact-form-text217">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle27"
                      >
                        <span className="contact-form-text218">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="contact-form-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="contact-form-dropdown-toggle28"
                >
                  <span className="contact-form-text219">
                    Кушимча хизматлар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="contact-form-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="contact-form-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle29"
                      >
                        <span className="contact-form-text220">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle30"
                      >
                        <span className="contact-form-text221">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle31"
                      >
                        <span className="contact-form-text225">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle32"
                      >
                        <span className="contact-form-text228">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle33"
                      >
                        <span className="contact-form-text229">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle34"
                      >
                        <span className="contact-form-text233">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle35"
                      >
                        <span className="contact-form-text234">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="contact-form-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="contact-form-dropdown-toggle38"
                  >
                    <span className="contact-form-text237">FAQ</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="contact-form-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="contact-form-dropdown-toggle39"
                >
                  <span className="contact-form-text238">Алокалар</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="contact-form-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="contact-form-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle40"
                      >
                        <span className="contact-form-text239">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle41"
                      >
                        <span className="contact-form-text240">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle42"
                      >
                        <span className="contact-form-text241">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="contact-form-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="contact-form-dropdown-toggle43"
                      >
                        <span className="contact-form-text242">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="contact-form-container39">
          <input
            type="text"
            placeholder="Кидирмок"
            className="contact-form-textinput18 input"
          />
          <div className="contact-form-container40">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="contact-form-icon20"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="contact-form-container41">
            <span className="contact-form-text245">A+</span>
            <span className="contact-form-text246">A-</span>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="contact-form-burger-menu2">
          <svg viewBox="0 0 1024 1024" className="contact-form-icon22">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="contact-form-mobile-menu">
          <div className="contact-form-nav">
            <div className="contact-form-container42">
              <div className="contact-form-container43">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="contact-form-textinput19 input"
                />
                <div className="contact-form-container44">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="contact-form-icon24"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="contact-form-container45">
                  <span className="contact-form-text247">A+</span>
                  <span className="contact-form-text248">A-</span>
                </div>
              </div>
              <div
                data-thq="thq-close-menu"
                className="contact-form-close-menu"
              >
                <svg viewBox="0 0 1024 1024" className="contact-form-icon26">
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="contact-form-links2">
            <div
              data-thq="thq-dropdown"
              className="contact-form-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="contact-form-dropdown-toggle44"
                >
                  <span className="contact-form-text249">Бош сахифа</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="contact-form-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="contact-form-dropdown-toggle45"
              >
                <span className="contact-form-text250">Умумий</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="contact-form-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="contact-form-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="contact-form-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle46"
                    >
                      <span className="contact-form-text251">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle47"
                    >
                      <span className="contact-form-text254">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle48"
                    >
                      <span className="contact-form-text255">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle49"
                    >
                      <span className="contact-form-text256">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle50"
                    >
                      <span className="contact-form-text261">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle51"
                    >
                      <span className="contact-form-text262">
                        Тулов Шартлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle52"
                    >
                      <span className="contact-form-text263">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle53"
                    >
                      <span className="contact-form-text266">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="contact-form-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="contact-form-dropdown-toggle54"
              >
                <span className="contact-form-text267">Виза олиш</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="contact-form-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="contact-form-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="contact-form-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle55"
                    >
                      <span className="contact-form-text268">
                        Шенген визаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle56"
                    >
                      <span className="contact-form-text269">Миллий виза</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle57"
                    >
                      <span className="contact-form-text270">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle58"
                    >
                      <span className="contact-form-text271">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle59"
                    >
                      <span className="contact-form-text274">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle60"
                    >
                      <span className="contact-form-text278">
                        Ариза шакллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle61"
                    >
                      <span className="contact-form-text279">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="contact-form-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="contact-form-dropdown-toggle62"
              >
                <span className="contact-form-text280">Кушимча хизматлар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="contact-form-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="contact-form-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="contact-form-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle63"
                    >
                      <span className="contact-form-text281">
                        VIP - хизмати
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle64"
                    >
                      <span className="contact-form-text282">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle65"
                    >
                      <span className="contact-form-text283">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle66"
                    >
                      <span className="contact-form-text284">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle67"
                    >
                      <span className="contact-form-text285">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle68"
                    >
                      <span className="contact-form-text288">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle69"
                    >
                      <span className="contact-form-text291">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle70"
                    >
                      <span className="contact-form-text292">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle71"
                    >
                      <span className="contact-form-text293">Prime Тиме</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="contact-form-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="contact-form-dropdown-toggle72"
                >
                  <span className="contact-form-text294">FAQ</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="contact-form-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="contact-form-dropdown-toggle73"
              >
                <span className="contact-form-text295">Алокалар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="contact-form-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="contact-form-icon34"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="contact-form-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle74"
                    >
                      <span className="contact-form-text296">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle75"
                    >
                      <span className="contact-form-text297">
                        Алока маркази
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle76"
                    >
                      <span className="contact-form-text298">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="contact-form-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="contact-form-dropdown-toggle77"
                    >
                      <span className="contact-form-text299">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="contact-form-container46">
        <div className="contact-form-container47">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="contact-form-image19"
          />
          <span className="contact-form-text302">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="contact-form-container48">
          <div className="contact-form-container49">
            <span className="contact-form-text303">Ходимларни бошқариш</span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="contact-form-image20"
            />
          </div>
          <div className="contact-form-container50">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="contact-form-navlink78"
            >
              <div className="contact-form-container51">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="contact-form-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="contact-form-text304">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="contact-form-navlink79"
            >
              <div className="contact-form-container52">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="contact-form-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="contact-form-text305">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="contact-form-navlink80"
            >
              <div className="contact-form-container53">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="contact-form-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="contact-form-text309">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="contact-form-navlink81"
            >
              <div className="contact-form-container54">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="contact-form-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="contact-form-text313">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="contact-form-navlink82"
            >
              <div className="contact-form-container55">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="contact-form-icon44"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="contact-form-text317">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="contact-form-container56">
        <div className="contact-form-container57">
          <span className="contact-form-text321">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="contact-form-container58">
            <Link to="/visametric-gurukhi" className="contact-form-navlink83">
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="contact-form-navlink84"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/w3c-logo2-200h.png"
          className="contact-form-image21"
        />
        <a href="#main" className="contact-form-link3">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="contact-form-image22"
          />
        </a>
      </div>
    </div>
  )
}

export default ContactForm
