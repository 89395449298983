import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './umumiy-malumot.css'

const UmumiyMalumot = (props) => {
  return (
    <div className="umumiy-malumot-container10">
      <Helmet>
        <title>umumiy-malumot - Visametric</title>
        <meta property="og:title" content="umumiy-malumot - Visametric" />
      </Helmet>
      <div data-thq="thq-burger-menu" className="umumiy-malumot-burger-menu1">
        <svg viewBox="0 0 1024 1024" className="umumiy-malumot-icon10">
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div id="main" className="umumiy-malumot-container11">
        <h1 className="umumiy-malumot-text100">Умумий маълумот</h1>
        <div className="umumiy-malumot-container12">
          <Link to="/uzbekistan-germanyuz" className="umumiy-malumot-navlink10">
            <div className="umumiy-malumot-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="umumiy-malumot-icon12"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="umumiy-malumot-text101">Бош саҳифа</h1>
            </div>
          </Link>
          <Link to="/uzbekistan-germanyuz" className="umumiy-malumot-navlink11">
            <div className="umumiy-malumot-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="umumiy-malumot-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="umumiy-malumot-text102">FAQ</h1>
            </div>
          </Link>
          <Link to="/uzbekistan-germanyuz" className="umumiy-malumot-navlink12">
            <div className="umumiy-malumot-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="umumiy-malumot-icon16"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="umumiy-malumot-text103">Умумий маълумот</h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="umumiy-malumot-container16">
        <div className="umumiy-malumot-container17">
          <div className="umumiy-malumot-container18">
            <span className="umumiy-malumot-text104">FAQ</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="umumiy-malumot-icon18"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="umumiy-malumot-image10"
          />
          <div className="umumiy-malumot-container19">
            <Link to="/umumiy-malumot" className="umumiy-malumot-navlink13">
              <div className="umumiy-malumot-container20">
                <img
                  alt="image"
                  src="/rectangle%2030.svg"
                  className="umumiy-malumot-image11"
                />
                <span className="umumiy-malumot-text105">Умумий маълумот</span>
              </div>
            </Link>
            <Link to="/ariza-oldin" className="umumiy-malumot-navlink14">
              <div className="umumiy-malumot-container21">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="umumiy-malumot-image12"
                />
                <span className="umumiy-malumot-text106">Ариза олдин</span>
              </div>
            </Link>
            <Link
              to="/ariza-topshirish-zharaeni"
              className="umumiy-malumot-navlink15"
            >
              <div className="umumiy-malumot-container22">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="umumiy-malumot-image13"
                />
                <span className="umumiy-malumot-text107">
                  Ариза топшириш жараёни
                </span>
              </div>
            </Link>
            <Link to="/uzhzhatlar" className="umumiy-malumot-navlink16">
              <div className="umumiy-malumot-container23">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="umumiy-malumot-image14"
                />
                <span className="umumiy-malumot-text108">Ҳужжатлар</span>
              </div>
            </Link>
            <Link to="/shimcha-khizmatlar" className="umumiy-malumot-navlink17">
              <div className="umumiy-malumot-container24">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="umumiy-malumot-image15"
                />
                <span className="umumiy-malumot-text109">
                  Қўшимча хизматлар
                </span>
              </div>
            </Link>
            <Link
              to="/ariza-topshirishdan-snggi-olat"
              className="umumiy-malumot-navlink18"
            >
              <div className="umumiy-malumot-container25">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="umumiy-malumot-image16"
                />
                <span className="umumiy-malumot-text110">
                  Ариза топширишдан сўнгги ҳолат
                </span>
              </div>
            </Link>
          </div>
        </div>
        <div className="umumiy-malumot-container26">
          <span className="umumiy-malumot-text111">
            <span>1. Узбекистон / FAQ / Умумий маълумот</span>
            <br className="umumiy-malumot-text113"></br>
          </span>
          <span className="umumiy-malumot-text114">
            <span className="umumiy-malumot-text115">
              Мажбурият аризаси нима?
            </span>
            <br className="umumiy-malumot-text116"></br>
            <br className="umumiy-malumot-text117"></br>
            <span>
              Мажбурият аризаси, учинчи давлат фуқароси яшаш харажатлари
              хавфсизлиги учун қўлланилади ва маъмурий жараёнида исботлаш
              имконини беради ва доимий яшовчининг картаси Турар жой қонунининг
              5-моддаси 1-бандига биноан тўлдирилади.
            </span>
            <br className="umumiy-malumot-text119"></br>
            <span>
              Мажбурият хатини қабул қилганидан сўнг қабул қилувчи ариза
              берувчининг яшаши ва виза олиш ҳаражатларини ўз бўйнига олади.
              Хатни олувчи жисмоний ва юридик шахс бўлиши мумкин.
            </span>
            <br className="umumiy-malumot-text121"></br>
            <br className="umumiy-malumot-text122"></br>
            <br className="umumiy-malumot-text123"></br>
            <span className="umumiy-malumot-text124">
              Мажбуриятларни қабул қилиш аризаси билан Мултивиза олиш учун ариза
              бера оламанми?
            </span>
            <br className="umumiy-malumot-text125"></br>
            <br className="umumiy-malumot-text126"></br>
            <span>
              Ҳа. Қабул қилувчи давлатингиз чет эллик шахс режалаштираётган
              қолиш вақтини (бир йилдан 4 йилгача) ва мажбурият аризасини сўраши
              лозим.
            </span>
            <br className="umumiy-malumot-text128"></br>
            <span>
              Бу ҳолатда Германия ваколатхонаси бутун яшаш вақти учун молиявий
              кафолатга эга бўлади.
            </span>
            <br className="umumiy-malumot-text130"></br>
            <span>
              Чет элликлар ваколатли органи фақат қисқа давр учун мажбуриятларни
              бажариш мажбуриятини олган бўлса, сиз (ариза топширувчи) қўшимча
              тарзда молиявий аҳвол кафолатини тақдим этишингиз керак. Бу сўнгги
              3-6-ойлик банк ҳисоб рақами аризаси сизнинг ҳар ойлик
              даромадингизни кўрсатади ва чет элда кўпроқ муддатга қолиш
              имкониятингизни тасдиқлайди.
            </span>
            <br className="umumiy-malumot-text132"></br>
            <br className="umumiy-malumot-text133"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="umumiy-malumot-text135"></br>
            <span className="umumiy-malumot-text136">Қариндошлик нима?</span>
            <br className="umumiy-malumot-text137"></br>
            <br className="umumiy-malumot-text138"></br>
            <span>
              Элчихона виза тақдим қилишдан олдин кариндошликни аниқлайди. Лозим
              топилса, таклиф қилувчи ва таклиф этилувчи ўртасидаги
              кариндошликни текширади.
            </span>
            <br className="umumiy-malumot-text140"></br>
            <span>
              Шахс билан қариндошлик туғилганлик ва никоҳ гувоҳномалари асосида
              тасдиқланади. Масалан, опа ёки акангиз билан қариндошликни
              исботлашингиз учун уларнинг туғилганлик тўғрисидаги гувоҳнома
              ҳамда ўзингизнинг гувоҳномангизни тақдим қилишингиз керак. Бу
              иккала шахс бир ота онага эгалигини тасдиқлайди
            </span>
            <br className="umumiy-malumot-text142"></br>
            <br className="umumiy-malumot-text143"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="umumiy-malumot-text145"></br>
            <span className="umumiy-malumot-text146">
              Норасмий таклифнома нима ва у қандай кўринишга эга?
            </span>
            <br className="umumiy-malumot-text147"></br>
            <br className="umumiy-malumot-text148"></br>
            <span>
              Оила аъзоларингизни кўриб келишингиз учун бериладиган виза учун
              қайси фуқарони кўриб келишингиз томонидан берилган норасмий
              таклифномани тақдим этишингиз керак.
            </span>
            <br className="umumiy-malumot-text150"></br>
            <span>
              Норасмий таклифнома немис тилида бўлиши керак ва унда саёхат
              мазмуни ва вақти ёзилган бўлиши керак. Норасмий таклифнома қўлда
              ёзилган ёки компьютерда таҳрир қилинган бўлиши мумкин. У шахсан
              таклиф қилувчи томонидан ёзилган бўлиши керак. Имзо
              аутентификацияси зарур эмас.
            </span>
            <br className="umumiy-malumot-text152"></br>
            <br className="umumiy-malumot-text153"></br>
            <br className="umumiy-malumot-text154"></br>
            <span className="umumiy-malumot-text155">
              Виза шаклини текшириб чиқиш учун қанча вақт зарур?
            </span>
            <br className="umumiy-malumot-text156"></br>
            <br className="umumiy-malumot-text157"></br>
            <span>
              Шенген зонаси учун аризани куриб чикиш муддати 10 иш кунидан
              иборат.
            </span>
            <br className="umumiy-malumot-text159"></br>
            <span>
              Андижон ва Бухородаги Виза марказларида ариза берилган холда,
              аризани куриб чикиш муддати 15 иш кунидан иборат.
            </span>
            <br className="umumiy-malumot-text161"></br>
            <br className="umumiy-malumot-text162"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="umumiy-malumot-text164"></br>
            <span className="umumiy-malumot-text165">Виза нархи қанча?</span>
            <br className="umumiy-malumot-text166"></br>
            <br className="umumiy-malumot-text167"></br>
            <span>
              Шенген визаси 80 Евро туради. 6 ёшгача болалар солиқдан озод
              ҳисобланади. Виза 6-12 ёшгача бўлган болалар учун 40 евро туради.
            </span>
            <br className="umumiy-malumot-text169"></br>
            <br className="umumiy-malumot-text170"></br>
            <br className="umumiy-malumot-text171"></br>
            <span className="umumiy-malumot-text172">
              Шенген визасини тақдим қилинишидан олдин элчихона нимани
              текширади?
            </span>
            <br className="umumiy-malumot-text173"></br>
            <br className="umumiy-malumot-text174"></br>
            <span>Элчихона қуйидаги мезонларга кўра текширув олиб боради:</span>
            <br className="umumiy-malumot-text176"></br>
            <span>- Ташриф мақсади (масалан, меҳмондочилик, иш сафари);</span>
            <br className="umumiy-malumot-text178"></br>
            <span>- Қайтиб келиш истаги;</span>
            <br className="umumiy-malumot-text180"></br>
            <span>
              - Саёҳат молиялаштирилуви (қабул қилувчи томон ёки саёҳатчи);
            </span>
            <br className="umumiy-malumot-text182"></br>
            <span>- Тиббий суғуртани таёминлаш;</span>
            <br className="umumiy-malumot-text184"></br>
            <span>
              Бошқа маълумотлар (AZR, SIS рўйхатга олади, масалан, жиноятлар ёки
              бошқа қоидабузарлик/майда қоидабузарликлар).
            </span>
            <br className="umumiy-malumot-text186"></br>
            <br className="umumiy-malumot-text187"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="umumiy-malumot-text189"></br>
            <span className="umumiy-malumot-text190">
              Йиллик виза ва мулти-виза нима?
            </span>
            <br className="umumiy-malumot-text191"></br>
            <br className="umumiy-malumot-text192"></br>
            <span>
              Бу ерда амал қилиш муддати бир йил ва ундан кўпроқ максимал 5 йил
              бўлган, олти ой ичида 3 ой муддатга қолиш имконини берувчи, ҳамда
              давлатга бир неча бор кириб чиқишга рухсат берувчи шенген визаси
              тури ҳақида гап кетган. Европа парламент ива Европа Иттифоқининг
              2009 йил 13 июлдаги Жамоатчилик Визалари кодексини тузиш
              тўғрисидаги (EG) № 810/2009 қарори бунга сабабдир. (Қисқартириб:
              “Виза Кодекси”)
            </span>
            <br className="umumiy-malumot-text194"></br>
            <span>
              Виза кодексининг 24 моддаси 2 бандига асосан, ариза топширувчи
              оилавий ёки иш сабабларига кўра тез-тез ташриф уюштиришга
              мажбурлигини исботласа ёки кўрсатса, кўп марталик визалар кўпроқ
              муддатга берилади.
            </span>
            <br className="umumiy-malumot-text196"></br>
            <span>
              Қоидага асосан, бир йиллик виза ёки кўп марталик виза ариза
              топширувчи ўзининг шаффофлиги ва ишончни исботлаганидан сўнг
              берилиши мумкин, хусусан, олдинги визаларнинг қонуний ишлатилиши
              тасдиқланганидан сўнг (Виза кодексининг 24- моддаси 2 банди)
            </span>
            <br className="umumiy-malumot-text198"></br>
            <span>
              Ҳар қандай ҳолатда ҳам «90» сони виза кредитида «Ташриф
              муддати»дан кейин қўйилади. Бу бутун амал қилиш муддатига рухсат
              берилган вақт эмас, аммо олти ой давомидаги ташриф учун рухсат
              берилган вақт ҳисобланади.
            </span>
            <br className="umumiy-malumot-text200"></br>
            <br className="umumiy-malumot-text201"></br>
            <br className="umumiy-malumot-text202"></br>
            <span className="umumiy-malumot-text203">
              VisaMetric қабул маркази ходими томонидан тушунтириш ишлари
              нотўғри олиб борилди, ёки менинг аризам учинчи шахс томонидан виза
              олиб бериш ваъдаси билан тўлдирилди. Менинг аризамга рад жавоби
              берилди, нима қилсам бўлади?
            </span>
            <br className="umumiy-malumot-text204"></br>
            <br className="umumiy-malumot-text205"></br>
            <span>
              Аризангиз мазмуни учун жавобгарликни ўз зиммангизга оласиз.
              VisaMetric да ишламайдиган шахслар уларнинг хизматидан
              фойдаланаётганингизга амин бўлишса, бу муаммони улар билан
              ечишингизни сўраймиз.
            </span>
            <br className="umumiy-malumot-text207"></br>
            <span>
              Ҳамма маълумотларни тоза виждон билан тўғри тақдим этишингизни
              сўраймиз. Ҳеч ким профессионал тўлдирилган ариза сизга виза
              олишингиз кафолати эканлигини айтишига йўл қўйманг – ҳеч ким сизга
              виза ваъда қилолмайди! Ҳар қандай одам ариза шаклидаги саволларга
              шахсан жавоб бера олади. Агар анкета топширишда муаммога дуч
              келсангиз, Visametric ходимлари сизга албатта ёрдам беради.
            </span>
            <br className="umumiy-malumot-text209"></br>
            <br className="umumiy-malumot-text210"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="umumiy-malumot-text212"></br>
            <span className="umumiy-malumot-text213">
              Бошқа ҳужжатлар
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="umumiy-malumot-text214"></br>
            <span>
              Мен/менинг меҳмонимдан рўйхатда кўрсатилмаган бошқа ҳужжатлар
              сўралди, бу қандай содир бўлди?
            </span>
            <br className="umumiy-malumot-text216"></br>
            <br className="umumiy-malumot-text217"></br>
            <span>
              Элчихона айнан бир махсус ишлар бўйича қарор қабул қилади. Лекин
              рўйхат фақатгина ҳаммада бўлиши мумкин бўлган ҳолатларни эмас,
              умумий маълумотларни ўз ичига олиши мумкин. Хусусан, сизнинг
              ҳолатингизда рўйхатга киритилмаган ҳужжат талаб қилиниши ҳам
              мумкин. Қўшимча маълумотлар талаб қилинганлиги сиз учун виза
              берилмаслигини англатмайди.
            </span>
            <br className="umumiy-malumot-text219"></br>
            <br className="umumiy-malumot-text220"></br>
            <br className="umumiy-malumot-text221"></br>
            <span className="umumiy-malumot-text222">
              Дипломатик паспорт эгалари учун визасиз кириш.
            </span>
            <br className="umumiy-malumot-text223"></br>
            <br className="umumiy-malumot-text224"></br>
            <span>
              2014 йил 1 сентабридан бошлаб дипломатик паспорт эгалари учун
              Шенген давлатлари ҳудудига қисқа муддатли визалар зарур эмас.
            </span>
            <br className="umumiy-malumot-text226"></br>
            <br className="umumiy-malumot-text227"></br>
            <br className="umumiy-malumot-text228"></br>
            <span className="umumiy-malumot-text229">
              Качон Узбекистондаги Visametric булимида виза олиш учун ариза
              бериш мумкин булади?
            </span>
            <br className="umumiy-malumot-text230"></br>
            <br className="umumiy-malumot-text231"></br>
            <span>
              Качонки Сиз Узбекистонда доимий ва конуний равишда яшаб келаётган
              булсангиз ва асосий борадиган давлатингиз Германия булса,
              шундагина Узбекистондаги Visametric булимида ариза беришингиз
              мумкин.
            </span>
            <br className="umumiy-malumot-text233"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="umumiy-malumot-text235"></br>
            <br className="umumiy-malumot-text236"></br>
            <span className="umumiy-malumot-text237">
              Саёхатим давомида Шенген зонаси давлатларида бўлмоқчиман. Германия
              элчихонасига ариза бера олманми?
            </span>
            <br className="umumiy-malumot-text238"></br>
            <br className="umumiy-malumot-text239"></br>
            <span>
              Агар Германияда ўтказадиган вақтингиз Шенген зонасининг қолган
              давлатларникидан кўпроқ бўлса, Германия элчихонасига мурожаат
              қилсангиз бўлади. Бир нечта давлатда маълум бир вақтда қолсангиз,
              виза учун биринчи кирган давлатингиз элчихонасига мурожаат
              қилишингиз керак.
            </span>
            <br className="umumiy-malumot-text241"></br>
            <br className="umumiy-malumot-text242"></br>
            <br className="umumiy-malumot-text243"></br>
            <span className="umumiy-malumot-text244">
              Качон ариза беришим мумкин?
            </span>
            <br className="umumiy-malumot-text245"></br>
            <br className="umumiy-malumot-text246"></br>
            <span>
              Ариза топшириш сайтимизда сиз томондан белгиланган вактда
              факатгина кабулга ёзилган куннгизда амалги оширилади.
            </span>
            <br className="umumiy-malumot-text248"></br>
            <span>
              Санани белгилаш электрон шаклда кабул системамиз оркали амалги
              оширилади. Илтимос, белгиланган кун ва соатдан 15 минут илгари
              келишингиз суралади.
            </span>
            <br className="umumiy-malumot-text250"></br>
            <br className="umumiy-malumot-text251"></br>
            <br className="umumiy-malumot-text252"></br>
            <span className="umumiy-malumot-text253">
              Вояга етмаганлар учун қўшимча ҳужжатлар
            </span>
            <br className="umumiy-malumot-text254"></br>
            <br className="umumiy-malumot-text255"></br>
            <span>Вояга етмаганлар йурикномада кўрсатилган барча</span>
            <br className="umumiy-malumot-text257"></br>
            <span>
              ҳужжатларни тақдим этишлари зарур. Шунингдек, туғилганлик
              тўғрисидаги
            </span>
            <br className="umumiy-malumot-text259"></br>
            <span>
              маълумотномани доим тақдим этишингиз керак. Иштирок этмаётган
              ота-онадан
            </span>
            <br className="umumiy-malumot-text261"></br>
            <span>
              бири Шенген худуди давлатларига саёхат учун нотариал тасдиқланган
            </span>
            <br className="umumiy-malumot-text263"></br>
            <span>
              розиликни шахсан тақдим этишлари зарур. Розилик асл нусхада узбек
              тилида нотариал тасдикланган ва немис тилига угирилган холатда
              нусхаси билан бирга такдим килинади.
            </span>
            <br className="umumiy-malumot-text265"></br>
            <span>
              Агар биринчи саёхат Шенген худудининг бошка давлатга амалги
              оширилса, розилик хатини уша давлат тилига, булмаса инглиз тилига
              таржима килиш зарур.
            </span>
            <br className="umumiy-malumot-text267"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="umumiy-malumot-text269"></br>
            <br className="umumiy-malumot-text270"></br>
            <span className="umumiy-malumot-text271">
              Аризам тезроқ қониқтирилиши учун кўпроқ тўлашим мумкинми?
            </span>
            <br className="umumiy-malumot-text272"></br>
            <br className="umumiy-malumot-text273"></br>
            <span>
              Йўқ, буни иложи йўқ. VisaMetric қонунда кўрсатилгандан ортиқча
              тўловларни қабул қилолмайди
            </span>
            <br className="umumiy-malumot-text275"></br>
            <span>
              Қўшимча тўловлар фақат белгиланган харажатлар учун амалга
              оширилади (масалан, қўнғироқлар ёки Германияга факс жўнатиш учун).
            </span>
            <br className="umumiy-malumot-text277"></br>
            <br className="umumiy-malumot-text278"></br>
            <br className="umumiy-malumot-text279"></br>
            <span className="umumiy-malumot-text280">
              Хозиргача амалдаги Шенген визасига эгаман. Лекин паспортимда бўш
              варақлари йўқ ва мен янги паспорт учун ариза беришим керак Визамга
              нима бўлади?
            </span>
            <br className="umumiy-malumot-text281"></br>
            <br className="umumiy-malumot-text282"></br>
            <span>
              Паспорт яроқлилик муддати тугаган бўлса ҳам, олдинги паспорт
              визангиз яроқли бўлади Шунингдек, сиз 2та паспорт билан муаммосиз
              саёхат қилишингиз мумкин Бошқа жиҳатдан, Виза хизматлари ахборот
              бўлимига янги ва эски паспортларингиз билан мурожаат қилишингиз
              мумкин.
            </span>
            <br className="umumiy-malumot-text284"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="umumiy-malumot-text286"></br>
            <br className="umumiy-malumot-text287"></br>
            <span className="umumiy-malumot-text288">
              Чет эл паспортларини Визалаштириш: Фақатгина бузилмаган, ҳақиқий
              ва (асосий паспорт эгалари учун) қўл имзоси бўлган паспортлар
              визага эга бўлиши мумкин.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="umumiy-malumot-text289"></br>
            <br className="umumiy-malumot-text290"></br>
            <span>
              Бундан ташқари, Виза кодексига кўра, Шенген визалари фақатгина:
            </span>
            <br className="umumiy-malumot-text292"></br>
            <br className="umumiy-malumot-text293"></br>
            <span>
              - режалаштирилган ташриф тугагандан камида 3 ой давомида ҳақиқий
              бўлади;
            </span>
            <br className="umumiy-malumot-text295"></br>
            <br className="umumiy-malumot-text296"></br>
            <span>
              {' '}
              - камида сўнгги 10 йил ичида чиқарилган;
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="umumiy-malumot-text298"></br>
            <br className="umumiy-malumot-text299"></br>
            <span>
              {' '}
              - камида иккита бўш варақга эга бўлиши (виза берилиши учун)*
            </span>
            <br className="umumiy-malumot-text301"></br>
            <br className="umumiy-malumot-text302"></br>
            <span>
              * Агар виза ота онасининг паспортида рўйхатдан ўтган вояга етмаган
              бола учун берилаётган бўлса яна битта бўш варақга эга бўлиши шарт.
            </span>
            <br className="umumiy-malumot-text304"></br>
            <br className="umumiy-malumot-text305"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="umumiy-malumot-text307"></br>
            <br className="umumiy-malumot-text308"></br>
            <span className="umumiy-malumot-text309">
              Иловани тўлдиришга қайси тилни ишлатасиз?
            </span>
            <br className="umumiy-malumot-text310"></br>
            <br className="umumiy-malumot-text311"></br>
            <span>
              Шенген визаси учун (анкета) сўровномаси немис ва инглиз тилида
              тўлдирилиши мумкин.
            </span>
            <br className="umumiy-malumot-text313"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="umumiy-malumot-text315"></br>
            <br className="umumiy-malumot-text316"></br>
            <span className="umumiy-malumot-text317">
              Иловани ёзма равишда қўлда тўлдира оламанми?
            </span>
            <br className="umumiy-malumot-text318"></br>
            <br className="umumiy-malumot-text319"></br>
            <span>
              Техник муаммоларга кўра онлайн шаклни тўлдира олмасангиз, иловани
              қўлёзма равишда тўлдиришингиз мумкин. Бироқ, бу ҳолатда илова
              топширилгандан кейинги кутиш даври узаяди.
            </span>
            <br className="umumiy-malumot-text321"></br>
            <br className="umumiy-malumot-text322"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="umumiy-malumot-text324"></br>
          </span>
        </div>
      </div>
      <div id="header" className="umumiy-malumot-container27">
        <Link to="/" className="umumiy-malumot-navlink19">
          <div className="umumiy-malumot-container28">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="umumiy-malumot-image17"
            />
            <p className="umumiy-malumot-text325">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="umumiy-malumot-container29">
          <div className="umumiy-malumot-container30">
            <span className="umumiy-malumot-text328">
              <span>Apply for Visa to</span>
              <span className="umumiy-malumot-text330"> Germany</span>
            </span>
            <span className="umumiy-malumot-text331">
              <span className="umumiy-malumot-text332">in Uzbekistan</span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="umumiy-malumot-image18"
          />
          <span className="umumiy-malumot-text363">|</span>
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header
        data-thq="thq-navbar"
        className="umumiy-malumot-navbar-interactive"
      >
        <div data-thq="thq-navbar-nav" className="umumiy-malumot-desktop-menu">
          <div className="umumiy-malumot-container31">
            <nav className="umumiy-malumot-links1">
              <div
                data-thq="thq-dropdown"
                className="umumiy-malumot-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="umumiy-malumot-dropdown-toggle10"
                  >
                    <span className="umumiy-malumot-text364">Бош сахифа</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="umumiy-malumot-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="umumiy-malumot-dropdown-toggle11"
                >
                  <span className="umumiy-malumot-text365">Умумий</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="umumiy-malumot-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="umumiy-malumot-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle12"
                      >
                        <span className="umumiy-malumot-text366 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle13"
                      >
                        <span className="umumiy-malumot-text369 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle14"
                      >
                        <span className="umumiy-malumot-text370 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle15"
                      >
                        <span className="umumiy-malumot-text371 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle16"
                      >
                        <span className="umumiy-malumot-text376 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle17"
                      >
                        <span className="umumiy-malumot-text377 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle18"
                      >
                        <span className="umumiy-malumot-text378 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle19"
                      >
                        <span className="umumiy-malumot-text382 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="umumiy-malumot-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="umumiy-malumot-dropdown-toggle20"
                >
                  <span className="umumiy-malumot-text383">Виза олиш</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="umumiy-malumot-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="umumiy-malumot-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle21"
                      >
                        <span className="umumiy-malumot-text384">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle22"
                      >
                        <span className="umumiy-malumot-text385">
                          Миллий виза
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle23"
                      >
                        <span className="umumiy-malumot-text386">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle24"
                      >
                        <span className="umumiy-malumot-text387">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle25"
                      >
                        <span className="umumiy-malumot-text390">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle26"
                      >
                        <span className="umumiy-malumot-text394">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle27"
                      >
                        <span className="umumiy-malumot-text395">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="umumiy-malumot-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="umumiy-malumot-dropdown-toggle28"
                >
                  <span className="umumiy-malumot-text396">
                    Кушимча хизматлар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="umumiy-malumot-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="umumiy-malumot-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle29"
                      >
                        <span className="umumiy-malumot-text397">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle30"
                      >
                        <span className="umumiy-malumot-text398">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle31"
                      >
                        <span className="umumiy-malumot-text402">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle32"
                      >
                        <span className="umumiy-malumot-text405">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle33"
                      >
                        <span className="umumiy-malumot-text406">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle34"
                      >
                        <span className="umumiy-malumot-text410">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle35"
                      >
                        <span className="umumiy-malumot-text411">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="umumiy-malumot-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="umumiy-malumot-dropdown-toggle38"
                  >
                    <span className="umumiy-malumot-text414">FAQ</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="umumiy-malumot-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="umumiy-malumot-dropdown-toggle39"
                >
                  <span className="umumiy-malumot-text415">Алокалар</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="umumiy-malumot-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="umumiy-malumot-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle40"
                      >
                        <span className="umumiy-malumot-text416">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle41"
                      >
                        <span className="umumiy-malumot-text417">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle42"
                      >
                        <span className="umumiy-malumot-text418">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="umumiy-malumot-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="umumiy-malumot-dropdown-toggle43"
                      >
                        <span className="umumiy-malumot-text419">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="umumiy-malumot-container32">
          <input
            type="text"
            placeholder="Кидирмок"
            className="umumiy-malumot-textinput1 input"
          />
          <div className="umumiy-malumot-container33">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="umumiy-malumot-icon20"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="umumiy-malumot-container34">
            <span className="umumiy-malumot-text422">A+</span>
            <span className="umumiy-malumot-text423">A-</span>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="umumiy-malumot-burger-menu2">
          <svg viewBox="0 0 1024 1024" className="umumiy-malumot-icon22">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="umumiy-malumot-mobile-menu">
          <div className="umumiy-malumot-nav">
            <div className="umumiy-malumot-container35">
              <div className="umumiy-malumot-container36">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="umumiy-malumot-textinput2 input"
                />
                <div className="umumiy-malumot-container37">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="umumiy-malumot-icon24"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="umumiy-malumot-container38">
                  <span className="umumiy-malumot-text424">A+</span>
                  <span className="umumiy-malumot-text425">A-</span>
                </div>
              </div>
              <div
                data-thq="thq-close-menu"
                className="umumiy-malumot-close-menu"
              >
                <svg viewBox="0 0 1024 1024" className="umumiy-malumot-icon26">
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="umumiy-malumot-links2">
            <div
              data-thq="thq-dropdown"
              className="umumiy-malumot-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="umumiy-malumot-dropdown-toggle44"
                >
                  <span className="umumiy-malumot-text426">Бош сахифа</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="umumiy-malumot-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="umumiy-malumot-dropdown-toggle45"
              >
                <span className="umumiy-malumot-text427">Умумий</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="umumiy-malumot-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="umumiy-malumot-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="umumiy-malumot-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle46"
                    >
                      <span className="umumiy-malumot-text428">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle47"
                    >
                      <span className="umumiy-malumot-text431">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle48"
                    >
                      <span className="umumiy-malumot-text432">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle49"
                    >
                      <span className="umumiy-malumot-text433">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle50"
                    >
                      <span className="umumiy-malumot-text438">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle51"
                    >
                      <span className="umumiy-malumot-text439">
                        Тулов Шартлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle52"
                    >
                      <span className="umumiy-malumot-text440">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle53"
                    >
                      <span className="umumiy-malumot-text443">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="umumiy-malumot-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="umumiy-malumot-dropdown-toggle54"
              >
                <span className="umumiy-malumot-text444">Виза олиш</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="umumiy-malumot-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="umumiy-malumot-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="umumiy-malumot-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle55"
                    >
                      <span className="umumiy-malumot-text445">
                        Шенген визаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle56"
                    >
                      <span className="umumiy-malumot-text446">
                        Миллий виза
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle57"
                    >
                      <span className="umumiy-malumot-text447">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle58"
                    >
                      <span className="umumiy-malumot-text448">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle59"
                    >
                      <span className="umumiy-malumot-text451">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle60"
                    >
                      <span className="umumiy-malumot-text455">
                        Ариза шакллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle61"
                    >
                      <span className="umumiy-malumot-text456">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="umumiy-malumot-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="umumiy-malumot-dropdown-toggle62"
              >
                <span className="umumiy-malumot-text457">
                  Кушимча хизматлар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="umumiy-malumot-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="umumiy-malumot-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="umumiy-malumot-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle63"
                    >
                      <span className="umumiy-malumot-text458">
                        VIP - хизмати
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle64"
                    >
                      <span className="umumiy-malumot-text459">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle65"
                    >
                      <span className="umumiy-malumot-text460">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle66"
                    >
                      <span className="umumiy-malumot-text461">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle67"
                    >
                      <span className="umumiy-malumot-text462">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle68"
                    >
                      <span className="umumiy-malumot-text465">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle69"
                    >
                      <span className="umumiy-malumot-text468">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle70"
                    >
                      <span className="umumiy-malumot-text469">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle71"
                    >
                      <span className="umumiy-malumot-text470">Prime Тиме</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="umumiy-malumot-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="umumiy-malumot-dropdown-toggle72"
                >
                  <span className="umumiy-malumot-text471">FAQ</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="umumiy-malumot-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="umumiy-malumot-dropdown-toggle73"
              >
                <span className="umumiy-malumot-text472">Алокалар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="umumiy-malumot-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="umumiy-malumot-icon34"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="umumiy-malumot-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle74"
                    >
                      <span className="umumiy-malumot-text473">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle75"
                    >
                      <span className="umumiy-malumot-text474">
                        Алока маркази
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle76"
                    >
                      <span className="umumiy-malumot-text475">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="umumiy-malumot-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="umumiy-malumot-dropdown-toggle77"
                    >
                      <span className="umumiy-malumot-text476">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="umumiy-malumot-container39">
        <div className="umumiy-malumot-container40">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="umumiy-malumot-image20"
          />
          <span className="umumiy-malumot-text479">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="umumiy-malumot-container41">
          <div className="umumiy-malumot-container42">
            <span className="umumiy-malumot-text480">Ходимларни бошқариш</span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="umumiy-malumot-image21"
            />
          </div>
          <div className="umumiy-malumot-container43">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="umumiy-malumot-navlink80"
            >
              <div className="umumiy-malumot-container44">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="umumiy-malumot-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="umumiy-malumot-text481">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="umumiy-malumot-navlink81"
            >
              <div className="umumiy-malumot-container45">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="umumiy-malumot-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="umumiy-malumot-text482">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="umumiy-malumot-navlink82"
            >
              <div className="umumiy-malumot-container46">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="umumiy-malumot-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="umumiy-malumot-text486">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="umumiy-malumot-navlink83"
            >
              <div className="umumiy-malumot-container47">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="umumiy-malumot-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="umumiy-malumot-text490">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="umumiy-malumot-navlink84"
            >
              <div className="umumiy-malumot-container48">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="umumiy-malumot-icon44"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="umumiy-malumot-text494">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="umumiy-malumot-container49">
        <div className="umumiy-malumot-container50">
          <span className="umumiy-malumot-text498">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="umumiy-malumot-container51">
            <Link to="/visametric-gurukhi" className="umumiy-malumot-navlink85">
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="umumiy-malumot-navlink86"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/w3c-logo2-200h.png"
          className="umumiy-malumot-image22"
        />
        <a href="#main" className="umumiy-malumot-link">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="umumiy-malumot-image23"
          />
        </a>
      </div>
    </div>
  )
}

export default UmumiyMalumot
