import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './uzhzhatlar.css'

const Uzhzhatlar = (props) => {
  return (
    <div className="uzhzhatlar-container10">
      <Helmet>
        <title>uzhzhatlar - Visametric</title>
        <meta property="og:title" content="uzhzhatlar - Visametric" />
      </Helmet>
      <div data-thq="thq-burger-menu" className="uzhzhatlar-burger-menu1">
        <svg viewBox="0 0 1024 1024" className="uzhzhatlar-icon10">
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div id="main" className="uzhzhatlar-container11">
        <h1 className="uzhzhatlar-text100">Ҳужжатлар</h1>
        <div className="uzhzhatlar-container12">
          <Link to="/uzbekistan-germanyuz" className="uzhzhatlar-navlink10">
            <div className="uzhzhatlar-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="uzhzhatlar-icon12"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="uzhzhatlar-text101">Бош саҳифа</h1>
            </div>
          </Link>
          <Link to="/uzbekistan-germanyuz" className="uzhzhatlar-navlink11">
            <div className="uzhzhatlar-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="uzhzhatlar-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="uzhzhatlar-text102">FAQ</h1>
            </div>
          </Link>
          <Link to="/uzbekistan-germanyuz" className="uzhzhatlar-navlink12">
            <div className="uzhzhatlar-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="uzhzhatlar-icon16"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="uzhzhatlar-text103">Ҳужжатлар</h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="uzhzhatlar-container16">
        <div className="uzhzhatlar-container17">
          <div className="uzhzhatlar-container18">
            <span className="uzhzhatlar-text104">FAQ</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="uzhzhatlar-icon18"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="uzhzhatlar-image10"
          />
          <div className="uzhzhatlar-container19">
            <Link to="/umumiy-malumot" className="uzhzhatlar-navlink13">
              <div className="uzhzhatlar-container20">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="uzhzhatlar-image11"
                />
                <span className="uzhzhatlar-text105">Умумий маълумот</span>
              </div>
            </Link>
            <Link to="/ariza-oldin" className="uzhzhatlar-navlink14">
              <div className="uzhzhatlar-container21">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="uzhzhatlar-image12"
                />
                <span className="uzhzhatlar-text106">Ариза олдин</span>
              </div>
            </Link>
            <Link
              to="/ariza-topshirish-zharaeni"
              className="uzhzhatlar-navlink15"
            >
              <div className="uzhzhatlar-container22">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="uzhzhatlar-image13"
                />
                <span className="uzhzhatlar-text107">
                  Ариза топшириш жараёни
                </span>
              </div>
            </Link>
            <Link to="/uzhzhatlar" className="uzhzhatlar-navlink16">
              <div className="uzhzhatlar-container23">
                <img
                  alt="image"
                  src="/rectangle%2030.svg"
                  className="uzhzhatlar-image14"
                />
                <span className="uzhzhatlar-text108">Ҳужжатлар</span>
              </div>
            </Link>
            <Link to="/shimcha-khizmatlar" className="uzhzhatlar-navlink17">
              <div className="uzhzhatlar-container24">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="uzhzhatlar-image15"
                />
                <span className="uzhzhatlar-text109">Қўшимча хизматлар</span>
              </div>
            </Link>
            <Link
              to="/ariza-topshirishdan-snggi-olat"
              className="uzhzhatlar-navlink18"
            >
              <div className="uzhzhatlar-container25">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="uzhzhatlar-image16"
                />
                <span className="uzhzhatlar-text110">
                  Ариза топширишдан сўнгги ҳолат
                </span>
              </div>
            </Link>
          </div>
        </div>
        <div className="uzhzhatlar-container26">
          <span className="uzhzhatlar-text111">
            <span>1. Узбекистон / FAQ / Ҳужжатлар</span>
            <br className="uzhzhatlar-text113"></br>
          </span>
          <span className="uzhzhatlar-text114">
            <span className="uzhzhatlar-text115">Мажбурият баёноти нима?</span>
            <br className="uzhzhatlar-text116"></br>
            <br className="uzhzhatlar-text117"></br>
            <span>
              Мажбурият баёноти (таклифнома) бу - учинчи давлат фуқароси яъни
              таклиф қилинаётган шахснинг сафари давомидаги иқтисодий
              харажатлари ва шарт-шароитларини қоплаш ҳамда сафар давомида яшаш
              ҳуқуқини берувчи Қонуннинг 5-моддаси 1-бандига асосан маъмурий
              тартибда исботлаш имконини беради.
            </span>
            <br className="uzhzhatlar-text119"></br>
            <br className="uzhzhatlar-text120"></br>
            <span>
              Таклиф қилаётган шахс - мажбурият баёнотини яъни таклифномани
              тегишли давлат идорасидан қабул қилганидан сўнг, таклиф
              қилинаётган шахснинг сафари давомидаги барча харажатларини ўз
              бўйнига олади. Таклиф қилаётган томон - жисмоний ёки юридик шахс
              бўлиши мумкин.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="uzhzhatlar-text122"></br>
            <br className="uzhzhatlar-text123"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="uzhzhatlar-text125"></br>
            <span className="uzhzhatlar-text126">
              «90 кун/6 ойлик таклифномани олдим, лекин икки йиллик виза учун
              ариза бермоқчиман» Бунинг иложи борми?
            </span>
            <br className="uzhzhatlar-text127"></br>
            <br className="uzhzhatlar-text128"></br>
            <span>
              Ҳа, таклифномага асосан фақат биринчи 90 кун давомида молиявий
              томондан суғурталанган бўлиши керак. Зарурий виза яъни икки йиллик
              виза учун молиявий имкониятингизни исботлашингиз керак. Агар чет
              эл ваколатли органлари таклифномада фақат қисқа давр учун қарорни
              қабул қилган бўлса, бундай ҳолда Сиз қолган давр учун ўз молиявий
              ҳолатингизни исботловчи ҳужжатларни тақдим қилишингиз лозим, банк
              томонидан тақдим этиладиган маълумотнома ёки сўнгги 3-6-ойлик
              даромадингиз ва хорижда кўпроқ вақтга қола билиш имкониятингизни
              исботлашингиз керак.
            </span>
            <br className="uzhzhatlar-text130"></br>
            <br className="uzhzhatlar-text131"></br>
            <br className="uzhzhatlar-text132"></br>
            <span className="uzhzhatlar-text133">
              Таклифнома билан Мултивиза олиш учун ариза бера оламанми?
            </span>
            <br className="uzhzhatlar-text134"></br>
            <br className="uzhzhatlar-text135"></br>
            <span>
              Ҳа. Сизни таклиф қилаётган чет эллик шахс, режалаштирилаётган
              қолиш вақтингизни (бир йилдан 4 йилгача) таклифномада кўрсатган
              бўлиши лозим.
            </span>
            <br className="uzhzhatlar-text137"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="uzhzhatlar-text139"></br>
            <br className="uzhzhatlar-text140"></br>
            <span className="uzhzhatlar-text141">
              Сизга маълумки, бизнинг компаниямиз ўз мижозларини бир неча
              маротаба таклиф қилган. Нима учун биз ҳар сафар виза олиш учун
              аризада молиявий ҳолатимизга тегишли барча ҳужжатларни тақдим
              этамиз?
            </span>
            <br className="uzhzhatlar-text142"></br>
            <br className="uzhzhatlar-text143"></br>
            <span>
              Мавжуд қоидаларга асосан элчихона таклиф қилаётган томон
              тўғрисидаги маълумотларни сақлаб турмайди. Биринчи мижозни таклиф
              этиш учун жўнатилган ҳужжатлар, элчихонада виза аризаси билан
              бирга сақланиб, компаниялар бўйича тартибга солинмайди. Шунинг
              учун ҳар бир мижоз томонидан алоҳида ҳужжатлар тақдим этилиши
              керак.
            </span>
            <br className="uzhzhatlar-text145"></br>
            <br className="uzhzhatlar-text146"></br>
            <span>
              Элчихона тушунганлигингиз учун сиздан миннатдор.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="uzhzhatlar-text148"></br>
            <br className="uzhzhatlar-text149"></br>
            <br className="uzhzhatlar-text150"></br>
            <span className="uzhzhatlar-text151">
              Таклифномада менинг фамилиям хато ёзилган бўлса, нима қилишим
              керак?
            </span>
            <br className="uzhzhatlar-text152"></br>
            <br className="uzhzhatlar-text153"></br>
            <span>
              Таклиф этилаётган шахс аниқ бўлган тақдирда, таклифномада
              кўрсатилган фамилия - паспортдаги фамилия билан фарқ қилса,
              чегарадан ўтишда рад этилишига олиб келмайди.
            </span>
            <br className="uzhzhatlar-text155"></br>
            <br className="uzhzhatlar-text156"></br>
            <br className="uzhzhatlar-text157"></br>
            <span className="uzhzhatlar-text158">
              Ўзаро қариндошлик муносабатларининг исботи нима?
            </span>
            <br className="uzhzhatlar-text159"></br>
            <br className="uzhzhatlar-text160"></br>
            <span>
              Виза беришдан олдин Элчихона аризанинг тўғрилигини тасдиқлайди.
              Агар керак бўлса, Элчихона таклиф этилган шахс билан таклиф
              этаётган шахс ўртасидаги муносабатни текшириши мумкин.
            </span>
            <br className="uzhzhatlar-text162"></br>
            <br className="uzhzhatlar-text163"></br>
            <span>
              Бирор киши билан бўлган қариндошлик муносабатингиз, туғилганлик ва
              никоҳ гувоҳномалари билан тасдиқланади. Мисол учун,
              буви-боболарингиз билан бўлган қариндошликни тасдиқлаш учун
              Сизнинг туғилганлик ҳақидаги гувоҳномангизни ва ота ёки
              онангизнинг туғилганлик ҳақидаги гувоҳномасини тақдим этишингиз
              керак.
            </span>
            <br className="uzhzhatlar-text165"></br>
            <br className="uzhzhatlar-text166"></br>
            <br className="uzhzhatlar-text167"></br>
            <span className="uzhzhatlar-text168">
              Норасмий таклифнома нима ва у қандай кўринишда бўлиши керак?
            </span>
            <br className="uzhzhatlar-text169"></br>
            <br className="uzhzhatlar-text170"></br>
            <span>
              Оила аъзоларингиз ёки танишларингизникига ташриф буюриш мақсадида
              виза олиш учун ариза топширишда, таклиф қилаётган шахсдан норасмий
              таклифнома бериш талаб қилинади. Норасмий таклифнома қўлда ёки
              компьютерда чоп этилиши мумкин. Норасмий таклифнома немис тили ёки
              инглиз тилида бўлиши ва таклиф этилган шахснинг фамилияси, исми,
              туғилган санаси, сафарнинг давомийлиги ва мақсади ҳамда барча
              таклиф этилган шахслар кўрсатилиши керак.
            </span>
            <br className="uzhzhatlar-text172"></br>
            <br className="uzhzhatlar-text173"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="uzhzhatlar-text175"></br>
            <span className="uzhzhatlar-text176">
              Ариза шакли (анкета)ни қаердан олсам бўлади ва у қанча туради?
            </span>
            <br className="uzhzhatlar-text177"></br>
            <br className="uzhzhatlar-text178"></br>
            <span>
              Ариза шаклини бизнинг офисларимиз қабулхонасидан олишингиз мумкин.
              Шунингдек, Сиз уни бизнинг веб саҳифамиздан юклаб олишингиз
              мумкин. Аризаларнинг барча шакллари бепул.
            </span>
            <br className="uzhzhatlar-text180"></br>
            <br className="uzhzhatlar-text181"></br>
            <br className="uzhzhatlar-text182"></br>
            <span className="uzhzhatlar-text183">
              Вояга етмаган ариза топширувчилар
            </span>
            <br className="uzhzhatlar-text184"></br>
            <br className="uzhzhatlar-text185"></br>
            <span className="uzhzhatlar-text186">
              Ёлғиз она(ота)/ажрашган/бева она/ота сифатида нима қилишим керак?
            </span>
            <br className="uzhzhatlar-text187"></br>
            <br className="uzhzhatlar-text188"></br>
            <span>
              Илтимос, ёлғиз она(ота)/ажрашган/бева она/ота эканлигини
              тасдиқловчи барча ҳужжатлар/далилларни ариза топшириш вақтида
              тақдим этинг.
            </span>
            <br className="uzhzhatlar-text190"></br>
            <br className="uzhzhatlar-text191"></br>
            <br className="uzhzhatlar-text192"></br>
            <span className="uzhzhatlar-text193">
              Вояга етмаганларга розилик.
            </span>
            <br className="uzhzhatlar-text194"></br>
            <br className="uzhzhatlar-text195"></br>
            <span>
              Агар вояга етмаган бола ёлғиз ёки фақат бир қонуний вакил (одатда
              ота-она) билан бирга саёҳат қилаётган бўлса, визага ариза топшириш
              учун иккала қонуний вакиллар (ота-она)нинг ёки бошқа қонуний
              вакилларнинг розилиги керак бўлади. Ушбу розилик аризаси нотариал
              тасдиқланган бўлиши шарт.
            </span>
            <br className="uzhzhatlar-text197"></br>
            <br className="uzhzhatlar-text198"></br>
            <span>
              Розилик аризасида қонуний вакиллар (ота-она)нинг бола саёҳатига
              розилиги кўрсатиб ўтилади. Шунингдек, бу саёҳат даври ва мақсадини
              белгилаб беради. Бундан ташқари, қоидага кўра, саёҳат даврида
              болага ҳамроҳлик қилувчи шахс учун кўрсатилган бўлади.
            </span>
            <br className="uzhzhatlar-text200"></br>
            <br className="uzhzhatlar-text201"></br>
            <span>
              Таъкидлаш жоизки, розилик аризаси режалаштирилган сафар ёки
              сўралаётган виза доирасида қонуний вакиллар (ота-она)нинг
              хуқуқларини ҳимоя қилади.
            </span>
            <br className="uzhzhatlar-text203"></br>
            <br className="uzhzhatlar-text204"></br>
            <span>
              Шунинг учун ҳам элчихона фақатгина виза олиш учун визага мурожаат
              этувчининг, 6 ойдан кўп булмаган муддатдаги розилик аризасини
              кўриб чиқади. Яъни розилик аризаси 6 ойдан ортиқ вақтни қамраб
              олса, визага ариза топшириш пайтида ушбу розилик аризаси қабул
              қилинмайди. Бу вазиятда, илтимос, янги нотариал тасдиқланган
              розилик аризасини тақдим қилинг.
            </span>
            <br className="uzhzhatlar-text206"></br>
            <br className="uzhzhatlar-text207"></br>
            <span>Хизмат ва иш юзасидаги сафарларга доир таклифномалар</span>
            <br className="uzhzhatlar-text209"></br>
            <br className="uzhzhatlar-text210"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="uzhzhatlar-text212"></br>
            <span className="uzhzhatlar-text213">
              Расмий таклифномада менинг сафар давомидаги молиявий харажатларим
              қопланмаса ҳам виза олиб биламанми?
            </span>
            <br className="uzhzhatlar-text214"></br>
            <br className="uzhzhatlar-text215"></br>
            <span>
              Расмий таклифномасиз сизнинг сафар давомидаги молиявий
              харажатларингиз қопланмаса ҳам виза олишингиз мумкин. Бундай
              вазиятларда, Сиз ўз саёҳатингиз давомидаги харажатларни мустақил
              қоплай олишингизни исботловчи ҳужжатни тақдим қилишингиз керак.
              Исботловчи ҳужжат турлари:
            </span>
            <br className="uzhzhatlar-text217"></br>
            <br className="uzhzhatlar-text218"></br>
            <span>- банк ҳисоб рақами ёки ҳисоб рақами баёноти,</span>
            <br className="uzhzhatlar-text220"></br>
            <br className="uzhzhatlar-text221"></br>
            <span>ЁКИ</span>
            <br className="uzhzhatlar-text223"></br>
            <br className="uzhzhatlar-text224"></br>
            <span>- кредит карталари ва кредит карталари баёноти</span>
            <br className="uzhzhatlar-text226"></br>
            <br className="uzhzhatlar-text227"></br>
            <span>
              Бундан ташқари, тиббий суғуртани шахсан тўлашингизга тўғри келади.
            </span>
            <br className="uzhzhatlar-text229"></br>
            <br className="uzhzhatlar-text230"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="uzhzhatlar-text232"></br>
            <span className="uzhzhatlar-text233">
              Меҳмоним учун расмий таклифномани қаерда тайёрлашим мумкин ва бу
              қанча туради?
            </span>
            <br className="uzhzhatlar-text234"></br>
            <br className="uzhzhatlar-text235"></br>
            <span>
              Сиз расмий таклифномани Германияда яъни яшаш жойингиздаги, чет эл
              фуқаролари бўйича ваколатли миграцион органларига мурожаат
              қилишингиз мумкин.
            </span>
            <br className="uzhzhatlar-text237"></br>
            <br className="uzhzhatlar-text238"></br>
            <span>
              Сиз у ерда тўлов қобилиятингизни исботлашингиз керак. Бундан
              ташқари, сиз меҳмоннинг исми фамилияси, туғилган куни, манзили ва
              паспорт рақамини билишингиз керак. Таклифномани расмийлаштиришда
              29 евро миқдорида божхона тўловини амалга оширишингиз керак.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="uzhzhatlar-text240"></br>
            <br className="uzhzhatlar-text241"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="uzhzhatlar-text243"></br>
            <span className="uzhzhatlar-text244">
              Расмий таклифноманинг амал қилиш муддати қанча?
            </span>
            <br className="uzhzhatlar-text245"></br>
            <br className="uzhzhatlar-text246"></br>
            <span>
              Одатда элчихона виза берилгандан кейин 6 ойдан кўп бўлмаган
              муддатда амал қиладиган расмий таклифномаларни кўриб чиқади.
            </span>
            <br className="uzhzhatlar-text248"></br>
            <br className="uzhzhatlar-text249"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="uzhzhatlar-text251"></br>
            <span className="uzhzhatlar-text252">
              Биз компанияни вакиллари ҳисобланамиз ва бизнес ҳамкорларимизнинг
              мажбуриятларини ўз зиммамизга олишни хоҳлаймиз, буни қандай амалга
              оширамиз?
            </span>
            <br className="uzhzhatlar-text253"></br>
            <br className="uzhzhatlar-text254"></br>
            <span className="uzhzhatlar-text255">
              Илтимос, таклифингизда қуйидагини ёзинг «Яшаш ҳуқуқини берувчи
              Қонуннинг 66-68-параграфларига асосан харажатларни қоплаш
              мажбуриятини …..»
            </span>
            <br className="uzhzhatlar-text256"></br>
            <br className="uzhzhatlar-text257"></br>
            <span>
              Компаниянгиз тўлов қобилиятини исботи сифатида, илтимос, хўжалик
              субъектларининг маълумотномасини юборинг. Таклифнома компаниянинг
              ташқи ишлар юридик вакиллиги хўжалик юритиш субъектлар реестри
              гувоҳномасига эга шахс томонидан имзоланганлигига ишонч ҳосил
              қилинг. Илтимос, имзоловчининг шахсни тасдиқловчи гувоҳномаси ёки
              паспорти нусхасини тақдим этинг.
            </span>
            <br className="uzhzhatlar-text259"></br>
            <br className="uzhzhatlar-text260"></br>
            <span>
              Таклифномани хўжалик юритувчи субъектлар томонидан рўйхатга олиш
              ҳуқуқига эга бўлмаган шахс имзолаган тақдирда, ваколатли шахс ушбу
              ваколатни унга тақдим этиши керак. Илтимос, талаб қилинган
              рухсатномани ваколатли шахсларнинг шахсни тасдиқлаш картаси ёки
              паспорт нусхаси билан бирга юборинг.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="uzhzhatlar-text262"></br>
            <br className="uzhzhatlar-text263"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="uzhzhatlar-text265"></br>
            <span className="uzhzhatlar-text266">Бошқа ҳужжатлар</span>
            <br className="uzhzhatlar-text267"></br>
            <br className="uzhzhatlar-text268"></br>
            <span>
              Мен/менинг меҳмонимдан рўйхатда кўрсатилмаган бошқа ҳужжатлар
              сўралди, бу қандай содир бўлди?
            </span>
            <br className="uzhzhatlar-text270"></br>
            <br className="uzhzhatlar-text271"></br>
            <span>
              Элчихона доимо ҳар бир ҳолат бўйича алоҳида қарор қабул қилади
              ҳамда керакли ҳужжатлар рўйхати фақатгина умумий маълумотлардан
              иборат. Шунинг учун Сизнинг ҳолатингизда, керакли ҳужжатлар
              рўйхатида бўлмаган ҳужжат талаб қилиниши мумкин. Қўшимча
              маълумотлар талаб қилинганлиги сиз учун виза берилмаслигини
              англатмайди.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="uzhzhatlar-text273"></br>
            <br className="uzhzhatlar-text274"></br>
            <br className="uzhzhatlar-text275"></br>
            <span className="uzhzhatlar-text276">
              Менинг аризам рад этилди, бунда Германиядаги таклиф қилувчи томон
              нима қила олади?
            </span>
            <br className="uzhzhatlar-text277"></br>
            <br className="uzhzhatlar-text278"></br>
            <span>
              Сиз, ҳамда қабул қилувчи томон элчихонага раддияни
              тушунтириш/қайта кўриб чиқиш бўйича сўровнома юборишингиз мумкин.
              Бу вазиятда, паспорт олишдаги юқорида келтириб ўтилган сабабларни
              ҳисобга олишингиз керак бўлади. Таклиф қилувчи томон Сиз
              томонингиздан имзоланган, қонуний тасдиқланган ҳужжатни тақдим
              этиши керак, бу Сиз томонингиздан виза олиш жараёни тўғрисидаги
              маълумотни олиш имконини беради.
            </span>
            <br className="uzhzhatlar-text280"></br>
            <br className="uzhzhatlar-text281"></br>
            <span>
              Агар элчихона Сизни таклиф қилувчи шахснинг тушунтириш хатидан
              қониқмаган тақдирда, таклиф қилувчи шахс почта орқали элчихонанинг
              батафсил тушунтирилган ёзма жавоб хатини олади. Ушбу ёзма
              тушунтириш хати Берлин Маъмурий судига тақдим қилиниши мумкин.
            </span>
            <br className="uzhzhatlar-text283"></br>
            <br className="uzhzhatlar-text284"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="uzhzhatlar-text286"></br>
            <span className="uzhzhatlar-text287">
              Менинг қўлимда расмий таклифнома мавжуд, таклифномада барча
              молиявий харажатларим қопланиши кўрсатилган. Шунга қарамасдан,
              қайтиб келишимга шубҳа туфайли виза олишга рад жавоби берилди.
            </span>
            <br className="uzhzhatlar-text288"></br>
            <br className="uzhzhatlar-text289"></br>
            <span>
              Лекин мен, расмий таклифномага кўра бу шахс Германияни тарк
              этишига кафолат бераман.
            </span>
            <br className="uzhzhatlar-text291"></br>
            <br className="uzhzhatlar-text292"></br>
            <span>
              Расмий таклифнома фақатгина аризачининг Германияда молиявий кўллаб
              қувватланишини кафолатлайди. Ариза берувчининг қайтиб келишини
              кафолатлаш мумкин эмас. Бу масала фақатгина аризачининг шахси
              тўғрисидаги маълумотлар текширилгандан сўнг ҳал қилиниши мумкин.
            </span>
            <br className="uzhzhatlar-text294"></br>
            <br className="uzhzhatlar-text295"></br>
            <br className="uzhzhatlar-text296"></br>
            <span className="uzhzhatlar-text297">
              Талаба ва ўқувчиларнинг Германияга ташрифи академик ёки расмий
              алмашинув дастури доирасида бўлмаса, улар виза йиғимидан озод
              қилинмайди. Малака ошириш учун визалар ҳам тўловдан озод
              қилинмайди.
            </span>
            <br className="uzhzhatlar-text298"></br>
            <br className="uzhzhatlar-text299"></br>
            <span>
              Тасдиқ: расмий таклифнома, талабалик карточкаси,
              университет/мактабдан маълумотнома, кузатиб борувчи
              ўқитувчиларнинг ишга қабул қилинганлиги тўғрисида аризаси
            </span>
            <br className="uzhzhatlar-text301"></br>
            <br className="uzhzhatlar-text302"></br>
            <span>
              Жисмоний камчиликларга эга шахслар ва уларни кузатиб борувчилар,
              агар зарур бўлса
            </span>
            <br className="uzhzhatlar-text304"></br>
            <br className="uzhzhatlar-text305"></br>
            <span>Тасдиқлаш: ногиронлар картаси</span>
            <br className="uzhzhatlar-text307"></br>
            <br className="uzhzhatlar-text308"></br>
            <span>Халқаро спорт тадбирлари ва кузатиб борувчи ходимлар</span>
            <br className="uzhzhatlar-text310"></br>
            <br className="uzhzhatlar-text311"></br>
            <span>
              Тасдиқлаш: Озарбайжон Спорт федерацияси тасдиғи ёки таклифномаси
            </span>
            <br className="uzhzhatlar-text313"></br>
            <br className="uzhzhatlar-text314"></br>
            <span>
              Илмий, маданий ва санъат тадбирларида, шунингдек, университет ва
              бошқа алмашинув дастурлари иштирокчилари
            </span>
            <br className="uzhzhatlar-text316"></br>
            <br className="uzhzhatlar-text317"></br>
            <span>Тасдиқлаш: расмий таклифнома</span>
            <br className="uzhzhatlar-text319"></br>
            <br className="uzhzhatlar-text320"></br>
            <span>
              Тезкор тиббий ёрдамга муҳтожлар ва уларни кузатиб борувчилар
            </span>
            <br className="uzhzhatlar-text322"></br>
            <br className="uzhzhatlar-text323"></br>
            <span>
              Тасдиқлаш: Тезкор тиббий ёрдамга муҳтожлар ва уларни кузатиб
              борувчилар зарурлигини тасдиқловчи касалхона йўлланмаси
            </span>
            <br className="uzhzhatlar-text325"></br>
            <br className="uzhzhatlar-text326"></br>
            <span>Яқин қариндошларини дафн маросимига бораётган шахслар</span>
            <br className="uzhzhatlar-text328"></br>
            <br className="uzhzhatlar-text329"></br>
            <span>
              Тасдиқлаш: яқин қариндошлари - умр йўлдош, фарзандлар (асраб
              олинган), ота-оналар (ёки ота-оналик ҳуқуқига эга бошқа шахслар),
              бобо-бувилар ва невараларнинг дафн маросимларини тасдиқловчи
              ҳужжат.
            </span>
            <br className="uzhzhatlar-text331"></br>
            <br className="uzhzhatlar-text332"></br>
            <span>
              Қариндошларини касаллик сабабли кўришга бораётган шахслар
            </span>
            <br className="uzhzhatlar-text334"></br>
            <br className="uzhzhatlar-text335"></br>
            <span>
              Тасдиқлаш: яқин қариндошлари - умр йўлдош, фарзандлар (асраб
              олинган), ота-оналар (ёки ота-оналик ҳуқуқига эга бошқа шахслар),
              бобо-бувилар ва невараларнинг касаллигини тасдиқловчи ҳужжат.
            </span>
            <br className="uzhzhatlar-text337"></br>
            <br className="uzhzhatlar-text338"></br>
            <span>
              Конференциялар,семинарлар, курслар ҳамда алмашинув дастурлари
              фуқаролик ташкилотлари вакиллари.
            </span>
            <br className="uzhzhatlar-text340"></br>
            <br className="uzhzhatlar-text341"></br>
            <span>
              Тасдиқлаш: ташкил қилиш ва таклиф қилишда иштирок этиш ҳақидаги
              иштирокчи карточкаси/қатнашиш қайдномаси
            </span>
            <br className="uzhzhatlar-text343"></br>
            <br className="uzhzhatlar-text344"></br>
            <span>Нафақахўр</span>
            <br className="uzhzhatlar-text346"></br>
            <br className="uzhzhatlar-text347"></br>
            <span>Тасдиқлаш: ижтимоий ҳизмат гувоҳномаси</span>
            <br className="uzhzhatlar-text349"></br>
            <br className="uzhzhatlar-text350"></br>
            <span>12 ёшгача бўлган болалар</span>
            <br className="uzhzhatlar-text352"></br>
            <br className="uzhzhatlar-text353"></br>
            <span>Журналистлар ва кузатиб борувчи техник ходимлар</span>
            <br className="uzhzhatlar-text355"></br>
            <br className="uzhzhatlar-text356"></br>
            <span>
              Тасдиқлаш: Ариза тақдим қилишда солиқдан озод қилишни тасдиқловчи
              ҳужжатлар тақдим қилинади.
            </span>
            <br className="uzhzhatlar-text358"></br>
            <br className="uzhzhatlar-text359"></br>
            <span>
              Элчихона кўрсатмалари қуйида келтирилади юқорида кўрсатиб
              ўтилганлар фақат солиқдан озод қилинишига алоқадор
            </span>
            <br className="uzhzhatlar-text361"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="uzhzhatlar-text363"></br>
            <br className="uzhzhatlar-text364"></br>
            <span className="uzhzhatlar-text365">
              Дипломатик паспорт эгалари учун визасиз кириш.
            </span>
            <br className="uzhzhatlar-text366"></br>
            <br className="uzhzhatlar-text367"></br>
            <span>
              2014 йил 1 сентабридан бошлаб дипломатик паспорт эгалари учун
              Шенген давлатлари ҳудудига қисқа муддатли визалар зарур эмас.
            </span>
            <br className="uzhzhatlar-text369"></br>
            <br className="uzhzhatlar-text370"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="uzhzhatlar-text372"></br>
            <span className="uzhzhatlar-text373">
              Визага ариза беришим учун ўзим билан қандай ҳужжатларни келтиришим
              керак?
            </span>
            <br className="uzhzhatlar-text374"></br>
            <br className="uzhzhatlar-text375"></br>
            <span>
              Саёҳатингизнинг мақсадига кўра, керакли ҳужжатлар рўйхатида
              келтирилган барча ҳужжатларни тайёрлашингиз керак. Фақат тўлиқ
              йиғилган ҳужжатларгина қабул қилинишини ёдда тутинг. Кейинчалик
              қўшимча ҳужжатлар тақдим қилинишининг иложи йўқ.
            </span>
            <br className="uzhzhatlar-text377"></br>
            <br className="uzhzhatlar-text378"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="uzhzhatlar-text380"></br>
            <span className="uzhzhatlar-text381">
              Вояга етмаганларнинг хорижга чиқиш паспорти. Нималарга эътибор
              қаратишим зарур?
            </span>
            <br className="uzhzhatlar-text382"></br>
            <br className="uzhzhatlar-text383"></br>
            <span>
              Вояга етмаган шахсда хорижга чиқиш паспорти ёки туғилганлик
              гувоҳномаси мавжуд бўлса, ота-онаси ёки ҳамроҳлик қилувчи бошқа
              қонуний вакиллар билан биргаликда фақатгина нотариал тасдиқланган
              розилик аризаси ёрдамида визага ариза топширишлари мумкин.
            </span>
            <br className="uzhzhatlar-text385"></br>
            <br className="uzhzhatlar-text386"></br>
            <span>
              Агар вояга етмаган бола ёлғиз ёки фақат бир қонуний вакил (одатда
              ота-она) билан бирга саёҳат қилаётган бўлса, визага ариза топшириш
              учун иккала қонуний вакиллар (ота-она)нинг ёки бошқа қонуний
              вакилларнинг розилиги керак бўлади. Ушбу розилик аризаси нотариал
              тасдиқланган бўлиши шарт.
            </span>
            <br className="uzhzhatlar-text388"></br>
            <br className="uzhzhatlar-text389"></br>
            <span>
              Розилик аризасида қонуний вакиллар (ота-она)нинг бола саёҳатига
              розилиги кўрсатиб ўтилади.
            </span>
            <br className="uzhzhatlar-text391"></br>
            <br className="uzhzhatlar-text392"></br>
            <br className="uzhzhatlar-text393"></br>
            <span className="uzhzhatlar-text394">
              Вояга етмаган шахс томонидан ариза топширилишида нималарга эътибор
              беришим керак?
            </span>
            <br className="uzhzhatlar-text395"></br>
            <br className="uzhzhatlar-text396"></br>
            <span>
              Вояга етмаган шахслар учун ҳам ариза шакли (анкета) тўлдирилади.
              Ушбу анкета ота-она ҳуқуқига эга барча шахслар томонидан
              имзоланиши зарур. Ота-она ҳуқуқига эга шахснинг имзолашга имкони
              бўлмаса, Шенген давлатлари ҳудудига саёҳат учун нотариал
              тасдиқланган розилик аризаси тақдим қилиниши шарт.
            </span>
            <br className="uzhzhatlar-text398"></br>
            <br className="uzhzhatlar-text399"></br>
            <span>
              16 ёшга тўлган аризачи анкета шаклини шахсан ўзи тўлдириши шарт.
            </span>
            <br className="uzhzhatlar-text401"></br>
            <br className="uzhzhatlar-text402"></br>
            <br className="uzhzhatlar-text403"></br>
            <span className="uzhzhatlar-text404">
              Аризачининг паспортига қўйилган талаблар: Фақатгина шикаст
              етмаган, ҳақиқий ва шахсан қўлда имзоланган (фақат етганлар учун)
              паспортлар виза учун қабул қилиниши мумкин.
            </span>
            <br className="uzhzhatlar-text405"></br>
            <br className="uzhzhatlar-text406"></br>
            <span>
              Бундан ташқари, Виза кодексига кўра, Шенген визалари фақатгина
            </span>
            <br className="uzhzhatlar-text408"></br>
            <br className="uzhzhatlar-text409"></br>
            <span>
              - режалаштирилган сафар тугагандан сўнг, паспортнинг амал қилиш
              муддати камида 3 ой давомида ҳақиқий бўлиши керак:
            </span>
            <br className="uzhzhatlar-text411"></br>
            <br className="uzhzhatlar-text412"></br>
            <span>
              - парпорт камида охирги 10 йил ичида ҳақиқий бўлиши керак:
            </span>
            <br className="uzhzhatlar-text414"></br>
            <br className="uzhzhatlar-text415"></br>
            <span>
              - паспорт камида иккита бўш варақга эга бўлиши керак (виза
              берилиши учун)*
            </span>
            <br className="uzhzhatlar-text417"></br>
            <br className="uzhzhatlar-text418"></br>
            <span>
              * Агар виза ота-онасининг паспортида рўйхатдан ўтган вояга етмаган
              бола учун берилаётган бўлса яна битта бўш варақга эга бўлиши шарт.
            </span>
            <br className="uzhzhatlar-text420"></br>
            <br className="uzhzhatlar-text421"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="uzhzhatlar-text423"></br>
            <span className="uzhzhatlar-text424">
              Мен ариза анкетасини қайси тилда тўлдиришим керак?
            </span>
            <br className="uzhzhatlar-text425"></br>
            <br className="uzhzhatlar-text426"></br>
            <span>
              Ариза анкетасини Немис ёки Инглиз тилида тўлдириш керак.
            </span>
            <br className="uzhzhatlar-text428"></br>
            <br className="uzhzhatlar-text429"></br>
            <br className="uzhzhatlar-text430"></br>
            <span className="uzhzhatlar-text431">
              Ариза анкетасини ёзма равишда қўлда тўлдира оламанми?
            </span>
            <br className="uzhzhatlar-text432"></br>
            <br className="uzhzhatlar-text433"></br>
            <span>
              Техник сабабларга кўра онлайн ариза анкетасини тўлдира олмасангиз,
              анкетани қўлда тўлдиришингиз мумкин. Бироқ, бу ҳолатда визага
              ариза топшириш жараёни узайиши мумкин.
            </span>
          </span>
        </div>
      </div>
      <div id="header" className="uzhzhatlar-container27">
        <Link to="/" className="uzhzhatlar-navlink19">
          <div className="uzhzhatlar-container28">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="uzhzhatlar-image17"
            />
            <p className="uzhzhatlar-text435">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="uzhzhatlar-container29">
          <div className="uzhzhatlar-container30">
            <span className="uzhzhatlar-text438">
              <span>Apply for Visa to</span>
              <span className="uzhzhatlar-text440"> Germany</span>
            </span>
            <span className="uzhzhatlar-text441">
              <span className="uzhzhatlar-text442">in Uzbekistan</span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="uzhzhatlar-image18"
          />
          <span className="uzhzhatlar-text473">|</span>
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header data-thq="thq-navbar" className="uzhzhatlar-navbar-interactive">
        <div data-thq="thq-navbar-nav" className="uzhzhatlar-desktop-menu">
          <div className="uzhzhatlar-container31">
            <nav className="uzhzhatlar-links1">
              <div
                data-thq="thq-dropdown"
                className="uzhzhatlar-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="uzhzhatlar-dropdown-toggle10"
                  >
                    <span className="uzhzhatlar-text474">Бош сахифа</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="uzhzhatlar-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="uzhzhatlar-dropdown-toggle11"
                >
                  <span className="uzhzhatlar-text475">Умумий</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="uzhzhatlar-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="uzhzhatlar-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle12"
                      >
                        <span className="uzhzhatlar-text476 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle13"
                      >
                        <span className="uzhzhatlar-text479 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle14"
                      >
                        <span className="uzhzhatlar-text480 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle15"
                      >
                        <span className="uzhzhatlar-text481 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle16"
                      >
                        <span className="uzhzhatlar-text486 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle17"
                      >
                        <span className="uzhzhatlar-text487 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle18"
                      >
                        <span className="uzhzhatlar-text488 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle19"
                      >
                        <span className="uzhzhatlar-text492 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="uzhzhatlar-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="uzhzhatlar-dropdown-toggle20"
                >
                  <span className="uzhzhatlar-text493">Виза олиш</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="uzhzhatlar-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="uzhzhatlar-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle21"
                      >
                        <span className="uzhzhatlar-text494">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle22"
                      >
                        <span className="uzhzhatlar-text495">Миллий виза</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle23"
                      >
                        <span className="uzhzhatlar-text496">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle24"
                      >
                        <span className="uzhzhatlar-text497">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle25"
                      >
                        <span className="uzhzhatlar-text500">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle26"
                      >
                        <span className="uzhzhatlar-text504">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle27"
                      >
                        <span className="uzhzhatlar-text505">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="uzhzhatlar-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="uzhzhatlar-dropdown-toggle28"
                >
                  <span className="uzhzhatlar-text506">Кушимча хизматлар</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="uzhzhatlar-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="uzhzhatlar-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle29"
                      >
                        <span className="uzhzhatlar-text507">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle30"
                      >
                        <span className="uzhzhatlar-text508">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle31"
                      >
                        <span className="uzhzhatlar-text512">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle32"
                      >
                        <span className="uzhzhatlar-text515">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle33"
                      >
                        <span className="uzhzhatlar-text516">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle34"
                      >
                        <span className="uzhzhatlar-text520">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle35"
                      >
                        <span className="uzhzhatlar-text521">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="uzhzhatlar-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="uzhzhatlar-dropdown-toggle38"
                  >
                    <span className="uzhzhatlar-text524">FAQ</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="uzhzhatlar-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="uzhzhatlar-dropdown-toggle39"
                >
                  <span className="uzhzhatlar-text525">Алокалар</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="uzhzhatlar-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="uzhzhatlar-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle40"
                      >
                        <span className="uzhzhatlar-text526">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle41"
                      >
                        <span className="uzhzhatlar-text527">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle42"
                      >
                        <span className="uzhzhatlar-text528">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzhzhatlar-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzhzhatlar-dropdown-toggle43"
                      >
                        <span className="uzhzhatlar-text529">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="uzhzhatlar-container32">
          <input
            type="text"
            placeholder="Кидирмок"
            className="uzhzhatlar-textinput1 input"
          />
          <div className="uzhzhatlar-container33">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="uzhzhatlar-icon20"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="uzhzhatlar-container34">
            <span className="uzhzhatlar-text532">A+</span>
            <span className="uzhzhatlar-text533">A-</span>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="uzhzhatlar-burger-menu2">
          <svg viewBox="0 0 1024 1024" className="uzhzhatlar-icon22">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="uzhzhatlar-mobile-menu">
          <div className="uzhzhatlar-nav">
            <div className="uzhzhatlar-container35">
              <div className="uzhzhatlar-container36">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="uzhzhatlar-textinput2 input"
                />
                <div className="uzhzhatlar-container37">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="uzhzhatlar-icon24"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="uzhzhatlar-container38">
                  <span className="uzhzhatlar-text534">A+</span>
                  <span className="uzhzhatlar-text535">A-</span>
                </div>
              </div>
              <div data-thq="thq-close-menu" className="uzhzhatlar-close-menu">
                <svg viewBox="0 0 1024 1024" className="uzhzhatlar-icon26">
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="uzhzhatlar-links2">
            <div
              data-thq="thq-dropdown"
              className="uzhzhatlar-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="uzhzhatlar-dropdown-toggle44"
                >
                  <span className="uzhzhatlar-text536">Бош сахифа</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="uzhzhatlar-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="uzhzhatlar-dropdown-toggle45"
              >
                <span className="uzhzhatlar-text537">Умумий</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="uzhzhatlar-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="uzhzhatlar-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="uzhzhatlar-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle46"
                    >
                      <span className="uzhzhatlar-text538">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle47"
                    >
                      <span className="uzhzhatlar-text541">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle48"
                    >
                      <span className="uzhzhatlar-text542">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle49"
                    >
                      <span className="uzhzhatlar-text543">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle50"
                    >
                      <span className="uzhzhatlar-text548">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle51"
                    >
                      <span className="uzhzhatlar-text549">Тулов Шартлари</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle52"
                    >
                      <span className="uzhzhatlar-text550">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle53"
                    >
                      <span className="uzhzhatlar-text553">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="uzhzhatlar-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="uzhzhatlar-dropdown-toggle54"
              >
                <span className="uzhzhatlar-text554">Виза олиш</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="uzhzhatlar-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="uzhzhatlar-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="uzhzhatlar-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle55"
                    >
                      <span className="uzhzhatlar-text555">Шенген визаси</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle56"
                    >
                      <span className="uzhzhatlar-text556">Миллий виза</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle57"
                    >
                      <span className="uzhzhatlar-text557">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle58"
                    >
                      <span className="uzhzhatlar-text558">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle59"
                    >
                      <span className="uzhzhatlar-text561">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle60"
                    >
                      <span className="uzhzhatlar-text565">Ариза шакллари</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle61"
                    >
                      <span className="uzhzhatlar-text566">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="uzhzhatlar-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="uzhzhatlar-dropdown-toggle62"
              >
                <span className="uzhzhatlar-text567">Кушимча хизматлар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="uzhzhatlar-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="uzhzhatlar-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="uzhzhatlar-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle63"
                    >
                      <span className="uzhzhatlar-text568">VIP - хизмати</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle64"
                    >
                      <span className="uzhzhatlar-text569">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle65"
                    >
                      <span className="uzhzhatlar-text570">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle66"
                    >
                      <span className="uzhzhatlar-text571">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle67"
                    >
                      <span className="uzhzhatlar-text572">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle68"
                    >
                      <span className="uzhzhatlar-text575">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle69"
                    >
                      <span className="uzhzhatlar-text578">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle70"
                    >
                      <span className="uzhzhatlar-text579">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle71"
                    >
                      <span className="uzhzhatlar-text580">Prime Тиме</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="uzhzhatlar-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="uzhzhatlar-dropdown-toggle72"
                >
                  <span className="uzhzhatlar-text581">FAQ</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="uzhzhatlar-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="uzhzhatlar-dropdown-toggle73"
              >
                <span className="uzhzhatlar-text582">Алокалар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="uzhzhatlar-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="uzhzhatlar-icon34"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="uzhzhatlar-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle74"
                    >
                      <span className="uzhzhatlar-text583">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle75"
                    >
                      <span className="uzhzhatlar-text584">Алока маркази</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle76"
                    >
                      <span className="uzhzhatlar-text585">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzhzhatlar-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzhzhatlar-dropdown-toggle77"
                    >
                      <span className="uzhzhatlar-text586">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="uzhzhatlar-container39">
        <div className="uzhzhatlar-container40">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="uzhzhatlar-image20"
          />
          <span className="uzhzhatlar-text589">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="uzhzhatlar-container41">
          <div className="uzhzhatlar-container42">
            <span className="uzhzhatlar-text590">Ходимларни бошқариш</span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="uzhzhatlar-image21"
            />
          </div>
          <div className="uzhzhatlar-container43">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="uzhzhatlar-navlink80"
            >
              <div className="uzhzhatlar-container44">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="uzhzhatlar-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="uzhzhatlar-text591">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="uzhzhatlar-navlink81"
            >
              <div className="uzhzhatlar-container45">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="uzhzhatlar-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="uzhzhatlar-text592">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="uzhzhatlar-navlink82"
            >
              <div className="uzhzhatlar-container46">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="uzhzhatlar-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="uzhzhatlar-text596">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="uzhzhatlar-navlink83"
            >
              <div className="uzhzhatlar-container47">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="uzhzhatlar-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="uzhzhatlar-text600">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="uzhzhatlar-navlink84"
            >
              <div className="uzhzhatlar-container48">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="uzhzhatlar-icon44"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="uzhzhatlar-text604">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="uzhzhatlar-container49">
        <div className="uzhzhatlar-container50">
          <span className="uzhzhatlar-text608">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="uzhzhatlar-container51">
            <Link to="/visametric-gurukhi" className="uzhzhatlar-navlink85">
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="uzhzhatlar-navlink86"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/w3c-logo2-200h.png"
          className="uzhzhatlar-image22"
        />
        <a href="#main" className="uzhzhatlar-link">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="uzhzhatlar-image23"
          />
        </a>
      </div>
    </div>
  )
}

export default Uzhzhatlar
