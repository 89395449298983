import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './ariza-topshirish-uchun-srovnoma-tldirish.css'

const ArizaTopshirishUchunSrovnomaTldirish = (props) => {
  return (
    <div className="ariza-topshirish-uchun-srovnoma-tldirish-container10">
      <Helmet>
        <title>ariza-topshirish-uchun-srovnoma-tldirish - Visametric</title>
        <meta
          property="og:title"
          content="ariza-topshirish-uchun-srovnoma-tldirish - Visametric"
        />
      </Helmet>
      <div
        data-thq="thq-burger-menu"
        className="ariza-topshirish-uchun-srovnoma-tldirish-burger-menu1"
      >
        <svg
          viewBox="0 0 1024 1024"
          className="ariza-topshirish-uchun-srovnoma-tldirish-icon10"
        >
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div
        id="main"
        className="ariza-topshirish-uchun-srovnoma-tldirish-container11"
      >
        <h1 className="ariza-topshirish-uchun-srovnoma-tldirish-text100">
          Ариза топшириш учун сўровнома тўлдириш
        </h1>
        <div className="ariza-topshirish-uchun-srovnoma-tldirish-container12">
          <Link
            to="/uzbekistan-germanyuz"
            className="ariza-topshirish-uchun-srovnoma-tldirish-navlink10"
          >
            <div className="ariza-topshirish-uchun-srovnoma-tldirish-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="ariza-topshirish-uchun-srovnoma-tldirish-icon12"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="ariza-topshirish-uchun-srovnoma-tldirish-text101">
                Бош саҳифа
              </h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="ariza-topshirish-uchun-srovnoma-tldirish-navlink11"
          >
            <div className="ariza-topshirish-uchun-srovnoma-tldirish-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="ariza-topshirish-uchun-srovnoma-tldirish-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="ariza-topshirish-uchun-srovnoma-tldirish-text102">
                Қўшимча хизматлар
              </h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="ariza-topshirish-uchun-srovnoma-tldirish-navlink12"
          >
            <div className="ariza-topshirish-uchun-srovnoma-tldirish-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="ariza-topshirish-uchun-srovnoma-tldirish-icon16"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="ariza-topshirish-uchun-srovnoma-tldirish-text103">
                Ариза топшириш учун сўровнома тўлдириш
              </h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="ariza-topshirish-uchun-srovnoma-tldirish-container16">
        <div className="ariza-topshirish-uchun-srovnoma-tldirish-container17">
          <div className="ariza-topshirish-uchun-srovnoma-tldirish-container18">
            <span className="ariza-topshirish-uchun-srovnoma-tldirish-text104">
              Қўшимча хизматлар
            </span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="ariza-topshirish-uchun-srovnoma-tldirish-icon18"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="ariza-topshirish-uchun-srovnoma-tldirish-image10"
          />
          <div className="ariza-topshirish-uchun-srovnoma-tldirish-container19">
            <Link
              to="/vip-khizmati"
              className="ariza-topshirish-uchun-srovnoma-tldirish-navlink13"
            >
              <div className="ariza-topshirish-uchun-srovnoma-tldirish-container20">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-image11"
                />
                <span className="ariza-topshirish-uchun-srovnoma-tldirish-text105">
                  VIP - хизмати
                </span>
              </div>
            </Link>
            <Link
              to="/ariza-topshirish-uchun-srovnoma-tldirish"
              className="ariza-topshirish-uchun-srovnoma-tldirish-navlink14"
            >
              <div className="ariza-topshirish-uchun-srovnoma-tldirish-container21">
                <img
                  alt="image"
                  src="/rectangle%2030.svg"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-image12"
                />
                <span className="ariza-topshirish-uchun-srovnoma-tldirish-text106">
                  Ариза топшириш учун сўровнома тўлдириш
                </span>
              </div>
            </Link>
            <Link
              to="/biometrik-fotosura"
              className="ariza-topshirish-uchun-srovnoma-tldirish-navlink15"
            >
              <div className="ariza-topshirish-uchun-srovnoma-tldirish-container22">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-image13"
                />
                <span className="ariza-topshirish-uchun-srovnoma-tldirish-text107">
                  Биометрик фотосура
                </span>
              </div>
            </Link>
            <Link
              to="/foto-nuskha-faks-chop-etish-khizmatlari"
              className="ariza-topshirish-uchun-srovnoma-tldirish-navlink16"
            >
              <div className="ariza-topshirish-uchun-srovnoma-tldirish-container23">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-image14"
                />
                <span className="ariza-topshirish-uchun-srovnoma-tldirish-text108">
                  Фото нусха, факс, чоп этиш хизматлари
                </span>
              </div>
            </Link>
            <Link
              to="/kurerlik-khizmati-orali-etkazib-berish"
              className="ariza-topshirish-uchun-srovnoma-tldirish-navlink17"
            >
              <div className="ariza-topshirish-uchun-srovnoma-tldirish-container24">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-image15"
                />
                <span className="ariza-topshirish-uchun-srovnoma-tldirish-text109">
                  Курьерлик хизмати орқали етказиб бериш
                </span>
              </div>
            </Link>
            <Link
              to="/khabardor-ilish-khizmati"
              className="ariza-topshirish-uchun-srovnoma-tldirish-navlink18"
            >
              <div className="ariza-topshirish-uchun-srovnoma-tldirish-container25">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-image16"
                />
                <span className="ariza-topshirish-uchun-srovnoma-tldirish-text110">
                  Хабардор қилиш хизмати
                </span>
              </div>
            </Link>
            <Link
              to="/khuzhzhatlar-tarzhimasi"
              className="ariza-topshirish-uchun-srovnoma-tldirish-navlink19"
            >
              <div className="ariza-topshirish-uchun-srovnoma-tldirish-container26">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-image17"
                />
                <span className="ariza-topshirish-uchun-srovnoma-tldirish-text111">
                  Хужжатлар таржимаси
                </span>
              </div>
            </Link>
            <Link
              to="/khalaro-tibbiy-suurta"
              className="ariza-topshirish-uchun-srovnoma-tldirish-navlink20"
            >
              <div className="ariza-topshirish-uchun-srovnoma-tldirish-container27">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-image18"
                />
                <span className="ariza-topshirish-uchun-srovnoma-tldirish-text112">
                  Халқаро тиббий суғурта
                </span>
              </div>
            </Link>
            <Link
              to="/prime-time"
              className="ariza-topshirish-uchun-srovnoma-tldirish-navlink21"
            >
              <div className="ariza-topshirish-uchun-srovnoma-tldirish-container28">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-image19"
                />
                <span className="ariza-topshirish-uchun-srovnoma-tldirish-text113">
                  Prime Тиме
                </span>
              </div>
            </Link>
          </div>
        </div>
        <div className="ariza-topshirish-uchun-srovnoma-tldirish-container29">
          <span className="ariza-topshirish-uchun-srovnoma-tldirish-text114">
            <span className="ariza-topshirish-uchun-srovnoma-tldirish-text115">
              Ариза топшириш учун сўровнома тўлдириш (бир киши учун 6 евро)
            </span>
            <br></br>
            <br></br>
            <span>
              VisaMetric қабул марказлари визани расмийлаштириш сўровномани
              тўлдириш бўйича хизматларни таклиф қилишади.
            </span>
          </span>
        </div>
      </div>
      <div
        id="header"
        className="ariza-topshirish-uchun-srovnoma-tldirish-container30"
      >
        <Link
          to="/"
          className="ariza-topshirish-uchun-srovnoma-tldirish-navlink22"
        >
          <div className="ariza-topshirish-uchun-srovnoma-tldirish-container31">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="ariza-topshirish-uchun-srovnoma-tldirish-image20"
            />
            <p className="ariza-topshirish-uchun-srovnoma-tldirish-text119">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="ariza-topshirish-uchun-srovnoma-tldirish-container32">
          <div className="ariza-topshirish-uchun-srovnoma-tldirish-container33">
            <span className="ariza-topshirish-uchun-srovnoma-tldirish-text122">
              <span>Apply for Visa to</span>
              <span className="ariza-topshirish-uchun-srovnoma-tldirish-text124">
                {' '}
                Germany
              </span>
            </span>
            <span className="ariza-topshirish-uchun-srovnoma-tldirish-text125">
              <span className="ariza-topshirish-uchun-srovnoma-tldirish-text126">
                in Uzbekistan
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="ariza-topshirish-uchun-srovnoma-tldirish-image21"
          />
          <span className="ariza-topshirish-uchun-srovnoma-tldirish-text157">
            |
          </span>
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header
        data-thq="thq-navbar"
        className="ariza-topshirish-uchun-srovnoma-tldirish-navbar-interactive"
      >
        <div
          data-thq="thq-navbar-nav"
          className="ariza-topshirish-uchun-srovnoma-tldirish-desktop-menu"
        >
          <div className="ariza-topshirish-uchun-srovnoma-tldirish-container34">
            <nav className="ariza-topshirish-uchun-srovnoma-tldirish-links1">
              <div
                data-thq="thq-dropdown"
                className="ariza-topshirish-uchun-srovnoma-tldirish-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle10"
                  >
                    <span className="ariza-topshirish-uchun-srovnoma-tldirish-text158">
                      Бош сахифа
                    </span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ariza-topshirish-uchun-srovnoma-tldirish-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle11"
                >
                  <span className="ariza-topshirish-uchun-srovnoma-tldirish-text159">
                    Умумий
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle12"
                      >
                        <span className="ariza-topshirish-uchun-srovnoma-tldirish-text160 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle13"
                      >
                        <span className="ariza-topshirish-uchun-srovnoma-tldirish-text163 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle14"
                      >
                        <span className="ariza-topshirish-uchun-srovnoma-tldirish-text164 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle15"
                      >
                        <span className="ariza-topshirish-uchun-srovnoma-tldirish-text165 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle16"
                      >
                        <span className="ariza-topshirish-uchun-srovnoma-tldirish-text170 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle17"
                      >
                        <span className="ariza-topshirish-uchun-srovnoma-tldirish-text171 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle18"
                      >
                        <span className="ariza-topshirish-uchun-srovnoma-tldirish-text172 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle19"
                      >
                        <span className="ariza-topshirish-uchun-srovnoma-tldirish-text176 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ariza-topshirish-uchun-srovnoma-tldirish-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle20"
                >
                  <span className="ariza-topshirish-uchun-srovnoma-tldirish-text177">
                    Виза олиш
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle21"
                      >
                        <span className="ariza-topshirish-uchun-srovnoma-tldirish-text178">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle22"
                      >
                        <span className="ariza-topshirish-uchun-srovnoma-tldirish-text179">
                          Миллий виза
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle23"
                      >
                        <span className="ariza-topshirish-uchun-srovnoma-tldirish-text180">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle24"
                      >
                        <span className="ariza-topshirish-uchun-srovnoma-tldirish-text181">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle25"
                      >
                        <span className="ariza-topshirish-uchun-srovnoma-tldirish-text184">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle26"
                      >
                        <span className="ariza-topshirish-uchun-srovnoma-tldirish-text188">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle27"
                      >
                        <span className="ariza-topshirish-uchun-srovnoma-tldirish-text189">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ariza-topshirish-uchun-srovnoma-tldirish-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle28"
                >
                  <span className="ariza-topshirish-uchun-srovnoma-tldirish-text190">
                    Кушимча хизматлар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle29"
                      >
                        <span className="ariza-topshirish-uchun-srovnoma-tldirish-text191">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle30"
                      >
                        <span className="ariza-topshirish-uchun-srovnoma-tldirish-text192">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle31"
                      >
                        <span className="ariza-topshirish-uchun-srovnoma-tldirish-text196">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle32"
                      >
                        <span className="ariza-topshirish-uchun-srovnoma-tldirish-text199">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle33"
                      >
                        <span className="ariza-topshirish-uchun-srovnoma-tldirish-text200">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle34"
                      >
                        <span className="ariza-topshirish-uchun-srovnoma-tldirish-text204">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle35"
                      >
                        <span className="ariza-topshirish-uchun-srovnoma-tldirish-text205">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ariza-topshirish-uchun-srovnoma-tldirish-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle38"
                  >
                    <span className="ariza-topshirish-uchun-srovnoma-tldirish-text208">
                      FAQ
                    </span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ariza-topshirish-uchun-srovnoma-tldirish-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle39"
                >
                  <span className="ariza-topshirish-uchun-srovnoma-tldirish-text209">
                    Алокалар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle40"
                      >
                        <span className="ariza-topshirish-uchun-srovnoma-tldirish-text210">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle41"
                      >
                        <span className="ariza-topshirish-uchun-srovnoma-tldirish-text211">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle42"
                      >
                        <span className="ariza-topshirish-uchun-srovnoma-tldirish-text212">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle43"
                      >
                        <span className="ariza-topshirish-uchun-srovnoma-tldirish-text213">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="ariza-topshirish-uchun-srovnoma-tldirish-container35">
          <input
            type="text"
            placeholder="Кидирмок"
            className="ariza-topshirish-uchun-srovnoma-tldirish-textinput1 input"
          />
          <div className="ariza-topshirish-uchun-srovnoma-tldirish-container36">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="ariza-topshirish-uchun-srovnoma-tldirish-icon20"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="ariza-topshirish-uchun-srovnoma-tldirish-container37">
            <span className="ariza-topshirish-uchun-srovnoma-tldirish-text216">
              A+
            </span>
            <span className="ariza-topshirish-uchun-srovnoma-tldirish-text217">
              A-
            </span>
          </div>
        </div>
        <div
          data-thq="thq-burger-menu"
          className="ariza-topshirish-uchun-srovnoma-tldirish-burger-menu2"
        >
          <svg
            viewBox="0 0 1024 1024"
            className="ariza-topshirish-uchun-srovnoma-tldirish-icon22"
          >
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div
          data-thq="thq-mobile-menu"
          className="ariza-topshirish-uchun-srovnoma-tldirish-mobile-menu"
        >
          <div className="ariza-topshirish-uchun-srovnoma-tldirish-nav">
            <div className="ariza-topshirish-uchun-srovnoma-tldirish-container38">
              <div className="ariza-topshirish-uchun-srovnoma-tldirish-container39">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-textinput2 input"
                />
                <div className="ariza-topshirish-uchun-srovnoma-tldirish-container40">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="ariza-topshirish-uchun-srovnoma-tldirish-icon24"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="ariza-topshirish-uchun-srovnoma-tldirish-container41">
                  <span className="ariza-topshirish-uchun-srovnoma-tldirish-text218">
                    A+
                  </span>
                  <span className="ariza-topshirish-uchun-srovnoma-tldirish-text219">
                    A-
                  </span>
                </div>
              </div>
              <div
                data-thq="thq-close-menu"
                className="ariza-topshirish-uchun-srovnoma-tldirish-close-menu"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-icon26"
                >
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="ariza-topshirish-uchun-srovnoma-tldirish-links2">
            <div
              data-thq="thq-dropdown"
              className="ariza-topshirish-uchun-srovnoma-tldirish-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle44"
                >
                  <span className="ariza-topshirish-uchun-srovnoma-tldirish-text220">
                    Бош сахифа
                  </span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ariza-topshirish-uchun-srovnoma-tldirish-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle45"
              >
                <span className="ariza-topshirish-uchun-srovnoma-tldirish-text221">
                  Умумий
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle46"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text222">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle47"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text225">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle48"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text226">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle49"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text227">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle50"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text232">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle51"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text233">
                        Тулов Шартлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle52"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text234">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle53"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text237">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ariza-topshirish-uchun-srovnoma-tldirish-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle54"
              >
                <span className="ariza-topshirish-uchun-srovnoma-tldirish-text238">
                  Виза олиш
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle55"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text239">
                        Шенген визаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle56"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text240">
                        Миллий виза
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle57"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text241">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle58"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text242">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle59"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text245">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle60"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text249">
                        Ариза шакллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle61"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text250">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ariza-topshirish-uchun-srovnoma-tldirish-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle62"
              >
                <span className="ariza-topshirish-uchun-srovnoma-tldirish-text251">
                  Кушимча хизматлар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle63"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text252">
                        VIP - хизмати
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle64"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text253">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle65"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text254">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle66"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text255">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle67"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text256">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle68"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text259">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle69"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text262">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle70"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text263">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle71"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text264">
                        Prime Тиме
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ariza-topshirish-uchun-srovnoma-tldirish-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle72"
                >
                  <span className="ariza-topshirish-uchun-srovnoma-tldirish-text265">
                    FAQ
                  </span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ariza-topshirish-uchun-srovnoma-tldirish-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle73"
              >
                <span className="ariza-topshirish-uchun-srovnoma-tldirish-text266">
                  Алокалар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-icon34"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle74"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text267">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle75"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text268">
                        Алока маркази
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle76"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text269">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-uchun-srovnoma-tldirish-dropdown-toggle77"
                    >
                      <span className="ariza-topshirish-uchun-srovnoma-tldirish-text270">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="ariza-topshirish-uchun-srovnoma-tldirish-container42">
        <div className="ariza-topshirish-uchun-srovnoma-tldirish-container43">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="ariza-topshirish-uchun-srovnoma-tldirish-image23"
          />
          <span className="ariza-topshirish-uchun-srovnoma-tldirish-text273">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="ariza-topshirish-uchun-srovnoma-tldirish-container44">
          <div className="ariza-topshirish-uchun-srovnoma-tldirish-container45">
            <span className="ariza-topshirish-uchun-srovnoma-tldirish-text274">
              Ходимларни бошқариш
            </span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="ariza-topshirish-uchun-srovnoma-tldirish-image24"
            />
          </div>
          <div className="ariza-topshirish-uchun-srovnoma-tldirish-container46">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="ariza-topshirish-uchun-srovnoma-tldirish-navlink83"
            >
              <div className="ariza-topshirish-uchun-srovnoma-tldirish-container47">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ariza-topshirish-uchun-srovnoma-tldirish-text275">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="ariza-topshirish-uchun-srovnoma-tldirish-navlink84"
            >
              <div className="ariza-topshirish-uchun-srovnoma-tldirish-container48">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ariza-topshirish-uchun-srovnoma-tldirish-text276">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="ariza-topshirish-uchun-srovnoma-tldirish-navlink85"
            >
              <div className="ariza-topshirish-uchun-srovnoma-tldirish-container49">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ariza-topshirish-uchun-srovnoma-tldirish-text280">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="ariza-topshirish-uchun-srovnoma-tldirish-navlink86"
            >
              <div className="ariza-topshirish-uchun-srovnoma-tldirish-container50">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ariza-topshirish-uchun-srovnoma-tldirish-text284">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="ariza-topshirish-uchun-srovnoma-tldirish-navlink87"
            >
              <div className="ariza-topshirish-uchun-srovnoma-tldirish-container51">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ariza-topshirish-uchun-srovnoma-tldirish-icon44"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ariza-topshirish-uchun-srovnoma-tldirish-text288">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="ariza-topshirish-uchun-srovnoma-tldirish-container52">
        <div className="ariza-topshirish-uchun-srovnoma-tldirish-container53">
          <span className="ariza-topshirish-uchun-srovnoma-tldirish-text292">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="ariza-topshirish-uchun-srovnoma-tldirish-container54">
            <Link
              to="/visametric-gurukhi"
              className="ariza-topshirish-uchun-srovnoma-tldirish-navlink88"
            >
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="ariza-topshirish-uchun-srovnoma-tldirish-navlink89"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/w3c-logo2-200h.png"
          className="ariza-topshirish-uchun-srovnoma-tldirish-image25"
        />
        <a
          href="#main"
          className="ariza-topshirish-uchun-srovnoma-tldirish-link"
        >
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="ariza-topshirish-uchun-srovnoma-tldirish-image26"
          />
        </a>
      </div>
    </div>
  )
}

export default ArizaTopshirishUchunSrovnomaTldirish
