import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'; // For navigation
import { Helmet } from 'react-helmet';

import NavbarInteractive from '../components/navbar-interactive';
import './home.css';

const Home = (props) => {
  const history = useHistory(); // Initialize useHistory
  const [visitingCountries, setVisitingCountries] = useState([]);

  // Handle country of residence change
  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;

    if (selectedCountry === 'Uzbekistan') {
      // Update visiting countries when Uzbekistan is selected
      setVisitingCountries(['Germany', 'Sweden']);
    } else if (selectedCountry === 'Tajikistan') {
      // Update visiting countries when Tajikistan is selected
      setVisitingCountries([
        'Austria',
        'Belgium',
        'Finland',
        'France',
        'Germany',
        'Italy',
        'Luxembourg',
        'Netherlands',
        'Norway',
        'Portugal',
        'Sweden',
      ]);
    } else {
      // Reset visiting countries for other selections
      setVisitingCountries([]);
    }
  };

  // Handle visiting country change
  const handleVisitingCountryChange = (event) => {
    const selectedVisitingCountry = event.target.value;

    if (selectedVisitingCountry === 'Germany') {
      const countryOfResidence = document.querySelector(
        '.home-select1'
      ).value;

      if (countryOfResidence === 'Uzbekistan') {
        history.push('/uzbekistan-germanyuz'); // Redirect if Uzbekistan is selected
      } else if (countryOfResidence === 'Tajikistan') {
        history.push('/tajikistan-germanyuz'); // Redirect if Tajikistan is selected
      }
    }
  };

  return (
    <div className="home-container1">
      <Helmet>
        <title>Visametric</title>
        <meta property="og:title" content="Visametric" />
      </Helmet>
      <NavbarInteractive rootClassName="navbar-interactiveroot-class-name" />
      <div className="home-container2">
        <div className="home-container3">
          <span className="home-text10">I&apos;m applying from</span>
          <select className="home-select1" onChange={handleCountryChange}>
            <option value="Option 1" className="home-option10">
              Country of Residence
            </option>
            <option value="Albania">Albania</option>
            <option value="Azerbaijan">Azerbaijan</option>
            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
            <option value="Kazakhstan">Kazakhstan</option>
            <option value="Kosovo">Kosovo</option>
            <option value="Kyrgyzstan">Kyrgyzstan</option>
            <option value="North Macedonia">North Macedonia</option>
            <option value="Russia">Russia</option>
            <option value="Serbia">Serbia</option>
            <option value="Tajikistan">Tajikistan</option>
            <option value="Ukraine">Ukraine</option>
            <option value="Uzbekistan">Uzbekistan</option>
          </select>
        </div>
        <div className="home-container4">
          <span className="home-text11"> and I&apos;m going to</span>
          <select className="home-select2" onChange={handleVisitingCountryChange}>
            <option value="Option 1" className="home-option23">
              Visiting Country
            </option>
            {visitingCountries.map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="home-container5">
        <p className="home-text12">
          <span>
            VisaMetric focuses on providing Diplomatic Missions with
            high-quality non-discretionary support services related to Visa
            applications and other areas (such as Consular Services).
          </span>
          <br />
        </p>
        <p className="home-text15">
          <span>
            VisaMetric Group has processed to date over 7,000,000 visa
            applications for the following Governments: Republic of Italy,
            Federal Republic of Germany, Republic of Estonia, Republic of Korea,
            Republic of Malta*, Republic of Portugal*, Republic of Slovenia*,
            Kingdom of Belgium*, Grand Duchy of Luxembourg*, Kingdom of the
            Netherlands*, Republic of Austria*, Kingdom of Sweden*, Republic of
            Greece*, Kingdom of Norway*, Republic of France*, Kingdom of Spain*,
            Republic of Finland*, Republic of Latvia* (*through selected Italian
            and/or German Diplomatic Missions).
          </span>
          <br />
        </p>
        <p className="home-text18">
          <span>VisaMetric has operations in 16 countries</span>
          <br />
        </p>
      </div>
      <div className="home-container6">
        <img alt="image" src="/logo-white-200h.png" className="home-image" />
      </div>
    </div>
  );
};

export default Home;
