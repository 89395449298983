import React from 'react'

import { Helmet } from 'react-helmet'

import './talim-va-malaka-oshirish.css'

const TalimVaMalakaOshirish = (props) => {
  return (
    <div className="talim-va-malaka-oshirish-container">
      <Helmet>
        <title>talim-va-malaka-oshirish - Visametric</title>
        <meta
          property="og:title"
          content="talim-va-malaka-oshirish - Visametric"
        />
      </Helmet>
    </div>
  )
}

export default TalimVaMalakaOshirish
