import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './kurerlik-khizmati-orali-etkazib-berish.css'

const KurerlikKhizmatiOraliEtkazibBerish = (props) => {
  return (
    <div className="kurerlik-khizmati-orali-etkazib-berish-container10">
      <Helmet>
        <title>kurerlik-khizmati-orali-etkazib-berish - Visametric</title>
        <meta
          property="og:title"
          content="kurerlik-khizmati-orali-etkazib-berish - Visametric"
        />
      </Helmet>
      <div
        data-thq="thq-burger-menu"
        className="kurerlik-khizmati-orali-etkazib-berish-burger-menu1"
      >
        <svg
          viewBox="0 0 1024 1024"
          className="kurerlik-khizmati-orali-etkazib-berish-icon10"
        >
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div
        id="main"
        className="kurerlik-khizmati-orali-etkazib-berish-container11"
      >
        <h1 className="kurerlik-khizmati-orali-etkazib-berish-text100">
          Курьерлик хизмати орқали етказиб бериш
        </h1>
        <div className="kurerlik-khizmati-orali-etkazib-berish-container12">
          <Link
            to="/uzbekistan-germanyuz"
            className="kurerlik-khizmati-orali-etkazib-berish-navlink10"
          >
            <div className="kurerlik-khizmati-orali-etkazib-berish-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="kurerlik-khizmati-orali-etkazib-berish-icon12"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="kurerlik-khizmati-orali-etkazib-berish-text101">
                Бош саҳифа
              </h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="kurerlik-khizmati-orali-etkazib-berish-navlink11"
          >
            <div className="kurerlik-khizmati-orali-etkazib-berish-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="kurerlik-khizmati-orali-etkazib-berish-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="kurerlik-khizmati-orali-etkazib-berish-text102">
                Қўшимча хизматлар
              </h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="kurerlik-khizmati-orali-etkazib-berish-navlink12"
          >
            <div className="kurerlik-khizmati-orali-etkazib-berish-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="kurerlik-khizmati-orali-etkazib-berish-icon16"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="kurerlik-khizmati-orali-etkazib-berish-text103">
                Курьерлик хизмати орқали етказиб бериш
              </h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="kurerlik-khizmati-orali-etkazib-berish-container16">
        <div className="kurerlik-khizmati-orali-etkazib-berish-container17">
          <div className="kurerlik-khizmati-orali-etkazib-berish-container18">
            <span className="kurerlik-khizmati-orali-etkazib-berish-text104">
              Қўшимча хизматлар
            </span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="kurerlik-khizmati-orali-etkazib-berish-icon18"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="kurerlik-khizmati-orali-etkazib-berish-image10"
          />
          <div className="kurerlik-khizmati-orali-etkazib-berish-container19">
            <Link
              to="/vip-khizmati"
              className="kurerlik-khizmati-orali-etkazib-berish-navlink13"
            >
              <div className="kurerlik-khizmati-orali-etkazib-berish-container20">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="kurerlik-khizmati-orali-etkazib-berish-image11"
                />
                <span className="kurerlik-khizmati-orali-etkazib-berish-text105">
                  VIP - хизмати
                </span>
              </div>
            </Link>
            <Link
              to="/ariza-topshirish-uchun-srovnoma-tldirish"
              className="kurerlik-khizmati-orali-etkazib-berish-navlink14"
            >
              <div className="kurerlik-khizmati-orali-etkazib-berish-container21">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="kurerlik-khizmati-orali-etkazib-berish-image12"
                />
                <span className="kurerlik-khizmati-orali-etkazib-berish-text106">
                  Ариза топшириш учун сўровнома тўлдириш
                </span>
              </div>
            </Link>
            <Link
              to="/biometrik-fotosura"
              className="kurerlik-khizmati-orali-etkazib-berish-navlink15"
            >
              <div className="kurerlik-khizmati-orali-etkazib-berish-container22">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="kurerlik-khizmati-orali-etkazib-berish-image13"
                />
                <span className="kurerlik-khizmati-orali-etkazib-berish-text107">
                  Биометрик фотосура
                </span>
              </div>
            </Link>
            <Link
              to="/foto-nuskha-faks-chop-etish-khizmatlari"
              className="kurerlik-khizmati-orali-etkazib-berish-navlink16"
            >
              <div className="kurerlik-khizmati-orali-etkazib-berish-container23">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="kurerlik-khizmati-orali-etkazib-berish-image14"
                />
                <span className="kurerlik-khizmati-orali-etkazib-berish-text108">
                  Фото нусха, факс, чоп этиш хизматлари
                </span>
              </div>
            </Link>
            <Link
              to="/kurerlik-khizmati-orali-etkazib-berish"
              className="kurerlik-khizmati-orali-etkazib-berish-navlink17"
            >
              <div className="kurerlik-khizmati-orali-etkazib-berish-container24">
                <img
                  alt="image"
                  src="/rectangle%2030.svg"
                  className="kurerlik-khizmati-orali-etkazib-berish-image15"
                />
                <span className="kurerlik-khizmati-orali-etkazib-berish-text109">
                  Курьерлик хизмати орқали етказиб бериш
                </span>
              </div>
            </Link>
            <Link
              to="/khabardor-ilish-khizmati"
              className="kurerlik-khizmati-orali-etkazib-berish-navlink18"
            >
              <div className="kurerlik-khizmati-orali-etkazib-berish-container25">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="kurerlik-khizmati-orali-etkazib-berish-image16"
                />
                <span className="kurerlik-khizmati-orali-etkazib-berish-text110">
                  Хабардор қилиш хизмати
                </span>
              </div>
            </Link>
            <Link
              to="/khuzhzhatlar-tarzhimasi"
              className="kurerlik-khizmati-orali-etkazib-berish-navlink19"
            >
              <div className="kurerlik-khizmati-orali-etkazib-berish-container26">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="kurerlik-khizmati-orali-etkazib-berish-image17"
                />
                <span className="kurerlik-khizmati-orali-etkazib-berish-text111">
                  Хужжатлар таржимаси
                </span>
              </div>
            </Link>
            <Link
              to="/khalaro-tibbiy-suurta"
              className="kurerlik-khizmati-orali-etkazib-berish-navlink20"
            >
              <div className="kurerlik-khizmati-orali-etkazib-berish-container27">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="kurerlik-khizmati-orali-etkazib-berish-image18"
                />
                <span className="kurerlik-khizmati-orali-etkazib-berish-text112">
                  Халқаро тиббий суғурта
                </span>
              </div>
            </Link>
            <Link
              to="/prime-time"
              className="kurerlik-khizmati-orali-etkazib-berish-navlink21"
            >
              <div className="kurerlik-khizmati-orali-etkazib-berish-container28">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="kurerlik-khizmati-orali-etkazib-berish-image19"
                />
                <span className="kurerlik-khizmati-orali-etkazib-berish-text113">
                  Prime Тиме
                </span>
              </div>
            </Link>
          </div>
        </div>
        <div className="kurerlik-khizmati-orali-etkazib-berish-container29">
          <span className="kurerlik-khizmati-orali-etkazib-berish-text114">
            <span className="kurerlik-khizmati-orali-etkazib-berish-text115">
              Курьерлик хизмати орқали етказиб бериш (бир киши учун 10 €)
            </span>
            <br className="kurerlik-khizmati-orali-etkazib-berish-text116"></br>
            <br className="kurerlik-khizmati-orali-etkazib-berish-text117"></br>
            <span>
              Аризангизни топшираётганда сиз курер орқали паспортни етказиб
              бериш хизматига буюртма беришингиз мумкин. Сизнинг аризангиз
              элчихона томонидан кўриб чиқилгандан сўнг, тайёр бўлган паспорт
              сиз кўрсатган манзилга юборилади. Юк ташиш малумотларингиз тўг’ри
              эканлигига ишонч ҳосил қилинг. Сиз жо’натмангизни курерлик хизмати
              веб-сайти орқали кузатишингиз мумкин.
            </span>
          </span>
        </div>
      </div>
      <div
        id="header"
        className="kurerlik-khizmati-orali-etkazib-berish-container30"
      >
        <Link
          to="/"
          className="kurerlik-khizmati-orali-etkazib-berish-navlink22"
        >
          <div className="kurerlik-khizmati-orali-etkazib-berish-container31">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="kurerlik-khizmati-orali-etkazib-berish-image20"
            />
            <p className="kurerlik-khizmati-orali-etkazib-berish-text119">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="kurerlik-khizmati-orali-etkazib-berish-container32">
          <div className="kurerlik-khizmati-orali-etkazib-berish-container33">
            <span className="kurerlik-khizmati-orali-etkazib-berish-text122">
              <span>Apply for Visa to</span>
              <span className="kurerlik-khizmati-orali-etkazib-berish-text124">
                {' '}
                Germany
              </span>
            </span>
            <span className="kurerlik-khizmati-orali-etkazib-berish-text125">
              <span className="kurerlik-khizmati-orali-etkazib-berish-text126">
                in Uzbekistan
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="kurerlik-khizmati-orali-etkazib-berish-image21"
          />
          <span className="kurerlik-khizmati-orali-etkazib-berish-text157">
            |
          </span>
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header
        data-thq="thq-navbar"
        className="kurerlik-khizmati-orali-etkazib-berish-navbar-interactive"
      >
        <div
          data-thq="thq-navbar-nav"
          className="kurerlik-khizmati-orali-etkazib-berish-desktop-menu"
        >
          <div className="kurerlik-khizmati-orali-etkazib-berish-container34">
            <nav className="kurerlik-khizmati-orali-etkazib-berish-links1">
              <div
                data-thq="thq-dropdown"
                className="kurerlik-khizmati-orali-etkazib-berish-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle10"
                  >
                    <span className="kurerlik-khizmati-orali-etkazib-berish-text158">
                      Бош сахифа
                    </span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="kurerlik-khizmati-orali-etkazib-berish-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle11"
                >
                  <span className="kurerlik-khizmati-orali-etkazib-berish-text159">
                    Умумий
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle12"
                      >
                        <span className="kurerlik-khizmati-orali-etkazib-berish-text160 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle13"
                      >
                        <span className="kurerlik-khizmati-orali-etkazib-berish-text163 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle14"
                      >
                        <span className="kurerlik-khizmati-orali-etkazib-berish-text164 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle15"
                      >
                        <span className="kurerlik-khizmati-orali-etkazib-berish-text165 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle16"
                      >
                        <span className="kurerlik-khizmati-orali-etkazib-berish-text170 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle17"
                      >
                        <span className="kurerlik-khizmati-orali-etkazib-berish-text171 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle18"
                      >
                        <span className="kurerlik-khizmati-orali-etkazib-berish-text172 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle19"
                      >
                        <span className="kurerlik-khizmati-orali-etkazib-berish-text176 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="kurerlik-khizmati-orali-etkazib-berish-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle20"
                >
                  <span className="kurerlik-khizmati-orali-etkazib-berish-text177">
                    Виза олиш
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle21"
                      >
                        <span className="kurerlik-khizmati-orali-etkazib-berish-text178">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle22"
                      >
                        <span className="kurerlik-khizmati-orali-etkazib-berish-text179">
                          Миллий виза
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle23"
                      >
                        <span className="kurerlik-khizmati-orali-etkazib-berish-text180">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle24"
                      >
                        <span className="kurerlik-khizmati-orali-etkazib-berish-text181">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle25"
                      >
                        <span className="kurerlik-khizmati-orali-etkazib-berish-text184">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle26"
                      >
                        <span className="kurerlik-khizmati-orali-etkazib-berish-text188">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle27"
                      >
                        <span className="kurerlik-khizmati-orali-etkazib-berish-text189">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="kurerlik-khizmati-orali-etkazib-berish-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle28"
                >
                  <span className="kurerlik-khizmati-orali-etkazib-berish-text190">
                    Кушимча хизматлар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle29"
                      >
                        <span className="kurerlik-khizmati-orali-etkazib-berish-text191">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle30"
                      >
                        <span className="kurerlik-khizmati-orali-etkazib-berish-text192">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle31"
                      >
                        <span className="kurerlik-khizmati-orali-etkazib-berish-text196">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle32"
                      >
                        <span className="kurerlik-khizmati-orali-etkazib-berish-text199">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle33"
                      >
                        <span className="kurerlik-khizmati-orali-etkazib-berish-text200">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle34"
                      >
                        <span className="kurerlik-khizmati-orali-etkazib-berish-text204">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle35"
                      >
                        <span className="kurerlik-khizmati-orali-etkazib-berish-text205">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="kurerlik-khizmati-orali-etkazib-berish-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle38"
                  >
                    <span className="kurerlik-khizmati-orali-etkazib-berish-text208">
                      FAQ
                    </span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="kurerlik-khizmati-orali-etkazib-berish-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle39"
                >
                  <span className="kurerlik-khizmati-orali-etkazib-berish-text209">
                    Алокалар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle40"
                      >
                        <span className="kurerlik-khizmati-orali-etkazib-berish-text210">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle41"
                      >
                        <span className="kurerlik-khizmati-orali-etkazib-berish-text211">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle42"
                      >
                        <span className="kurerlik-khizmati-orali-etkazib-berish-text212">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kurerlik-khizmati-orali-etkazib-berish-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle43"
                      >
                        <span className="kurerlik-khizmati-orali-etkazib-berish-text213">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="kurerlik-khizmati-orali-etkazib-berish-container35">
          <input
            type="text"
            placeholder="Кидирмок"
            className="kurerlik-khizmati-orali-etkazib-berish-textinput1 input"
          />
          <div className="kurerlik-khizmati-orali-etkazib-berish-container36">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="kurerlik-khizmati-orali-etkazib-berish-icon20"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="kurerlik-khizmati-orali-etkazib-berish-container37">
            <span className="kurerlik-khizmati-orali-etkazib-berish-text216">
              A+
            </span>
            <span className="kurerlik-khizmati-orali-etkazib-berish-text217">
              A-
            </span>
          </div>
        </div>
        <div
          data-thq="thq-burger-menu"
          className="kurerlik-khizmati-orali-etkazib-berish-burger-menu2"
        >
          <svg
            viewBox="0 0 1024 1024"
            className="kurerlik-khizmati-orali-etkazib-berish-icon22"
          >
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div
          data-thq="thq-mobile-menu"
          className="kurerlik-khizmati-orali-etkazib-berish-mobile-menu"
        >
          <div className="kurerlik-khizmati-orali-etkazib-berish-nav">
            <div className="kurerlik-khizmati-orali-etkazib-berish-container38">
              <div className="kurerlik-khizmati-orali-etkazib-berish-container39">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="kurerlik-khizmati-orali-etkazib-berish-textinput2 input"
                />
                <div className="kurerlik-khizmati-orali-etkazib-berish-container40">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="kurerlik-khizmati-orali-etkazib-berish-icon24"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="kurerlik-khizmati-orali-etkazib-berish-container41">
                  <span className="kurerlik-khizmati-orali-etkazib-berish-text218">
                    A+
                  </span>
                  <span className="kurerlik-khizmati-orali-etkazib-berish-text219">
                    A-
                  </span>
                </div>
              </div>
              <div
                data-thq="thq-close-menu"
                className="kurerlik-khizmati-orali-etkazib-berish-close-menu"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="kurerlik-khizmati-orali-etkazib-berish-icon26"
                >
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="kurerlik-khizmati-orali-etkazib-berish-links2">
            <div
              data-thq="thq-dropdown"
              className="kurerlik-khizmati-orali-etkazib-berish-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle44"
                >
                  <span className="kurerlik-khizmati-orali-etkazib-berish-text220">
                    Бош сахифа
                  </span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="kurerlik-khizmati-orali-etkazib-berish-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle45"
              >
                <span className="kurerlik-khizmati-orali-etkazib-berish-text221">
                  Умумий
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="kurerlik-khizmati-orali-etkazib-berish-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="kurerlik-khizmati-orali-etkazib-berish-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle46"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text222">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle47"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text225">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle48"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text226">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle49"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text227">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle50"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text232">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle51"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text233">
                        Тулов Шартлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle52"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text234">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle53"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text237">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="kurerlik-khizmati-orali-etkazib-berish-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle54"
              >
                <span className="kurerlik-khizmati-orali-etkazib-berish-text238">
                  Виза олиш
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="kurerlik-khizmati-orali-etkazib-berish-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="kurerlik-khizmati-orali-etkazib-berish-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle55"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text239">
                        Шенген визаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle56"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text240">
                        Миллий виза
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle57"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text241">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle58"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text242">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle59"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text245">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle60"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text249">
                        Ариза шакллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle61"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text250">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="kurerlik-khizmati-orali-etkazib-berish-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle62"
              >
                <span className="kurerlik-khizmati-orali-etkazib-berish-text251">
                  Кушимча хизматлар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="kurerlik-khizmati-orali-etkazib-berish-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="kurerlik-khizmati-orali-etkazib-berish-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle63"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text252">
                        VIP - хизмати
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle64"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text253">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle65"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text254">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle66"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text255">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle67"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text256">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle68"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text259">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle69"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text262">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle70"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text263">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle71"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text264">
                        Prime Тиме
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="kurerlik-khizmati-orali-etkazib-berish-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle72"
                >
                  <span className="kurerlik-khizmati-orali-etkazib-berish-text265">
                    FAQ
                  </span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="kurerlik-khizmati-orali-etkazib-berish-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle73"
              >
                <span className="kurerlik-khizmati-orali-etkazib-berish-text266">
                  Алокалар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="kurerlik-khizmati-orali-etkazib-berish-icon34"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="kurerlik-khizmati-orali-etkazib-berish-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle74"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text267">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle75"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text268">
                        Алока маркази
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle76"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text269">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kurerlik-khizmati-orali-etkazib-berish-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kurerlik-khizmati-orali-etkazib-berish-dropdown-toggle77"
                    >
                      <span className="kurerlik-khizmati-orali-etkazib-berish-text270">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="kurerlik-khizmati-orali-etkazib-berish-container42">
        <div className="kurerlik-khizmati-orali-etkazib-berish-container43">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="kurerlik-khizmati-orali-etkazib-berish-image23"
          />
          <span className="kurerlik-khizmati-orali-etkazib-berish-text273">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="kurerlik-khizmati-orali-etkazib-berish-container44">
          <div className="kurerlik-khizmati-orali-etkazib-berish-container45">
            <span className="kurerlik-khizmati-orali-etkazib-berish-text274">
              Ходимларни бошқариш
            </span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="kurerlik-khizmati-orali-etkazib-berish-image24"
            />
          </div>
          <div className="kurerlik-khizmati-orali-etkazib-berish-container46">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="kurerlik-khizmati-orali-etkazib-berish-navlink83"
            >
              <div className="kurerlik-khizmati-orali-etkazib-berish-container47">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="kurerlik-khizmati-orali-etkazib-berish-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="kurerlik-khizmati-orali-etkazib-berish-text275">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="kurerlik-khizmati-orali-etkazib-berish-navlink84"
            >
              <div className="kurerlik-khizmati-orali-etkazib-berish-container48">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="kurerlik-khizmati-orali-etkazib-berish-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="kurerlik-khizmati-orali-etkazib-berish-text276">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="kurerlik-khizmati-orali-etkazib-berish-navlink85"
            >
              <div className="kurerlik-khizmati-orali-etkazib-berish-container49">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="kurerlik-khizmati-orali-etkazib-berish-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="kurerlik-khizmati-orali-etkazib-berish-text280">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="kurerlik-khizmati-orali-etkazib-berish-navlink86"
            >
              <div className="kurerlik-khizmati-orali-etkazib-berish-container50">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="kurerlik-khizmati-orali-etkazib-berish-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="kurerlik-khizmati-orali-etkazib-berish-text284">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="kurerlik-khizmati-orali-etkazib-berish-navlink87"
            >
              <div className="kurerlik-khizmati-orali-etkazib-berish-container51">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="kurerlik-khizmati-orali-etkazib-berish-icon44"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="kurerlik-khizmati-orali-etkazib-berish-text288">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="kurerlik-khizmati-orali-etkazib-berish-container52">
        <div className="kurerlik-khizmati-orali-etkazib-berish-container53">
          <span className="kurerlik-khizmati-orali-etkazib-berish-text292">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="kurerlik-khizmati-orali-etkazib-berish-container54">
            <Link
              to="/visametric-gurukhi"
              className="kurerlik-khizmati-orali-etkazib-berish-navlink88"
            >
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="kurerlik-khizmati-orali-etkazib-berish-navlink89"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/w3c-logo2-200h.png"
          className="kurerlik-khizmati-orali-etkazib-berish-image25"
        />
        <a href="#main" className="kurerlik-khizmati-orali-etkazib-berish-link">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="kurerlik-khizmati-orali-etkazib-berish-image26"
          />
        </a>
      </div>
    </div>
  )
}

export default KurerlikKhizmatiOraliEtkazibBerish
