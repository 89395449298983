import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './kerakli-uzhzhatlar.css'

const KerakliUzhzhatlar = (props) => {
  return (
    <div className="kerakli-uzhzhatlar-container10">
      <Helmet>
        <title>kerakli-uzhzhatlar - Visametric</title>
        <meta property="og:title" content="kerakli-uzhzhatlar - Visametric" />
      </Helmet>
      <div
        data-thq="thq-burger-menu"
        className="kerakli-uzhzhatlar-burger-menu1"
      >
        <svg viewBox="0 0 1024 1024" className="kerakli-uzhzhatlar-icon10">
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div id="main" className="kerakli-uzhzhatlar-container11">
        <h1 className="kerakli-uzhzhatlar-text100">Керакли ҳужжатлар</h1>
        <div className="kerakli-uzhzhatlar-container12">
          <Link
            to="/uzbekistan-germanyuz"
            className="kerakli-uzhzhatlar-navlink10"
          >
            <div className="kerakli-uzhzhatlar-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="kerakli-uzhzhatlar-icon12"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="kerakli-uzhzhatlar-text101">Бош саҳифа</h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="kerakli-uzhzhatlar-navlink11"
          >
            <div className="kerakli-uzhzhatlar-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="kerakli-uzhzhatlar-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="kerakli-uzhzhatlar-text102">Виза олиш</h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="kerakli-uzhzhatlar-navlink12"
          >
            <div className="kerakli-uzhzhatlar-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="kerakli-uzhzhatlar-icon16"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="kerakli-uzhzhatlar-text103">Керакли ҳужжатлар</h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="kerakli-uzhzhatlar-container16">
        <div className="kerakli-uzhzhatlar-container17">
          <div className="kerakli-uzhzhatlar-container18">
            <span className="kerakli-uzhzhatlar-text104">Виза олиш</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="kerakli-uzhzhatlar-icon18"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="kerakli-uzhzhatlar-image10"
          />
          <div className="kerakli-uzhzhatlar-container19">
            <Link to="/shengen-vizasi" className="kerakli-uzhzhatlar-navlink13">
              <div className="kerakli-uzhzhatlar-container20">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="kerakli-uzhzhatlar-image11"
                />
                <span className="kerakli-uzhzhatlar-text105">
                  Шенген визаси
                </span>
              </div>
            </Link>
            <Link to="/milliy-viza" className="kerakli-uzhzhatlar-navlink14">
              <div className="kerakli-uzhzhatlar-container21">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="kerakli-uzhzhatlar-image12"
                />
                <span className="kerakli-uzhzhatlar-text106">Миллий виза</span>
              </div>
            </Link>
            <Link
              to="/rasmiy-bayramlar-kuni"
              className="kerakli-uzhzhatlar-navlink15"
            >
              <div className="kerakli-uzhzhatlar-container22">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="kerakli-uzhzhatlar-image13"
                />
                <span className="kerakli-uzhzhatlar-text107">
                  Онлайн-сўровнома
                </span>
              </div>
            </Link>
            <Link
              to="/kerakli-uzhzhatlar"
              className="kerakli-uzhzhatlar-navlink16"
            >
              <div className="kerakli-uzhzhatlar-container23">
                <img
                  alt="image"
                  src="/rectangle%2030.svg"
                  className="kerakli-uzhzhatlar-image14"
                />
                <span className="kerakli-uzhzhatlar-text108">
                  Керакли ҳужжатлар
                </span>
              </div>
            </Link>
            <Link
              to="/uchrashuv-sanasini-va-vatini-belgilash"
              className="kerakli-uzhzhatlar-navlink17"
            >
              <div className="kerakli-uzhzhatlar-container24">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="kerakli-uzhzhatlar-image15"
                />
                <span className="kerakli-uzhzhatlar-text109">
                  Учрашув санасини ва вақтини белгилаш
                </span>
              </div>
            </Link>
            <Link
              to="/ariza-shakllari"
              className="kerakli-uzhzhatlar-navlink18"
            >
              <div className="kerakli-uzhzhatlar-container25">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="kerakli-uzhzhatlar-image16"
                />
                <span className="kerakli-uzhzhatlar-text110">
                  Ариза шакллари
                </span>
              </div>
            </Link>
            <Link
              to="/ilovani-kuzatish"
              className="kerakli-uzhzhatlar-navlink19"
            >
              <div className="kerakli-uzhzhatlar-container26">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="kerakli-uzhzhatlar-image17"
                />
                <span className="kerakli-uzhzhatlar-text111">
                  Иловани кузатиш
                </span>
              </div>
            </Link>
          </div>
        </div>
        <div className="kerakli-uzhzhatlar-container27">
          <div className="kerakli-uzhzhatlar-container28">
            <button type="button" className="kerakli-uzhzhatlar-button button">
              <span className="kerakli-uzhzhatlar-text112">
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>Онлайн сўровномани тўлдириш (Videx)</span>
              </span>
            </button>
            <Link
              to="/ariza-shakllari"
              className="kerakli-uzhzhatlar-navlink20 button"
            >
              <span className="kerakli-uzhzhatlar-text115">
                {' '}
                Сўровномани қўлда тўлдириш
              </span>
            </Link>
            <a
              href="https://videx.diplo.de/videx/visum-erfassung/videx-kurzfristiger-aufenthalt"
              target="_blank"
              rel="noreferrer noopener"
              className="kerakli-uzhzhatlar-link1 button"
            >
              <span className="kerakli-uzhzhatlar-text116">
                {' '}
                Онлайн сўровнома Шенген (Videx)
              </span>
            </a>
            <a
              href="https://videx.diplo.de/videx/visum-erfassung/videx-langfristiger-aufenthalt"
              target="_blank"
              rel="noreferrer noopener"
              className="kerakli-uzhzhatlar-link2 button"
            >
              <span className="kerakli-uzhzhatlar-text117">
                {' '}
                Онлайн сўровнома Миллий (Videx)
              </span>
            </a>
          </div>
        </div>
      </div>
      <div id="header" className="kerakli-uzhzhatlar-container29">
        <Link to="/" className="kerakli-uzhzhatlar-navlink21">
          <div className="kerakli-uzhzhatlar-container30">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="kerakli-uzhzhatlar-image18"
            />
            <p className="kerakli-uzhzhatlar-text118">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="kerakli-uzhzhatlar-container31">
          <div className="kerakli-uzhzhatlar-container32">
            <span className="kerakli-uzhzhatlar-text121">
              <span>Apply for Visa to</span>
              <span className="kerakli-uzhzhatlar-text123"> Germany</span>
            </span>
            <span className="kerakli-uzhzhatlar-text124">
              <span className="kerakli-uzhzhatlar-text125">in Uzbekistan</span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="kerakli-uzhzhatlar-image19"
          />
          <span className="kerakli-uzhzhatlar-text156">|</span>
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header
        data-thq="thq-navbar"
        className="kerakli-uzhzhatlar-navbar-interactive"
      >
        <div
          data-thq="thq-navbar-nav"
          className="kerakli-uzhzhatlar-desktop-menu"
        >
          <div className="kerakli-uzhzhatlar-container33">
            <nav className="kerakli-uzhzhatlar-links1">
              <div
                data-thq="thq-dropdown"
                className="kerakli-uzhzhatlar-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="kerakli-uzhzhatlar-dropdown-toggle10"
                  >
                    <span className="kerakli-uzhzhatlar-text157">
                      Бош сахифа
                    </span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="kerakli-uzhzhatlar-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="kerakli-uzhzhatlar-dropdown-toggle11"
                >
                  <span className="kerakli-uzhzhatlar-text158">Умумий</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="kerakli-uzhzhatlar-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="kerakli-uzhzhatlar-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle12"
                      >
                        <span className="kerakli-uzhzhatlar-text159 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle13"
                      >
                        <span className="kerakli-uzhzhatlar-text162 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle14"
                      >
                        <span className="kerakli-uzhzhatlar-text163 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle15"
                      >
                        <span className="kerakli-uzhzhatlar-text164 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle16"
                      >
                        <span className="kerakli-uzhzhatlar-text169 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle17"
                      >
                        <span className="kerakli-uzhzhatlar-text170 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle18"
                      >
                        <span className="kerakli-uzhzhatlar-text171 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle19"
                      >
                        <span className="kerakli-uzhzhatlar-text175 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="kerakli-uzhzhatlar-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="kerakli-uzhzhatlar-dropdown-toggle20"
                >
                  <span className="kerakli-uzhzhatlar-text176">Виза олиш</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="kerakli-uzhzhatlar-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="kerakli-uzhzhatlar-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle21"
                      >
                        <span className="kerakli-uzhzhatlar-text177">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle22"
                      >
                        <span className="kerakli-uzhzhatlar-text178">
                          Миллий виза
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle23"
                      >
                        <span className="kerakli-uzhzhatlar-text179">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle24"
                      >
                        <span className="kerakli-uzhzhatlar-text180">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle25"
                      >
                        <span className="kerakli-uzhzhatlar-text183">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle26"
                      >
                        <span className="kerakli-uzhzhatlar-text187">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle27"
                      >
                        <span className="kerakli-uzhzhatlar-text188">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="kerakli-uzhzhatlar-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="kerakli-uzhzhatlar-dropdown-toggle28"
                >
                  <span className="kerakli-uzhzhatlar-text189">
                    Кушимча хизматлар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="kerakli-uzhzhatlar-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="kerakli-uzhzhatlar-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle29"
                      >
                        <span className="kerakli-uzhzhatlar-text190">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle30"
                      >
                        <span className="kerakli-uzhzhatlar-text191">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle31"
                      >
                        <span className="kerakli-uzhzhatlar-text195">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle32"
                      >
                        <span className="kerakli-uzhzhatlar-text198">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle33"
                      >
                        <span className="kerakli-uzhzhatlar-text199">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle34"
                      >
                        <span className="kerakli-uzhzhatlar-text203">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle35"
                      >
                        <span className="kerakli-uzhzhatlar-text204">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="kerakli-uzhzhatlar-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="kerakli-uzhzhatlar-dropdown-toggle38"
                  >
                    <span className="kerakli-uzhzhatlar-text207">FAQ</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="kerakli-uzhzhatlar-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="kerakli-uzhzhatlar-dropdown-toggle39"
                >
                  <span className="kerakli-uzhzhatlar-text208">Алокалар</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="kerakli-uzhzhatlar-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="kerakli-uzhzhatlar-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle40"
                      >
                        <span className="kerakli-uzhzhatlar-text209">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle41"
                      >
                        <span className="kerakli-uzhzhatlar-text210">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle42"
                      >
                        <span className="kerakli-uzhzhatlar-text211">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="kerakli-uzhzhatlar-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="kerakli-uzhzhatlar-dropdown-toggle43"
                      >
                        <span className="kerakli-uzhzhatlar-text212">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="kerakli-uzhzhatlar-container34">
          <input
            type="text"
            placeholder="Кидирмок"
            className="kerakli-uzhzhatlar-textinput1 input"
          />
          <div className="kerakli-uzhzhatlar-container35">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="kerakli-uzhzhatlar-icon20"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="kerakli-uzhzhatlar-container36">
            <span className="kerakli-uzhzhatlar-text215">A+</span>
            <span className="kerakli-uzhzhatlar-text216">A-</span>
          </div>
        </div>
        <div
          data-thq="thq-burger-menu"
          className="kerakli-uzhzhatlar-burger-menu2"
        >
          <svg viewBox="0 0 1024 1024" className="kerakli-uzhzhatlar-icon22">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div
          data-thq="thq-mobile-menu"
          className="kerakli-uzhzhatlar-mobile-menu"
        >
          <div className="kerakli-uzhzhatlar-nav">
            <div className="kerakli-uzhzhatlar-container37">
              <div className="kerakli-uzhzhatlar-container38">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="kerakli-uzhzhatlar-textinput2 input"
                />
                <div className="kerakli-uzhzhatlar-container39">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="kerakli-uzhzhatlar-icon24"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="kerakli-uzhzhatlar-container40">
                  <span className="kerakli-uzhzhatlar-text217">A+</span>
                  <span className="kerakli-uzhzhatlar-text218">A-</span>
                </div>
              </div>
              <div
                data-thq="thq-close-menu"
                className="kerakli-uzhzhatlar-close-menu"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="kerakli-uzhzhatlar-icon26"
                >
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="kerakli-uzhzhatlar-links2">
            <div
              data-thq="thq-dropdown"
              className="kerakli-uzhzhatlar-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="kerakli-uzhzhatlar-dropdown-toggle44"
                >
                  <span className="kerakli-uzhzhatlar-text219">Бош сахифа</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="kerakli-uzhzhatlar-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="kerakli-uzhzhatlar-dropdown-toggle45"
              >
                <span className="kerakli-uzhzhatlar-text220">Умумий</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="kerakli-uzhzhatlar-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="kerakli-uzhzhatlar-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="kerakli-uzhzhatlar-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle46"
                    >
                      <span className="kerakli-uzhzhatlar-text221">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle47"
                    >
                      <span className="kerakli-uzhzhatlar-text224">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle48"
                    >
                      <span className="kerakli-uzhzhatlar-text225">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle49"
                    >
                      <span className="kerakli-uzhzhatlar-text226">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle50"
                    >
                      <span className="kerakli-uzhzhatlar-text231">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle51"
                    >
                      <span className="kerakli-uzhzhatlar-text232">
                        Тулов Шартлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle52"
                    >
                      <span className="kerakli-uzhzhatlar-text233">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle53"
                    >
                      <span className="kerakli-uzhzhatlar-text236">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="kerakli-uzhzhatlar-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="kerakli-uzhzhatlar-dropdown-toggle54"
              >
                <span className="kerakli-uzhzhatlar-text237">Виза олиш</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="kerakli-uzhzhatlar-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="kerakli-uzhzhatlar-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="kerakli-uzhzhatlar-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle55"
                    >
                      <span className="kerakli-uzhzhatlar-text238">
                        Шенген визаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle56"
                    >
                      <span className="kerakli-uzhzhatlar-text239">
                        Миллий виза
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle57"
                    >
                      <span className="kerakli-uzhzhatlar-text240">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle58"
                    >
                      <span className="kerakli-uzhzhatlar-text241">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle59"
                    >
                      <span className="kerakli-uzhzhatlar-text244">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle60"
                    >
                      <span className="kerakli-uzhzhatlar-text248">
                        Ариза шакллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle61"
                    >
                      <span className="kerakli-uzhzhatlar-text249">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="kerakli-uzhzhatlar-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="kerakli-uzhzhatlar-dropdown-toggle62"
              >
                <span className="kerakli-uzhzhatlar-text250">
                  Кушимча хизматлар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="kerakli-uzhzhatlar-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="kerakli-uzhzhatlar-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="kerakli-uzhzhatlar-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle63"
                    >
                      <span className="kerakli-uzhzhatlar-text251">
                        VIP - хизмати
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle64"
                    >
                      <span className="kerakli-uzhzhatlar-text252">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle65"
                    >
                      <span className="kerakli-uzhzhatlar-text253">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle66"
                    >
                      <span className="kerakli-uzhzhatlar-text254">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle67"
                    >
                      <span className="kerakli-uzhzhatlar-text255">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle68"
                    >
                      <span className="kerakli-uzhzhatlar-text258">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle69"
                    >
                      <span className="kerakli-uzhzhatlar-text261">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle70"
                    >
                      <span className="kerakli-uzhzhatlar-text262">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle71"
                    >
                      <span className="kerakli-uzhzhatlar-text263">
                        Prime Тиме
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="kerakli-uzhzhatlar-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="kerakli-uzhzhatlar-dropdown-toggle72"
                >
                  <span className="kerakli-uzhzhatlar-text264">FAQ</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="kerakli-uzhzhatlar-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="kerakli-uzhzhatlar-dropdown-toggle73"
              >
                <span className="kerakli-uzhzhatlar-text265">Алокалар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="kerakli-uzhzhatlar-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="kerakli-uzhzhatlar-icon34"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="kerakli-uzhzhatlar-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle74"
                    >
                      <span className="kerakli-uzhzhatlar-text266">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle75"
                    >
                      <span className="kerakli-uzhzhatlar-text267">
                        Алока маркази
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle76"
                    >
                      <span className="kerakli-uzhzhatlar-text268">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="kerakli-uzhzhatlar-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="kerakli-uzhzhatlar-dropdown-toggle77"
                    >
                      <span className="kerakli-uzhzhatlar-text269">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="kerakli-uzhzhatlar-container41">
        <div className="kerakli-uzhzhatlar-container42">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="kerakli-uzhzhatlar-image21"
          />
          <span className="kerakli-uzhzhatlar-text272">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="kerakli-uzhzhatlar-container43">
          <div className="kerakli-uzhzhatlar-container44">
            <span className="kerakli-uzhzhatlar-text273">
              Ходимларни бошқариш
            </span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="kerakli-uzhzhatlar-image22"
            />
          </div>
          <div className="kerakli-uzhzhatlar-container45">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="kerakli-uzhzhatlar-navlink82"
            >
              <div className="kerakli-uzhzhatlar-container46">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="kerakli-uzhzhatlar-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="kerakli-uzhzhatlar-text274">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="kerakli-uzhzhatlar-navlink83"
            >
              <div className="kerakli-uzhzhatlar-container47">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="kerakli-uzhzhatlar-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="kerakli-uzhzhatlar-text275">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="kerakli-uzhzhatlar-navlink84"
            >
              <div className="kerakli-uzhzhatlar-container48">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="kerakli-uzhzhatlar-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="kerakli-uzhzhatlar-text279">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="kerakli-uzhzhatlar-navlink85"
            >
              <div className="kerakli-uzhzhatlar-container49">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="kerakli-uzhzhatlar-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="kerakli-uzhzhatlar-text283">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="kerakli-uzhzhatlar-navlink86"
            >
              <div className="kerakli-uzhzhatlar-container50">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="kerakli-uzhzhatlar-icon44"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="kerakli-uzhzhatlar-text287">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="kerakli-uzhzhatlar-container51">
        <div className="kerakli-uzhzhatlar-container52">
          <span className="kerakli-uzhzhatlar-text291">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="kerakli-uzhzhatlar-container53">
            <Link
              to="/visametric-gurukhi"
              className="kerakli-uzhzhatlar-navlink87"
            >
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="kerakli-uzhzhatlar-navlink88"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/w3c-logo2-200h.png"
          className="kerakli-uzhzhatlar-image23"
        />
        <a href="#main" className="kerakli-uzhzhatlar-link3">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="kerakli-uzhzhatlar-image24"
          />
        </a>
      </div>
    </div>
  )
}

export default KerakliUzhzhatlar
