import React from 'react'
import { Link } from 'react-router-dom'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import './rasmiy-bayramlar-kuni.css'

const RasmiyBayramlarKuni = (props) => {
  return (
    <div className="rasmiy-bayramlar-kuni-container10">
      <Helmet>
        <title>rasmiy-bayramlar-kuni - Visametric</title>
        <meta
          property="og:title"
          content="rasmiy-bayramlar-kuni - Visametric"
        />
      </Helmet>
      <div id="main" className="rasmiy-bayramlar-kuni-container11">
        <h1 className="rasmiy-bayramlar-kuni-text100">Расмий байрамлар куни</h1>
        <div className="rasmiy-bayramlar-kuni-container12">
          <Link
            to="/uzbekistan-germanyuz"
            className="rasmiy-bayramlar-kuni-navlink10"
          >
            <div className="rasmiy-bayramlar-kuni-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="rasmiy-bayramlar-kuni-icon10"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="rasmiy-bayramlar-kuni-text101">Бош саҳифа</h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="rasmiy-bayramlar-kuni-navlink11"
          >
            <div className="rasmiy-bayramlar-kuni-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="rasmiy-bayramlar-kuni-icon12"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="rasmiy-bayramlar-kuni-text102">Умумий</h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="rasmiy-bayramlar-kuni-navlink12"
          >
            <div className="rasmiy-bayramlar-kuni-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="rasmiy-bayramlar-kuni-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="rasmiy-bayramlar-kuni-text103">
                Расмий байрамлар куни
              </h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="rasmiy-bayramlar-kuni-container16">
        <div className="rasmiy-bayramlar-kuni-container17">
          <div className="rasmiy-bayramlar-kuni-container18">
            <span className="rasmiy-bayramlar-kuni-text104">Умумий</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="rasmiy-bayramlar-kuni-icon16"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="rasmiy-bayramlar-kuni-image10"
          />
          <div className="rasmiy-bayramlar-kuni-container19">
            <Link
              to="/submission-collection"
              className="rasmiy-bayramlar-kuni-navlink13"
            >
              <div className="rasmiy-bayramlar-kuni-container20">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="rasmiy-bayramlar-kuni-image11"
                />
                <span className="rasmiy-bayramlar-kuni-text105">
                  Ҳужжатларни топшириш/Ҳужжатларни олиш
                </span>
              </div>
            </Link>
            <Link
              to="/tegishli-malumotlar"
              className="rasmiy-bayramlar-kuni-navlink14"
            >
              <div className="rasmiy-bayramlar-kuni-container21">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="rasmiy-bayramlar-kuni-image12"
                />
                <span className="rasmiy-bayramlar-kuni-text106">
                  Тегишли маълумотлар
                </span>
              </div>
            </Link>
            <Link
              to="/rasmiy-bayramlar-kuni"
              className="rasmiy-bayramlar-kuni-navlink15"
            >
              <div className="rasmiy-bayramlar-kuni-container22">
                <img
                  alt="image"
                  src="/rectangle%2030.svg"
                  className="rasmiy-bayramlar-kuni-image13"
                />
                <span className="rasmiy-bayramlar-kuni-text107">
                  Расмий байрамлар куни
                </span>
              </div>
            </Link>
            <Link
              to="/arizalarni-krib-chiish-muddati"
              className="rasmiy-bayramlar-kuni-navlink16"
            >
              <div className="rasmiy-bayramlar-kuni-container23">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="rasmiy-bayramlar-kuni-image14"
                />
                <span className="rasmiy-bayramlar-kuni-text108">
                  Аризаларни кўриб чиқиш муддати
                </span>
              </div>
            </Link>
            <Link
              to="/biometrik-malumotlarini"
              className="rasmiy-bayramlar-kuni-navlink17"
            >
              <div className="rasmiy-bayramlar-kuni-container24">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="rasmiy-bayramlar-kuni-image15"
                />
                <span className="rasmiy-bayramlar-kuni-text109">
                  биометрик маълумотларини
                </span>
              </div>
            </Link>
            <Link
              to="/tlov-shartlari"
              className="rasmiy-bayramlar-kuni-navlink18"
            >
              <div className="rasmiy-bayramlar-kuni-container25">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="rasmiy-bayramlar-kuni-image16"
                />
                <span className="rasmiy-bayramlar-kuni-text110">
                  Тўлов Шартлари
                </span>
              </div>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="rasmiy-bayramlar-kuni-navlink19"
            >
              <div className="rasmiy-bayramlar-kuni-container26">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="rasmiy-bayramlar-kuni-image17"
                />
                <span className="rasmiy-bayramlar-kuni-text111">
                  Техника хавфсизлиги кўрсатмалари
                </span>
              </div>
            </Link>
            <Link
              to="/foydali-avolalar-uz"
              className="rasmiy-bayramlar-kuni-navlink20"
            >
              <div className="rasmiy-bayramlar-kuni-container27">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="rasmiy-bayramlar-kuni-image18"
                />
                <span className="rasmiy-bayramlar-kuni-text112">
                  Фойдали ҳаволалар
                </span>
              </div>
            </Link>
          </div>
        </div>
        <div className="rasmiy-bayramlar-kuni-container28">
          <span className="rasmiy-bayramlar-kuni-text113">
            Эътибор беринг, VisaMetric виза маркази ва Германия элчихонасининг
            дам олиш ва таътил кунлари фарқ қилиши мумкин.
          </span>
          <span className="rasmiy-bayramlar-kuni-text114">
            VisaMetric виза маркази ва Германиянинг Ўзбекистондаги элчихонаси
            2024-йилда қуйидаги байрамларда ёпилади:
          </span>
          <div className="rasmiy-bayramlar-kuni-container29">
            <div className="rasmiy-bayramlar-kuni-container30">
              <Script
                html={`<table border="3" cellpadding="3" cellspacing="3" style="border-collapse:collapse;border:0px;width:95%;">
	<tbody>
		<tr>
			<td colspan="2" style="background-color: rgb(34, 34, 34); text-align: center;">
			<p><span style="color:#ffffff;"><strong>Байрамлар тақвими 2024</strong></span></p>
			</td>
			<td style="background-color: rgb(34, 34, 34); text-align: center;"><span style="color:#ffffff;"><strong>Виза Олиш Маркази</strong></span></td>
			<td style="background-color: rgb(34, 34, 34); text-align: center;"><span style="color:#ffffff;"><strong>Германия Елчихонаси</strong></span></td>
		</tr>
		<tr height="21">
			<td height="21" style="border-color: rgb(255, 255, 255); background-color: rgb(221, 221, 221); width: 120px;"><strong>01.01.2024</strong></td>
			<td colspan="1" rowspan="2" style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); width: 300px;"><span style="color:#ffffff;"><strong>Янги йил байрамлари*</strong></span></td>
			<td colspan="1" style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); width: 300px; text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); width: 300px; text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
		</tr>
		<tr>
			<td height="21" style="border-color: rgb(255, 255, 255); background-color: rgb(221, 221, 221); width: 120px;"><strong>02.01.2024*</strong></td>
			<td colspan="1" style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); width: 300px; text-align: center;"><span style="color:#ffffff;"><strong>Очиқ</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); width: 300px; text-align: center;"><span style="color:#ffffff;"><strong>Очиқ</strong></span></td>
		</tr>
		<tr height="21">
			<td height="21" style="border-color: rgb(255, 255, 255); background-color: rgb(238, 238, 238);">08.03.2024</td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0);"><span style="color:#ffffff;"><strong>Халқаро хотин-қизлар куни</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
		</tr>
		<tr>
			<td height="21" style="border-color: rgb(255, 255, 255); background-color: rgb(221, 221, 221);"><strong>20.03.2024*</strong></td>
			<td colspan="1" rowspan="3" style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0);"><span style="color:#ffffff;"><strong>Наврўз*</strong></span></td>
			<td colspan="1" style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Очиқ</strong></span></td>
		</tr>
		<tr height="21">
			<td height="21" style="border-color: rgb(255, 255, 255); background-color: rgb(221, 221, 221);"><strong>21.03.2024*</strong></td>
			<td colspan="1" style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
		</tr>
		<tr height="21">
			<td height="21" style="border-color: rgb(255, 255, 255); background-color: rgb(221, 221, 221);"><strong>22.03.2024*</strong></td>
			<td colspan="1" style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Очиқ</strong></span></td>
		</tr>
		<tr>
			<td height="21" style="border-color: rgb(255, 255, 255); background-color: rgb(238, 238, 238);">29.03.2024</td>
			<td colspan="1" style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0);"><span style="color:#ffffff;"><strong>Пасха арафасидаги жума (Германия)</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Консултациялар / ҳужжатлар бериш учун очиқ</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
		</tr>
		<tr>
			<td height="21" style="border-color: rgb(255, 255, 255); background-color: rgb(221, 221, 221);"><strong>01.04.2024</strong></td>
			<td colspan="1" style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0);"><span style="color:#ffffff;"><strong>Пасханинг иккинчи куни</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Консултациялар / ҳужжатлар бериш учун очиқ</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
		</tr>
		<tr>
			<td height="21" style="border-color: rgb(255, 255, 255); background-color: rgb(238, 238, 238);">10.04.2024</td>
			<td colspan="1" rowspan="3" style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0);"><span style="color:#ffffff;"><strong>Рамазон Ҳайити*</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Очиқ</strong></span></td>
		</tr>
		<tr>
			<td height="21" style="border-color: rgb(255, 255, 255); background-color: rgb(238, 238, 238);">11.04.2024</td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Очиқ</strong></span></td>
		</tr>
		<tr>
			<td height="21" style="border-color: rgb(255, 255, 255); background-color: rgb(238, 238, 238);">12.04.2024</td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Очиқ</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Очиқ</strong></span></td>
		</tr>
		<tr>
			<td height="21" style="border-color: rgb(255, 255, 255); background-color: rgb(221, 221, 221);"><strong>01.05.2024</strong></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0);"><span style="color:#ffffff;"><strong>Миллатлар бирлиги куни</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Консултациялар / ҳужжатлар бериш учун очиқ</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
		</tr>
		<tr>
			<td height="21" style="border-color: rgb(255, 255, 255); background-color: rgb(238, 238, 238);">09.05.2024</td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0);"><span style="color:#ffffff;"><strong>Хотира ва қадрлаш куни</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
		</tr>
		<tr>
			<td height="21" style="border-color: rgb(255, 255, 255); background-color: rgb(221, 221, 221);"><strong>18.05.2024</strong></td>
			<td colspan="1" style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0);"><span style="color:#ffffff;"><strong>Муқаддас учлик куни</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Консултациялар / ҳужжатлар бериш учун очиқ</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
		</tr>
		<tr>
			<td height="21" style="border-color: rgb(255, 255, 255); background-color: rgb(238, 238, 238);">20.05.2024</td>
			<td colspan="1" style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0);"><span style="color:#ffffff;"><strong>Муқаддас Руҳ куни</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Консултациялар / ҳужжатлар бериш учун очиқ</strong></span></td>
			<td colspan="1" rowspan="1" style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
		</tr>
		<tr>
			<td height="21" style="border-color: rgb(255, 255, 255); background-color: rgb(221, 221, 221);"><strong>18.06.2024*</strong></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0);"><span style="color:#ffffff;"><strong>Қурбон ҳайит байрами*</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Очиқ</strong></span></td>
		</tr>
		<tr>
			<td height="21" style="border-color: rgb(255, 255, 255); background-color: rgb(238, 238, 238);">01.09.2024</td>
			<td colspan="1" rowspan="2" style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0);"><span style="color:#ffffff;"><strong>Ўзбекистон Республикаси Мустақиллик куни</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
		</tr>
		<tr>
			<td height="21" style="border-color: rgb(255, 255, 255); background-color: rgb(238, 238, 238);">02.09.2024</td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
		</tr>
		<tr>
			<td height="21" style="border-color: rgb(255, 255, 255); background-color: rgb(221, 221, 221);"><strong>01.10.2024</strong></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0);"><strong><span style="color:#ffffff;">Ўқитувчилар куни</span></strong></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Очиқ</strong></span></td>
		</tr>
		<tr>
			<td height="21" style="border-color: rgb(255, 255, 255); background-color: rgb(238, 238, 238);">03.10.2024</td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0);"><span style="color:#ffffff;"><strong>Германия Бирдамлиги куни</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Консултациялар / ҳужжатлар бериш учун очиқ</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
		</tr>
		<tr>
			<td height="21" style="border-color: rgb(255, 255, 255); background-color: rgb(221, 221, 221);"><strong>08.12.2024</strong></td>
			<td colspan="1" rowspan="2" style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0);"><span style="color:#ffffff;"><strong>Ўзбекистон Республикаси Конституцияси куни</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
		</tr>
		<tr>
			<td height="21" style="border-color: rgb(255, 255, 255); background-color: rgb(221, 221, 221);"><strong>09.12.2024</strong></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
		</tr>
		<tr>
			<td height="21" style="border-color: rgb(255, 255, 255); background-color: rgb(238, 238, 238);">25.12.2024</td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0);"><span style="color:#ffffff;"><strong>Рождествонинг биринчи куни</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Консултациялар / ҳужжатлар бериш учун очиқ</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(204, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
		</tr>
		<tr>
			<td height="21" style="border-color: rgb(255, 255, 255); background-color: rgb(221, 221, 221);"><strong>26.12.2024</strong></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0);"><span style="color:#ffffff;"><strong>Рождествонинг иккинчи куни</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Консултациялар / ҳужжатлар бериш учун очиқ</strong></span></td>
			<td style="border-color: rgb(255, 255, 255); background-color: rgb(153, 0, 0); text-align: center;"><span style="color:#ffffff;"><strong>Ёпиқ</strong></span></td>
		</tr>
	</tbody>
</table>`}
              ></Script>
            </div>
          </div>
          <span className="rasmiy-bayramlar-kuni-text115">
            *Ушбу байрамлар ўзгарувчан кунларга эга ва аниқ саналар кейинчалик
            Ўзбекистон Президенти томонидан тасдиқланади.
          </span>
        </div>
      </div>
      <div id="header" className="rasmiy-bayramlar-kuni-container31">
        <Link to="/" className="rasmiy-bayramlar-kuni-navlink21">
          <div className="rasmiy-bayramlar-kuni-container32">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="rasmiy-bayramlar-kuni-image19"
            />
            <p className="rasmiy-bayramlar-kuni-text116">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="rasmiy-bayramlar-kuni-container33">
          <div className="rasmiy-bayramlar-kuni-container34">
            <span className="rasmiy-bayramlar-kuni-text119">
              <span>Apply for Visa to</span>
              <span className="rasmiy-bayramlar-kuni-text121"> Germany</span>
            </span>
            <span className="rasmiy-bayramlar-kuni-text122">
              <span className="rasmiy-bayramlar-kuni-text123">
                in Uzbekistan
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="rasmiy-bayramlar-kuni-image20"
          />
          <span className="rasmiy-bayramlar-kuni-text154">|</span>
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header
        data-thq="thq-navbar"
        className="rasmiy-bayramlar-kuni-navbar-interactive"
      >
        <div
          data-thq="thq-navbar-nav"
          className="rasmiy-bayramlar-kuni-desktop-menu"
        >
          <div className="rasmiy-bayramlar-kuni-container35">
            <nav className="rasmiy-bayramlar-kuni-links1">
              <div
                data-thq="thq-dropdown"
                className="rasmiy-bayramlar-kuni-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="rasmiy-bayramlar-kuni-dropdown-toggle10"
                  >
                    <span className="rasmiy-bayramlar-kuni-text155">
                      Бош сахифа
                    </span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="rasmiy-bayramlar-kuni-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="rasmiy-bayramlar-kuni-dropdown-toggle11"
                >
                  <span className="rasmiy-bayramlar-kuni-text156">Умумий</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="rasmiy-bayramlar-kuni-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="rasmiy-bayramlar-kuni-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle12"
                      >
                        <span className="rasmiy-bayramlar-kuni-text157 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle13"
                      >
                        <span className="rasmiy-bayramlar-kuni-text160 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle14"
                      >
                        <span className="rasmiy-bayramlar-kuni-text161 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle15"
                      >
                        <span className="rasmiy-bayramlar-kuni-text162 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle16"
                      >
                        <span className="rasmiy-bayramlar-kuni-text167 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle17"
                      >
                        <span className="rasmiy-bayramlar-kuni-text168 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle18"
                      >
                        <span className="rasmiy-bayramlar-kuni-text169 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle19"
                      >
                        <span className="rasmiy-bayramlar-kuni-text173 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="rasmiy-bayramlar-kuni-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="rasmiy-bayramlar-kuni-dropdown-toggle20"
                >
                  <span className="rasmiy-bayramlar-kuni-text174">
                    Виза олиш
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="rasmiy-bayramlar-kuni-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="rasmiy-bayramlar-kuni-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle21"
                      >
                        <span className="rasmiy-bayramlar-kuni-text175">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle22"
                      >
                        <span className="rasmiy-bayramlar-kuni-text176">
                          Миллий виза
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle23"
                      >
                        <span className="rasmiy-bayramlar-kuni-text177">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle24"
                      >
                        <span className="rasmiy-bayramlar-kuni-text178">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle25"
                      >
                        <span className="rasmiy-bayramlar-kuni-text181">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle26"
                      >
                        <span className="rasmiy-bayramlar-kuni-text185">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle27"
                      >
                        <span className="rasmiy-bayramlar-kuni-text186">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="rasmiy-bayramlar-kuni-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="rasmiy-bayramlar-kuni-dropdown-toggle28"
                >
                  <span className="rasmiy-bayramlar-kuni-text187">
                    Кушимча хизматлар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="rasmiy-bayramlar-kuni-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="rasmiy-bayramlar-kuni-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle29"
                      >
                        <span className="rasmiy-bayramlar-kuni-text188">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle30"
                      >
                        <span className="rasmiy-bayramlar-kuni-text189">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle31"
                      >
                        <span className="rasmiy-bayramlar-kuni-text193">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle32"
                      >
                        <span className="rasmiy-bayramlar-kuni-text196">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle33"
                      >
                        <span className="rasmiy-bayramlar-kuni-text197">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle34"
                      >
                        <span className="rasmiy-bayramlar-kuni-text201">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle35"
                      >
                        <span className="rasmiy-bayramlar-kuni-text202">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="rasmiy-bayramlar-kuni-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="rasmiy-bayramlar-kuni-dropdown-toggle38"
                  >
                    <span className="rasmiy-bayramlar-kuni-text205">FAQ</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="rasmiy-bayramlar-kuni-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="rasmiy-bayramlar-kuni-dropdown-toggle39"
                >
                  <span className="rasmiy-bayramlar-kuni-text206">
                    Алокалар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="rasmiy-bayramlar-kuni-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="rasmiy-bayramlar-kuni-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle40"
                      >
                        <span className="rasmiy-bayramlar-kuni-text207">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle41"
                      >
                        <span className="rasmiy-bayramlar-kuni-text208">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle42"
                      >
                        <span className="rasmiy-bayramlar-kuni-text209">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="rasmiy-bayramlar-kuni-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="rasmiy-bayramlar-kuni-dropdown-toggle43"
                      >
                        <span className="rasmiy-bayramlar-kuni-text210">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="rasmiy-bayramlar-kuni-container36">
          <input
            type="text"
            placeholder="Кидирмок"
            className="rasmiy-bayramlar-kuni-textinput1 input"
          />
          <div className="rasmiy-bayramlar-kuni-container37">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="rasmiy-bayramlar-kuni-icon18"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="rasmiy-bayramlar-kuni-container38">
            <span className="rasmiy-bayramlar-kuni-text213">A+</span>
            <span className="rasmiy-bayramlar-kuni-text214">A-</span>
          </div>
        </div>
        <div
          data-thq="thq-burger-menu"
          className="rasmiy-bayramlar-kuni-burger-menu"
        >
          <svg viewBox="0 0 1024 1024" className="rasmiy-bayramlar-kuni-icon20">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div
          data-thq="thq-mobile-menu"
          className="rasmiy-bayramlar-kuni-mobile-menu"
        >
          <div className="rasmiy-bayramlar-kuni-nav">
            <div className="rasmiy-bayramlar-kuni-container39">
              <div className="rasmiy-bayramlar-kuni-container40">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="rasmiy-bayramlar-kuni-textinput2 input"
                />
                <div className="rasmiy-bayramlar-kuni-container41">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="rasmiy-bayramlar-kuni-icon22"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="rasmiy-bayramlar-kuni-container42">
                  <span className="rasmiy-bayramlar-kuni-text215">A+</span>
                  <span className="rasmiy-bayramlar-kuni-text216">A-</span>
                </div>
              </div>
              <div
                data-thq="thq-close-menu"
                className="rasmiy-bayramlar-kuni-close-menu"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="rasmiy-bayramlar-kuni-icon24"
                >
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="rasmiy-bayramlar-kuni-links2">
            <div
              data-thq="thq-dropdown"
              className="rasmiy-bayramlar-kuni-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="rasmiy-bayramlar-kuni-dropdown-toggle44"
                >
                  <span className="rasmiy-bayramlar-kuni-text217">
                    Бош сахифа
                  </span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="rasmiy-bayramlar-kuni-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="rasmiy-bayramlar-kuni-dropdown-toggle45"
              >
                <span className="rasmiy-bayramlar-kuni-text218">Умумий</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="rasmiy-bayramlar-kuni-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="rasmiy-bayramlar-kuni-icon26"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="rasmiy-bayramlar-kuni-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle46"
                    >
                      <span className="rasmiy-bayramlar-kuni-text219">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle47"
                    >
                      <span className="rasmiy-bayramlar-kuni-text222">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle48"
                    >
                      <span className="rasmiy-bayramlar-kuni-text223">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle49"
                    >
                      <span className="rasmiy-bayramlar-kuni-text224">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle50"
                    >
                      <span className="rasmiy-bayramlar-kuni-text229">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle51"
                    >
                      <span className="rasmiy-bayramlar-kuni-text230">
                        Тулов Шартлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle52"
                    >
                      <span className="rasmiy-bayramlar-kuni-text231">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle53"
                    >
                      <span className="rasmiy-bayramlar-kuni-text234">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="rasmiy-bayramlar-kuni-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="rasmiy-bayramlar-kuni-dropdown-toggle54"
              >
                <span className="rasmiy-bayramlar-kuni-text235">Виза олиш</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="rasmiy-bayramlar-kuni-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="rasmiy-bayramlar-kuni-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="rasmiy-bayramlar-kuni-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle55"
                    >
                      <span className="rasmiy-bayramlar-kuni-text236">
                        Шенген визаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle56"
                    >
                      <span className="rasmiy-bayramlar-kuni-text237">
                        Миллий виза
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle57"
                    >
                      <span className="rasmiy-bayramlar-kuni-text238">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle58"
                    >
                      <span className="rasmiy-bayramlar-kuni-text239">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle59"
                    >
                      <span className="rasmiy-bayramlar-kuni-text242">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle60"
                    >
                      <span className="rasmiy-bayramlar-kuni-text246">
                        Ариза шакллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle61"
                    >
                      <span className="rasmiy-bayramlar-kuni-text247">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="rasmiy-bayramlar-kuni-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="rasmiy-bayramlar-kuni-dropdown-toggle62"
              >
                <span className="rasmiy-bayramlar-kuni-text248">
                  Кушимча хизматлар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="rasmiy-bayramlar-kuni-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="rasmiy-bayramlar-kuni-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="rasmiy-bayramlar-kuni-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle63"
                    >
                      <span className="rasmiy-bayramlar-kuni-text249">
                        VIP - хизмати
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle64"
                    >
                      <span className="rasmiy-bayramlar-kuni-text250">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle65"
                    >
                      <span className="rasmiy-bayramlar-kuni-text251">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle66"
                    >
                      <span className="rasmiy-bayramlar-kuni-text252">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle67"
                    >
                      <span className="rasmiy-bayramlar-kuni-text253">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle68"
                    >
                      <span className="rasmiy-bayramlar-kuni-text256">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle69"
                    >
                      <span className="rasmiy-bayramlar-kuni-text259">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle70"
                    >
                      <span className="rasmiy-bayramlar-kuni-text260">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle71"
                    >
                      <span className="rasmiy-bayramlar-kuni-text261">
                        Prime Тиме
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="rasmiy-bayramlar-kuni-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="rasmiy-bayramlar-kuni-dropdown-toggle72"
                >
                  <span className="rasmiy-bayramlar-kuni-text262">FAQ</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="rasmiy-bayramlar-kuni-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="rasmiy-bayramlar-kuni-dropdown-toggle73"
              >
                <span className="rasmiy-bayramlar-kuni-text263">Алокалар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="rasmiy-bayramlar-kuni-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="rasmiy-bayramlar-kuni-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="rasmiy-bayramlar-kuni-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle74"
                    >
                      <span className="rasmiy-bayramlar-kuni-text264">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle75"
                    >
                      <span className="rasmiy-bayramlar-kuni-text265">
                        Алока маркази
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle76"
                    >
                      <span className="rasmiy-bayramlar-kuni-text266">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="rasmiy-bayramlar-kuni-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="rasmiy-bayramlar-kuni-dropdown-toggle77"
                    >
                      <span className="rasmiy-bayramlar-kuni-text267">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="rasmiy-bayramlar-kuni-container43">
        <div className="rasmiy-bayramlar-kuni-container44">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="rasmiy-bayramlar-kuni-image22"
          />
          <span className="rasmiy-bayramlar-kuni-text270">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="rasmiy-bayramlar-kuni-container45">
          <div className="rasmiy-bayramlar-kuni-container46">
            <span className="rasmiy-bayramlar-kuni-text271">
              Ходимларни бошқариш
            </span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="rasmiy-bayramlar-kuni-image23"
            />
          </div>
          <div className="rasmiy-bayramlar-kuni-container47">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="rasmiy-bayramlar-kuni-navlink82"
            >
              <div className="rasmiy-bayramlar-kuni-container48">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="rasmiy-bayramlar-kuni-icon34"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="rasmiy-bayramlar-kuni-text272">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="rasmiy-bayramlar-kuni-navlink83"
            >
              <div className="rasmiy-bayramlar-kuni-container49">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="rasmiy-bayramlar-kuni-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="rasmiy-bayramlar-kuni-text273">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="rasmiy-bayramlar-kuni-navlink84"
            >
              <div className="rasmiy-bayramlar-kuni-container50">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="rasmiy-bayramlar-kuni-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="rasmiy-bayramlar-kuni-text277">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="rasmiy-bayramlar-kuni-navlink85"
            >
              <div className="rasmiy-bayramlar-kuni-container51">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="rasmiy-bayramlar-kuni-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="rasmiy-bayramlar-kuni-text281">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="rasmiy-bayramlar-kuni-navlink86"
            >
              <div className="rasmiy-bayramlar-kuni-container52">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="rasmiy-bayramlar-kuni-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="rasmiy-bayramlar-kuni-text285">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="rasmiy-bayramlar-kuni-container53">
        <div className="rasmiy-bayramlar-kuni-container54">
          <span className="rasmiy-bayramlar-kuni-text289">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="rasmiy-bayramlar-kuni-container55">
            <Link
              to="/visametric-gurukhi"
              className="rasmiy-bayramlar-kuni-navlink87"
            >
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="rasmiy-bayramlar-kuni-navlink88"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/w3c-logo2-200h.png"
          className="rasmiy-bayramlar-kuni-image24"
        />
        <a href="#main" className="rasmiy-bayramlar-kuni-link">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="rasmiy-bayramlar-kuni-image25"
          />
        </a>
      </div>
    </div>
  )
}

export default RasmiyBayramlarKuni
