import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './biometrik-malumotlarini.css'

const BiometrikMalumotlarini = (props) => {
  return (
    <div className="biometrik-malumotlarini-container10">
      <Helmet>
        <title>biometrik-malumotlarini - Visametric</title>
        <meta
          property="og:title"
          content="biometrik-malumotlarini - Visametric"
        />
      </Helmet>
      <div id="main" className="biometrik-malumotlarini-container11">
        <h1 className="biometrik-malumotlarini-text100">
          <span>биометрик маълумотларини</span>
          <br></br>
        </h1>
        <div className="biometrik-malumotlarini-container12">
          <Link
            to="/uzbekistan-germanyuz"
            className="biometrik-malumotlarini-navlink10"
          >
            <div className="biometrik-malumotlarini-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="biometrik-malumotlarini-icon10"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="biometrik-malumotlarini-text103">Бош саҳифа</h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="biometrik-malumotlarini-navlink11"
          >
            <div className="biometrik-malumotlarini-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="biometrik-malumotlarini-icon12"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="biometrik-malumotlarini-text104">Умумий</h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="biometrik-malumotlarini-navlink12"
          >
            <div className="biometrik-malumotlarini-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="biometrik-malumotlarini-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="biometrik-malumotlarini-text105">
                биометрик маълумотларини
              </h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="biometrik-malumotlarini-container16">
        <div className="biometrik-malumotlarini-container17">
          <div className="biometrik-malumotlarini-container18">
            <span className="biometrik-malumotlarini-text106">Умумий</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="biometrik-malumotlarini-icon16"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="biometrik-malumotlarini-image10"
          />
          <div className="biometrik-malumotlarini-container19">
            <Link
              to="/submission-collection"
              className="biometrik-malumotlarini-navlink13"
            >
              <div className="biometrik-malumotlarini-container20">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="biometrik-malumotlarini-image11"
                />
                <span className="biometrik-malumotlarini-text107">
                  Ҳужжатларни топшириш/Ҳужжатларни олиш
                </span>
              </div>
            </Link>
            <Link
              to="/tegishli-malumotlar"
              className="biometrik-malumotlarini-navlink14"
            >
              <div className="biometrik-malumotlarini-container21">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="biometrik-malumotlarini-image12"
                />
                <span className="biometrik-malumotlarini-text108">
                  Тегишли маълумотлар
                </span>
              </div>
            </Link>
            <Link
              to="/rasmiy-bayramlar-kuni"
              className="biometrik-malumotlarini-navlink15"
            >
              <div className="biometrik-malumotlarini-container22">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="biometrik-malumotlarini-image13"
                />
                <span className="biometrik-malumotlarini-text109">
                  Расмий байрамлар куни
                </span>
              </div>
            </Link>
            <Link
              to="/arizalarni-krib-chiish-muddati"
              className="biometrik-malumotlarini-navlink16"
            >
              <div className="biometrik-malumotlarini-container23">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="biometrik-malumotlarini-image14"
                />
                <span className="biometrik-malumotlarini-text110">
                  Аризаларни кўриб чиқиш муддати
                </span>
              </div>
            </Link>
            <Link
              to="/biometrik-malumotlarini"
              className="biometrik-malumotlarini-navlink17"
            >
              <div className="biometrik-malumotlarini-container24">
                <img
                  alt="image"
                  src="/rectangle%2030.svg"
                  className="biometrik-malumotlarini-image15"
                />
                <span className="biometrik-malumotlarini-text111">
                  биометрик маълумотларини
                </span>
              </div>
            </Link>
            <Link
              to="/tlov-shartlari"
              className="biometrik-malumotlarini-navlink18"
            >
              <div className="biometrik-malumotlarini-container25">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="biometrik-malumotlarini-image16"
                />
                <span className="biometrik-malumotlarini-text112">
                  Тўлов Шартлари
                </span>
              </div>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="biometrik-malumotlarini-navlink19"
            >
              <div className="biometrik-malumotlarini-container26">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="biometrik-malumotlarini-image17"
                />
                <span className="biometrik-malumotlarini-text113">
                  Техника хавфсизлиги кўрсатмалари
                </span>
              </div>
            </Link>
            <Link
              to="/foydali-avolalar-uz"
              className="biometrik-malumotlarini-navlink20"
            >
              <div className="biometrik-malumotlarini-container27">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="biometrik-malumotlarini-image18"
                />
                <span className="biometrik-malumotlarini-text114">
                  Фойдали ҳаволалар
                </span>
              </div>
            </Link>
          </div>
        </div>
        <div className="biometrik-malumotlarini-container28">
          <span className="biometrik-malumotlarini-text115">
            <span className="biometrik-malumotlarini-text116">
              Виза Ахборот Тизими (VIS)
            </span>
            <br className="biometrik-malumotlarini-text117"></br>
            <br></br>
            <span>
              VIS – Шенген давлатлари ҳудудида виза алмашинуви тизими VIS нинг
              асосий вазифалари: виза бериш жараёнларини соддалаштириш, ташқи
              чегараларда текширишни енгиллаштириш ва ҳавфсизликни кўчайтириш,
              шу жумладан, ариза топширучининг ҳам.
            </span>
            <br></br>
            <br></br>
            <span>
              Шенген давлатлари ҳудудида 2011 йил 11 октябрдан бошлаб виза
              тизими жорий қилинган. Шенген давлатлари ҳудудидаги виза
              марказлари VIS га қадам ва қадам қўшилди.
            </span>
            <br></br>
            <br></br>
            <span>
              Қозоғистон, Қирғизистон, Тожикистон, Ўзбекистон, Эрон,
              Туркманистон, Азорбайжон ва Арманистон VIS нинг марказий ахборот
              тизимига 14.11.2013 да қўшилган. Шу кундан эътиборан Шенген визаси
              аризачилари ўз бармоқ изларини топширишлари зарур.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <span className="biometrik-malumotlarini-text129">
              VIS бир қанча ўзгаришларни тақдим этди:
            </span>
            <br></br>
            <br></br>
            <span>
              •
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              Ариза топширувчилар биринчи ариза топшириш вақтида ўз биометрик
              маълумотларини тўплаш учун шахсан қатнашишлари шарт: ариза
              топширувчи 10 та бармоқ изини ва битта расмини беради;
            </span>
            <br></br>
            <br></br>
            <span>•  </span>
            <span>
              Бармоқ излари VIS маълумотлар базасида сақланиб қолинади, шунинг
              учун беш йил давомидаги сўнгги бармоқ изини топширишда шахсан
              иштироки шарт эмас;
            </span>
            <br></br>
            <br></br>
            <span>
              • Шенген давлатлари ҳудудига киришда чегара органлари VIS орқали
              саёҳатчининг шахсини икки мартадан текширишлари мумкин.
            </span>
            <br></br>
            <br className="biometrik-malumotlarini-text142"></br>
            <br className="biometrik-malumotlarini-text143"></br>
            <span className="biometrik-malumotlarini-text144">
              Бармоқ излари бўйича талабларга истисно равишда қўйидаги шахслар
              киради:
            </span>
            <br></br>
            <br></br>
            <span>
              •
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>12 ёшгача бўлган болалар;</span>
            <br></br>
            <br></br>
            <span>
              •
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              Психологик ёки жисмоний муаммоларга кўра ўз бармоқ изларини
              топшириш имкониятига егf бўлмаган шахслар;
            </span>
            <br></br>
            <br></br>
            <span>
              •
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              Давлат ёки ҳукумат бошлиқлари, миллий ҳукумат бошлиқлари турмуш
              ўртоқлари билан бирга; фақат ҳукумат бошлиқлари ёки халқаро
              ташкилотлар расмий таклифнома берган ҳолларда.
            </span>
            <br></br>
            <br></br>
            <span>
              •
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              Охирги 59 ой ичида бармоқ изларини топширган аризачилар.
            </span>
            <br></br>
            <br></br>
            <span>
              •
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              Аризачи виза аризасини топширишиш вақтида ўзи иштирок этиши шарт.
              Қачонки Германия элчихонаси томонидан ёки бошқа Шенген визасига
              қабул қилувчилар томонидан бармоқ излари тасдиқланган бўлса, ўз
              иштирокисиз виза аризасини топшириши мумкин бўлади.
            </span>
          </span>
        </div>
      </div>
      <div id="header" className="biometrik-malumotlarini-container29">
        <Link to="/" className="biometrik-malumotlarini-navlink21">
          <div className="biometrik-malumotlarini-container30">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="biometrik-malumotlarini-image19"
            />
            <p className="biometrik-malumotlarini-text165">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="biometrik-malumotlarini-container31">
          <div className="biometrik-malumotlarini-container32">
            <span className="biometrik-malumotlarini-text168">
              <span>Apply for Visa to</span>
              <span className="biometrik-malumotlarini-text170"> Germany</span>
            </span>
            <span className="biometrik-malumotlarini-text171">
              <span className="biometrik-malumotlarini-text172">
                in Uzbekistan
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="biometrik-malumotlarini-image20"
          />
          <span className="biometrik-malumotlarini-text203">|</span>
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header
        data-thq="thq-navbar"
        className="biometrik-malumotlarini-navbar-interactive"
      >
        <div
          data-thq="thq-navbar-nav"
          className="biometrik-malumotlarini-desktop-menu"
        >
          <div className="biometrik-malumotlarini-container33">
            <nav className="biometrik-malumotlarini-links1">
              <div
                data-thq="thq-dropdown"
                className="biometrik-malumotlarini-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="biometrik-malumotlarini-dropdown-toggle10"
                  >
                    <span className="biometrik-malumotlarini-text204">
                      Бош сахифа
                    </span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="biometrik-malumotlarini-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="biometrik-malumotlarini-dropdown-toggle11"
                >
                  <span className="biometrik-malumotlarini-text205">
                    Умумий
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="biometrik-malumotlarini-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="biometrik-malumotlarini-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle12"
                      >
                        <span className="biometrik-malumotlarini-text206 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle13"
                      >
                        <span className="biometrik-malumotlarini-text209 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle14"
                      >
                        <span className="biometrik-malumotlarini-text210 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle15"
                      >
                        <span className="biometrik-malumotlarini-text211 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle16"
                      >
                        <span className="biometrik-malumotlarini-text216 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle17"
                      >
                        <span className="biometrik-malumotlarini-text217 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle18"
                      >
                        <span className="biometrik-malumotlarini-text218 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle19"
                      >
                        <span className="biometrik-malumotlarini-text222 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="biometrik-malumotlarini-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="biometrik-malumotlarini-dropdown-toggle20"
                >
                  <span className="biometrik-malumotlarini-text223">
                    Виза олиш
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="biometrik-malumotlarini-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="biometrik-malumotlarini-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle21"
                      >
                        <span className="biometrik-malumotlarini-text224">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle22"
                      >
                        <span className="biometrik-malumotlarini-text225">
                          Миллий виза
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle23"
                      >
                        <span className="biometrik-malumotlarini-text226">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle24"
                      >
                        <span className="biometrik-malumotlarini-text227">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle25"
                      >
                        <span className="biometrik-malumotlarini-text230">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle26"
                      >
                        <span className="biometrik-malumotlarini-text234">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle27"
                      >
                        <span className="biometrik-malumotlarini-text235">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="biometrik-malumotlarini-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="biometrik-malumotlarini-dropdown-toggle28"
                >
                  <span className="biometrik-malumotlarini-text236">
                    Кушимча хизматлар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="biometrik-malumotlarini-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="biometrik-malumotlarini-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle29"
                      >
                        <span className="biometrik-malumotlarini-text237">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle30"
                      >
                        <span className="biometrik-malumotlarini-text238">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle31"
                      >
                        <span className="biometrik-malumotlarini-text242">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle32"
                      >
                        <span className="biometrik-malumotlarini-text245">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle33"
                      >
                        <span className="biometrik-malumotlarini-text246">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle34"
                      >
                        <span className="biometrik-malumotlarini-text250">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle35"
                      >
                        <span className="biometrik-malumotlarini-text251">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="biometrik-malumotlarini-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="biometrik-malumotlarini-dropdown-toggle38"
                  >
                    <span className="biometrik-malumotlarini-text254">FAQ</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="biometrik-malumotlarini-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="biometrik-malumotlarini-dropdown-toggle39"
                >
                  <span className="biometrik-malumotlarini-text255">
                    Алокалар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="biometrik-malumotlarini-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="biometrik-malumotlarini-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle40"
                      >
                        <span className="biometrik-malumotlarini-text256">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle41"
                      >
                        <span className="biometrik-malumotlarini-text257">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle42"
                      >
                        <span className="biometrik-malumotlarini-text258">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="biometrik-malumotlarini-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="biometrik-malumotlarini-dropdown-toggle43"
                      >
                        <span className="biometrik-malumotlarini-text259">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="biometrik-malumotlarini-container34">
          <input
            type="text"
            placeholder="Кидирмок"
            className="biometrik-malumotlarini-textinput1 input"
          />
          <div className="biometrik-malumotlarini-container35">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="biometrik-malumotlarini-icon18"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="biometrik-malumotlarini-container36">
            <span className="biometrik-malumotlarini-text262">A+</span>
            <span className="biometrik-malumotlarini-text263">A-</span>
          </div>
        </div>
        <div
          data-thq="thq-burger-menu"
          className="biometrik-malumotlarini-burger-menu"
        >
          <svg
            viewBox="0 0 1024 1024"
            className="biometrik-malumotlarini-icon20"
          >
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div
          data-thq="thq-mobile-menu"
          className="biometrik-malumotlarini-mobile-menu"
        >
          <div className="biometrik-malumotlarini-nav">
            <div className="biometrik-malumotlarini-container37">
              <div className="biometrik-malumotlarini-container38">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="biometrik-malumotlarini-textinput2 input"
                />
                <div className="biometrik-malumotlarini-container39">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="biometrik-malumotlarini-icon22"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="biometrik-malumotlarini-container40">
                  <span className="biometrik-malumotlarini-text264">A+</span>
                  <span className="biometrik-malumotlarini-text265">A-</span>
                </div>
              </div>
              <div
                data-thq="thq-close-menu"
                className="biometrik-malumotlarini-close-menu"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="biometrik-malumotlarini-icon24"
                >
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="biometrik-malumotlarini-links2">
            <div
              data-thq="thq-dropdown"
              className="biometrik-malumotlarini-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="biometrik-malumotlarini-dropdown-toggle44"
                >
                  <span className="biometrik-malumotlarini-text266">
                    Бош сахифа
                  </span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="biometrik-malumotlarini-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="biometrik-malumotlarini-dropdown-toggle45"
              >
                <span className="biometrik-malumotlarini-text267">Умумий</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="biometrik-malumotlarini-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="biometrik-malumotlarini-icon26"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="biometrik-malumotlarini-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle46"
                    >
                      <span className="biometrik-malumotlarini-text268">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle47"
                    >
                      <span className="biometrik-malumotlarini-text271">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle48"
                    >
                      <span className="biometrik-malumotlarini-text272">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle49"
                    >
                      <span className="biometrik-malumotlarini-text273">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle50"
                    >
                      <span className="biometrik-malumotlarini-text278">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle51"
                    >
                      <span className="biometrik-malumotlarini-text279">
                        Тулов Шартлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle52"
                    >
                      <span className="biometrik-malumotlarini-text280">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle53"
                    >
                      <span className="biometrik-malumotlarini-text283">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="biometrik-malumotlarini-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="biometrik-malumotlarini-dropdown-toggle54"
              >
                <span className="biometrik-malumotlarini-text284">
                  Виза олиш
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="biometrik-malumotlarini-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="biometrik-malumotlarini-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="biometrik-malumotlarini-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle55"
                    >
                      <span className="biometrik-malumotlarini-text285">
                        Шенген визаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle56"
                    >
                      <span className="biometrik-malumotlarini-text286">
                        Миллий виза
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle57"
                    >
                      <span className="biometrik-malumotlarini-text287">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle58"
                    >
                      <span className="biometrik-malumotlarini-text288">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle59"
                    >
                      <span className="biometrik-malumotlarini-text291">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle60"
                    >
                      <span className="biometrik-malumotlarini-text295">
                        Ариза шакллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle61"
                    >
                      <span className="biometrik-malumotlarini-text296">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="biometrik-malumotlarini-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="biometrik-malumotlarini-dropdown-toggle62"
              >
                <span className="biometrik-malumotlarini-text297">
                  Кушимча хизматлар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="biometrik-malumotlarini-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="biometrik-malumotlarini-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="biometrik-malumotlarini-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle63"
                    >
                      <span className="biometrik-malumotlarini-text298">
                        VIP - хизмати
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle64"
                    >
                      <span className="biometrik-malumotlarini-text299">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle65"
                    >
                      <span className="biometrik-malumotlarini-text300">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle66"
                    >
                      <span className="biometrik-malumotlarini-text301">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle67"
                    >
                      <span className="biometrik-malumotlarini-text302">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle68"
                    >
                      <span className="biometrik-malumotlarini-text305">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle69"
                    >
                      <span className="biometrik-malumotlarini-text308">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle70"
                    >
                      <span className="biometrik-malumotlarini-text309">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle71"
                    >
                      <span className="biometrik-malumotlarini-text310">
                        Prime Тиме
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="biometrik-malumotlarini-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="biometrik-malumotlarini-dropdown-toggle72"
                >
                  <span className="biometrik-malumotlarini-text311">FAQ</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="biometrik-malumotlarini-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="biometrik-malumotlarini-dropdown-toggle73"
              >
                <span className="biometrik-malumotlarini-text312">
                  Алокалар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="biometrik-malumotlarini-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="biometrik-malumotlarini-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="biometrik-malumotlarini-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle74"
                    >
                      <span className="biometrik-malumotlarini-text313">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle75"
                    >
                      <span className="biometrik-malumotlarini-text314">
                        Алока маркази
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle76"
                    >
                      <span className="biometrik-malumotlarini-text315">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="biometrik-malumotlarini-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="biometrik-malumotlarini-dropdown-toggle77"
                    >
                      <span className="biometrik-malumotlarini-text316">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="biometrik-malumotlarini-container41">
        <div className="biometrik-malumotlarini-container42">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="biometrik-malumotlarini-image22"
          />
          <span className="biometrik-malumotlarini-text319">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="biometrik-malumotlarini-container43">
          <div className="biometrik-malumotlarini-container44">
            <span className="biometrik-malumotlarini-text320">
              Ходимларни бошқариш
            </span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="biometrik-malumotlarini-image23"
            />
          </div>
          <div className="biometrik-malumotlarini-container45">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="biometrik-malumotlarini-navlink82"
            >
              <div className="biometrik-malumotlarini-container46">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="biometrik-malumotlarini-icon34"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="biometrik-malumotlarini-text321">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="biometrik-malumotlarini-navlink83"
            >
              <div className="biometrik-malumotlarini-container47">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="biometrik-malumotlarini-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="biometrik-malumotlarini-text322">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="biometrik-malumotlarini-navlink84"
            >
              <div className="biometrik-malumotlarini-container48">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="biometrik-malumotlarini-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="biometrik-malumotlarini-text326">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="biometrik-malumotlarini-navlink85"
            >
              <div className="biometrik-malumotlarini-container49">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="biometrik-malumotlarini-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="biometrik-malumotlarini-text330">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="biometrik-malumotlarini-navlink86"
            >
              <div className="biometrik-malumotlarini-container50">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="biometrik-malumotlarini-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="biometrik-malumotlarini-text334">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="biometrik-malumotlarini-container51">
        <div className="biometrik-malumotlarini-container52">
          <span className="biometrik-malumotlarini-text338">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="biometrik-malumotlarini-container53">
            <Link
              to="/visametric-gurukhi"
              className="biometrik-malumotlarini-navlink87"
            >
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="biometrik-malumotlarini-navlink88"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/w3c-logo2-200h.png"
          className="biometrik-malumotlarini-image24"
        />
        <a href="#main" className="biometrik-malumotlarini-link">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="biometrik-malumotlarini-image25"
          />
        </a>
      </div>
    </div>
  )
}

export default BiometrikMalumotlarini
