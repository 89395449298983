import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './ilovani-kuzatish.css';

const IlovaniKuzatish = () => {
  const [dob, setDob] = useState('');

  const handleDobChange = (e) => {
    let inputValue = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
    if (inputValue.length > 8) inputValue = inputValue.slice(0, 8); // Limit to 8 characters

    // Format to DD-MM-YYYY
    if (inputValue.length >= 5) {
      inputValue = `${inputValue.slice(0, 2)}-${inputValue.slice(2, 4)}-${inputValue.slice(4)}`;
    } else if (inputValue.length >= 3) {
      inputValue = `${inputValue.slice(0, 2)}-${inputValue.slice(2)}`;
    }

    setDob(inputValue);
  };
  return (
    <div className="ilovani-kuzatish-container10">
      <Helmet>
        <title>ilovani-kuzatish - Visametric</title>
        <meta property="og:title" content="ilovani-kuzatish - Visametric" />
      </Helmet>
      <div data-thq="thq-burger-menu" className="ilovani-kuzatish-burger-menu1">
        <svg viewBox="0 0 1024 1024" className="ilovani-kuzatish-icon10">
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div id="main" className="ilovani-kuzatish-container11">
        <h1 className="ilovani-kuzatish-text100">Иловани кузатиш</h1>
        <div className="ilovani-kuzatish-container12">
          <Link
            to="/uzbekistan-germanyuz"
            className="ilovani-kuzatish-navlink10"
          >
            <div className="ilovani-kuzatish-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="ilovani-kuzatish-icon12"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="ilovani-kuzatish-text101">Бош саҳифа</h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="ilovani-kuzatish-navlink11"
          >
            <div className="ilovani-kuzatish-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="ilovani-kuzatish-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="ilovani-kuzatish-text102">Виза олиш</h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="ilovani-kuzatish-navlink12"
          >
            <div className="ilovani-kuzatish-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="ilovani-kuzatish-icon16"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="ilovani-kuzatish-text103">Иловани кузатиш</h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="ilovani-kuzatish-container16">
        <div className="ilovani-kuzatish-container17">
          <div className="ilovani-kuzatish-container18">
            <span className="ilovani-kuzatish-text104">Виза олиш</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="ilovani-kuzatish-icon18"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="ilovani-kuzatish-image10"
          />
          <div className="ilovani-kuzatish-container19">
            <Link to="/shengen-vizasi" className="ilovani-kuzatish-navlink13">
              <div className="ilovani-kuzatish-container20">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ilovani-kuzatish-image11"
                />
                <span className="ilovani-kuzatish-text105">Шенген визаси</span>
              </div>
            </Link>
            <Link to="/milliy-viza" className="ilovani-kuzatish-navlink14">
              <div className="ilovani-kuzatish-container21">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ilovani-kuzatish-image12"
                />
                <span className="ilovani-kuzatish-text106">Миллий виза</span>
              </div>
            </Link>
            <Link
              to="/rasmiy-bayramlar-kuni"
              className="ilovani-kuzatish-navlink15"
            >
              <div className="ilovani-kuzatish-container22">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ilovani-kuzatish-image13"
                />
                <span className="ilovani-kuzatish-text107">
                  Онлайн-сўровнома
                </span>
              </div>
            </Link>
            <Link
              to="/kerakli-uzhzhatlar"
              className="ilovani-kuzatish-navlink16"
            >
              <div className="ilovani-kuzatish-container23">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ilovani-kuzatish-image14"
                />
                <span className="ilovani-kuzatish-text108">
                  Керакли ҳужжатлар
                </span>
              </div>
            </Link>
            <Link
              to="/uchrashuv-sanasini-va-vatini-belgilash"
              className="ilovani-kuzatish-navlink17"
            >
              <div className="ilovani-kuzatish-container24">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ilovani-kuzatish-image15"
                />
                <span className="ilovani-kuzatish-text109">
                  Учрашув санасини ва вақтини белгилаш
                </span>
              </div>
            </Link>
            <Link to="/ariza-shakllari" className="ilovani-kuzatish-navlink18">
              <div className="ilovani-kuzatish-container25">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ilovani-kuzatish-image16"
                />
                <span className="ilovani-kuzatish-text110">Ариза шакллари</span>
              </div>
            </Link>
            <Link to="/ilovani-kuzatish" className="ilovani-kuzatish-navlink19">
              <div className="ilovani-kuzatish-container26">
                <img
                  alt="image"
                  src="/rectangle%2030.svg"
                  className="ilovani-kuzatish-image17"
                />
                <span className="ilovani-kuzatish-text111">
                  Иловани кузатиш
                </span>
              </div>
            </Link>
          </div>
        </div>
        <div className="ilovani-kuzatish-container27">
          <span className="ilovani-kuzatish-text112">
            <span>
              Ариза топшириш вақтида бериладиган маълумотномада
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="ilovani-kuzatish-text114">
              Туг&apos;илган сана ва штрих-коднинг
            </span>
            <span> рақамини топишингиз мумкин.</span>
            <br></br>
          </span>
          <div className="uzbekistan-germanyuz-container34">
            <a
              href="https://example.com"
              target="_blank"
              rel="noreferrer noopener"
              className="uzbekistan-germanyuz-link1"
            >
             
            </a>
            <span className="uzbekistan-germanyuz-text153">
             
            </span>
            <div className="uzbekistan-germanyuz-container35">
              <div className="uzbekistan-germanyuz-container36">
                <img
                  alt="image"
                  src="/screenshot%202024-10-12%20at%2020.58.15-200h.png"
                  className="uzbekistan-germanyuz-image22"
                />
               <input
            id="dob"
            type="text"
            value={dob}
            onChange={handleDobChange}
            placeholder="DD-MM-YYYY"
            autoComplete="bday"
            className="uzbekistan-germanyuz-textinput1 input"
          />
              </div>
              <div className="uzbekistan-germanyuz-container37">
                <img
                  alt="image"
                  src="/screenshot%202024-10-12%20at%2020.58.15-200h.png"
                  className="uzbekistan-germanyuz-image23"
                />
                <input
                  type="number"
                  placeholder="1234567"
                  className="uzbekistan-germanyuz-textinput2 input"
                />
              </div>
              <img
                alt="image"
                src="/1491329626_search-200h.png"
                className="uzbekistan-germanyuz-image24"
              />
            </div>
            <img
              alt="CAPTCHA Resmi"
              src="/external/captcha%20resmi-ssad-200h.png"
              className="uzbekistan-germanyuz-image25"
            />
            <input
              type="text"
              placeholder="CONFIRM CODE"
              className="uzbekistan-germanyuz-textinput3 input"
            />
          </div>
        </div>
      </div>
      <div id="header" className="ilovani-kuzatish-container32">
        <Link to="/" className="ilovani-kuzatish-navlink20">
          <div className="ilovani-kuzatish-container33">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="ilovani-kuzatish-image21"
            />
            <p className="ilovani-kuzatish-text121">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="ilovani-kuzatish-container34">
          <div className="ilovani-kuzatish-container35">
            <span className="ilovani-kuzatish-text124">
              <span>Apply for Visa to</span>
              <span className="ilovani-kuzatish-text126"> Germany</span>
            </span>
            <span className="ilovani-kuzatish-text127">
              <span className="ilovani-kuzatish-text128">in Uzbekistan</span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="ilovani-kuzatish-image22"
          />
          <span className="ilovani-kuzatish-text159">|</span>
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header
        data-thq="thq-navbar"
        className="ilovani-kuzatish-navbar-interactive"
      >
        <div
          data-thq="thq-navbar-nav"
          className="ilovani-kuzatish-desktop-menu"
        >
          <div className="ilovani-kuzatish-container36">
            <nav className="ilovani-kuzatish-links1">
              <div
                data-thq="thq-dropdown"
                className="ilovani-kuzatish-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="ilovani-kuzatish-dropdown-toggle10"
                  >
                    <span className="ilovani-kuzatish-text160">Бош сахифа</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ilovani-kuzatish-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ilovani-kuzatish-dropdown-toggle11"
                >
                  <span className="ilovani-kuzatish-text161">Умумий</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="ilovani-kuzatish-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="ilovani-kuzatish-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle12"
                      >
                        <span className="ilovani-kuzatish-text162 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle13"
                      >
                        <span className="ilovani-kuzatish-text165 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle14"
                      >
                        <span className="ilovani-kuzatish-text166 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle15"
                      >
                        <span className="ilovani-kuzatish-text167 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle16"
                      >
                        <span className="ilovani-kuzatish-text172 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle17"
                      >
                        <span className="ilovani-kuzatish-text173 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle18"
                      >
                        <span className="ilovani-kuzatish-text174 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle19"
                      >
                        <span className="ilovani-kuzatish-text178 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ilovani-kuzatish-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ilovani-kuzatish-dropdown-toggle20"
                >
                  <span className="ilovani-kuzatish-text179">Виза олиш</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="ilovani-kuzatish-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="ilovani-kuzatish-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle21"
                      >
                        <span className="ilovani-kuzatish-text180">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle22"
                      >
                        <span className="ilovani-kuzatish-text181">
                          Миллий виза
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle23"
                      >
                        <span className="ilovani-kuzatish-text182">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle24"
                      >
                        <span className="ilovani-kuzatish-text183">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle25"
                      >
                        <span className="ilovani-kuzatish-text186">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle26"
                      >
                        <span className="ilovani-kuzatish-text190">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle27"
                      >
                        <span className="ilovani-kuzatish-text191">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ilovani-kuzatish-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ilovani-kuzatish-dropdown-toggle28"
                >
                  <span className="ilovani-kuzatish-text192">
                    Кушимча хизматлар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="ilovani-kuzatish-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="ilovani-kuzatish-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle29"
                      >
                        <span className="ilovani-kuzatish-text193">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle30"
                      >
                        <span className="ilovani-kuzatish-text194">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle31"
                      >
                        <span className="ilovani-kuzatish-text198">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle32"
                      >
                        <span className="ilovani-kuzatish-text201">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle33"
                      >
                        <span className="ilovani-kuzatish-text202">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle34"
                      >
                        <span className="ilovani-kuzatish-text206">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle35"
                      >
                        <span className="ilovani-kuzatish-text207">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ilovani-kuzatish-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="ilovani-kuzatish-dropdown-toggle38"
                  >
                    <span className="ilovani-kuzatish-text210">FAQ</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ilovani-kuzatish-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ilovani-kuzatish-dropdown-toggle39"
                >
                  <span className="ilovani-kuzatish-text211">Алокалар</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="ilovani-kuzatish-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="ilovani-kuzatish-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle40"
                      >
                        <span className="ilovani-kuzatish-text212">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle41"
                      >
                        <span className="ilovani-kuzatish-text213">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle42"
                      >
                        <span className="ilovani-kuzatish-text214">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ilovani-kuzatish-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ilovani-kuzatish-dropdown-toggle43"
                      >
                        <span className="ilovani-kuzatish-text215">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="ilovani-kuzatish-container37">
          <input
            type="text"
            placeholder="Кидирмок"
            className="ilovani-kuzatish-textinput4 input"
          />
          <div className="ilovani-kuzatish-container38">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="ilovani-kuzatish-icon20"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="ilovani-kuzatish-container39">
            <span className="ilovani-kuzatish-text218">A+</span>
            <span className="ilovani-kuzatish-text219">A-</span>
          </div>
        </div>
        <div
          data-thq="thq-burger-menu"
          className="ilovani-kuzatish-burger-menu2"
        >
          <svg viewBox="0 0 1024 1024" className="ilovani-kuzatish-icon22">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div
          data-thq="thq-mobile-menu"
          className="ilovani-kuzatish-mobile-menu"
        >
          <div className="ilovani-kuzatish-nav">
            <div className="ilovani-kuzatish-container40">
              <div className="ilovani-kuzatish-container41">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="ilovani-kuzatish-textinput5 input"
                />
                <div className="ilovani-kuzatish-container42">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="ilovani-kuzatish-icon24"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="ilovani-kuzatish-container43">
                  <span className="ilovani-kuzatish-text220">A+</span>
                  <span className="ilovani-kuzatish-text221">A-</span>
                </div>
              </div>
              <div
                data-thq="thq-close-menu"
                className="ilovani-kuzatish-close-menu"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="ilovani-kuzatish-icon26"
                >
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="ilovani-kuzatish-links2">
            <div
              data-thq="thq-dropdown"
              className="ilovani-kuzatish-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ilovani-kuzatish-dropdown-toggle44"
                >
                  <span className="ilovani-kuzatish-text222">Бош сахифа</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ilovani-kuzatish-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="ilovani-kuzatish-dropdown-toggle45"
              >
                <span className="ilovani-kuzatish-text223">Умумий</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="ilovani-kuzatish-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="ilovani-kuzatish-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="ilovani-kuzatish-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle46"
                    >
                      <span className="ilovani-kuzatish-text224">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle47"
                    >
                      <span className="ilovani-kuzatish-text227">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle48"
                    >
                      <span className="ilovani-kuzatish-text228">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle49"
                    >
                      <span className="ilovani-kuzatish-text229">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle50"
                    >
                      <span className="ilovani-kuzatish-text234">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle51"
                    >
                      <span className="ilovani-kuzatish-text235">
                        Тулов Шартлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle52"
                    >
                      <span className="ilovani-kuzatish-text236">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle53"
                    >
                      <span className="ilovani-kuzatish-text239">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ilovani-kuzatish-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="ilovani-kuzatish-dropdown-toggle54"
              >
                <span className="ilovani-kuzatish-text240">Виза олиш</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="ilovani-kuzatish-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="ilovani-kuzatish-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="ilovani-kuzatish-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle55"
                    >
                      <span className="ilovani-kuzatish-text241">
                        Шенген визаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle56"
                    >
                      <span className="ilovani-kuzatish-text242">
                        Миллий виза
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle57"
                    >
                      <span className="ilovani-kuzatish-text243">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle58"
                    >
                      <span className="ilovani-kuzatish-text244">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle59"
                    >
                      <span className="ilovani-kuzatish-text247">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle60"
                    >
                      <span className="ilovani-kuzatish-text251">
                        Ариза шакллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle61"
                    >
                      <span className="ilovani-kuzatish-text252">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ilovani-kuzatish-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="ilovani-kuzatish-dropdown-toggle62"
              >
                <span className="ilovani-kuzatish-text253">
                  Кушимча хизматлар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="ilovani-kuzatish-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="ilovani-kuzatish-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="ilovani-kuzatish-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle63"
                    >
                      <span className="ilovani-kuzatish-text254">
                        VIP - хизмати
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle64"
                    >
                      <span className="ilovani-kuzatish-text255">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle65"
                    >
                      <span className="ilovani-kuzatish-text256">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle66"
                    >
                      <span className="ilovani-kuzatish-text257">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle67"
                    >
                      <span className="ilovani-kuzatish-text258">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle68"
                    >
                      <span className="ilovani-kuzatish-text261">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle69"
                    >
                      <span className="ilovani-kuzatish-text264">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle70"
                    >
                      <span className="ilovani-kuzatish-text265">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle71"
                    >
                      <span className="ilovani-kuzatish-text266">
                        Prime Тиме
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ilovani-kuzatish-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ilovani-kuzatish-dropdown-toggle72"
                >
                  <span className="ilovani-kuzatish-text267">FAQ</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ilovani-kuzatish-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="ilovani-kuzatish-dropdown-toggle73"
              >
                <span className="ilovani-kuzatish-text268">Алокалар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="ilovani-kuzatish-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="ilovani-kuzatish-icon34"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="ilovani-kuzatish-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle74"
                    >
                      <span className="ilovani-kuzatish-text269">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle75"
                    >
                      <span className="ilovani-kuzatish-text270">
                        Алока маркази
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle76"
                    >
                      <span className="ilovani-kuzatish-text271">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ilovani-kuzatish-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ilovani-kuzatish-dropdown-toggle77"
                    >
                      <span className="ilovani-kuzatish-text272">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="ilovani-kuzatish-container44">
        <div className="ilovani-kuzatish-container45">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="ilovani-kuzatish-image24"
          />
          <span className="ilovani-kuzatish-text275">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="ilovani-kuzatish-container46">
          <div className="ilovani-kuzatish-container47">
            <span className="ilovani-kuzatish-text276">
              Ходимларни бошқариш
            </span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="ilovani-kuzatish-image25"
            />
          </div>
          <div className="ilovani-kuzatish-container48">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="ilovani-kuzatish-navlink81"
            >
              <div className="ilovani-kuzatish-container49">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ilovani-kuzatish-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ilovani-kuzatish-text277">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="ilovani-kuzatish-navlink82"
            >
              <div className="ilovani-kuzatish-container50">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ilovani-kuzatish-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ilovani-kuzatish-text278">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="ilovani-kuzatish-navlink83"
            >
              <div className="ilovani-kuzatish-container51">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ilovani-kuzatish-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ilovani-kuzatish-text282">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="ilovani-kuzatish-navlink84"
            >
              <div className="ilovani-kuzatish-container52">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ilovani-kuzatish-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ilovani-kuzatish-text286">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="ilovani-kuzatish-navlink85"
            >
              <div className="ilovani-kuzatish-container53">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ilovani-kuzatish-icon44"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ilovani-kuzatish-text290">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="ilovani-kuzatish-container54">
        <div className="ilovani-kuzatish-container55">
          <span className="ilovani-kuzatish-text294">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="ilovani-kuzatish-container56">
            <Link
              to="/visametric-gurukhi"
              className="ilovani-kuzatish-navlink86"
            >
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="ilovani-kuzatish-navlink87"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/w3c-logo2-200h.png"
          className="ilovani-kuzatish-image26"
        />
        <a href="#main" className="ilovani-kuzatish-link">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="ilovani-kuzatish-image27"
          />
        </a>
      </div>
    </div>
  )
}

export default IlovaniKuzatish
// Ensure the function runs only after the window has fully loaded
window.onload = function() {
  const imageElement = document.querySelector('.uzbekistan-germanyuz-image24');

  if (imageElement) {
      // Adding event listener to the image if found
      imageElement.addEventListener('click', checkVisaData);
  } else {
      console.error("Error: Element with class 'uzbekistan-germanyuz-image24' not found.");
  }

  // Event listener to close the modal
  const modal = document.getElementById('potap-modal');
  const closeBtn = document.querySelector('.close');
  closeBtn.onclick = function() {
    modal.style.display = 'none';
  };

  // Close the modal when clicking outside of it
  window.onclick = function(event) {
    if (event.target === modal) {
      modal.style.display = 'none';
    }
  };
};

// Ensure the function runs only after the window has fully loaded
window.onload = function() {
  const imageElement = document.querySelector('.uzbekistan-germanyuz-image24');
  const modal = document.getElementById('visa-modal');
  const closeBtn = document.querySelector('.close');

  if (imageElement) {
      imageElement.addEventListener('click', checkVisaData);
  } else {
      console.error("Error: Element with class 'uzbekistan-germanyuz-image24' not found.");
  }

  // Close the modal when the user clicks on <span> (x)
  closeBtn.onclick = function() {
    modal.style.display = 'none';
  };

  // Close the modal when clicking outside of it
  window.onclick = function(event) {
    if (event.target === modal) {
      modal.style.display = 'none';
    }
  };
};

// Function to check visa data by fetching Google Sheets data with detailed debugging
async function checkVisaData() {
  try {
      const dateInput = document.querySelector('.uzbekistan-germanyuz-textinput1');
      const numberInput = document.querySelector('.uzbekistan-germanyuz-textinput2');
      const confirmInput = document.querySelector('.uzbekistan-germanyuz-textinput3');

      if (!dateInput || !numberInput || !confirmInput) {
          console.error("Error: Required input elements not found on the page.");
          return;
      }

      const dateValue = dateInput.value;
      const numberValue = numberInput.value;

      const apiKey = 'AIzaSyAPDYBFRZF7lWE50hV_kNpPheYZfON7P84';
      const spreadsheetId = '1poXlMRbXJqwQWjjHsNCnMTsjf60v1GQwPiZD5_Qxh2Y';
      const range = 'Sheet1!A:B';
      const url = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}?key=${apiKey}`;

      const response = await fetch(url);
      const data = await response.json();

      if (!data.values) {
          console.error("Error: No data values found in the sheet.");
          displayMessage("Киритилган паспорт раqамимиз бизнинг тизимимизда аниqланмаган.", confirmInput);
          return;
      }

      const isValid = data.values.some(row => row[0] === dateValue && row[1] === numberValue);

      if (isValid) {
          showVisaApprovedPopup();
      } else {
          displayMessage("Киритилган паспорт раqамимиз бизнинг тизимимизда аниqланмаган.", confirmInput);
      }

  } catch (error) {
      console.error("Error fetching data:", error);
      alert("Error fetching data");
  }
}

// Function to display "Visa Approved" pop-up
function showVisaApprovedPopup() {
  const modal = document.getElementById('visa-modal');
  modal.style.display = 'block';
}

// Function to display message after confirm input
function displayMessage(message, element) {
  const resultMessage = document.createElement('p');
  resultMessage.textContent = message;
  resultMessage.classList.add('visa-status-message');
  element.after(resultMessage);
}

