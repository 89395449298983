import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './ariza-shakllari.css'

const ArizaShakllari = (props) => {
  return (
    <div className="ariza-shakllari-container10">
      <Helmet>
        <title>ariza-shakllari - Visametric</title>
        <meta property="og:title" content="ariza-shakllari - Visametric" />
      </Helmet>
      <div data-thq="thq-burger-menu" className="ariza-shakllari-burger-menu1">
        <svg viewBox="0 0 1024 1024" className="ariza-shakllari-icon10">
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div id="main" className="ariza-shakllari-container11">
        <h1 className="ariza-shakllari-text100">Ариза шакллари</h1>
        <div className="ariza-shakllari-container12">
          <Link
            to="/uzbekistan-germanyuz"
            className="ariza-shakllari-navlink10"
          >
            <div className="ariza-shakllari-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="ariza-shakllari-icon12"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="ariza-shakllari-text101">Бош саҳифа</h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="ariza-shakllari-navlink11"
          >
            <div className="ariza-shakllari-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="ariza-shakllari-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="ariza-shakllari-text102">Виза олиш</h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="ariza-shakllari-navlink12"
          >
            <div className="ariza-shakllari-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="ariza-shakllari-icon16"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="ariza-shakllari-text103">Ариза шакллари</h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="ariza-shakllari-container16">
        <div className="ariza-shakllari-container17">
          <div className="ariza-shakllari-container18">
            <span className="ariza-shakllari-text104">Виза олиш</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="ariza-shakllari-icon18"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="ariza-shakllari-image10"
          />
          <div className="ariza-shakllari-container19">
            <Link to="/shengen-vizasi" className="ariza-shakllari-navlink13">
              <div className="ariza-shakllari-container20">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-shakllari-image11"
                />
                <span className="ariza-shakllari-text105">Шенген визаси</span>
              </div>
            </Link>
            <Link to="/milliy-viza" className="ariza-shakllari-navlink14">
              <div className="ariza-shakllari-container21">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-shakllari-image12"
                />
                <span className="ariza-shakllari-text106">Миллий виза</span>
              </div>
            </Link>
            <Link
              to="/rasmiy-bayramlar-kuni"
              className="ariza-shakllari-navlink15"
            >
              <div className="ariza-shakllari-container22">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-shakllari-image13"
                />
                <span className="ariza-shakllari-text107">
                  Онлайн-сўровнома
                </span>
              </div>
            </Link>
            <Link
              to="/kerakli-uzhzhatlar"
              className="ariza-shakllari-navlink16"
            >
              <div className="ariza-shakllari-container23">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-shakllari-image14"
                />
                <span className="ariza-shakllari-text108">
                  Керакли ҳужжатлар
                </span>
              </div>
            </Link>
            <Link
              to="/uchrashuv-sanasini-va-vatini-belgilash"
              className="ariza-shakllari-navlink17"
            >
              <div className="ariza-shakllari-container24">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-shakllari-image15"
                />
                <span className="ariza-shakllari-text109">
                  Учрашув санасини ва вақтини белгилаш
                </span>
              </div>
            </Link>
            <Link to="/ariza-shakllari" className="ariza-shakllari-navlink18">
              <div className="ariza-shakllari-container25">
                <img
                  alt="image"
                  src="/rectangle%2030.svg"
                  className="ariza-shakllari-image16"
                />
                <span className="ariza-shakllari-text110">Ариза шакллари</span>
              </div>
            </Link>
            <Link to="/ilovani-kuzatish" className="ariza-shakllari-navlink19">
              <div className="ariza-shakllari-container26">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-shakllari-image17"
                />
                <span className="ariza-shakllari-text111">Иловани кузатиш</span>
              </div>
            </Link>
          </div>
        </div>
        <div className="ariza-shakllari-container27">
          <div className="ariza-shakllari-container28">
            <div className="ariza-shakllari-container29">
              <div className="ariza-shakllari-container30">
                <img
                  alt="image"
                  src="/vector-2.svg"
                  className="ariza-shakllari-image18"
                />
              </div>
              <div className="ariza-shakllari-container31">
                <span className="ariza-shakllari-text112">
                  Шенген визасини олиш учун анкета бланки (немисча — инглизча)
                </span>
              </div>
            </div>
            <div className="ariza-shakllari-container32">
              <div className="ariza-shakllari-container33">
                <img
                  alt="image"
                  src="/vector-2.svg"
                  className="ariza-shakllari-image19"
                />
              </div>
              <div className="ariza-shakllari-container34">
                <span className="ariza-shakllari-text113">
                  Шенген визасини олиш учун анкета бланки (немисча - ўзбекча)
                </span>
              </div>
            </div>
            <div className="ariza-shakllari-container35">
              <div className="ariza-shakllari-container36">
                <img
                  alt="image"
                  src="/vector-2.svg"
                  className="ariza-shakllari-image20"
                />
              </div>
              <div className="ariza-shakllari-container37">
                <span className="ariza-shakllari-text114">
                  Шенген визасини олиш учун анкета бланки (немисча — русча)
                </span>
              </div>
            </div>
            <div className="ariza-shakllari-container38">
              <div className="ariza-shakllari-container39">
                <img
                  alt="image"
                  src="/vector-2.svg"
                  className="ariza-shakllari-image21"
                />
              </div>
              <span className="ariza-shakllari-text115">
                Миллий виза учун ариза
              </span>
            </div>
          </div>
          <div className="ariza-shakllari-container40">
            <button type="button" className="ariza-shakllari-button button">
              <span className="ariza-shakllari-text116">
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>Онлайн сўровномани тўлдириш (Videx)</span>
              </span>
            </button>
            <a
              href="https://videx.diplo.de/videx/visum-erfassung/videx-langfristiger-aufenthalt"
              target="_blank"
              rel="noreferrer noopener"
              className="ariza-shakllari-link1 button"
            >
              <span className="ariza-shakllari-text119">
                <span>Онлайн сўровнома (Videx)</span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div id="header" className="ariza-shakllari-container41">
        <Link to="/" className="ariza-shakllari-navlink20">
          <div className="ariza-shakllari-container42">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="ariza-shakllari-image22"
            />
            <p className="ariza-shakllari-text122">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="ariza-shakllari-container43">
          <div className="ariza-shakllari-container44">
            <span className="ariza-shakllari-text125">
              <span>Apply for Visa to</span>
              <span className="ariza-shakllari-text127"> Germany</span>
            </span>
            <span className="ariza-shakllari-text128">
              <span className="ariza-shakllari-text129">in Uzbekistan</span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="ariza-shakllari-image23"
          />
          <span className="ariza-shakllari-text160">|</span>
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header
        data-thq="thq-navbar"
        className="ariza-shakllari-navbar-interactive"
      >
        <div data-thq="thq-navbar-nav" className="ariza-shakllari-desktop-menu">
          <div className="ariza-shakllari-container45">
            <nav className="ariza-shakllari-links1">
              <div
                data-thq="thq-dropdown"
                className="ariza-shakllari-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="ariza-shakllari-dropdown-toggle10"
                  >
                    <span className="ariza-shakllari-text161">Бош сахифа</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ariza-shakllari-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-shakllari-dropdown-toggle11"
                >
                  <span className="ariza-shakllari-text162">Умумий</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="ariza-shakllari-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="ariza-shakllari-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle12"
                      >
                        <span className="ariza-shakllari-text163 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle13"
                      >
                        <span className="ariza-shakllari-text166 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle14"
                      >
                        <span className="ariza-shakllari-text167 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle15"
                      >
                        <span className="ariza-shakllari-text168 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle16"
                      >
                        <span className="ariza-shakllari-text173 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle17"
                      >
                        <span className="ariza-shakllari-text174 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle18"
                      >
                        <span className="ariza-shakllari-text175 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle19"
                      >
                        <span className="ariza-shakllari-text179 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ariza-shakllari-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-shakllari-dropdown-toggle20"
                >
                  <span className="ariza-shakllari-text180">Виза олиш</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="ariza-shakllari-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="ariza-shakllari-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle21"
                      >
                        <span className="ariza-shakllari-text181">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle22"
                      >
                        <span className="ariza-shakllari-text182">
                          Миллий виза
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle23"
                      >
                        <span className="ariza-shakllari-text183">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle24"
                      >
                        <span className="ariza-shakllari-text184">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle25"
                      >
                        <span className="ariza-shakllari-text187">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle26"
                      >
                        <span className="ariza-shakllari-text191">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle27"
                      >
                        <span className="ariza-shakllari-text192">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ariza-shakllari-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-shakllari-dropdown-toggle28"
                >
                  <span className="ariza-shakllari-text193">
                    Кушимча хизматлар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="ariza-shakllari-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="ariza-shakllari-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle29"
                      >
                        <span className="ariza-shakllari-text194">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle30"
                      >
                        <span className="ariza-shakllari-text195">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle31"
                      >
                        <span className="ariza-shakllari-text199">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle32"
                      >
                        <span className="ariza-shakllari-text202">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle33"
                      >
                        <span className="ariza-shakllari-text203">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle34"
                      >
                        <span className="ariza-shakllari-text207">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle35"
                      >
                        <span className="ariza-shakllari-text208">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ariza-shakllari-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="ariza-shakllari-dropdown-toggle38"
                  >
                    <span className="ariza-shakllari-text211">FAQ</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ariza-shakllari-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-shakllari-dropdown-toggle39"
                >
                  <span className="ariza-shakllari-text212">Алокалар</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="ariza-shakllari-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="ariza-shakllari-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle40"
                      >
                        <span className="ariza-shakllari-text213">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle41"
                      >
                        <span className="ariza-shakllari-text214">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle42"
                      >
                        <span className="ariza-shakllari-text215">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-shakllari-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-shakllari-dropdown-toggle43"
                      >
                        <span className="ariza-shakllari-text216">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="ariza-shakllari-container46">
          <input
            type="text"
            placeholder="Кидирмок"
            className="ariza-shakllari-textinput1 input"
          />
          <div className="ariza-shakllari-container47">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="ariza-shakllari-icon20"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="ariza-shakllari-container48">
            <span className="ariza-shakllari-text219">A+</span>
            <span className="ariza-shakllari-text220">A-</span>
          </div>
        </div>
        <div
          data-thq="thq-burger-menu"
          className="ariza-shakllari-burger-menu2"
        >
          <svg viewBox="0 0 1024 1024" className="ariza-shakllari-icon22">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="ariza-shakllari-mobile-menu">
          <div className="ariza-shakllari-nav">
            <div className="ariza-shakllari-container49">
              <div className="ariza-shakllari-container50">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="ariza-shakllari-textinput2 input"
                />
                <div className="ariza-shakllari-container51">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="ariza-shakllari-icon24"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="ariza-shakllari-container52">
                  <span className="ariza-shakllari-text221">A+</span>
                  <span className="ariza-shakllari-text222">A-</span>
                </div>
              </div>
              <div
                data-thq="thq-close-menu"
                className="ariza-shakllari-close-menu"
              >
                <svg viewBox="0 0 1024 1024" className="ariza-shakllari-icon26">
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="ariza-shakllari-links2">
            <div
              data-thq="thq-dropdown"
              className="ariza-shakllari-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-shakllari-dropdown-toggle44"
                >
                  <span className="ariza-shakllari-text223">Бош сахифа</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ariza-shakllari-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="ariza-shakllari-dropdown-toggle45"
              >
                <span className="ariza-shakllari-text224">Умумий</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="ariza-shakllari-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="ariza-shakllari-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="ariza-shakllari-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle46"
                    >
                      <span className="ariza-shakllari-text225">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle47"
                    >
                      <span className="ariza-shakllari-text228">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle48"
                    >
                      <span className="ariza-shakllari-text229">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle49"
                    >
                      <span className="ariza-shakllari-text230">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle50"
                    >
                      <span className="ariza-shakllari-text235">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle51"
                    >
                      <span className="ariza-shakllari-text236">
                        Тулов Шартлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle52"
                    >
                      <span className="ariza-shakllari-text237">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle53"
                    >
                      <span className="ariza-shakllari-text240">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ariza-shakllari-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="ariza-shakllari-dropdown-toggle54"
              >
                <span className="ariza-shakllari-text241">Виза олиш</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="ariza-shakllari-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="ariza-shakllari-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="ariza-shakllari-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle55"
                    >
                      <span className="ariza-shakllari-text242">
                        Шенген визаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle56"
                    >
                      <span className="ariza-shakllari-text243">
                        Миллий виза
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle57"
                    >
                      <span className="ariza-shakllari-text244">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle58"
                    >
                      <span className="ariza-shakllari-text245">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle59"
                    >
                      <span className="ariza-shakllari-text248">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle60"
                    >
                      <span className="ariza-shakllari-text252">
                        Ариза шакллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle61"
                    >
                      <span className="ariza-shakllari-text253">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ariza-shakllari-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="ariza-shakllari-dropdown-toggle62"
              >
                <span className="ariza-shakllari-text254">
                  Кушимча хизматлар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="ariza-shakllari-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="ariza-shakllari-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="ariza-shakllari-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle63"
                    >
                      <span className="ariza-shakllari-text255">
                        VIP - хизмати
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle64"
                    >
                      <span className="ariza-shakllari-text256">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle65"
                    >
                      <span className="ariza-shakllari-text257">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle66"
                    >
                      <span className="ariza-shakllari-text258">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle67"
                    >
                      <span className="ariza-shakllari-text259">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle68"
                    >
                      <span className="ariza-shakllari-text262">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle69"
                    >
                      <span className="ariza-shakllari-text265">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle70"
                    >
                      <span className="ariza-shakllari-text266">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle71"
                    >
                      <span className="ariza-shakllari-text267">
                        Prime Тиме
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ariza-shakllari-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-shakllari-dropdown-toggle72"
                >
                  <span className="ariza-shakllari-text268">FAQ</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ariza-shakllari-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="ariza-shakllari-dropdown-toggle73"
              >
                <span className="ariza-shakllari-text269">Алокалар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="ariza-shakllari-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="ariza-shakllari-icon34"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="ariza-shakllari-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle74"
                    >
                      <span className="ariza-shakllari-text270">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle75"
                    >
                      <span className="ariza-shakllari-text271">
                        Алока маркази
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle76"
                    >
                      <span className="ariza-shakllari-text272">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-shakllari-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-shakllari-dropdown-toggle77"
                    >
                      <span className="ariza-shakllari-text273">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="ariza-shakllari-container53">
        <div className="ariza-shakllari-container54">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="ariza-shakllari-image25"
          />
          <span className="ariza-shakllari-text276">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="ariza-shakllari-container55">
          <div className="ariza-shakllari-container56">
            <span className="ariza-shakllari-text277">Ходимларни бошқариш</span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="ariza-shakllari-image26"
            />
          </div>
          <div className="ariza-shakllari-container57">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="ariza-shakllari-navlink81"
            >
              <div className="ariza-shakllari-container58">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ariza-shakllari-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ariza-shakllari-text278">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="ariza-shakllari-navlink82"
            >
              <div className="ariza-shakllari-container59">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ariza-shakllari-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ariza-shakllari-text279">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="ariza-shakllari-navlink83"
            >
              <div className="ariza-shakllari-container60">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ariza-shakllari-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ariza-shakllari-text283">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="ariza-shakllari-navlink84"
            >
              <div className="ariza-shakllari-container61">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ariza-shakllari-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ariza-shakllari-text287">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="ariza-shakllari-navlink85"
            >
              <div className="ariza-shakllari-container62">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ariza-shakllari-icon44"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ariza-shakllari-text291">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="ariza-shakllari-container63">
        <div className="ariza-shakllari-container64">
          <span className="ariza-shakllari-text295">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="ariza-shakllari-container65">
            <Link
              to="/visametric-gurukhi"
              className="ariza-shakllari-navlink86"
            >
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="ariza-shakllari-navlink87"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/w3c-logo2-200h.png"
          className="ariza-shakllari-image27"
        />
        <a href="#main" className="ariza-shakllari-link2">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="ariza-shakllari-image28"
          />
        </a>
      </div>
    </div>
  )
}

export default ArizaShakllari
