import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './submission-collection.css'

const SubmissionCollection = (props) => {
  return (
    <div className="submission-collection-container10">
      <Helmet>
        <title>submission-collection - Visametric</title>
        <meta
          property="og:title"
          content="submission-collection - Visametric"
        />
    </Helmet>
      <div
        data-thq="thq-burger-menu"
        className="submission-collection-burger-menu1"
      >
        <svg viewBox="0 0 1024 1024" className="submission-collection-icon10">
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div id="main" className="submission-collection-container11">
        <h1 className="submission-collection-text100">
          <span>Ҳужжатларни топшириш/</span>
          <span>Ҳужжатларни олиш</span>
        </h1>
        <div className="submission-collection-container12">
          <Link
            to="/uzbekistan-germanyuz"
            className="submission-collection-navlink10"
          >
            <div className="submission-collection-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="submission-collection-icon12"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="submission-collection-text103">Бош саҳифа</h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="submission-collection-navlink11"
          >
            <div className="submission-collection-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="submission-collection-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="submission-collection-text104">Умумий</h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="submission-collection-navlink12"
          >
            <div className="submission-collection-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="submission-collection-icon16"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="submission-collection-text105">
                Ҳужжатларни топшириш/Ҳужжатларни олиш
              </h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="submission-collection-container16">
        <div className="submission-collection-container17">
          <div className="submission-collection-container18">
            <span className="submission-collection-text106">Умумий</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="submission-collection-icon18"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="submission-collection-image10"
          />
          <div className="submission-collection-container19">
            <div className="submission-collection-container20">
              <img
                alt="image"
                src="/rectangle%2030.svg"
                className="submission-collection-image11"
              />
              <span className="submission-collection-text107">
                Ҳужжатларни топшириш/Ҳужжатларни олиш
              </span>
            </div>
            <Link
              to="/tegishli-malumotlar"
              className="submission-collection-navlink13"
            >
              <div className="submission-collection-container21">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="submission-collection-image12"
                />
                <span className="submission-collection-text108">
                  Тегишли маълумотлар
                </span>
              </div>
            </Link>
            <Link
              to="/rasmiy-bayramlar-kuni"
              className="submission-collection-navlink14"
            >
              <div className="submission-collection-container22">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="submission-collection-image13"
                />
                <span className="submission-collection-text109">
                  Расмий байрамлар куни
                </span>
              </div>
            </Link>
            <Link
              to="/arizalarni-krib-chiish-muddati"
              className="submission-collection-navlink15"
            >
              <div className="submission-collection-container23">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="submission-collection-image14"
                />
                <span className="submission-collection-text110">
                  Аризаларни кўриб чиқиш муддати
                </span>
              </div>
            </Link>
            <Link
              to="/biometrik-malumotlarini"
              className="submission-collection-navlink16"
            >
              <div className="submission-collection-container24">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="submission-collection-image15"
                />
                <span className="submission-collection-text111">
                  биометрик маълумотларини
                </span>
              </div>
            </Link>
            <Link
              to="/tlov-shartlari"
              className="submission-collection-navlink17"
            >
              <div className="submission-collection-container25">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="submission-collection-image16"
                />
                <span className="submission-collection-text112">
                  Тўлов Шартлари
                </span>
              </div>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="submission-collection-navlink18"
            >
              <div className="submission-collection-container26">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="submission-collection-image17"
                />
                <span className="submission-collection-text113">
                  Техника хавфсизлиги кўрсатмалари
                </span>
              </div>
            </Link>
            <Link
              to="/foydali-avolalar-uz"
              className="submission-collection-navlink19"
            >
              <div className="submission-collection-container27">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="submission-collection-image18"
                />
                <span className="submission-collection-text114">
                  Фойдали ҳаволалар
                </span>
              </div>
            </Link>
          </div>
        </div>
        <div className="submission-collection-container28">
          <span className="submission-collection-text115">
            Ҳужжатларни топшириш/Ҳужжатларни олиш
          </span>
          <span className="submission-collection-text116">
            <span className="submission-collection-text117">
              Ҳужжатларни топшириш
            </span>
            <br className="submission-collection-text118"></br>
            <br className="submission-collection-text119"></br>
            <span className="submission-collection-text120">
              • 18 ёшдан юқори бўлган аризачилар виза учун шахсан мурожаъат
              қилиши мумкин.
            </span>
            <br className="submission-collection-text121"></br>
            <br className="submission-collection-text122"></br>
            <span className="submission-collection-text123">
              Агар бармоқ излари топширилган бўлса:
            </span>
            <br className="submission-collection-text124"></br>
            <br className="submission-collection-text125"></br>
            <span className="submission-collection-text126">
              • Расман тасдиқланган ишончнома билан(ишончнома бошқа шахсга
              берилмаслик)
            </span>
            <br className="submission-collection-text127"></br>
            <br className="submission-collection-text128"></br>
            <span className="submission-collection-text129">
              Ваколатли вакил расман тасдиқланган ишончноманинг асл нусхасини
              тақдим этиши керак,бу ишончномада вакилга ариза берувчи томонидан
              Германия виза марказига мурожаъат қилиши учун ҳужжатларни
              топширишга ваколат берилганлиги кўрсатилган бўлиши керак.
            </span>
            <br className="submission-collection-text130"></br>
            <br className="submission-collection-text131"></br>
            <span className="submission-collection-text132">
              • 18 ёшгача бўлган болалар учун ота-она ёки вакиллардан бири
              туғилганлик тўғрисидаги гувоҳнома билан (асли ва нусхаси) тақдим
              этган ҳолда мурожаат қилиши мумкин. Бундан ташқари, иккинчи
              ота-онадан бола учун Германия виза марказига ҳужжатларни
              расмийлаштириш учун расман тасдиқланган ишончномани (ишончнома
              бошқа шахсга берилмаслик) тақдим этилиши керак бўлади.
            </span>
            <br className="submission-collection-text133"></br>
            <br className="submission-collection-text134"></br>
            <span className="submission-collection-text135">Муҳим:</span>
            <br className="submission-collection-text136"></br>
            <br className="submission-collection-text137"></br>
            <span className="submission-collection-text138">
              Бир оила аъзоси бўлган ёки саёҳат қилувчи гуруҳининг аъзолари бир
              хил саналарда ва бирга саёҳат қилиш учун биргаликда ариза
              топширилганда:
            </span>
            <br className="submission-collection-text139"></br>
            <br className="submission-collection-text140"></br>
            <span className="submission-collection-text141">
              • Шартномада кўрсатилган гуруҳ/оила аъзолари топшириши ва олиши
              учун ва VisaMetric номидаги шаклни,гуруҳ/оиланинг асосий
              шахси(шартномадаги буюртмачиси)томонидан ишончнома тақдим этиши
              керак.
            </span>
            <br className="submission-collection-text142"></br>
            <br className="submission-collection-text143"></br>
            <span className="submission-collection-text144">
              • Эътибор беринг, шартнома тузилган мижоз билан гуруҳ/оила аъзоси
              бўлиши ва улар билан бирга сайохатга чиқиши керак.
            </span>
            <br className="submission-collection-text145"></br>
            <br className="submission-collection-text146"></br>
            <span className="submission-collection-text147">
              Акс ҳолда, расмий тасдиқланган ишончнома учинчи шаҳсдан ёки
              аризачидан талаб қилинади, агар биргаликда сафар килинмаса.
            </span>
            <br className="submission-collection-text148"></br>
            <br className="submission-collection-text149"></br>
            <span className="submission-collection-text150">
              Ушбу турдаги ариза билан шартнома бўйича ваколатли шахс/мижоз
              шартнома асосида ариза топшириш жараёнида қуйидагиларни тақдим
              қилиши керак:
            </span>
            <br className="submission-collection-text151"></br>
            <br className="submission-collection-text152"></br>
            <span className="submission-collection-text153">
              - аризачи томононидан имзоланиб тўлдирилган Визаметрик ишончнома
              бланкаси(асл нусхаси)
            </span>
            <br className="submission-collection-text154"></br>
            <br className="submission-collection-text155"></br>
            <span className="submission-collection-text156">
              - аризачининг ва вакил шахснинг шахсини тасдиқловчи гувоҳнома
              нусхаси (расм саҳифаси)
            </span>
            <br className="submission-collection-text157"></br>
            <br className="submission-collection-text158"></br>
            <span className="submission-collection-text159">
              - вакл шахснинг шахсини тасдиқловчи гувоҳномасининг асл нусхаси.
            </span>
            <br className="submission-collection-text160"></br>
            <br className="submission-collection-text161"></br>
            <span className="submission-collection-text162">
              • Агарда вояга етмаган аризачи ота-онасиз ёки вакил шахссиз ҳужжат
              топширса, аризачи томонидан расмий ишончнома талаб қилинади. Вакл
              шахсдан ёки иккала ота-она томонидан ишончнома расмийлаштирилиши
              керак, шахсни тасдиқловчи ҳужжатлар асосида. Бундан ташқари,
              ишончномани имзолаган ота-онанинг ёки қонуний васийнинг шахсини
              тасдиқловчи ҳужжатининг нусхаси (расм саҳифа) ваколатли вакилнинг
              ички шахсни тасдиқловчи ҳужжатнинг нусхаси, (расм саҳифа) тақдим
              этиш лозим, боланинг туғилганлик ҳақидаги гувоҳномасини аслини ва
              нусҳасини такдим этишингиз керак.
            </span>
            <br className="submission-collection-text163"></br>
            <br className="submission-collection-text164"></br>
            <span className="submission-collection-text165">
              Ҳужжатларни олиш
            </span>
            <br className="submission-collection-text166"></br>
            <br className="submission-collection-text167"></br>
            <span className="submission-collection-text168">
              • Шартнома тузилган шахснинг пасрорт ва унга тегишли яни (мижоз
              шартномасидаги &quot;мижоз&quot; майдони) шахсан олиши мумкин.
              Ҳужжатларни олиш учун зарур бўлган ҳужжатлар : мижоз шартномаси,
              олувчини шахсини тасдикловчи ички ҳужжат (ёки шартнома тузилган
              ҳужжат, масалан: чет эл фукаролари учун яшаш учун рухсатнома ).
            </span>
            <br className="submission-collection-text169"></br>
            <br className="submission-collection-text170"></br>
            <span className="submission-collection-text171">
              • Учинчи шахс (мижоздан ташкари) фақат расман тасдиқланган
              ишончнома асосида олиши мумкин. Ҳужжатларни олиш учун талаб
              қилинган ҳужжатлар: мижоз шартномаси, олувчини шахсини тасдиқловчи
              ички ҳужжат (асл нусхаси + фотосурати билан саҳифанинг нусхаси),
              виза олиш марказида ҳужжатларни қабул қилиш учун расман
              тасдиқланган ишончнома (асл нусхаси).
            </span>
            <br className="submission-collection-text172"></br>
            <br className="submission-collection-text173"></br>
            <span className="submission-collection-text174">
              • Германия элчихонаси ёки Германия Бош консуллиги буйруғи билан
              виза тўлови учун маблағлар қайтарилган тақдирда, расман
              тасдиқланган ишончномада, шунингдек, ваколатли шахс аризачи
              номидан шартнома бўйича пул маблағларини олишга ваколатли эканлиги
              кўрсатилиши керак.
            </span>
            <br className="submission-collection-text175"></br>
            <br className="submission-collection-text176"></br>
            <span className="submission-collection-text177">
              • Расман тасдиқланган ишончномада ушбу васиятнома бўлмаса, пул
              маблағлари ваколатли шахсга берилиши мумкин эмас.
            </span>
            <br className="submission-collection-text178"></br>
            <br className="submission-collection-text179"></br>
            <span className="submission-collection-text180">
              • Гуруҳ/оила учун шартнома тузилган тақдирда, фақат шартномада
              мижоз сифатида кўрсатилган шахс барча паспорт ва унга оид
              ҳужжатларни олиб кетиши мумкин. Ҳужжатларни олиш учун зарур
              ҳужжатлар: мижоз шартномаси, олувчининг шахсини тасдиқловчи ички
              ҳужжат (ёки шартнома тузилган ҳужжат, масалан: чет эл фуқаролари
              учун яшаш учун рухсатнома).
            </span>
            <br className="submission-collection-text181"></br>
            <br className="submission-collection-text182"></br>
            <span className="submission-collection-text183">
              Агар бутун гурух/оила учун ҳужжат олиш учун бошка шахс томонидан
              тузилган бўлса, у ҳолда мижоздан учинчи шахсга расман тасдиқланган
              ишончнома талаб қилинади ва у нафақат мижознинг паспортини , балки
              шартнома бўйича барча ариза берувчиларга рухсатномани ўз ичига
              олади.
            </span>
            <br className="submission-collection-text184"></br>
            <br className="submission-collection-text185"></br>
            <span className="submission-collection-text186">
              • Вояга етмаган аризачилар учун ҳужжатлар фақат мижос сифатида
              шартномасида кўрсатилган ота-она ёки қонуний васий томонидан олиб
              кетиш мумкин. Ҳужжатларни олиш учун зарур ҳужжатлар: мижоз
              шарномаси, олувчининг шахсини тасдиқловчи ички ҳужжат ( ёки
              шартнома тузилган ҳужжат, масалан: чет эл фуқаролари учун, яшаш
              учун рухсатнома). Акс ҳолда, вояга етмаган бола учун ҳужжатларни
              олиш қоидалари учинчи шахслар томонидан ҳужжатларни олиш қоидалари
              билан бир хил .
            </span>
            <br className="submission-collection-text187"></br>
            <br className="submission-collection-text188"></br>
            <span className="submission-collection-text189">
              Эътибор беринг, сиз Виза марказидан паспорт ва сизга оид
              ҳужжатларни олганингизда, сўровномани (ходимларимиз томонидан
              тақдим этиладиган) тўлдиришнгиз керак бўлади.
            </span>
          </span>
        </div>
      </div>
      <div id="header" className="submission-collection-container29">
        <Link to="/" className="submission-collection-navlink20">
          <div className="submission-collection-container30">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="submission-collection-image19"
            />
            <p className="submission-collection-text190">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="submission-collection-container31">
          <div className="submission-collection-container32">
            <span className="submission-collection-text193">
              <span>Apply for Visa to</span>
              <span className="submission-collection-text195"> Germany</span>
            </span>
            <span className="submission-collection-text196">
              <span className="submission-collection-text197">
                in Uzbekistan
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="submission-collection-image20"
          />
          <span className="submission-collection-text228">|</span>
 <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header
        data-thq="thq-navbar"
        className="submission-collection-navbar-interactive"
      >
        <div
          data-thq="thq-navbar-nav"
          className="submission-collection-desktop-menu"
        >
          <div className="submission-collection-container33">
            <nav className="submission-collection-links1">
              <div
                data-thq="thq-dropdown"
                className="submission-collection-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="submission-collection-dropdown-toggle10"
                  >
                    <span className="submission-collection-text229">
                      Бош сахифа
                    </span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="submission-collection-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="submission-collection-dropdown-toggle11"
                >
                  <span className="submission-collection-text230">Умумий</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="submission-collection-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="submission-collection-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle12"
                      >
                        <span className="submission-collection-text231 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle13"
                      >
                        <span className="submission-collection-text234 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle14"
                      >
                        <span className="submission-collection-text235 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle15"
                      >
                        <span className="submission-collection-text236 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle16"
                      >
                        <span className="submission-collection-text241 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle17"
                      >
                        <span className="submission-collection-text242 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle18"
                      >
                        <span className="submission-collection-text243 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle19"
                      >
                        <span className="submission-collection-text247 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="submission-collection-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="submission-collection-dropdown-toggle20"
                >
                  <span className="submission-collection-text248">
                    Виза олиш
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="submission-collection-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="submission-collection-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle21"
                      >
                        <span className="submission-collection-text249">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle22"
                      >
                        <span className="submission-collection-text250">
                          Миллий виза
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle23"
                      >
                        <span className="submission-collection-text251">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle24"
                      >
                        <span className="submission-collection-text252">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle25"
                      >
                        <span className="submission-collection-text255">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle26"
                      >
                        <span className="submission-collection-text259">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle27"
                      >
                        <span className="submission-collection-text260">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="submission-collection-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="submission-collection-dropdown-toggle28"
                >
                  <span className="submission-collection-text261">
                    Кушимча хизматлар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="submission-collection-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="submission-collection-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle29"
                      >
                        <span className="submission-collection-text262">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle30"
                      >
                        <span className="submission-collection-text263">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle31"
                      >
                        <span className="submission-collection-text267">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle32"
                      >
                        <span className="submission-collection-text270">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle33"
                      >
                        <span className="submission-collection-text271">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle34"
                      >
                        <span className="submission-collection-text275">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle35"
                      >
                        <span className="submission-collection-text276">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="submission-collection-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="submission-collection-dropdown-toggle38"
                  >
                    <span className="submission-collection-text279">FAQ</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="submission-collection-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="submission-collection-dropdown-toggle39"
                >
                  <span className="submission-collection-text280">
                    Алокалар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="submission-collection-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="submission-collection-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle40"
                      >
                        <span className="submission-collection-text281">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle41"
                      >
                        <span className="submission-collection-text282">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle42"
                      >
                        <span className="submission-collection-text283">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="submission-collection-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="submission-collection-dropdown-toggle43"
                      >
                        <span className="submission-collection-text284">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="submission-collection-container34">
          <input
            type="text"
            placeholder="Кидирмок"
            className="submission-collection-textinput1 input"
          />
          <div className="submission-collection-container35">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="submission-collection-icon20"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="submission-collection-container36">
            <span className="submission-collection-text287">A+</span>
            <span className="submission-collection-text288">A-</span>
          </div>
        </div>
        <div
          data-thq="thq-burger-menu"
          className="submission-collection-burger-menu2"
        >
          <svg viewBox="0 0 1024 1024" className="submission-collection-icon22">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div
          data-thq="thq-mobile-menu"
          className="submission-collection-mobile-menu"
        >
          <div className="submission-collection-nav">
            <div className="submission-collection-container37">
              <div className="submission-collection-container38">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="submission-collection-textinput2 input"
                />
                <div className="submission-collection-container39">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="submission-collection-icon24"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="submission-collection-container40">
                  <span className="submission-collection-text289">A+</span>
                  <span className="submission-collection-text290">A-</span>
                </div>
              </div>
              <div
                data-thq="thq-close-menu"
                className="submission-collection-close-menu"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="submission-collection-icon26"
                >
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="submission-collection-links2">
            <div
              data-thq="thq-dropdown"
              className="submission-collection-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="submission-collection-dropdown-toggle44"
                >
                  <span className="submission-collection-text291">
                    Бош сахифа
                  </span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="submission-collection-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="submission-collection-dropdown-toggle45"
              >
                <span className="submission-collection-text292">Умумий</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="submission-collection-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="submission-collection-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="submission-collection-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle46"
                    >
                      <span className="submission-collection-text293">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle47"
                    >
                      <span className="submission-collection-text296">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle48"
                    >
                      <span className="submission-collection-text297">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle49"
                    >
                      <span className="submission-collection-text298">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle50"
                    >
                      <span className="submission-collection-text303">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle51"
                    >
                      <span className="submission-collection-text304">
                        Тулов Шартлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle52"
                    >
                      <span className="submission-collection-text305">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle53"
                    >
                      <span className="submission-collection-text308">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="submission-collection-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="submission-collection-dropdown-toggle54"
              >
                <span className="submission-collection-text309">Виза олиш</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="submission-collection-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="submission-collection-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="submission-collection-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle55"
                    >
                      <span className="submission-collection-text310">
                        Шенген визаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle56"
                    >
                      <span className="submission-collection-text311">
                        Миллий виза
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle57"
                    >
                      <span className="submission-collection-text312">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle58"
                    >
                      <span className="submission-collection-text313">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle59"
                    >
                      <span className="submission-collection-text316">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle60"
                    >
                      <span className="submission-collection-text320">
                        Ариза шакллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle61"
                    >
                      <span className="submission-collection-text321">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="submission-collection-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="submission-collection-dropdown-toggle62"
              >
                <span className="submission-collection-text322">
                  Кушимча хизматлар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="submission-collection-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="submission-collection-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="submission-collection-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle63"
                    >
                      <span className="submission-collection-text323">
                        VIP - хизмати
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle64"
                    >
                      <span className="submission-collection-text324">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle65"
                    >
                      <span className="submission-collection-text325">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle66"
                    >
                      <span className="submission-collection-text326">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle67"
                    >
                      <span className="submission-collection-text327">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle68"
                    >
                      <span className="submission-collection-text330">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle69"
                    >
                      <span className="submission-collection-text333">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle70"
                    >
                      <span className="submission-collection-text334">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle71"
                    >
                      <span className="submission-collection-text335">
                        Prime Тиме
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="submission-collection-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="submission-collection-dropdown-toggle72"
                >
                  <span className="submission-collection-text336">FAQ</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="submission-collection-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="submission-collection-dropdown-toggle73"
              >
                <span className="submission-collection-text337">Алокалар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="submission-collection-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="submission-collection-icon34"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="submission-collection-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle74"
                    >
                      <span className="submission-collection-text338">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle75"
                    >
                      <span className="submission-collection-text339">
                        Алока маркази
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle76"
                    >
                      <span className="submission-collection-text340">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="submission-collection-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="submission-collection-dropdown-toggle77"
                    >
                      <span className="submission-collection-text341">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="submission-collection-container41">
        <div className="submission-collection-container42">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="submission-collection-image22"
          />
          <span className="submission-collection-text344">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="submission-collection-container43">
          <div className="submission-collection-container44">
            <span className="submission-collection-text345">
              Ходимларни бошқариш
            </span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="submission-collection-image23"
            />
          </div>
          <div className="submission-collection-container45">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="submission-collection-navlink81"
            >
              <div className="submission-collection-container46">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="submission-collection-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="submission-collection-text346">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="submission-collection-navlink82"
            >
              <div className="submission-collection-container47">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="submission-collection-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="submission-collection-text347">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="submission-collection-navlink83"
            >
              <div className="submission-collection-container48">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="submission-collection-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="submission-collection-text351">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="submission-collection-navlink84"
            >
              <div className="submission-collection-container49">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="submission-collection-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="submission-collection-text355">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="submission-collection-navlink85"
            >
              <div className="submission-collection-container50">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="submission-collection-icon44"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="submission-collection-text359">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="submission-collection-container51">
        <div className="submission-collection-container52">
          <span className="submission-collection-text363">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="submission-collection-container53">
            <Link
              to="/visametric-gurukhi"
              className="submission-collection-navlink86"
            >
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="submission-collection-navlink87"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/w3c-logo2-200h.png"
          className="submission-collection-image24"
        />
        <a href="#main" className="submission-collection-link">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="submission-collection-image25"
          />
        </a>
      </div>
    </div>
  )
}

export default SubmissionCollection
