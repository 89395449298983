import React from 'react'

import { Helmet } from 'react-helmet'

import './khodimlar-bulimi-konun-koidalari.css'

const KhodimlarBulimiKonunKoidalari = (props) => {
  return (
    <div className="khodimlar-bulimi-konun-koidalari-container">
      <Helmet>
        <title>khodimlar-bulimi-konun-koidalari - Visametric</title>
        <meta
          property="og:title"
          content="khodimlar-bulimi-konun-koidalari - Visametric"
        />
      </Helmet>
    </div>
  )
}

export default KhodimlarBulimiKonunKoidalari
