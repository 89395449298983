import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './ariza-topshirish-zharaeni.css'

const ArizaTopshirishZharaeni = (props) => {
  return (
    <div className="ariza-topshirish-zharaeni-container10">
      <Helmet>
        <title>ariza-topshirish-zharaeni - Visametric</title>
        <meta
          property="og:title"
          content="ariza-topshirish-zharaeni - Visametric"
        />
      </Helmet>
      <div
        data-thq="thq-burger-menu"
        className="ariza-topshirish-zharaeni-burger-menu1"
      >
        <svg
          viewBox="0 0 1024 1024"
          className="ariza-topshirish-zharaeni-icon10"
        >
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div id="main" className="ariza-topshirish-zharaeni-container11">
        <h1 className="ariza-topshirish-zharaeni-text100">Ариза олдин</h1>
        <div className="ariza-topshirish-zharaeni-container12">
          <Link
            to="/uzbekistan-germanyuz"
            className="ariza-topshirish-zharaeni-navlink10"
          >
            <div className="ariza-topshirish-zharaeni-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="ariza-topshirish-zharaeni-icon12"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="ariza-topshirish-zharaeni-text101">Бош саҳифа</h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="ariza-topshirish-zharaeni-navlink11"
          >
            <div className="ariza-topshirish-zharaeni-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="ariza-topshirish-zharaeni-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="ariza-topshirish-zharaeni-text102">FAQ</h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="ariza-topshirish-zharaeni-navlink12"
          >
            <div className="ariza-topshirish-zharaeni-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="ariza-topshirish-zharaeni-icon16"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="ariza-topshirish-zharaeni-text103">
                Ариза топшириш жараёни
              </h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="ariza-topshirish-zharaeni-container16">
        <div className="ariza-topshirish-zharaeni-container17">
          <div className="ariza-topshirish-zharaeni-container18">
            <span className="ariza-topshirish-zharaeni-text104">FAQ</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="ariza-topshirish-zharaeni-icon18"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="ariza-topshirish-zharaeni-image10"
          />
          <div className="ariza-topshirish-zharaeni-container19">
            <Link
              to="/umumiy-malumot"
              className="ariza-topshirish-zharaeni-navlink13"
            >
              <div className="ariza-topshirish-zharaeni-container20">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-topshirish-zharaeni-image11"
                />
                <span className="ariza-topshirish-zharaeni-text105">
                  Умумий маълумот
                </span>
              </div>
            </Link>
            <Link
              to="/ariza-oldin"
              className="ariza-topshirish-zharaeni-navlink14"
            >
              <div className="ariza-topshirish-zharaeni-container21">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-topshirish-zharaeni-image12"
                />
                <span className="ariza-topshirish-zharaeni-text106">
                  Ариза олдин
                </span>
              </div>
            </Link>
            <Link
              to="/ariza-topshirish-zharaeni"
              className="ariza-topshirish-zharaeni-navlink15"
            >
              <div className="ariza-topshirish-zharaeni-container22">
                <img
                  alt="image"
                  src="/rectangle%2030.svg"
                  className="ariza-topshirish-zharaeni-image13"
                />
                <span className="ariza-topshirish-zharaeni-text107">
                  Ариза топшириш жараёни
                </span>
              </div>
            </Link>
            <Link
              to="/uzhzhatlar"
              className="ariza-topshirish-zharaeni-navlink16"
            >
              <div className="ariza-topshirish-zharaeni-container23">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-topshirish-zharaeni-image14"
                />
                <span className="ariza-topshirish-zharaeni-text108">
                  Ҳужжатлар
                </span>
              </div>
            </Link>
            <Link
              to="/shimcha-khizmatlar"
              className="ariza-topshirish-zharaeni-navlink17"
            >
              <div className="ariza-topshirish-zharaeni-container24">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-topshirish-zharaeni-image15"
                />
                <span className="ariza-topshirish-zharaeni-text109">
                  Қўшимча хизматлар
                </span>
              </div>
            </Link>
            <Link
              to="/ariza-topshirishdan-snggi-olat"
              className="ariza-topshirish-zharaeni-navlink18"
            >
              <div className="ariza-topshirish-zharaeni-container25">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-topshirish-zharaeni-image16"
                />
                <span className="ariza-topshirish-zharaeni-text110">
                  Ариза топширишдан сўнгги ҳолат
                </span>
              </div>
            </Link>
          </div>
        </div>
        <div className="ariza-topshirish-zharaeni-container26">
          <span className="ariza-topshirish-zharaeni-text111">
            <span>1. Узбекистон / FAQ / Ариза топшириш жараёни</span>
            <br className="ariza-topshirish-zharaeni-text113"></br>
          </span>
          <span className="ariza-topshirish-zharaeni-text114">
            <span className="ariza-topshirish-zharaeni-text115">
              Виза аризасини текшириб чиқиш учун қанча вақт зарур?
            </span>
            <br className="ariza-topshirish-zharaeni-text116"></br>
            <br className="ariza-topshirish-zharaeni-text117"></br>
            <span>
              Шенген худуди учун аризани куриб чикиш муддати 10 иш кунидан
              иборат.
            </span>
            <br className="ariza-topshirish-zharaeni-text119"></br>
            <span>
              Андижон ва Бухородаги Виза марказларида ариза берилган холда,
              аризани куриб чикиш муддати 15 иш кунидан иборат.
            </span>
            <br className="ariza-topshirish-zharaeni-text121"></br>
            <br className="ariza-topshirish-zharaeni-text122"></br>
            <br className="ariza-topshirish-zharaeni-text123"></br>
            <span className="ariza-topshirish-zharaeni-text124">
              Визага ариза берилгандан кейин қанча вақт кутишим керак?
            </span>
            <br className="ariza-topshirish-zharaeni-text125"></br>
            <br className="ariza-topshirish-zharaeni-text126"></br>
            <span>
              Шенген давлати ҳудудидаги техник жараён туфайли, шенген виза кўриб
              чиқилиши икки ҳафтага чўзилиши мумкин. Элчихона бу сабабга кўра
              кўриб чиқиш вақтига таъсир қилмайди.
            </span>
            <br className="ariza-topshirish-zharaeni-text128"></br>
            <span>
              Шунинг учун, элчихона Шенген давлатларига ташриф ва виза учун
              аризани саёҳатдан камида икки ҳафта олдин режалаштиришни маслаҳат
              беради. Афсуски, аниқ асосга кўра тақдим қилинган виза учун
              аризалар этиборга олинмаслиги мумкин. Шенген визасига ариза
              беришнинг эрта муддати – ташрифдан 3 ой олдинги муддат. Миллий
              виза олиш учун олти – саккиз ҳафтадан кам бўлмаган муддат керак
              бўлади.
            </span>
            <br className="ariza-topshirish-zharaeni-text130"></br>
            <br className="ariza-topshirish-zharaeni-text131"></br>
            <br className="ariza-topshirish-zharaeni-text132"></br>
            <span className="ariza-topshirish-zharaeni-text133">
              Шенген визасини тақдим қилинишидан олдин элчихона нимани
              текширади?
            </span>
            <br className="ariza-topshirish-zharaeni-text134"></br>
            <br className="ariza-topshirish-zharaeni-text135"></br>
            <span>Элчихона қуйидаги мезонларга кўра текширув олиб боради:</span>
            <br className="ariza-topshirish-zharaeni-text137"></br>
            <span>- Ташриф мақсади (масалан, меҳмондочилик, иш сафари);</span>
            <br className="ariza-topshirish-zharaeni-text139"></br>
            <span>- Қайтиб келиш истаги;</span>
            <br className="ariza-topshirish-zharaeni-text141"></br>
            <span>
              - Саёҳат молиялаштирилиши (қабул қилувчи томон ёки саёҳатчи);
            </span>
            <br className="ariza-topshirish-zharaeni-text143"></br>
            <span>- Тиббий суғуртани таъминлаш;</span>
            <br className="ariza-topshirish-zharaeni-text145"></br>
            <span>
              - Бошқа маълумотлар (AZR, SIS рўйхатга олади, масалан, жиноятлар
              ёки бошқа қоидабузарлик/майда қоидабузарликлар).
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-topshirish-zharaeni-text147"></br>
            <br className="ariza-topshirish-zharaeni-text148"></br>
            <br className="ariza-topshirish-zharaeni-text149"></br>
            <span className="ariza-topshirish-zharaeni-text150">
              AZR ва SIS нима?
            </span>
            <br className="ariza-topshirish-zharaeni-text151"></br>
            <br className="ariza-topshirish-zharaeni-text152"></br>
            <span>
              AZR Ausländerzentralregister (Хорижий фуқароларнинг марказий
              регистри) аббревиатураси. У Келндаги Маъмурий федерал бошқармасида
              юритилади. Ҳар бир аризачи AZR да текширувдан ўтказилади. Маълум
              вазиятларда фактлар, масалан, Германияга охирги ташрифда жиноят
              содир этганлиги тўғрисида маълумот бор. Бундай саёҳатчилар
              Германияга кира олишмайди.
            </span>
            <br className="ariza-topshirish-zharaeni-text154"></br>
            <span>
              SIS – бу «Шенген ахборот тизими». У ҳам AZR сингаридир, лекин
              Шенген ҳудуди давлатлари даражасида. Шунингдек, элчихона
              Шенгеннинг бошқа давлатлари ҳудудида жиноят содир этган шахсларга
              виза тақдим этмайди.
            </span>
            <br className="ariza-topshirish-zharaeni-text156"></br>
            <br className="ariza-topshirish-zharaeni-text157"></br>
            <br className="ariza-topshirish-zharaeni-text158"></br>
            <span className="ariza-topshirish-zharaeni-text159">
              VisaMetric қабул маркази ходими томонидан тушунтириш ишлари
              нотўғри олиб борилди, ёки менинг аризам учинчи шахс томонидан виза
              олиб бериш ваъдаси билан тўлдирилди. Менинг аризамга рад жавоби
              берилди, нима қилсам бўлади?
            </span>
            <br className="ariza-topshirish-zharaeni-text160"></br>
            <br className="ariza-topshirish-zharaeni-text161"></br>
            <span>
              Аризангиз мазмуни учун жавобгарликни ўз зиммангизга оласиз.
              VisaMetric да ишламайдиган шахслар уларнинг хизматидан
              фойдаланаётганингизга амин бўлишса, бу муаммони улар билан
              ечишингизни сўраймиз.
            </span>
            <br className="ariza-topshirish-zharaeni-text163"></br>
            <span>
              Ҳамма маълумотларни тоза виждон билан тўғри тақдим этишингизни
              сўраймиз. Ҳеч ким профессионал тўлдирилган ариза сизга виза
              олишингиз кафолати эканлигини айтишига йўл қўйманг – ҳеч ким сизга
              виза ваъда қилолмайди! Ҳар қандай одам ариза шаклидаги саволларга
              шахсан жавоб бера олади. Агар анкета топширишда муаммога дуч
              келсангиз, VisaMetric ходимлари сизга албатта ёрдам беради.
            </span>
            <br className="ariza-topshirish-zharaeni-text165"></br>
            <br className="ariza-topshirish-zharaeni-text166"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-topshirish-zharaeni-text168"></br>
            <span className="ariza-topshirish-zharaeni-text169">
              Бир неча ҳафта аввал VisaMetric қабул маркази/элчихонасига
              жўнатган ҳужжатларим қаерда?
            </span>
            <br className="ariza-topshirish-zharaeni-text170"></br>
            <br className="ariza-topshirish-zharaeni-text171"></br>
            <span>
              Ариза топширувчилар сўралган ҳужжатларни ўзлари тақдим қилишлари
              лозим. Илтимос, барча ҳужжатларни VisaMetric қабул
              маркази/элчихонасига эмас, айнан меҳмоннинг ўзига юборинг.
            </span>
            <br className="ariza-topshirish-zharaeni-text173"></br>
            <br className="ariza-topshirish-zharaeni-text174"></br>
            <br className="ariza-topshirish-zharaeni-text175"></br>
            <span className="ariza-topshirish-zharaeni-text176">
              Ҳужжатлар тўплами тўлиқ бўлмаган тақдирда ҳам қабул қилинадими?
            </span>
            <br className="ariza-topshirish-zharaeni-text177"></br>
            <br className="ariza-topshirish-zharaeni-text178"></br>
            <span>
              VisaMetric маркази қабулхонасига тўлиқ бўлмаган ҳужжатлар тўплами
              билан борсангиз, вакилларимиз бу ҳақида тушунтириб беришади.
            </span>
            <br className="ariza-topshirish-zharaeni-text180"></br>
            <span>Кейин сизда қуйидаги имкониятлар пайдо бўлади:</span>
            <br className="ariza-topshirish-zharaeni-text182"></br>
            <br className="ariza-topshirish-zharaeni-text183"></br>
            <span>
              - Ҳужжатлар сизга қайтарилади ва сиз бизнинг веб саҳифамизда янги
              учрашув вақтини белгилайсиз;
            </span>
            <br className="ariza-topshirish-zharaeni-text185"></br>
            <br className="ariza-topshirish-zharaeni-text186"></br>
            <span>
              - Сиз тўлиқ бўлмаган хужжат топширгансиз. Лекин, етишмаган
              ҳужжатлар виза олишда рад жавоби олишингиз мумкин. Аризани
              жўнатишда тегишли огоҳлантиришни имзолашингиз керак.
            </span>
            <br className="ariza-topshirish-zharaeni-text188"></br>
            <br className="ariza-topshirish-zharaeni-text189"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-topshirish-zharaeni-text191"></br>
            <span className="ariza-topshirish-zharaeni-text192">
              Ариза тўпламида муайян ҳужжат йўқ Ҳужжатларни шу ҳолатда жўнатсам
              бўладими ёки аризам рад этиладими?
            </span>
            <br className="ariza-topshirish-zharaeni-text193"></br>
            <br className="ariza-topshirish-zharaeni-text194"></br>
            <span>
              Доим тўлиқ ҳужжатлар тўпламини жўнатинг. Қарор қандай бўлишини
              олдиндан айтиб бўлмайди, етмаётган ҳужжатларга қарамасдан ижобий
              жавоб бўлиши ҳам мумкин.
            </span>
            <br className="ariza-topshirish-zharaeni-text196"></br>
            <br className="ariza-topshirish-zharaeni-text197"></br>
            <br className="ariza-topshirish-zharaeni-text198"></br>
            <span className="ariza-topshirish-zharaeni-text199">
              Яқин вақтгача вояга етмаган болам яқин орагача паспортимда
              рўйхатдан ўтган эди. Унинг визаси менинг паспортимда рўйхатдан
              ўтган эди. Ҳозирда унинг ўз паспорти бор. Визага нима бўлади?
            </span>
            <br className="ariza-topshirish-zharaeni-text200"></br>
            <br className="ariza-topshirish-zharaeni-text201"></br>
            <span>
              Визани паспортингиздан боланинг янги паспортига ўтказишингиз
              мумкин. Илтимос, эски ҳамда янги паспортларингиз билан виза
              ахборот бўлимига юборинг. Ўтказиш бепул бўлади.
            </span>
            <br className="ariza-topshirish-zharaeni-text203"></br>
            <br className="ariza-topshirish-zharaeni-text204"></br>
          </span>
        </div>
      </div>
      <div id="header" className="ariza-topshirish-zharaeni-container27">
        <Link to="/" className="ariza-topshirish-zharaeni-navlink19">
          <div className="ariza-topshirish-zharaeni-container28">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="ariza-topshirish-zharaeni-image17"
            />
            <p className="ariza-topshirish-zharaeni-text205">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="ariza-topshirish-zharaeni-container29">
          <div className="ariza-topshirish-zharaeni-container30">
            <span className="ariza-topshirish-zharaeni-text208">
              <span>Apply for Visa to</span>
              <span className="ariza-topshirish-zharaeni-text210">
                {' '}
                Germany
              </span>
            </span>
            <span className="ariza-topshirish-zharaeni-text211">
              <span className="ariza-topshirish-zharaeni-text212">
                in Uzbekistan
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="ariza-topshirish-zharaeni-image18"
          />
          <span className="ariza-topshirish-zharaeni-text243">|</span>
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header
        data-thq="thq-navbar"
        className="ariza-topshirish-zharaeni-navbar-interactive"
      >
        <div
          data-thq="thq-navbar-nav"
          className="ariza-topshirish-zharaeni-desktop-menu"
        >
          <div className="ariza-topshirish-zharaeni-container31">
            <nav className="ariza-topshirish-zharaeni-links1">
              <div
                data-thq="thq-dropdown"
                className="ariza-topshirish-zharaeni-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="ariza-topshirish-zharaeni-dropdown-toggle10"
                  >
                    <span className="ariza-topshirish-zharaeni-text244">
                      Бош сахифа
                    </span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ariza-topshirish-zharaeni-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-topshirish-zharaeni-dropdown-toggle11"
                >
                  <span className="ariza-topshirish-zharaeni-text245">
                    Умумий
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="ariza-topshirish-zharaeni-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="ariza-topshirish-zharaeni-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle12"
                      >
                        <span className="ariza-topshirish-zharaeni-text246 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle13"
                      >
                        <span className="ariza-topshirish-zharaeni-text249 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle14"
                      >
                        <span className="ariza-topshirish-zharaeni-text250 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle15"
                      >
                        <span className="ariza-topshirish-zharaeni-text251 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle16"
                      >
                        <span className="ariza-topshirish-zharaeni-text256 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle17"
                      >
                        <span className="ariza-topshirish-zharaeni-text257 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle18"
                      >
                        <span className="ariza-topshirish-zharaeni-text258 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle19"
                      >
                        <span className="ariza-topshirish-zharaeni-text262 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ariza-topshirish-zharaeni-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-topshirish-zharaeni-dropdown-toggle20"
                >
                  <span className="ariza-topshirish-zharaeni-text263">
                    Виза олиш
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="ariza-topshirish-zharaeni-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="ariza-topshirish-zharaeni-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle21"
                      >
                        <span className="ariza-topshirish-zharaeni-text264">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle22"
                      >
                        <span className="ariza-topshirish-zharaeni-text265">
                          Миллий виза
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle23"
                      >
                        <span className="ariza-topshirish-zharaeni-text266">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle24"
                      >
                        <span className="ariza-topshirish-zharaeni-text267">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle25"
                      >
                        <span className="ariza-topshirish-zharaeni-text270">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle26"
                      >
                        <span className="ariza-topshirish-zharaeni-text274">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle27"
                      >
                        <span className="ariza-topshirish-zharaeni-text275">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ariza-topshirish-zharaeni-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-topshirish-zharaeni-dropdown-toggle28"
                >
                  <span className="ariza-topshirish-zharaeni-text276">
                    Кушимча хизматлар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="ariza-topshirish-zharaeni-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="ariza-topshirish-zharaeni-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle29"
                      >
                        <span className="ariza-topshirish-zharaeni-text277">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle30"
                      >
                        <span className="ariza-topshirish-zharaeni-text278">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle31"
                      >
                        <span className="ariza-topshirish-zharaeni-text282">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle32"
                      >
                        <span className="ariza-topshirish-zharaeni-text285">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle33"
                      >
                        <span className="ariza-topshirish-zharaeni-text286">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle34"
                      >
                        <span className="ariza-topshirish-zharaeni-text290">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle35"
                      >
                        <span className="ariza-topshirish-zharaeni-text291">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ariza-topshirish-zharaeni-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="ariza-topshirish-zharaeni-dropdown-toggle38"
                  >
                    <span className="ariza-topshirish-zharaeni-text294">
                      FAQ
                    </span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ariza-topshirish-zharaeni-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-topshirish-zharaeni-dropdown-toggle39"
                >
                  <span className="ariza-topshirish-zharaeni-text295">
                    Алокалар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="ariza-topshirish-zharaeni-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="ariza-topshirish-zharaeni-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle40"
                      >
                        <span className="ariza-topshirish-zharaeni-text296">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle41"
                      >
                        <span className="ariza-topshirish-zharaeni-text297">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle42"
                      >
                        <span className="ariza-topshirish-zharaeni-text298">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-topshirish-zharaeni-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-topshirish-zharaeni-dropdown-toggle43"
                      >
                        <span className="ariza-topshirish-zharaeni-text299">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="ariza-topshirish-zharaeni-container32">
          <input
            type="text"
            placeholder="Кидирмок"
            className="ariza-topshirish-zharaeni-textinput1 input"
          />
          <div className="ariza-topshirish-zharaeni-container33">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="ariza-topshirish-zharaeni-icon20"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="ariza-topshirish-zharaeni-container34">
            <span className="ariza-topshirish-zharaeni-text302">A+</span>
            <span className="ariza-topshirish-zharaeni-text303">A-</span>
          </div>
        </div>
        <div
          data-thq="thq-burger-menu"
          className="ariza-topshirish-zharaeni-burger-menu2"
        >
          <svg
            viewBox="0 0 1024 1024"
            className="ariza-topshirish-zharaeni-icon22"
          >
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div
          data-thq="thq-mobile-menu"
          className="ariza-topshirish-zharaeni-mobile-menu"
        >
          <div className="ariza-topshirish-zharaeni-nav">
            <div className="ariza-topshirish-zharaeni-container35">
              <div className="ariza-topshirish-zharaeni-container36">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="ariza-topshirish-zharaeni-textinput2 input"
                />
                <div className="ariza-topshirish-zharaeni-container37">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="ariza-topshirish-zharaeni-icon24"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="ariza-topshirish-zharaeni-container38">
                  <span className="ariza-topshirish-zharaeni-text304">A+</span>
                  <span className="ariza-topshirish-zharaeni-text305">A-</span>
                </div>
              </div>
              <div
                data-thq="thq-close-menu"
                className="ariza-topshirish-zharaeni-close-menu"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="ariza-topshirish-zharaeni-icon26"
                >
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="ariza-topshirish-zharaeni-links2">
            <div
              data-thq="thq-dropdown"
              className="ariza-topshirish-zharaeni-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-topshirish-zharaeni-dropdown-toggle44"
                >
                  <span className="ariza-topshirish-zharaeni-text306">
                    Бош сахифа
                  </span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ariza-topshirish-zharaeni-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="ariza-topshirish-zharaeni-dropdown-toggle45"
              >
                <span className="ariza-topshirish-zharaeni-text307">
                  Умумий
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="ariza-topshirish-zharaeni-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="ariza-topshirish-zharaeni-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="ariza-topshirish-zharaeni-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle46"
                    >
                      <span className="ariza-topshirish-zharaeni-text308">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle47"
                    >
                      <span className="ariza-topshirish-zharaeni-text311">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle48"
                    >
                      <span className="ariza-topshirish-zharaeni-text312">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle49"
                    >
                      <span className="ariza-topshirish-zharaeni-text313">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle50"
                    >
                      <span className="ariza-topshirish-zharaeni-text318">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle51"
                    >
                      <span className="ariza-topshirish-zharaeni-text319">
                        Тулов Шартлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle52"
                    >
                      <span className="ariza-topshirish-zharaeni-text320">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle53"
                    >
                      <span className="ariza-topshirish-zharaeni-text323">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ariza-topshirish-zharaeni-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="ariza-topshirish-zharaeni-dropdown-toggle54"
              >
                <span className="ariza-topshirish-zharaeni-text324">
                  Виза олиш
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="ariza-topshirish-zharaeni-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="ariza-topshirish-zharaeni-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="ariza-topshirish-zharaeni-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle55"
                    >
                      <span className="ariza-topshirish-zharaeni-text325">
                        Шенген визаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle56"
                    >
                      <span className="ariza-topshirish-zharaeni-text326">
                        Миллий виза
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle57"
                    >
                      <span className="ariza-topshirish-zharaeni-text327">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle58"
                    >
                      <span className="ariza-topshirish-zharaeni-text328">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle59"
                    >
                      <span className="ariza-topshirish-zharaeni-text331">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle60"
                    >
                      <span className="ariza-topshirish-zharaeni-text335">
                        Ариза шакллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle61"
                    >
                      <span className="ariza-topshirish-zharaeni-text336">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ariza-topshirish-zharaeni-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="ariza-topshirish-zharaeni-dropdown-toggle62"
              >
                <span className="ariza-topshirish-zharaeni-text337">
                  Кушимча хизматлар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="ariza-topshirish-zharaeni-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="ariza-topshirish-zharaeni-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="ariza-topshirish-zharaeni-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle63"
                    >
                      <span className="ariza-topshirish-zharaeni-text338">
                        VIP - хизмати
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle64"
                    >
                      <span className="ariza-topshirish-zharaeni-text339">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle65"
                    >
                      <span className="ariza-topshirish-zharaeni-text340">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle66"
                    >
                      <span className="ariza-topshirish-zharaeni-text341">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle67"
                    >
                      <span className="ariza-topshirish-zharaeni-text342">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle68"
                    >
                      <span className="ariza-topshirish-zharaeni-text345">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle69"
                    >
                      <span className="ariza-topshirish-zharaeni-text348">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle70"
                    >
                      <span className="ariza-topshirish-zharaeni-text349">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle71"
                    >
                      <span className="ariza-topshirish-zharaeni-text350">
                        Prime Тиме
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ariza-topshirish-zharaeni-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-topshirish-zharaeni-dropdown-toggle72"
                >
                  <span className="ariza-topshirish-zharaeni-text351">FAQ</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ariza-topshirish-zharaeni-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="ariza-topshirish-zharaeni-dropdown-toggle73"
              >
                <span className="ariza-topshirish-zharaeni-text352">
                  Алокалар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="ariza-topshirish-zharaeni-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="ariza-topshirish-zharaeni-icon34"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="ariza-topshirish-zharaeni-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle74"
                    >
                      <span className="ariza-topshirish-zharaeni-text353">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle75"
                    >
                      <span className="ariza-topshirish-zharaeni-text354">
                        Алока маркази
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle76"
                    >
                      <span className="ariza-topshirish-zharaeni-text355">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-topshirish-zharaeni-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-topshirish-zharaeni-dropdown-toggle77"
                    >
                      <span className="ariza-topshirish-zharaeni-text356">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="ariza-topshirish-zharaeni-container39">
        <div className="ariza-topshirish-zharaeni-container40">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="ariza-topshirish-zharaeni-image20"
          />
          <span className="ariza-topshirish-zharaeni-text359">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="ariza-topshirish-zharaeni-container41">
          <div className="ariza-topshirish-zharaeni-container42">
            <span className="ariza-topshirish-zharaeni-text360">
              Ходимларни бошқариш
            </span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="ariza-topshirish-zharaeni-image21"
            />
          </div>
          <div className="ariza-topshirish-zharaeni-container43">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="ariza-topshirish-zharaeni-navlink80"
            >
              <div className="ariza-topshirish-zharaeni-container44">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ariza-topshirish-zharaeni-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ariza-topshirish-zharaeni-text361">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="ariza-topshirish-zharaeni-navlink81"
            >
              <div className="ariza-topshirish-zharaeni-container45">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ariza-topshirish-zharaeni-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ariza-topshirish-zharaeni-text362">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="ariza-topshirish-zharaeni-navlink82"
            >
              <div className="ariza-topshirish-zharaeni-container46">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ariza-topshirish-zharaeni-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ariza-topshirish-zharaeni-text366">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="ariza-topshirish-zharaeni-navlink83"
            >
              <div className="ariza-topshirish-zharaeni-container47">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ariza-topshirish-zharaeni-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ariza-topshirish-zharaeni-text370">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="ariza-topshirish-zharaeni-navlink84"
            >
              <div className="ariza-topshirish-zharaeni-container48">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ariza-topshirish-zharaeni-icon44"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ariza-topshirish-zharaeni-text374">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="ariza-topshirish-zharaeni-container49">
        <div className="ariza-topshirish-zharaeni-container50">
          <span className="ariza-topshirish-zharaeni-text378">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="ariza-topshirish-zharaeni-container51">
            <Link
              to="/visametric-gurukhi"
              className="ariza-topshirish-zharaeni-navlink85"
            >
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="ariza-topshirish-zharaeni-navlink86"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/w3c-logo2-200h.png"
          className="ariza-topshirish-zharaeni-image22"
        />
        <a href="#main" className="ariza-topshirish-zharaeni-link">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="ariza-topshirish-zharaeni-image23"
          />
        </a>
      </div>
    </div>
  )
}

export default ArizaTopshirishZharaeni
