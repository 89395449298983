import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './callcenter.css'

const Callcenter = (props) => {
  return (
    <div className="callcenter-container10">
      <Helmet>
        <title>callcenter - Visametric</title>
        <meta property="og:title" content="callcenter - Visametric" />
      </Helmet>
      <div data-thq="thq-burger-menu" className="callcenter-burger-menu1">
        <svg viewBox="0 0 1024 1024" className="callcenter-icon10">
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div id="main" className="callcenter-container11">
        <h1 className="callcenter-text100">Алоқа маркази</h1>
        <div className="callcenter-container12">
          <Link to="/uzbekistan-germanyuz" className="callcenter-navlink10">
            <div className="callcenter-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="callcenter-icon12"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="callcenter-text101">Бош саҳифа</h1>
            </div>
          </Link>
          <Link to="/uzbekistan-germanyuz" className="callcenter-navlink11">
            <div className="callcenter-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="callcenter-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="callcenter-text102">Алоқалар</h1>
            </div>
          </Link>
          <Link to="/uzbekistan-germanyuz" className="callcenter-navlink12">
            <div className="callcenter-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="callcenter-icon16"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="callcenter-text103">Алоқа маркази</h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="callcenter-container16">
        <div className="callcenter-container17">
          <div className="callcenter-container18">
            <span className="callcenter-text104">Алоқалар</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="callcenter-icon18"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="callcenter-image10"
          />
          <div className="callcenter-container19">
            <Link to="/office" className="callcenter-navlink13">
              <div className="callcenter-container20">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="callcenter-image11"
                />
                <span className="callcenter-text105">
                  VisaMetric филиаллари
                </span>
              </div>
            </Link>
            <Link to="/callcenter" className="callcenter-navlink14">
              <div className="callcenter-container21">
                <img
                  alt="image"
                  src="/rectangle%2030.svg"
                  className="callcenter-image12"
                />
                <span className="callcenter-text106">Алоқа маркази</span>
              </div>
            </Link>
            <Link to="/contact" className="callcenter-navlink15">
              <div className="callcenter-container22">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="callcenter-image13"
                />
                <span className="callcenter-text107">Муштарий Анкетаси</span>
              </div>
            </Link>
            <Link to="/contact-form" className="callcenter-navlink16">
              <div className="callcenter-container23">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="callcenter-image14"
                />
                <span className="callcenter-text108">Қайта алока шакли</span>
              </div>
            </Link>
          </div>
        </div>
        <div className="callcenter-container24">
          <a
            href="https://ais.usvisa-info.com/en-uz/niv/information/contact_us"
            target="_blank"
            rel="noreferrer noopener"
            className="callcenter-link1"
          >
            <span className="callcenter-text109">Алоқа маркази</span>
            <br></br>
            <br></br>
            <span>
              VisaMetric офисларининг иш вақти ва алоқага чиқиш ҳақида
              маълумотлар:
            </span>
            <br></br>
            <br></br>
            <span className="callcenter-text115">
              Bizning qo‘ng‘iroqlar markazimiz faqat Germaniyaga viza arizalari
              bo‘yicha ma&apos;lumot beradi.
            </span>
            <br className="callcenter-text116"></br>
            <span className="callcenter-text117">
              AQSh uchun viza arizalari bo&apos;yicha maslahatlar uchun quyidagi
              havolada keltirilgan aloqa ma&apos;lumotlaridan foydalaning:
            </span>
            <span className="callcenter-text118">
              {' '}
              https://ais.usvisa-info.com/en-uz/niv/information/contact_us
            </span>
            <br></br>
            <br></br>
            <br></br>
            <span className="callcenter-text122">
              Телефон:
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
                                                       +998 (78) 120-09-93
            </span>
            <br></br>
            <br></br>
            <span className="callcenter-text126">
              Иш вақти (душанба-жума):  
            </span>
            <span>      09:00 - 18:00</span>
            <br className="callcenter-text128"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </a>
        </div>
      </div>
      <div id="header" className="callcenter-container25">
        <Link to="/" className="callcenter-navlink17">
          <div className="callcenter-container26">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="callcenter-image15"
            />
            <p className="callcenter-text130">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="callcenter-container27">
          <div className="callcenter-container28">
            <span className="callcenter-text133">
              <span>Apply for Visa to</span>
              <span className="callcenter-text135"> Germany</span>
            </span>
            <span className="callcenter-text136">
              <span className="callcenter-text137">in Uzbekistan</span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="callcenter-image16"
          />
          <span className="callcenter-text168">|</span>
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header data-thq="thq-navbar" className="callcenter-navbar-interactive">
        <div data-thq="thq-navbar-nav" className="callcenter-desktop-menu">
          <div className="callcenter-container29">
            <nav className="callcenter-links1">
              <div
                data-thq="thq-dropdown"
                className="callcenter-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="callcenter-dropdown-toggle10"
                  >
                    <span className="callcenter-text169">Бош сахифа</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="callcenter-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="callcenter-dropdown-toggle11"
                >
                  <span className="callcenter-text170">Умумий</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="callcenter-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="callcenter-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle12"
                      >
                        <span className="callcenter-text171 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle13"
                      >
                        <span className="callcenter-text174 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle14"
                      >
                        <span className="callcenter-text175 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle15"
                      >
                        <span className="callcenter-text176 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle16"
                      >
                        <span className="callcenter-text181 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle17"
                      >
                        <span className="callcenter-text182 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle18"
                      >
                        <span className="callcenter-text183 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle19"
                      >
                        <span className="callcenter-text187 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="callcenter-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="callcenter-dropdown-toggle20"
                >
                  <span className="callcenter-text188">Виза олиш</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="callcenter-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="callcenter-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle21"
                      >
                        <span className="callcenter-text189">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle22"
                      >
                        <span className="callcenter-text190">Миллий виза</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle23"
                      >
                        <span className="callcenter-text191">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle24"
                      >
                        <span className="callcenter-text192">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle25"
                      >
                        <span className="callcenter-text195">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle26"
                      >
                        <span className="callcenter-text199">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle27"
                      >
                        <span className="callcenter-text200">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="callcenter-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="callcenter-dropdown-toggle28"
                >
                  <span className="callcenter-text201">Кушимча хизматлар</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="callcenter-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="callcenter-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle29"
                      >
                        <span className="callcenter-text202">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle30"
                      >
                        <span className="callcenter-text203">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle31"
                      >
                        <span className="callcenter-text207">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle32"
                      >
                        <span className="callcenter-text210">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle33"
                      >
                        <span className="callcenter-text211">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle34"
                      >
                        <span className="callcenter-text215">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle35"
                      >
                        <span className="callcenter-text216">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="callcenter-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="callcenter-dropdown-toggle38"
                  >
                    <span className="callcenter-text219">FAQ</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="callcenter-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="callcenter-dropdown-toggle39"
                >
                  <span className="callcenter-text220">Алокалар</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="callcenter-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="callcenter-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle40"
                      >
                        <span className="callcenter-text221">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle41"
                      >
                        <span className="callcenter-text222">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle42"
                      >
                        <span className="callcenter-text223">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="callcenter-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="callcenter-dropdown-toggle43"
                      >
                        <span className="callcenter-text224">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="callcenter-container30">
          <input
            type="text"
            placeholder="Кидирмок"
            className="callcenter-textinput1 input"
          />
          <div className="callcenter-container31">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="callcenter-icon20"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="callcenter-container32">
            <span className="callcenter-text227">A+</span>
            <span className="callcenter-text228">A-</span>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="callcenter-burger-menu2">
          <svg viewBox="0 0 1024 1024" className="callcenter-icon22">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="callcenter-mobile-menu">
          <div className="callcenter-nav">
            <div className="callcenter-container33">
              <div className="callcenter-container34">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="callcenter-textinput2 input"
                />
                <div className="callcenter-container35">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="callcenter-icon24"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="callcenter-container36">
                  <span className="callcenter-text229">A+</span>
                  <span className="callcenter-text230">A-</span>
                </div>
              </div>
              <div data-thq="thq-close-menu" className="callcenter-close-menu">
                <svg viewBox="0 0 1024 1024" className="callcenter-icon26">
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="callcenter-links2">
            <div
              data-thq="thq-dropdown"
              className="callcenter-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="callcenter-dropdown-toggle44"
                >
                  <span className="callcenter-text231">Бош сахифа</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="callcenter-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="callcenter-dropdown-toggle45"
              >
                <span className="callcenter-text232">Умумий</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="callcenter-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="callcenter-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="callcenter-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle46"
                    >
                      <span className="callcenter-text233">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle47"
                    >
                      <span className="callcenter-text236">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle48"
                    >
                      <span className="callcenter-text237">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle49"
                    >
                      <span className="callcenter-text238">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle50"
                    >
                      <span className="callcenter-text243">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle51"
                    >
                      <span className="callcenter-text244">Тулов Шартлари</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle52"
                    >
                      <span className="callcenter-text245">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle53"
                    >
                      <span className="callcenter-text248">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="callcenter-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="callcenter-dropdown-toggle54"
              >
                <span className="callcenter-text249">Виза олиш</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="callcenter-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="callcenter-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="callcenter-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle55"
                    >
                      <span className="callcenter-text250">Шенген визаси</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle56"
                    >
                      <span className="callcenter-text251">Миллий виза</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle57"
                    >
                      <span className="callcenter-text252">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle58"
                    >
                      <span className="callcenter-text253">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle59"
                    >
                      <span className="callcenter-text256">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle60"
                    >
                      <span className="callcenter-text260">Ариза шакллари</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle61"
                    >
                      <span className="callcenter-text261">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="callcenter-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="callcenter-dropdown-toggle62"
              >
                <span className="callcenter-text262">Кушимча хизматлар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="callcenter-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="callcenter-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="callcenter-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle63"
                    >
                      <span className="callcenter-text263">VIP - хизмати</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle64"
                    >
                      <span className="callcenter-text264">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle65"
                    >
                      <span className="callcenter-text265">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle66"
                    >
                      <span className="callcenter-text266">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle67"
                    >
                      <span className="callcenter-text267">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle68"
                    >
                      <span className="callcenter-text270">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle69"
                    >
                      <span className="callcenter-text273">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle70"
                    >
                      <span className="callcenter-text274">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle71"
                    >
                      <span className="callcenter-text275">Prime Тиме</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="callcenter-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="callcenter-dropdown-toggle72"
                >
                  <span className="callcenter-text276">FAQ</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="callcenter-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="callcenter-dropdown-toggle73"
              >
                <span className="callcenter-text277">Алокалар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="callcenter-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="callcenter-icon34"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="callcenter-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle74"
                    >
                      <span className="callcenter-text278">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle75"
                    >
                      <span className="callcenter-text279">Алока маркази</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle76"
                    >
                      <span className="callcenter-text280">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="callcenter-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="callcenter-dropdown-toggle77"
                    >
                      <span className="callcenter-text281">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="callcenter-container37">
        <div className="callcenter-container38">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="callcenter-image18"
          />
          <span className="callcenter-text284">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="callcenter-container39">
          <div className="callcenter-container40">
            <span className="callcenter-text285">Ходимларни бошқариш</span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="callcenter-image19"
            />
          </div>
          <div className="callcenter-container41">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="callcenter-navlink78"
            >
              <div className="callcenter-container42">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="callcenter-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="callcenter-text286">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="callcenter-navlink79"
            >
              <div className="callcenter-container43">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="callcenter-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="callcenter-text287">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="callcenter-navlink80"
            >
              <div className="callcenter-container44">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="callcenter-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="callcenter-text291">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="callcenter-navlink81"
            >
              <div className="callcenter-container45">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="callcenter-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="callcenter-text295">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="callcenter-navlink82"
            >
              <div className="callcenter-container46">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="callcenter-icon44"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="callcenter-text299">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="callcenter-container47">
        <div className="callcenter-container48">
          <span className="callcenter-text303">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="callcenter-container49">
            <Link to="/visametric-gurukhi" className="callcenter-navlink83">
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="callcenter-navlink84"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/w3c-logo2-200h.png"
          className="callcenter-image20"
        />
        <a href="#main" className="callcenter-link2">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="callcenter-image21"
          />
        </a>
      </div>
    </div>
  )
}

export default Callcenter
