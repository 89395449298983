import React from 'react'

import { Helmet } from 'react-helmet'

import './hengen-vizasi.css'

const HengenVizasi = (props) => {
  return (
    <div className="hengen-vizasi-container">
      <Helmet>
        <title>hengen-vizasi - Visametric</title>
        <meta property="og:title" content="hengen-vizasi - Visametric" />
      </Helmet>
    </div>
  )
}

export default HengenVizasi
