import React from 'react'
import { Link } from 'react-router-dom'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import './tlov-shartlari.css'

const TlovShartlari = (props) => {
  return (
    <div className="tlov-shartlari-container10">
      <Helmet>
        <title>tlov-shartlari - Visametric</title>
        <meta property="og:title" content="tlov-shartlari - Visametric" />
      </Helmet>
      <div data-thq="thq-burger-menu" className="tlov-shartlari-burger-menu1">
        <svg viewBox="0 0 1024 1024" className="tlov-shartlari-icon10">
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div id="main" className="tlov-shartlari-container11">
        <h1 className="tlov-shartlari-text100">Тўлов Шартлари</h1>
        <div className="tlov-shartlari-container12">
          <Link to="/uzbekistan-germanyuz" className="tlov-shartlari-navlink10">
            <div className="tlov-shartlari-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="tlov-shartlari-icon12"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="tlov-shartlari-text101">Бош саҳифа</h1>
            </div>
          </Link>
          <Link to="/uzbekistan-germanyuz" className="tlov-shartlari-navlink11">
            <div className="tlov-shartlari-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="tlov-shartlari-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="tlov-shartlari-text102">Умумий</h1>
            </div>
          </Link>
          <Link to="/uzbekistan-germanyuz" className="tlov-shartlari-navlink12">
            <div className="tlov-shartlari-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="tlov-shartlari-icon16"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="tlov-shartlari-text103">Тўлов Шартлари</h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="tlov-shartlari-container16">
        <div className="tlov-shartlari-container17">
          <div className="tlov-shartlari-container18">
            <span className="tlov-shartlari-text104">Умумий</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="tlov-shartlari-icon18"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="tlov-shartlari-image10"
          />
          <div className="tlov-shartlari-container19">
            <Link
              to="/submission-collection"
              className="tlov-shartlari-navlink13"
            >
              <div className="tlov-shartlari-container20">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="tlov-shartlari-image11"
                />
                <span className="tlov-shartlari-text105">
                  Ҳужжатларни топшириш/Ҳужжатларни олиш
                </span>
              </div>
            </Link>
            <Link
              to="/tegishli-malumotlar"
              className="tlov-shartlari-navlink14"
            >
              <div className="tlov-shartlari-container21">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="tlov-shartlari-image12"
                />
                <span className="tlov-shartlari-text106">
                  Тегишли маълумотлар
                </span>
              </div>
            </Link>
            <Link
              to="/rasmiy-bayramlar-kuni"
              className="tlov-shartlari-navlink15"
            >
              <div className="tlov-shartlari-container22">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="tlov-shartlari-image13"
                />
                <span className="tlov-shartlari-text107">
                  Расмий байрамлар куни
                </span>
              </div>
            </Link>
            <Link
              to="/arizalarni-krib-chiish-muddati"
              className="tlov-shartlari-navlink16"
            >
              <div className="tlov-shartlari-container23">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="tlov-shartlari-image14"
                />
                <span className="tlov-shartlari-text108">
                  Аризаларни кўриб чиқиш муддати
                </span>
              </div>
            </Link>
            <Link
              to="/biometrik-malumotlarini"
              className="tlov-shartlari-navlink17"
            >
              <div className="tlov-shartlari-container24">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="tlov-shartlari-image15"
                />
                <span className="tlov-shartlari-text109">
                  биометрик маълумотларини
                </span>
              </div>
            </Link>
            <Link to="/tlov-shartlari" className="tlov-shartlari-navlink18">
              <div className="tlov-shartlari-container25">
                <img
                  alt="image"
                  src="/rectangle%2030.svg"
                  className="tlov-shartlari-image16"
                />
                <span className="tlov-shartlari-text110">Тўлов Шартлари</span>
              </div>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="tlov-shartlari-navlink19"
            >
              <div className="tlov-shartlari-container26">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="tlov-shartlari-image17"
                />
                <span className="tlov-shartlari-text111">
                  Техника хавфсизлиги кўрсатмалари
                </span>
              </div>
            </Link>
            <Link
              to="/foydali-avolalar-uz"
              className="tlov-shartlari-navlink20"
            >
              <div className="tlov-shartlari-container27">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="tlov-shartlari-image18"
                />
                <span className="tlov-shartlari-text112">
                  Фойдали ҳаволалар
                </span>
              </div>
            </Link>
          </div>
        </div>
        <div className="tlov-shartlari-container28">
          <span className="tlov-shartlari-text113">
            <span>Консуллик тўлови ва VisaMetric xизмат кўрсатиш тўлови</span>
            <br></br>
          </span>
          <span className="tlov-shartlari-text116">
            <span className="tlov-shartlari-text117">
              Қуйидаги консуллик тўлови ва VisaMetric хизмат кўрсатиш тўловлари
              ҳақида баътафсил маълумотлар келтирилган
            </span>
            <br></br>
            <br></br>
            <span>•  </span>
            <span>
              VisaMetric офисларидаги хизмат кўрсатиш тўловларилари ва қўшимча
              хизмат тўлови Ўзбекистон миллий валютаси сўм (UZS)да тўланади.
              VisaMetric офисларида терминал ёки нақд пул орқали тўловини амалга
              оширишингиз мумкин.
            </span>
            <br></br>
            <br></br>
            <span>•  </span>
            <span>
              Виза тўлови сўм (UZS) шаклда кунлик валюта айирбошлаш курсига кўра
              тўланади ва ҳохлаган банк филиалида тўловни амалга ошириш мумкин. 
            </span>
            <br></br>
            <br></br>
            <br></br>
            <span className="tlov-shartlari-text129">
              Қуйидаги шахслар тегишли тасдиқловчи ҳужжатларни тақдим қилган
              ҳолда консуллик тўловидан озод қилинади:
            </span>
            <br className="tlov-shartlari-text130"></br>
            <br></br>
            <span>•  </span>
            <span>6 ёшгача бўлган болалар;</span>
            <br></br>
            <br></br>
            <span>•  </span>
            <span>Евроиттифоқ фуқароларининг эр-хотин ва болалари;</span>
            <br></br>
            <br></br>
            <span>•  </span>
            <span>Дипломатик паспорт эгалари;</span>
            <br></br>
            <br></br>
            <span>•  </span>
            <span>
              Германия жамғармаларидан таклифнома олганлар: Fridrix Ebert
              Stiftung (FES) va Konrad Adenauer Stiftung (KAS);
            </span>
            <br></br>
            <br></br>
            <span>•  </span>
            <span>
              Таълим дастурларида иштирок этиш учун таклиф этилган аризачилар:
              Немис Халқаро Хамкорлик Жамияти (GIZ), Гётэ Институти, PASCH  
              Хамкорлар Мактаби, Хорижда Мененджментнинг Марказий Мактаби (ZfA),
              Германия Академик алмашув хизмати (DAAD);
            </span>
            <br></br>
            <br></br>
            <span>•  </span>
            <span>
              Erasmus Mundus, Erasmus +, EXHT (EXHT) халқаро жамғармалари ва
              ташкилотлари таклифларини қабул қилган ҳамда Германия, Бирлашган
              Миллатлар Ташкилоти;
            </span>
            <br></br>
            <br></br>
            <span>•  </span>
            <span>
              Германияда ўтказилган халқаро кўргазмалар иштирокчилари (фақат)
              стенд учун тасдиқланган тўловлари.
            </span>
          </span>
          <div className="tlov-shartlari-container29">
            <div className="tlov-shartlari-container30">
              <div className="tlov-shartlari-container31">
                <Script
                  html={`<table height="157" style="border-collapse:collapse;border-spacing:1px;border:0px none;width:100%;" width="731">
	<tbody>
		<tr>
			<th colspan="4" style="background-color: rgb(204, 0, 0); width: 36%;">
			<p><span style="color:#ffffff;"><strong>Виза консуллик тўлови – Шенген Визаси (ўзбек сўмида нақд ёки банк пластик картаси орқали)</strong></span></p>
			</th>
		</tr>
		<tr>
			<th style="background-color: rgb(68, 68, 68); width: 36%;">
			<p class="rte__list-item"> </p>
			</th>
			<td style="background-color: rgb(153, 153, 153); width: 15%; text-align: center;"><span style="color:#ffffff;"><strong>0 - 6 ёш</strong></span></td>
			<td style="background-color: rgb(153, 153, 153); width: 15%; text-align: center;"><span style="color:#ffffff;"><strong>6-12 ёш</strong></span></td>
			<td style="background-color: rgb(153, 153, 153); width: 15%; text-align: center;">
			<p><span style="color:#ffffff;"><strong>12+ ёш</strong></span></p>
			</td>
		</tr>
		<tr>
			<td colspan="1" rowspan="1" style="background-color: rgb(204, 204, 204); text-align: center;">
			<p><b>Ўзбекистон Фуқароси</b></p>
			</td>
			<td style="text-align: center; background-color: rgb(238, 238, 238);">0 €</td>
			<td style="text-align: center; background-color: rgb(238, 238, 238);">639 000 UZS (45 €)</td>
			<td style="text-align: center; background-color: rgb(238, 238, 238);">1 277 000 UZS (90 €)</td>
		</tr>
		<tr>
			<td colspan="1" rowspan="1" style="background-color: rgb(204, 204, 204); text-align: center;"><b>Бошқа давлат фукаролари</b></td>
			<td style="text-align: center; background-color: rgb(238, 238, 238);">0 €</td>
			<td style="text-align: center; background-color: rgb(238, 238, 238);">639 000 UZS (45 €)</td>
			<td style="text-align: center; background-color: rgb(238, 238, 238);">1 277 000 UZS (90 €)</td>
		</tr>
		<tr>
			<td colspan="1" rowspan="1" style="background-color: rgb(204, 204, 204); text-align: center;"><strong>Шенген визасини олишда қуйидаги мамлакатлар фуқаролари консуллик тўловидан озод этилади: </strong>(фуқоролиги Албания, Арманистон, Озорбойжон, Белоруссия, Босния, Грузия, Кабо-Верде, Македония, Молдова, Черногория, Сербия, Украина)</td>
			<td style="text-align: center; background-color: rgb(238, 238, 238);">0 €</td>
			<td style="text-align: center; background-color: rgb(238, 238, 238);">497 000 UZS (35 €)</td>
			<td style="text-align: center; background-color: rgb(238, 238, 238);">497 000 UZS (35 €)</td>
		</tr>
	</tbody>
</table>`}
                ></Script>
              </div>
            </div>
            <div className="tlov-shartlari-container32">
              <div className="tlov-shartlari-container33">
                <Script
                  html={`<table height="112" style="border-collapse:collapse;border-spacing:1px;border:0px none;width:100%;" width="691">
	<tbody>
		<tr>
			<th colspan="4" style="background-color: rgb(204, 0, 0); width: 40%; height: 40px;">
			<p><span style="color:#ffffff;"><strong>VisaMetric хизматлари учун тўланадиган тўлов – Шенген Визаси (ўзбек сўмида нақд ёки банк пластик картаси орқали)</strong></span></p>
			</th>
		</tr>
		<tr>
			<td style="background-color: rgb(204, 204, 204);"><strong>Хизмат кўрсатиш</strong></td>
			<td colspan="3" rowspan="1" style="text-align: center; background-color: rgb(238, 238, 238);">360 000 UZS (25,31 €)</td>
		</tr>
	</tbody>
</table>`}
                ></Script>
              </div>
            </div>
          </div>
          <div className="tlov-shartlari-container34">
            <div className="tlov-shartlari-container35">
              <div className="tlov-shartlari-container36">
                <Script
                  html={`<table height="112" style="border-collapse:collapse;border-spacing:1px;border:0px none;width:100%;" width="691">
	<tbody>
		<tr>
			<th colspan="5" style="background-color: rgb(204, 0, 0); width: 40%; height: 40px;">
			<p><span style="color:#ffffff;"><strong>Виза консуллик тўлови – Миллий виза (ўзбек сўмида нақд ёки банк пластик картаси орқали)</strong></span></p>
			</th>
		</tr>
		<tr>
			<td style="background-color: rgb(68, 68, 68);"> </td>
			<td colspan="2" style="text-align: center; background-color: rgb(204, 204, 204);"><span style="color:#ffffff;"><strong>0 - 18 ёш</strong></span></td>
			<td colspan="2" style="text-align: center; background-color: rgb(204, 204, 204);"><span style="color:#ffffff;"><strong>18+ ёш</strong></span></td>
		</tr>
		<tr>
			<td style="background-color: rgb(204, 204, 204);"><strong>Миллий виза</strong></td>
			<td colspan="2" style="text-align: center; background-color: rgb(238, 238, 238);">533 000 UZS (37,5 €)</td>
			<td colspan="2" style="text-align: center; background-color: rgb(238, 238, 238);">1 065 000 UZS (75 €)</td>
		</tr>
	</tbody>
</table>`}
                ></Script>
              </div>
            </div>
            <div className="tlov-shartlari-container37">
              <div className="tlov-shartlari-container38">
                <Script
                  html={`<table height="112" style="border-collapse:collapse;border-spacing:1px;border:0px none;width:100%;" width="691">
	<tbody>
		<tr>
			<th colspan="4" style="background-color: rgb(204, 0, 0); width: 40%; height: 40px;">
			<p><span style="color:#ffffff;"><strong>VisaMetric хизматлари учун тўланадиган тўлов – Миллий виза (ўзбек сўмида нақд ёки банк пластик картаси орқали)</strong></span></p>
			</th>
		</tr>
		<tr>
			<td style="background-color: rgb(204, 204, 204);"><strong>Хизмат кўрсатиш</strong></td>
			<td colspan="3" style="text-align: center; background-color: rgb(238, 238, 238);">409 000 UZS (28,81 €)</td>
		</tr>
	</tbody>
</table>`}
                ></Script>
              </div>
            </div>
          </div>
          <div className="tlov-shartlari-container39">
            <div className="tlov-shartlari-container40">
              <Script
                html={`<table height="237" style="border-collapse:collapse;border-spacing:1px;border:0px none;width:100%;" width="736">
	<tbody>
		<tr>
			<th colspan="3" style="background-color: rgb(204, 0, 0); width: 40%; height: 40px;">
			<p><strong><span style="color:#ffffff;">Қушимча хизматлар учун (ўзбек сўмида нақд ёки банк пластик картаси орқали)</span></strong></p>
			</th>
		</tr>
		<tr>
			<td style="background-color: rgb(204, 204, 204);"><b>Биометрик фотография</b></td>
			<td colspan="2" rowspan="1" style="text-align: center; background-color: rgb(238, 238, 238);">86 000 UZS (6 €) бир киши учун</td>
		</tr>
		<tr>
			<td style="background-color: rgb(204, 204, 204);"><b>Анкетани тўлдириш хизмати</b></td>
			<td colspan="2" style="text-align: center; background-color: rgb(238, 238, 238);">86 000 UZS (6 €) бир киши учун</td>
		</tr>
		<tr>
			<td style="background-color: rgb(204, 204, 204);"><b>Нусха олиш, факс, қоғозга печат қилиш хизматлари</b></td>
			<td colspan="2" rowspan="1" style="text-align: center; background-color: rgb(238, 238, 238);">5 000 UZS (30 центов) бир вароғи</td>
		</tr>
		<tr>
			<td style="background-color: rgb(204, 204, 204);"><b>Хужжатларни таржима қилиш хизмати</b></td>
			<td colspan="2" style="text-align: center; background-color: rgb(238, 238, 238);">142 000 UZS (10 €) бир вароғи</td>
		</tr>
		<tr>
			<td style="background-color: rgb(204, 204, 204);"><b>СМС </b></td>
			<td colspan="2" rowspan="1" style="text-align: center; background-color: rgb(238, 238, 238);">15 000 UZS (1 €) смс</td>
		</tr>
		<tr>
			<td style="background-color: rgb(204, 204, 204);"><b>Электрон почта</b></td>
			<td colspan="2" style="text-align: center; background-color: rgb(238, 238, 238);">Хизмат бепул</td>
		</tr>
		<tr>
			<td style="background-color: rgb(204, 204, 204);"><b>Телефон</b></td>
			<td colspan="2" rowspan="1" style="text-align: center; background-color: rgb(238, 238, 238);">29 000 UZS (2 €) қўнғироқ учун</td>
		</tr>
		<tr>
			<td style="background-color: rgb(204, 204, 204);"><b>Тиббий суғурта </b></td>
			<td colspan="2" style="text-align: center; background-color: rgb(238, 238, 238);">Ўрнатилган тарифга асосан</td>
		</tr>
		<tr>
			<td style="background-color:#cccccc;"><b>VIP</b></td>
			<td colspan="2" style="text-align: center; background-color: rgb(238, 238, 238);">1 065 000 UZS (75 €)</td>
		</tr>
		<tr>
			<td style="background-color:#cccccc;"><b>PRIME - TIME</b></td>
			<td colspan="2" style="text-align: center; background-color: rgb(238, 238, 238);">568 000 UZS (40 €)</td>
		</tr>
		<tr>
			<td style="background-color:#cccccc;"><b>Курьерлик хизмати орқали етказиб бериш</b></td>
			<td colspan="2" style="background-color:#eeeeee;text-align:center;">142,000 uzs (10 euro ) Бир киши учун</td>
		</tr>
	</tbody>
</table>`}
              ></Script>
            </div>
          </div>
        </div>
      </div>
      <div id="header" className="tlov-shartlari-container41">
        <Link to="/" className="tlov-shartlari-navlink21">
          <div className="tlov-shartlari-container42">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="tlov-shartlari-image19"
            />
            <p className="tlov-shartlari-text158">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="tlov-shartlari-container43">
          <div className="tlov-shartlari-container44">
            <span className="tlov-shartlari-text161">
              <span>Apply for Visa to</span>
              <span className="tlov-shartlari-text163"> Germany</span>
            </span>
            <span className="tlov-shartlari-text164">
              <span className="tlov-shartlari-text165">in Uzbekistan</span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="tlov-shartlari-image20"
          />
          <span className="tlov-shartlari-text196">|</span>
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header
        data-thq="thq-navbar"
        className="tlov-shartlari-navbar-interactive"
      >
        <div data-thq="thq-navbar-nav" className="tlov-shartlari-desktop-menu">
          <div className="tlov-shartlari-container45">
            <nav className="tlov-shartlari-links1">
              <div
                data-thq="thq-dropdown"
                className="tlov-shartlari-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="tlov-shartlari-dropdown-toggle10"
                  >
                    <span className="tlov-shartlari-text197">Бош сахифа</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="tlov-shartlari-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="tlov-shartlari-dropdown-toggle11"
                >
                  <span className="tlov-shartlari-text198">Умумий</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="tlov-shartlari-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="tlov-shartlari-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle12"
                      >
                        <span className="tlov-shartlari-text199 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle13"
                      >
                        <span className="tlov-shartlari-text202 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle14"
                      >
                        <span className="tlov-shartlari-text203 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle15"
                      >
                        <span className="tlov-shartlari-text204 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle16"
                      >
                        <span className="tlov-shartlari-text209 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle17"
                      >
                        <span className="tlov-shartlari-text210 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle18"
                      >
                        <span className="tlov-shartlari-text211 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle19"
                      >
                        <span className="tlov-shartlari-text215 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="tlov-shartlari-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="tlov-shartlari-dropdown-toggle20"
                >
                  <span className="tlov-shartlari-text216">Виза олиш</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="tlov-shartlari-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="tlov-shartlari-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle21"
                      >
                        <span className="tlov-shartlari-text217">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle22"
                      >
                        <span className="tlov-shartlari-text218">
                          Миллий виза
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle23"
                      >
                        <span className="tlov-shartlari-text219">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle24"
                      >
                        <span className="tlov-shartlari-text220">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle25"
                      >
                        <span className="tlov-shartlari-text223">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle26"
                      >
                        <span className="tlov-shartlari-text227">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle27"
                      >
                        <span className="tlov-shartlari-text228">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="tlov-shartlari-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="tlov-shartlari-dropdown-toggle28"
                >
                  <span className="tlov-shartlari-text229">
                    Кушимча хизматлар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="tlov-shartlari-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="tlov-shartlari-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle29"
                      >
                        <span className="tlov-shartlari-text230">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle30"
                      >
                        <span className="tlov-shartlari-text231">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle31"
                      >
                        <span className="tlov-shartlari-text235">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle32"
                      >
                        <span className="tlov-shartlari-text238">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle33"
                      >
                        <span className="tlov-shartlari-text239">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle34"
                      >
                        <span className="tlov-shartlari-text243">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle35"
                      >
                        <span className="tlov-shartlari-text244">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="tlov-shartlari-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="tlov-shartlari-dropdown-toggle38"
                  >
                    <span className="tlov-shartlari-text247">FAQ</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="tlov-shartlari-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="tlov-shartlari-dropdown-toggle39"
                >
                  <span className="tlov-shartlari-text248">Алокалар</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="tlov-shartlari-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="tlov-shartlari-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle40"
                      >
                        <span className="tlov-shartlari-text249">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle41"
                      >
                        <span className="tlov-shartlari-text250">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle42"
                      >
                        <span className="tlov-shartlari-text251">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tlov-shartlari-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tlov-shartlari-dropdown-toggle43"
                      >
                        <span className="tlov-shartlari-text252">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="tlov-shartlari-container46">
          <input
            type="text"
            placeholder="Кидирмок"
            className="tlov-shartlari-textinput1 input"
          />
          <div className="tlov-shartlari-container47">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="tlov-shartlari-icon20"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="tlov-shartlari-container48">
            <span className="tlov-shartlari-text255">A+</span>
            <span className="tlov-shartlari-text256">A-</span>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="tlov-shartlari-burger-menu2">
          <svg viewBox="0 0 1024 1024" className="tlov-shartlari-icon22">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="tlov-shartlari-mobile-menu">
          <div className="tlov-shartlari-nav">
            <div className="tlov-shartlari-container49">
              <div className="tlov-shartlari-container50">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="tlov-shartlari-textinput2 input"
                />
                <div className="tlov-shartlari-container51">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="tlov-shartlari-icon24"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="tlov-shartlari-container52">
                  <span className="tlov-shartlari-text257">A+</span>
                  <span className="tlov-shartlari-text258">A-</span>
                </div>
              </div>
              <div
                data-thq="thq-close-menu"
                className="tlov-shartlari-close-menu"
              >
                <svg viewBox="0 0 1024 1024" className="tlov-shartlari-icon26">
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="tlov-shartlari-links2">
            <div
              data-thq="thq-dropdown"
              className="tlov-shartlari-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="tlov-shartlari-dropdown-toggle44"
                >
                  <span className="tlov-shartlari-text259">Бош сахифа</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="tlov-shartlari-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="tlov-shartlari-dropdown-toggle45"
              >
                <span className="tlov-shartlari-text260">Умумий</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="tlov-shartlari-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="tlov-shartlari-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="tlov-shartlari-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle46"
                    >
                      <span className="tlov-shartlari-text261">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle47"
                    >
                      <span className="tlov-shartlari-text264">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle48"
                    >
                      <span className="tlov-shartlari-text265">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle49"
                    >
                      <span className="tlov-shartlari-text266">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle50"
                    >
                      <span className="tlov-shartlari-text271">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle51"
                    >
                      <span className="tlov-shartlari-text272">
                        Тулов Шартлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle52"
                    >
                      <span className="tlov-shartlari-text273">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle53"
                    >
                      <span className="tlov-shartlari-text276">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="tlov-shartlari-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="tlov-shartlari-dropdown-toggle54"
              >
                <span className="tlov-shartlari-text277">Виза олиш</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="tlov-shartlari-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="tlov-shartlari-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="tlov-shartlari-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle55"
                    >
                      <span className="tlov-shartlari-text278">
                        Шенген визаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle56"
                    >
                      <span className="tlov-shartlari-text279">
                        Миллий виза
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle57"
                    >
                      <span className="tlov-shartlari-text280">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle58"
                    >
                      <span className="tlov-shartlari-text281">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle59"
                    >
                      <span className="tlov-shartlari-text284">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle60"
                    >
                      <span className="tlov-shartlari-text288">
                        Ариза шакллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle61"
                    >
                      <span className="tlov-shartlari-text289">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="tlov-shartlari-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="tlov-shartlari-dropdown-toggle62"
              >
                <span className="tlov-shartlari-text290">
                  Кушимча хизматлар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="tlov-shartlari-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="tlov-shartlari-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="tlov-shartlari-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle63"
                    >
                      <span className="tlov-shartlari-text291">
                        VIP - хизмати
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle64"
                    >
                      <span className="tlov-shartlari-text292">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle65"
                    >
                      <span className="tlov-shartlari-text293">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle66"
                    >
                      <span className="tlov-shartlari-text294">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle67"
                    >
                      <span className="tlov-shartlari-text295">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle68"
                    >
                      <span className="tlov-shartlari-text298">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle69"
                    >
                      <span className="tlov-shartlari-text301">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle70"
                    >
                      <span className="tlov-shartlari-text302">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle71"
                    >
                      <span className="tlov-shartlari-text303">Prime Тиме</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="tlov-shartlari-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="tlov-shartlari-dropdown-toggle72"
                >
                  <span className="tlov-shartlari-text304">FAQ</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="tlov-shartlari-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="tlov-shartlari-dropdown-toggle73"
              >
                <span className="tlov-shartlari-text305">Алокалар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="tlov-shartlari-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="tlov-shartlari-icon34"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="tlov-shartlari-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle74"
                    >
                      <span className="tlov-shartlari-text306">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle75"
                    >
                      <span className="tlov-shartlari-text307">
                        Алока маркази
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle76"
                    >
                      <span className="tlov-shartlari-text308">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tlov-shartlari-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tlov-shartlari-dropdown-toggle77"
                    >
                      <span className="tlov-shartlari-text309">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="tlov-shartlari-container53">
        <div className="tlov-shartlari-container54">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="tlov-shartlari-image22"
          />
          <span className="tlov-shartlari-text312">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="tlov-shartlari-container55">
          <div className="tlov-shartlari-container56">
            <span className="tlov-shartlari-text313">Ходимларни бошқариш</span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="tlov-shartlari-image23"
            />
          </div>
          <div className="tlov-shartlari-container57">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="tlov-shartlari-navlink82"
            >
              <div className="tlov-shartlari-container58">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="tlov-shartlari-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="tlov-shartlari-text314">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="tlov-shartlari-navlink83"
            >
              <div className="tlov-shartlari-container59">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="tlov-shartlari-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="tlov-shartlari-text315">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="tlov-shartlari-navlink84"
            >
              <div className="tlov-shartlari-container60">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="tlov-shartlari-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="tlov-shartlari-text319">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="tlov-shartlari-navlink85"
            >
              <div className="tlov-shartlari-container61">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="tlov-shartlari-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="tlov-shartlari-text323">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="tlov-shartlari-navlink86"
            >
              <div className="tlov-shartlari-container62">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="tlov-shartlari-icon44"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="tlov-shartlari-text327">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="tlov-shartlari-container63">
        <div className="tlov-shartlari-container64">
          <span className="tlov-shartlari-text331">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="tlov-shartlari-container65">
            <Link to="/visametric-gurukhi" className="tlov-shartlari-navlink87">
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="tlov-shartlari-navlink88"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/w3c-logo2-200h.png"
          className="tlov-shartlari-image24"
        />
        <a href="#main" className="tlov-shartlari-link">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="tlov-shartlari-image25"
          />
        </a>
      </div>
    </div>
  )
}

export default TlovShartlari
