import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './uchrashuv-sanasini-va-vatini-belgilash.css'

const UchrashuvSanasiniVaVatiniBelgilash = (props) => {
  return (
    <div className="uchrashuv-sanasini-va-vatini-belgilash-container10">
      <Helmet>
        <title>uchrashuv-sanasini-va-vatini-belgilash - Visametric</title>
        <meta
          property="og:title"
          content="uchrashuv-sanasini-va-vatini-belgilash - Visametric"
        />
      </Helmet>
      <div
        data-thq="thq-burger-menu"
        className="uchrashuv-sanasini-va-vatini-belgilash-burger-menu1"
      >
        <svg
          viewBox="0 0 1024 1024"
          className="uchrashuv-sanasini-va-vatini-belgilash-icon10"
        >
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div
        id="main"
        className="uchrashuv-sanasini-va-vatini-belgilash-container11"
      >
        <h1 className="uchrashuv-sanasini-va-vatini-belgilash-text100">
          Онлайн-сўровнома
        </h1>
        <div className="uchrashuv-sanasini-va-vatini-belgilash-container12">
          <Link
            to="/uzbekistan-germanyuz"
            className="uchrashuv-sanasini-va-vatini-belgilash-navlink10"
          >
            <div className="uchrashuv-sanasini-va-vatini-belgilash-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="uchrashuv-sanasini-va-vatini-belgilash-icon12"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="uchrashuv-sanasini-va-vatini-belgilash-text101">
                Бош саҳифа
              </h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="uchrashuv-sanasini-va-vatini-belgilash-navlink11"
          >
            <div className="uchrashuv-sanasini-va-vatini-belgilash-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="uchrashuv-sanasini-va-vatini-belgilash-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="uchrashuv-sanasini-va-vatini-belgilash-text102">
                Виза олиш
              </h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="uchrashuv-sanasini-va-vatini-belgilash-navlink12"
          >
            <div className="uchrashuv-sanasini-va-vatini-belgilash-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="uchrashuv-sanasini-va-vatini-belgilash-icon16"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="uchrashuv-sanasini-va-vatini-belgilash-text103">
                Учрашув санасини ва вақтини белгилаш
              </h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="uchrashuv-sanasini-va-vatini-belgilash-container16">
        <div className="uchrashuv-sanasini-va-vatini-belgilash-container17">
          <div className="uchrashuv-sanasini-va-vatini-belgilash-container18">
            <span className="uchrashuv-sanasini-va-vatini-belgilash-text104">
              Виза олиш
            </span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="uchrashuv-sanasini-va-vatini-belgilash-icon18"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="uchrashuv-sanasini-va-vatini-belgilash-image10"
          />
          <div className="uchrashuv-sanasini-va-vatini-belgilash-container19">
            <Link
              to="/shengen-vizasi"
              className="uchrashuv-sanasini-va-vatini-belgilash-navlink13"
            >
              <div className="uchrashuv-sanasini-va-vatini-belgilash-container20">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="uchrashuv-sanasini-va-vatini-belgilash-image11"
                />
                <span className="uchrashuv-sanasini-va-vatini-belgilash-text105">
                  Шенген визаси
                </span>
              </div>
            </Link>
            <Link
              to="/milliy-viza"
              className="uchrashuv-sanasini-va-vatini-belgilash-navlink14"
            >
              <div className="uchrashuv-sanasini-va-vatini-belgilash-container21">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="uchrashuv-sanasini-va-vatini-belgilash-image12"
                />
                <span className="uchrashuv-sanasini-va-vatini-belgilash-text106">
                  Миллий виза
                </span>
              </div>
            </Link>
            <Link
              to="/rasmiy-bayramlar-kuni"
              className="uchrashuv-sanasini-va-vatini-belgilash-navlink15"
            >
              <div className="uchrashuv-sanasini-va-vatini-belgilash-container22">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="uchrashuv-sanasini-va-vatini-belgilash-image13"
                />
                <span className="uchrashuv-sanasini-va-vatini-belgilash-text107">
                  Онлайн-сўровнома
                </span>
              </div>
            </Link>
            <Link
              to="/kerakli-uzhzhatlar"
              className="uchrashuv-sanasini-va-vatini-belgilash-navlink16"
            >
              <div className="uchrashuv-sanasini-va-vatini-belgilash-container23">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="uchrashuv-sanasini-va-vatini-belgilash-image14"
                />
                <span className="uchrashuv-sanasini-va-vatini-belgilash-text108">
                  Керакли ҳужжатлар
                </span>
              </div>
            </Link>
            <Link
              to="/uchrashuv-sanasini-va-vatini-belgilash"
              className="uchrashuv-sanasini-va-vatini-belgilash-navlink17"
            >
              <div className="uchrashuv-sanasini-va-vatini-belgilash-container24">
                <img
                  alt="image"
                  src="/rectangle%2030.svg"
                  className="uchrashuv-sanasini-va-vatini-belgilash-image15"
                />
                <span className="uchrashuv-sanasini-va-vatini-belgilash-text109">
                  Учрашув санасини ва вақтини белгилаш
                </span>
              </div>
            </Link>
            <Link
              to="/ariza-shakllari"
              className="uchrashuv-sanasini-va-vatini-belgilash-navlink18"
            >
              <div className="uchrashuv-sanasini-va-vatini-belgilash-container25">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="uchrashuv-sanasini-va-vatini-belgilash-image16"
                />
                <span className="uchrashuv-sanasini-va-vatini-belgilash-text110">
                  Ариза шакллари
                </span>
              </div>
            </Link>
            <Link
              to="/ilovani-kuzatish"
              className="uchrashuv-sanasini-va-vatini-belgilash-navlink19"
            >
              <div className="uchrashuv-sanasini-va-vatini-belgilash-container26">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="uchrashuv-sanasini-va-vatini-belgilash-image17"
                />
                <span className="uchrashuv-sanasini-va-vatini-belgilash-text111">
                  Иловани кузатиш
                </span>
              </div>
            </Link>
          </div>
        </div>
        <div className="uchrashuv-sanasini-va-vatini-belgilash-container27">
          <span className="uchrashuv-sanasini-va-vatini-belgilash-text112">
            <span>Ҳужжатларни тақдим етиш учун рўйхатдан ўтиш</span>
            <br></br>
          </span>
          <span className="uchrashuv-sanasini-va-vatini-belgilash-text115">
            <span>Ҳурматли аризачилар.</span>
            <br></br>
            <br></br>
            <span>
              Маълум қиламизки, Висаметрик визага мурожаат қилиш марказига
              учрашув кунини белгилаш учун имкон қадар визанинг тўғри турини
              танлаш жуда муҳим.
            </span>
            <br></br>
            <br></br>
            <span>
              Агар сизнинг сафарингиз
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="uchrashuv-sanasini-va-vatini-belgilash-text123">
              90 кундан кам булса
            </span>
            <span>
              , у ҳолда сиз
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="uchrashuv-sanasini-va-vatini-belgilash-text125">
              C тоифадаги виза
            </span>
            <span> учун ариза топширишингиз керак.</span>
            <br></br>
            <br></br>
            <span>
              Агар сизнинг сафарингиз
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="uchrashuv-sanasini-va-vatini-belgilash-text130">
              90 кундан ортиқ булса,
            </span>
            <span>
              {' '}
              у ҳолда сиз
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="uchrashuv-sanasini-va-vatini-belgilash-text132">
              Д тоифадаги виза учун
            </span>
            <span> ариза топширишингиз керак.</span>
            <br></br>
            <br></br>
            <span>
              Еътибор қаратишингизни сўраймиз,агарда сизнинг учрашувингиз
              нотўғти виза турида белгиланган бўлса, Висаметриc ходимлари
              сизнинг ҳужжатларингизни қаъбул қилиша олмайди.
            </span>
            <br></br>
            <br></br>
            <span>
              Бундай ҳолда, сизга саёҳат вақти ва сабабларига мос келадиган виза
              тури учун янги учрашув кунини белгилаш керак бўлади.
            </span>
            <br></br>
            <br></br>
            <span>
              Илтимос қилиб сўраб қоламизки, учрашув кунини белгилашдан олдин
              диққатлироқ бўлишингизни.
            </span>
          </span>
          <div className="uchrashuv-sanasini-va-vatini-belgilash-container28">
            <span className="uchrashuv-sanasini-va-vatini-belgilash-text143">
              Шенген визасига мурожаат қилиш учун руйхатдан ўтишдан олдин
              қуйидаги кўрсатмаларга еътибор беринг:
            </span>
            <div className="uchrashuv-sanasini-va-vatini-belgilash-container29">
              <span className="uchrashuv-sanasini-va-vatini-belgilash-text144">
                <span>
                  •  Шенген визаси учун сафарингиз бошланишидан 180 кун олдин
                  мурожаат қилишингиз мумкин.
                </span>
                <br></br>
                <br></br>
                <span>•  </span>
                <span>
                  Белгиланган санада барча керакли ҳужжатларни тақдим етинг.
                </span>
                <br></br>
                <br></br>
                <span>•  </span>
                <span>
                  Германияга виза олиш учун мурожаат қилмоқчи бўлган чет елда
                  таҳсил олаётган талабалар ўзлари ўқиётган мамлакатга мурожаат
                  қилишлари
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>керак.</span>
                <br></br>
                <br></br>
                <span>•  </span>
                <span>
                  Ўчрашувни банд қилиш учун тўлов олдиндан амалга оширилади.
                </span>
              </span>
              <button
                type="button"
                className="uchrashuv-sanasini-va-vatini-belgilash-button1 button"
              >
                <span className="uchrashuv-sanasini-va-vatini-belgilash-text160">
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>Учрашув санасини белгилаш</span>
                </span>
              </button>
            </div>
          </div>
          <div className="uchrashuv-sanasini-va-vatini-belgilash-container30">
            <span className="uchrashuv-sanasini-va-vatini-belgilash-text163">
              <span>
                Ҳужжат топшириш санасини белгилаш бўйича бошқа ҳаракатлар
              </span>
              <br></br>
            </span>
            <div className="uchrashuv-sanasini-va-vatini-belgilash-container31">
              <span className="uchrashuv-sanasini-va-vatini-belgilash-text166">
                <span>
                  Агар учрашув санасини белгилаган бўлсангиз, сиз қуйидаги
                  ўзгаришларни амалга оширишингиз мумкин:
                </span>
                <br></br>
                <br></br>
                <span>•  </span>
                <span>Учрашув санасини кўриш</span>
                <br></br>
                <br></br>
                <span>•  </span>
                <span>Учрашув санасини бекор қилиш</span>
                <br></br>
              </span>
              <button
                type="button"
                className="uchrashuv-sanasini-va-vatini-belgilash-button2 button"
              >
                <span className="uchrashuv-sanasini-va-vatini-belgilash-text177">
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>Бошқа операциялар</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        id="header"
        className="uchrashuv-sanasini-va-vatini-belgilash-container32"
      >
        <Link
          to="/"
          className="uchrashuv-sanasini-va-vatini-belgilash-navlink20"
        >
          <div className="uchrashuv-sanasini-va-vatini-belgilash-container33">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="uchrashuv-sanasini-va-vatini-belgilash-image18"
            />
            <p className="uchrashuv-sanasini-va-vatini-belgilash-text180">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="uchrashuv-sanasini-va-vatini-belgilash-container34">
          <div className="uchrashuv-sanasini-va-vatini-belgilash-container35">
            <span className="uchrashuv-sanasini-va-vatini-belgilash-text183">
              <span>Apply for Visa to</span>
              <span className="uchrashuv-sanasini-va-vatini-belgilash-text185">
                {' '}
                Germany
              </span>
            </span>
            <span className="uchrashuv-sanasini-va-vatini-belgilash-text186">
              <span className="uchrashuv-sanasini-va-vatini-belgilash-text187">
                in Uzbekistan
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="uchrashuv-sanasini-va-vatini-belgilash-image19"
          />
          <span className="uchrashuv-sanasini-va-vatini-belgilash-text218">
            |
          </span>
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header
        data-thq="thq-navbar"
        className="uchrashuv-sanasini-va-vatini-belgilash-navbar-interactive"
      >
        <div
          data-thq="thq-navbar-nav"
          className="uchrashuv-sanasini-va-vatini-belgilash-desktop-menu"
        >
          <div className="uchrashuv-sanasini-va-vatini-belgilash-container36">
            <nav className="uchrashuv-sanasini-va-vatini-belgilash-links1">
              <div
                data-thq="thq-dropdown"
                className="uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle10"
                  >
                    <span className="uchrashuv-sanasini-va-vatini-belgilash-text219">
                      Бош сахифа
                    </span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle11"
                >
                  <span className="uchrashuv-sanasini-va-vatini-belgilash-text220">
                    Умумий
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle12"
                      >
                        <span className="uchrashuv-sanasini-va-vatini-belgilash-text221 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle13"
                      >
                        <span className="uchrashuv-sanasini-va-vatini-belgilash-text224 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle14"
                      >
                        <span className="uchrashuv-sanasini-va-vatini-belgilash-text225 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle15"
                      >
                        <span className="uchrashuv-sanasini-va-vatini-belgilash-text226 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle16"
                      >
                        <span className="uchrashuv-sanasini-va-vatini-belgilash-text231 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle17"
                      >
                        <span className="uchrashuv-sanasini-va-vatini-belgilash-text232 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle18"
                      >
                        <span className="uchrashuv-sanasini-va-vatini-belgilash-text233 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle19"
                      >
                        <span className="uchrashuv-sanasini-va-vatini-belgilash-text237 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle20"
                >
                  <span className="uchrashuv-sanasini-va-vatini-belgilash-text238">
                    Виза олиш
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle21"
                      >
                        <span className="uchrashuv-sanasini-va-vatini-belgilash-text239">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle22"
                      >
                        <span className="uchrashuv-sanasini-va-vatini-belgilash-text240">
                          Миллий виза
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle23"
                      >
                        <span className="uchrashuv-sanasini-va-vatini-belgilash-text241">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle24"
                      >
                        <span className="uchrashuv-sanasini-va-vatini-belgilash-text242">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle25"
                      >
                        <span className="uchrashuv-sanasini-va-vatini-belgilash-text245">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle26"
                      >
                        <span className="uchrashuv-sanasini-va-vatini-belgilash-text249">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle27"
                      >
                        <span className="uchrashuv-sanasini-va-vatini-belgilash-text250">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle28"
                >
                  <span className="uchrashuv-sanasini-va-vatini-belgilash-text251">
                    Кушимча хизматлар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle29"
                      >
                        <span className="uchrashuv-sanasini-va-vatini-belgilash-text252">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle30"
                      >
                        <span className="uchrashuv-sanasini-va-vatini-belgilash-text253">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle31"
                      >
                        <span className="uchrashuv-sanasini-va-vatini-belgilash-text257">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle32"
                      >
                        <span className="uchrashuv-sanasini-va-vatini-belgilash-text260">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle33"
                      >
                        <span className="uchrashuv-sanasini-va-vatini-belgilash-text261">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle34"
                      >
                        <span className="uchrashuv-sanasini-va-vatini-belgilash-text265">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle35"
                      >
                        <span className="uchrashuv-sanasini-va-vatini-belgilash-text266">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle38"
                  >
                    <span className="uchrashuv-sanasini-va-vatini-belgilash-text269">
                      FAQ
                    </span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle39"
                >
                  <span className="uchrashuv-sanasini-va-vatini-belgilash-text270">
                    Алокалар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle40"
                      >
                        <span className="uchrashuv-sanasini-va-vatini-belgilash-text271">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle41"
                      >
                        <span className="uchrashuv-sanasini-va-vatini-belgilash-text272">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle42"
                      >
                        <span className="uchrashuv-sanasini-va-vatini-belgilash-text273">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uchrashuv-sanasini-va-vatini-belgilash-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle43"
                      >
                        <span className="uchrashuv-sanasini-va-vatini-belgilash-text274">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="uchrashuv-sanasini-va-vatini-belgilash-container37">
          <input
            type="text"
            placeholder="Кидирмок"
            className="uchrashuv-sanasini-va-vatini-belgilash-textinput1 input"
          />
          <div className="uchrashuv-sanasini-va-vatini-belgilash-container38">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="uchrashuv-sanasini-va-vatini-belgilash-icon20"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="uchrashuv-sanasini-va-vatini-belgilash-container39">
            <span className="uchrashuv-sanasini-va-vatini-belgilash-text277">
              A+
            </span>
            <span className="uchrashuv-sanasini-va-vatini-belgilash-text278">
              A-
            </span>
          </div>
        </div>
        <div
          data-thq="thq-burger-menu"
          className="uchrashuv-sanasini-va-vatini-belgilash-burger-menu2"
        >
          <svg
            viewBox="0 0 1024 1024"
            className="uchrashuv-sanasini-va-vatini-belgilash-icon22"
          >
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div
          data-thq="thq-mobile-menu"
          className="uchrashuv-sanasini-va-vatini-belgilash-mobile-menu"
        >
          <div className="uchrashuv-sanasini-va-vatini-belgilash-nav">
            <div className="uchrashuv-sanasini-va-vatini-belgilash-container40">
              <div className="uchrashuv-sanasini-va-vatini-belgilash-container41">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="uchrashuv-sanasini-va-vatini-belgilash-textinput2 input"
                />
                <div className="uchrashuv-sanasini-va-vatini-belgilash-container42">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="uchrashuv-sanasini-va-vatini-belgilash-icon24"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="uchrashuv-sanasini-va-vatini-belgilash-container43">
                  <span className="uchrashuv-sanasini-va-vatini-belgilash-text279">
                    A+
                  </span>
                  <span className="uchrashuv-sanasini-va-vatini-belgilash-text280">
                    A-
                  </span>
                </div>
              </div>
              <div
                data-thq="thq-close-menu"
                className="uchrashuv-sanasini-va-vatini-belgilash-close-menu"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="uchrashuv-sanasini-va-vatini-belgilash-icon26"
                >
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="uchrashuv-sanasini-va-vatini-belgilash-links2">
            <div
              data-thq="thq-dropdown"
              className="uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle44"
                >
                  <span className="uchrashuv-sanasini-va-vatini-belgilash-text281">
                    Бош сахифа
                  </span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle45"
              >
                <span className="uchrashuv-sanasini-va-vatini-belgilash-text282">
                  Умумий
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="uchrashuv-sanasini-va-vatini-belgilash-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle46"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text283">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle47"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text286">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle48"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text287">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle49"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text288">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle50"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text293">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle51"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text294">
                        Тулов Шартлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle52"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text295">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle53"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text298">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle54"
              >
                <span className="uchrashuv-sanasini-va-vatini-belgilash-text299">
                  Виза олиш
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="uchrashuv-sanasini-va-vatini-belgilash-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle55"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text300">
                        Шенген визаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle56"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text301">
                        Миллий виза
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle57"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text302">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle58"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text303">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle59"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text306">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle60"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text310">
                        Ариза шакллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle61"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text311">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle62"
              >
                <span className="uchrashuv-sanasini-va-vatini-belgilash-text312">
                  Кушимча хизматлар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="uchrashuv-sanasini-va-vatini-belgilash-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle63"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text313">
                        VIP - хизмати
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle64"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text314">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle65"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text315">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle66"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text316">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle67"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text317">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle68"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text320">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle69"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text323">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle70"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text324">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle71"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text325">
                        Prime Тиме
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle72"
                >
                  <span className="uchrashuv-sanasini-va-vatini-belgilash-text326">
                    FAQ
                  </span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle73"
              >
                <span className="uchrashuv-sanasini-va-vatini-belgilash-text327">
                  Алокалар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="uchrashuv-sanasini-va-vatini-belgilash-icon34"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle74"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text328">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle75"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text329">
                        Алока маркази
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle76"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text330">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uchrashuv-sanasini-va-vatini-belgilash-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle77"
                    >
                      <span className="uchrashuv-sanasini-va-vatini-belgilash-text331">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="uchrashuv-sanasini-va-vatini-belgilash-container44">
        <div className="uchrashuv-sanasini-va-vatini-belgilash-container45">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="uchrashuv-sanasini-va-vatini-belgilash-image21"
          />
          <span className="uchrashuv-sanasini-va-vatini-belgilash-text334">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="uchrashuv-sanasini-va-vatini-belgilash-container46">
          <div className="uchrashuv-sanasini-va-vatini-belgilash-container47">
            <span className="uchrashuv-sanasini-va-vatini-belgilash-text335">
              Ходимларни бошқариш
            </span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="uchrashuv-sanasini-va-vatini-belgilash-image22"
            />
          </div>
          <div className="uchrashuv-sanasini-va-vatini-belgilash-container48">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="uchrashuv-sanasini-va-vatini-belgilash-navlink81"
            >
              <div className="uchrashuv-sanasini-va-vatini-belgilash-container49">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="uchrashuv-sanasini-va-vatini-belgilash-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="uchrashuv-sanasini-va-vatini-belgilash-text336">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="uchrashuv-sanasini-va-vatini-belgilash-navlink82"
            >
              <div className="uchrashuv-sanasini-va-vatini-belgilash-container50">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="uchrashuv-sanasini-va-vatini-belgilash-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="uchrashuv-sanasini-va-vatini-belgilash-text337">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="uchrashuv-sanasini-va-vatini-belgilash-navlink83"
            >
              <div className="uchrashuv-sanasini-va-vatini-belgilash-container51">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="uchrashuv-sanasini-va-vatini-belgilash-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="uchrashuv-sanasini-va-vatini-belgilash-text341">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="uchrashuv-sanasini-va-vatini-belgilash-navlink84"
            >
              <div className="uchrashuv-sanasini-va-vatini-belgilash-container52">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="uchrashuv-sanasini-va-vatini-belgilash-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="uchrashuv-sanasini-va-vatini-belgilash-text345">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="uchrashuv-sanasini-va-vatini-belgilash-navlink85"
            >
              <div className="uchrashuv-sanasini-va-vatini-belgilash-container53">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="uchrashuv-sanasini-va-vatini-belgilash-icon44"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="uchrashuv-sanasini-va-vatini-belgilash-text349">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="uchrashuv-sanasini-va-vatini-belgilash-container54">
        <div className="uchrashuv-sanasini-va-vatini-belgilash-container55">
          <span className="uchrashuv-sanasini-va-vatini-belgilash-text353">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="uchrashuv-sanasini-va-vatini-belgilash-container56">
            <Link
              to="/visametric-gurukhi"
              className="uchrashuv-sanasini-va-vatini-belgilash-navlink86"
            >
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="uchrashuv-sanasini-va-vatini-belgilash-navlink87"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/w3c-logo2-200h.png"
          className="uchrashuv-sanasini-va-vatini-belgilash-image23"
        />
        <a href="#main" className="uchrashuv-sanasini-va-vatini-belgilash-link">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="uchrashuv-sanasini-va-vatini-belgilash-image24"
          />
        </a>
      </div>
    </div>
  )
}

export default UchrashuvSanasiniVaVatiniBelgilash
