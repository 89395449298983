import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './shengen-vizasi.css'

const ShengenVizasi = (props) => {
  return (
    <div className="shengen-vizasi-container10">
      <Helmet>
        <title>shengen-vizasi - Visametric</title>
        <meta property="og:title" content="shengen-vizasi - Visametric" />
      </Helmet>
      <div data-thq="thq-burger-menu" className="shengen-vizasi-burger-menu1">
        <svg viewBox="0 0 1024 1024" className="shengen-vizasi-icon10">
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div id="main" className="shengen-vizasi-container11">
        <h1 className="shengen-vizasi-text100">Шенген визаси</h1>
        <div className="shengen-vizasi-container12">
          <Link to="/uzbekistan-germanyuz" className="shengen-vizasi-navlink10">
            <div className="shengen-vizasi-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="shengen-vizasi-icon12"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="shengen-vizasi-text101">Бош саҳифа</h1>
            </div>
          </Link>
          <Link to="/uzbekistan-germanyuz" className="shengen-vizasi-navlink11">
            <div className="shengen-vizasi-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="shengen-vizasi-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="shengen-vizasi-text102">Виза олиш</h1>
            </div>
          </Link>
          <Link to="/uzbekistan-germanyuz" className="shengen-vizasi-navlink12">
            <div className="shengen-vizasi-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="shengen-vizasi-icon16"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="shengen-vizasi-text103">Шенген визаси</h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="shengen-vizasi-container16">
        <div className="shengen-vizasi-container17">
          <div className="shengen-vizasi-container18">
            <span className="shengen-vizasi-text104">Виза олиш</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="shengen-vizasi-icon18"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="shengen-vizasi-image10"
          />
          <div className="shengen-vizasi-container19">
            <Link to="/shengen-vizasi" className="shengen-vizasi-navlink13">
              <div className="shengen-vizasi-container20">
                <img
                  alt="image"
                  src="/rectangle%2030.svg"
                  className="shengen-vizasi-image11"
                />
                <span className="shengen-vizasi-text105">Шенген визаси</span>
              </div>
            </Link>
            <Link to="/milliy-viza" className="shengen-vizasi-navlink14">
              <div className="shengen-vizasi-container21">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="shengen-vizasi-image12"
                />
                <span className="shengen-vizasi-text106">Миллий виза</span>
              </div>
            </Link>
            <Link to="/onlayn-srovnomasi" className="shengen-vizasi-navlink15">
              <div className="shengen-vizasi-container22">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="shengen-vizasi-image13"
                />
                <span className="shengen-vizasi-text107">Онлайн-сўровнома</span>
              </div>
            </Link>
            <Link to="/kerakli-uzhzhatlar" className="shengen-vizasi-navlink16">
              <div className="shengen-vizasi-container23">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="shengen-vizasi-image14"
                />
                <span className="shengen-vizasi-text108">
                  Керакли ҳужжатлар
                </span>
              </div>
            </Link>
            <Link
              to="/uchrashuv-sanasini-va-vatini-belgilash"
              className="shengen-vizasi-navlink17"
            >
              <div className="shengen-vizasi-container24">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="shengen-vizasi-image15"
                />
                <span className="shengen-vizasi-text109">
                  Учрашув санасини ва вақтини белгилаш
                </span>
              </div>
            </Link>
            <Link to="/ariza-shakllari" className="shengen-vizasi-navlink18">
              <div className="shengen-vizasi-container25">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="shengen-vizasi-image16"
                />
                <span className="shengen-vizasi-text110">Ариза шакллари</span>
              </div>
            </Link>
            <Link to="/ilovani-kuzatish" className="shengen-vizasi-navlink19">
              <div className="shengen-vizasi-container26">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="shengen-vizasi-image17"
                />
                <span className="shengen-vizasi-text111">Иловани кузатиш</span>
              </div>
            </Link>
          </div>
        </div>
        <div className="shengen-vizasi-container27">
          <span className="shengen-vizasi-text112">
            Шенген визаси ва чекловлар ҳақида қўшимча маълумот:
          </span>
          <div className="shengen-vizasi-container28">
            <span className="shengen-vizasi-text113">
              1. Ариза топширишдан олдин:
            </span>
            <div className="shengen-vizasi-container29">
              <Link
                to="/kerakli-uzhzhatlar"
                className="shengen-vizasi-navlink20"
              >
                <span className="shengen-vizasi-text114">
                  • Ҳамма зарур
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="shengen-vizasi-text115">
                  ҳужжатларни тайёрлаб
                </span>
                <span className="shengen-vizasi-text116"> қўйинг;</span>
              </Link>
              <Link
                to="/uchrashuv-sanasini-va-vatini-belgilash"
                className="shengen-vizasi-navlink21"
              >
                <span className="shengen-vizasi-text117">
                  •
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>Қабул кунини </span>
                <span className="shengen-vizasi-text119">онлайн тарзда</span>
                <span> белгиланг ва тўловни олдинан амалга оширинг.</span>
              </Link>
            </div>
          </div>
          <div className="shengen-vizasi-container30">
            <span className="shengen-vizasi-text121">
              <span>2. Аризани топшириш вақтида:</span>
              <br></br>
            </span>
            <div className="shengen-vizasi-container31">
              <Link to="/office" className="shengen-vizasi-navlink22">
                <span className="shengen-vizasi-text124">
                  •
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  Ҳужжатларни қабул қилиш ва бармоқ изларини олиш
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="shengen-vizasi-text126">
                  VisaMetric қабул марказларида
                </span>
                <span> ўтказилади.</span>
              </Link>
              <a
                href="https://taschkent.diplo.de/uz-uz"
                target="_blank"
                rel="noreferrer noopener"
                className="shengen-vizasi-link1"
              >
                <span className="shengen-vizasi-text128">
                  •
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  Сўнгра ҳужжатлар
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="shengen-vizasi-text130">
                  Германия элчихонасига
                </span>
                <span>
                  {' '}
                  кўриб чиқиш ва якуний хулосалар чиқариш учун юборилади.
                </span>
              </a>
              <Link
                to="/shimcha-khizmatlar"
                className="shengen-vizasi-navlink23"
              >
                <span className="shengen-vizasi-text132">
                  •
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="shengen-vizasi-text133">
                  Қўшимча хизматларимиздан
                </span>
                <span> фойдаланишингиз мумкинлигини эслатиб ўтамиз</span>
              </Link>
            </div>
          </div>
          <div className="shengen-vizasi-container32">
            <span className="shengen-vizasi-text135">
              <span>3. Ариза топширилганидан сўнг:</span>
              <br></br>
            </span>
            <div className="shengen-vizasi-container33">
              <Link to="/ilovani-kuzatish" className="shengen-vizasi-navlink24">
                <span className="shengen-vizasi-text138">• </span>
                <span>
                  Веб сайтимизда аризани
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="shengen-vizasi-text140">
                  кўзатишингиз мумкин
                </span>
              </Link>
              <Link to="/office" className="shengen-vizasi-navlink25">
                <span className="shengen-vizasi-text141">
                  •
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  Тўлиқ кўриб чиқилгандан сўнг шахсий паспортингизни
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="shengen-vizasi-text143">
                  VisaMetric қабул марказидан
                </span>
                <span> олиб кетишингиз мумкин.</span>
              </Link>
            </div>
          </div>
          <span className="shengen-vizasi-text145">
            Шенген визаси Шенген давлатлари ҳудудида 90 кунгача саёҳат қилиш
            имконини беради. Ариза топшириш қоидаларига асосан, ариза топширувчи
            кетишни режалаштираётган давлат визасига ёки саёҳатининг кўпроқ
            қисмини ўтказадиган давлат визасига ариза топшириши зарур. Агар
            асосий бориш жойи Германиядан фарқли бўлса, тегишли Шенген ҳудуди
            давлатларига ариза топшириши лозим.
          </span>
        </div>
      </div>
      <div id="header" className="shengen-vizasi-container34">
        <Link to="/" className="shengen-vizasi-navlink26">
          <div className="shengen-vizasi-container35">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="shengen-vizasi-image18"
            />
            <p className="shengen-vizasi-text146">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="shengen-vizasi-container36">
          <div className="shengen-vizasi-container37">
            <span className="shengen-vizasi-text149">
              <span>Apply for Visa to</span>
              <span className="shengen-vizasi-text151"> Germany</span>
            </span>
            <span className="shengen-vizasi-text152">
              <span className="shengen-vizasi-text153">in Uzbekistan</span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="shengen-vizasi-image19"
          />
          <span className="shengen-vizasi-text184">|</span>
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header
        data-thq="thq-navbar"
        className="shengen-vizasi-navbar-interactive"
      >
        <div data-thq="thq-navbar-nav" className="shengen-vizasi-desktop-menu">
          <div className="shengen-vizasi-container38">
            <nav className="shengen-vizasi-links1">
              <div
                data-thq="thq-dropdown"
                className="shengen-vizasi-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="shengen-vizasi-dropdown-toggle10"
                  >
                    <span className="shengen-vizasi-text185">Бош сахифа</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="shengen-vizasi-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="shengen-vizasi-dropdown-toggle11"
                >
                  <span className="shengen-vizasi-text186">Умумий</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="shengen-vizasi-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="shengen-vizasi-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle12"
                      >
                        <span className="shengen-vizasi-text187 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle13"
                      >
                        <span className="shengen-vizasi-text190 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle14"
                      >
                        <span className="shengen-vizasi-text191 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle15"
                      >
                        <span className="shengen-vizasi-text192 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle16"
                      >
                        <span className="shengen-vizasi-text197 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle17"
                      >
                        <span className="shengen-vizasi-text198 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle18"
                      >
                        <span className="shengen-vizasi-text199 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle19"
                      >
                        <span className="shengen-vizasi-text203 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="shengen-vizasi-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="shengen-vizasi-dropdown-toggle20"
                >
                  <span className="shengen-vizasi-text204">Виза олиш</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="shengen-vizasi-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="shengen-vizasi-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle21"
                      >
                        <span className="shengen-vizasi-text205">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle22"
                      >
                        <span className="shengen-vizasi-text206">
                          Миллий виза
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle23"
                      >
                        <span className="shengen-vizasi-text207">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle24"
                      >
                        <span className="shengen-vizasi-text208">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle25"
                      >
                        <span className="shengen-vizasi-text211">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle26"
                      >
                        <span className="shengen-vizasi-text215">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle27"
                      >
                        <span className="shengen-vizasi-text216">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="shengen-vizasi-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="shengen-vizasi-dropdown-toggle28"
                >
                  <span className="shengen-vizasi-text217">
                    Кушимча хизматлар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="shengen-vizasi-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="shengen-vizasi-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle29"
                      >
                        <span className="shengen-vizasi-text218">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle30"
                      >
                        <span className="shengen-vizasi-text219">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle31"
                      >
                        <span className="shengen-vizasi-text223">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle32"
                      >
                        <span className="shengen-vizasi-text226">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle33"
                      >
                        <span className="shengen-vizasi-text227">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle34"
                      >
                        <span className="shengen-vizasi-text231">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle35"
                      >
                        <span className="shengen-vizasi-text232">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="shengen-vizasi-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="shengen-vizasi-dropdown-toggle38"
                  >
                    <span className="shengen-vizasi-text235">FAQ</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="shengen-vizasi-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="shengen-vizasi-dropdown-toggle39"
                >
                  <span className="shengen-vizasi-text236">Алокалар</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="shengen-vizasi-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="shengen-vizasi-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle40"
                      >
                        <span className="shengen-vizasi-text237">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle41"
                      >
                        <span className="shengen-vizasi-text238">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle42"
                      >
                        <span className="shengen-vizasi-text239">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="shengen-vizasi-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="shengen-vizasi-dropdown-toggle43"
                      >
                        <span className="shengen-vizasi-text240">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="shengen-vizasi-container39">
          <input
            type="text"
            placeholder="Кидирмок"
            className="shengen-vizasi-textinput1 input"
          />
          <div className="shengen-vizasi-container40">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="shengen-vizasi-icon20"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="shengen-vizasi-container41">
            <span className="shengen-vizasi-text243">A+</span>
            <span className="shengen-vizasi-text244">A-</span>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="shengen-vizasi-burger-menu2">
          <svg viewBox="0 0 1024 1024" className="shengen-vizasi-icon22">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="shengen-vizasi-mobile-menu">
          <div className="shengen-vizasi-nav">
            <div className="shengen-vizasi-container42">
              <div className="shengen-vizasi-container43">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="shengen-vizasi-textinput2 input"
                />
                <div className="shengen-vizasi-container44">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="shengen-vizasi-icon24"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="shengen-vizasi-container45">
                  <span className="shengen-vizasi-text245">A+</span>
                  <span className="shengen-vizasi-text246">A-</span>
                </div>
              </div>
              <div
                data-thq="thq-close-menu"
                className="shengen-vizasi-close-menu"
              >
                <svg viewBox="0 0 1024 1024" className="shengen-vizasi-icon26">
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="shengen-vizasi-links2">
            <div
              data-thq="thq-dropdown"
              className="shengen-vizasi-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="shengen-vizasi-dropdown-toggle44"
                >
                  <span className="shengen-vizasi-text247">Бош сахифа</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="shengen-vizasi-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="shengen-vizasi-dropdown-toggle45"
              >
                <span className="shengen-vizasi-text248">Умумий</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="shengen-vizasi-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="shengen-vizasi-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="shengen-vizasi-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle46"
                    >
                      <span className="shengen-vizasi-text249">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle47"
                    >
                      <span className="shengen-vizasi-text252">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle48"
                    >
                      <span className="shengen-vizasi-text253">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle49"
                    >
                      <span className="shengen-vizasi-text254">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle50"
                    >
                      <span className="shengen-vizasi-text259">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle51"
                    >
                      <span className="shengen-vizasi-text260">
                        Тулов Шартлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle52"
                    >
                      <span className="shengen-vizasi-text261">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle53"
                    >
                      <span className="shengen-vizasi-text264">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="shengen-vizasi-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="shengen-vizasi-dropdown-toggle54"
              >
                <span className="shengen-vizasi-text265">Виза олиш</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="shengen-vizasi-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="shengen-vizasi-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="shengen-vizasi-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle55"
                    >
                      <span className="shengen-vizasi-text266">
                        Шенген визаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle56"
                    >
                      <span className="shengen-vizasi-text267">
                        Миллий виза
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle57"
                    >
                      <span className="shengen-vizasi-text268">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle58"
                    >
                      <span className="shengen-vizasi-text269">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle59"
                    >
                      <span className="shengen-vizasi-text272">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle60"
                    >
                      <span className="shengen-vizasi-text276">
                        Ариза шакллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle61"
                    >
                      <span className="shengen-vizasi-text277">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="shengen-vizasi-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="shengen-vizasi-dropdown-toggle62"
              >
                <span className="shengen-vizasi-text278">
                  Кушимча хизматлар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="shengen-vizasi-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="shengen-vizasi-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="shengen-vizasi-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle63"
                    >
                      <span className="shengen-vizasi-text279">
                        VIP - хизмати
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle64"
                    >
                      <span className="shengen-vizasi-text280">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle65"
                    >
                      <span className="shengen-vizasi-text281">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle66"
                    >
                      <span className="shengen-vizasi-text282">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle67"
                    >
                      <span className="shengen-vizasi-text283">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle68"
                    >
                      <span className="shengen-vizasi-text286">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle69"
                    >
                      <span className="shengen-vizasi-text289">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle70"
                    >
                      <span className="shengen-vizasi-text290">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle71"
                    >
                      <span className="shengen-vizasi-text291">Prime Тиме</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="shengen-vizasi-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="shengen-vizasi-dropdown-toggle72"
                >
                  <span className="shengen-vizasi-text292">FAQ</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="shengen-vizasi-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="shengen-vizasi-dropdown-toggle73"
              >
                <span className="shengen-vizasi-text293">Алокалар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="shengen-vizasi-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="shengen-vizasi-icon34"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="shengen-vizasi-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle74"
                    >
                      <span className="shengen-vizasi-text294">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle75"
                    >
                      <span className="shengen-vizasi-text295">
                        Алока маркази
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle76"
                    >
                      <span className="shengen-vizasi-text296">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="shengen-vizasi-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="shengen-vizasi-dropdown-toggle77"
                    >
                      <span className="shengen-vizasi-text297">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="shengen-vizasi-container46">
        <div className="shengen-vizasi-container47">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="shengen-vizasi-image21"
          />
          <span className="shengen-vizasi-text300">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="shengen-vizasi-container48">
          <div className="shengen-vizasi-container49">
            <span className="shengen-vizasi-text301">Ходимларни бошқариш</span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="shengen-vizasi-image22"
            />
          </div>
          <div className="shengen-vizasi-container50">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="shengen-vizasi-navlink87"
            >
              <div className="shengen-vizasi-container51">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="shengen-vizasi-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="shengen-vizasi-text302">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="shengen-vizasi-navlink88"
            >
              <div className="shengen-vizasi-container52">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="shengen-vizasi-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="shengen-vizasi-text303">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="shengen-vizasi-navlink89"
            >
              <div className="shengen-vizasi-container53">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="shengen-vizasi-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="shengen-vizasi-text307">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="shengen-vizasi-navlink90"
            >
              <div className="shengen-vizasi-container54">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="shengen-vizasi-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="shengen-vizasi-text311">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="shengen-vizasi-navlink91"
            >
              <div className="shengen-vizasi-container55">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="shengen-vizasi-icon44"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="shengen-vizasi-text315">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="shengen-vizasi-container56">
        <div className="shengen-vizasi-container57">
          <span className="shengen-vizasi-text319">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="shengen-vizasi-container58">
            <Link to="/visametric-gurukhi" className="shengen-vizasi-navlink92">
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="shengen-vizasi-navlink93"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/w3c-logo2-200h.png"
          className="shengen-vizasi-image23"
        />
        <a href="#main" className="shengen-vizasi-link2">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="shengen-vizasi-image24"
          />
        </a>
      </div>
    </div>
  )
}

export default ShengenVizasi
