import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './arizalarni-krib-chiish-muddati.css'

const ArizalarniKribChiishMuddati = (props) => {
  return (
    <div className="arizalarni-krib-chiish-muddati-container10">
      <Helmet>
        <title>arizalarni-krib-chiish-muddati - Visametric</title>
        <meta
          property="og:title"
          content="arizalarni-krib-chiish-muddati - Visametric"
        />
      </Helmet>
      <div id="main" className="arizalarni-krib-chiish-muddati-container11">
        <h1 className="arizalarni-krib-chiish-muddati-text100">
          <span>Аризаларни кўриб чиқиш муддати</span>
          <br></br>
        </h1>
        <div className="arizalarni-krib-chiish-muddati-container12">
          <Link
            to="/uzbekistan-germanyuz"
            className="arizalarni-krib-chiish-muddati-navlink10"
          >
            <div className="arizalarni-krib-chiish-muddati-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="arizalarni-krib-chiish-muddati-icon10"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="arizalarni-krib-chiish-muddati-text103">
                Бош саҳифа
              </h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="arizalarni-krib-chiish-muddati-navlink11"
          >
            <div className="arizalarni-krib-chiish-muddati-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="arizalarni-krib-chiish-muddati-icon12"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="arizalarni-krib-chiish-muddati-text104">Умумий</h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="arizalarni-krib-chiish-muddati-navlink12"
          >
            <div className="arizalarni-krib-chiish-muddati-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="arizalarni-krib-chiish-muddati-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="arizalarni-krib-chiish-muddati-text105">
                Аризаларни кўриб чиқиш муддати
              </h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="arizalarni-krib-chiish-muddati-container16">
        <div className="arizalarni-krib-chiish-muddati-container17">
          <div className="arizalarni-krib-chiish-muddati-container18">
            <span className="arizalarni-krib-chiish-muddati-text106">
              Умумий
            </span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="arizalarni-krib-chiish-muddati-icon16"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="arizalarni-krib-chiish-muddati-image10"
          />
          <div className="arizalarni-krib-chiish-muddati-container19">
            <Link
              to="/submission-collection"
              className="arizalarni-krib-chiish-muddati-navlink13"
            >
              <div className="arizalarni-krib-chiish-muddati-container20">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="arizalarni-krib-chiish-muddati-image11"
                />
                <span className="arizalarni-krib-chiish-muddati-text107">
                  Ҳужжатларни топшириш/Ҳужжатларни олиш
                </span>
              </div>
            </Link>
            <Link
              to="/tegishli-malumotlar"
              className="arizalarni-krib-chiish-muddati-navlink14"
            >
              <div className="arizalarni-krib-chiish-muddati-container21">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="arizalarni-krib-chiish-muddati-image12"
                />
                <span className="arizalarni-krib-chiish-muddati-text108">
                  Тегишли маълумотлар
                </span>
              </div>
            </Link>
            <Link
              to="/rasmiy-bayramlar-kuni"
              className="arizalarni-krib-chiish-muddati-navlink15"
            >
              <div className="arizalarni-krib-chiish-muddati-container22">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="arizalarni-krib-chiish-muddati-image13"
                />
                <span className="arizalarni-krib-chiish-muddati-text109">
                  Расмий байрамлар куни
                </span>
              </div>
            </Link>
            <Link
              to="/arizalarni-krib-chiish-muddati"
              className="arizalarni-krib-chiish-muddati-navlink16"
            >
              <div className="arizalarni-krib-chiish-muddati-container23">
                <img
                  alt="image"
                  src="/rectangle%2030.svg"
                  className="arizalarni-krib-chiish-muddati-image14"
                />
                <span className="arizalarni-krib-chiish-muddati-text110">
                  Аризаларни кўриб чиқиш муддати
                </span>
              </div>
            </Link>
            <Link
              to="/biometrik-malumotlarini"
              className="arizalarni-krib-chiish-muddati-navlink17"
            >
              <div className="arizalarni-krib-chiish-muddati-container24">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="arizalarni-krib-chiish-muddati-image15"
                />
                <span className="arizalarni-krib-chiish-muddati-text111">
                  биометрик маълумотларини
                </span>
              </div>
            </Link>
            <Link
              to="/tlov-shartlari"
              className="arizalarni-krib-chiish-muddati-navlink18"
            >
              <div className="arizalarni-krib-chiish-muddati-container25">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="arizalarni-krib-chiish-muddati-image16"
                />
                <span className="arizalarni-krib-chiish-muddati-text112">
                  Тўлов Шартлари
                </span>
              </div>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="arizalarni-krib-chiish-muddati-navlink19"
            >
              <div className="arizalarni-krib-chiish-muddati-container26">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="arizalarni-krib-chiish-muddati-image17"
                />
                <span className="arizalarni-krib-chiish-muddati-text113">
                  Техника хавфсизлиги кўрсатмалари
                </span>
              </div>
            </Link>
            <Link
              to="/foydali-avolalar-uz"
              className="arizalarni-krib-chiish-muddati-navlink20"
            >
              <div className="arizalarni-krib-chiish-muddati-container27">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="arizalarni-krib-chiish-muddati-image18"
                />
                <span className="arizalarni-krib-chiish-muddati-text114">
                  Фойдали ҳаволалар
                </span>
              </div>
            </Link>
          </div>
        </div>
        <div className="arizalarni-krib-chiish-muddati-container28">
          <span className="arizalarni-krib-chiish-muddati-text115">
            <span>
              Виза учун қабул қилинган аризалар VisaMetric орқали Германия
              элчихонасининг консуллик бўлимига юборилади. Виза натижаси
              тахминан 1
            </span>
            <span className="arizalarni-krib-chiish-muddati-text117">
              5 иш куни ичида кўриб чиқилади.
            </span>
            <br className="arizalarni-krib-chiish-muddati-text118"></br>
            <br className="arizalarni-krib-chiish-muddati-text119"></br>
            <span>
              Ўзбекистон фуқароси бўлмаган фуқаролар учун топширилган
              ҳужжатларни кўриб чиқиш +15 иш кунидан ортиқ давом етади.
              Учрашувни олдиндан белгиланг.
            </span>
            <br className="arizalarni-krib-chiish-muddati-text121"></br>
            <br className="arizalarni-krib-chiish-muddati-text122"></br>
            <span>
              Визанинг ижобий ёки салбий жавоби Германия элчихонаси томонидан
              қабул қилинади. Шу сабабли аризани олдиндан топширишингизни
              сўраймиз.
            </span>
            <br className="arizalarni-krib-chiish-muddati-text124"></br>
            <br className="arizalarni-krib-chiish-muddati-text125"></br>
            <span className="arizalarni-krib-chiish-muddati-text126">
              Эслатма:
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              Хужжатларни кўриб чиқиш мобайнида 15 иш кунидан ошиб кетган
              аризалар ёзма равишда тегишли Германия элчихонасидан сўралиши
              лозим. Германия элчихонаси етарли бўлмаган ҳужжатларни электрон
              почта орқали талаб қилишини ёдда тутинг.Шу сабабли электрон
              почтангизни доимий равишда текшириб туришингизни сўраймиз.
            </span>
          </span>
          <Link
            to="/ilovani-kuzatish"
            className="arizalarni-krib-chiish-muddati-navlink21 button"
          >
            <span className="arizalarni-krib-chiish-muddati-text128">
              {' '}
              Иловани кузатиш
            </span>
          </Link>
        </div>
      </div>
      <div id="header" className="arizalarni-krib-chiish-muddati-container29">
        <Link to="/" className="arizalarni-krib-chiish-muddati-navlink22">
          <div className="arizalarni-krib-chiish-muddati-container30">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="arizalarni-krib-chiish-muddati-image19"
            />
            <p className="arizalarni-krib-chiish-muddati-text129">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="arizalarni-krib-chiish-muddati-container31">
          <div className="arizalarni-krib-chiish-muddati-container32">
            <span className="arizalarni-krib-chiish-muddati-text132">
              <span>Apply for Visa to</span>
              <span className="arizalarni-krib-chiish-muddati-text134">
                {' '}
                Germany
              </span>
            </span>
            <span className="arizalarni-krib-chiish-muddati-text135">
              <span className="arizalarni-krib-chiish-muddati-text136">
                in Uzbekistan
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="arizalarni-krib-chiish-muddati-image20"
          />
          <span className="arizalarni-krib-chiish-muddati-text167">|</span>
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header
        data-thq="thq-navbar"
        className="arizalarni-krib-chiish-muddati-navbar-interactive"
      >
        <div
          data-thq="thq-navbar-nav"
          className="arizalarni-krib-chiish-muddati-desktop-menu"
        >
          <div className="arizalarni-krib-chiish-muddati-container33">
            <nav className="arizalarni-krib-chiish-muddati-links1">
              <div
                data-thq="thq-dropdown"
                className="arizalarni-krib-chiish-muddati-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="arizalarni-krib-chiish-muddati-dropdown-toggle10"
                  >
                    <span className="arizalarni-krib-chiish-muddati-text168">
                      Бош сахифа
                    </span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="arizalarni-krib-chiish-muddati-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="arizalarni-krib-chiish-muddati-dropdown-toggle11"
                >
                  <span className="arizalarni-krib-chiish-muddati-text169">
                    Умумий
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="arizalarni-krib-chiish-muddati-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="arizalarni-krib-chiish-muddati-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle12"
                      >
                        <span className="arizalarni-krib-chiish-muddati-text170 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle13"
                      >
                        <span className="arizalarni-krib-chiish-muddati-text173 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle14"
                      >
                        <span className="arizalarni-krib-chiish-muddati-text174 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle15"
                      >
                        <span className="arizalarni-krib-chiish-muddati-text175 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle16"
                      >
                        <span className="arizalarni-krib-chiish-muddati-text180 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle17"
                      >
                        <span className="arizalarni-krib-chiish-muddati-text181 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle18"
                      >
                        <span className="arizalarni-krib-chiish-muddati-text182 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle19"
                      >
                        <span className="arizalarni-krib-chiish-muddati-text186 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="arizalarni-krib-chiish-muddati-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="arizalarni-krib-chiish-muddati-dropdown-toggle20"
                >
                  <span className="arizalarni-krib-chiish-muddati-text187">
                    Виза олиш
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="arizalarni-krib-chiish-muddati-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="arizalarni-krib-chiish-muddati-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle21"
                      >
                        <span className="arizalarni-krib-chiish-muddati-text188">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle22"
                      >
                        <span className="arizalarni-krib-chiish-muddati-text189">
                          Миллий виза
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle23"
                      >
                        <span className="arizalarni-krib-chiish-muddati-text190">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle24"
                      >
                        <span className="arizalarni-krib-chiish-muddati-text191">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle25"
                      >
                        <span className="arizalarni-krib-chiish-muddati-text194">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle26"
                      >
                        <span className="arizalarni-krib-chiish-muddati-text198">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle27"
                      >
                        <span className="arizalarni-krib-chiish-muddati-text199">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="arizalarni-krib-chiish-muddati-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="arizalarni-krib-chiish-muddati-dropdown-toggle28"
                >
                  <span className="arizalarni-krib-chiish-muddati-text200">
                    Кушимча хизматлар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="arizalarni-krib-chiish-muddati-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="arizalarni-krib-chiish-muddati-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle29"
                      >
                        <span className="arizalarni-krib-chiish-muddati-text201">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle30"
                      >
                        <span className="arizalarni-krib-chiish-muddati-text202">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle31"
                      >
                        <span className="arizalarni-krib-chiish-muddati-text206">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle32"
                      >
                        <span className="arizalarni-krib-chiish-muddati-text209">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle33"
                      >
                        <span className="arizalarni-krib-chiish-muddati-text210">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle34"
                      >
                        <span className="arizalarni-krib-chiish-muddati-text214">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle35"
                      >
                        <span className="arizalarni-krib-chiish-muddati-text215">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="arizalarni-krib-chiish-muddati-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="arizalarni-krib-chiish-muddati-dropdown-toggle38"
                  >
                    <span className="arizalarni-krib-chiish-muddati-text218">
                      FAQ
                    </span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="arizalarni-krib-chiish-muddati-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="arizalarni-krib-chiish-muddati-dropdown-toggle39"
                >
                  <span className="arizalarni-krib-chiish-muddati-text219">
                    Алокалар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="arizalarni-krib-chiish-muddati-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="arizalarni-krib-chiish-muddati-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle40"
                      >
                        <span className="arizalarni-krib-chiish-muddati-text220">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle41"
                      >
                        <span className="arizalarni-krib-chiish-muddati-text221">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle42"
                      >
                        <span className="arizalarni-krib-chiish-muddati-text222">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="arizalarni-krib-chiish-muddati-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="arizalarni-krib-chiish-muddati-dropdown-toggle43"
                      >
                        <span className="arizalarni-krib-chiish-muddati-text223">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="arizalarni-krib-chiish-muddati-container34">
          <input
            type="text"
            placeholder="Кидирмок"
            className="arizalarni-krib-chiish-muddati-textinput1 input"
          />
          <div className="arizalarni-krib-chiish-muddati-container35">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="arizalarni-krib-chiish-muddati-icon18"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="arizalarni-krib-chiish-muddati-container36">
            <span className="arizalarni-krib-chiish-muddati-text226">A+</span>
            <span className="arizalarni-krib-chiish-muddati-text227">A-</span>
          </div>
        </div>
        <div
          data-thq="thq-burger-menu"
          className="arizalarni-krib-chiish-muddati-burger-menu"
        >
          <svg
            viewBox="0 0 1024 1024"
            className="arizalarni-krib-chiish-muddati-icon20"
          >
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div
          data-thq="thq-mobile-menu"
          className="arizalarni-krib-chiish-muddati-mobile-menu"
        >
          <div className="arizalarni-krib-chiish-muddati-nav">
            <div className="arizalarni-krib-chiish-muddati-container37">
              <div className="arizalarni-krib-chiish-muddati-container38">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="arizalarni-krib-chiish-muddati-textinput2 input"
                />
                <div className="arizalarni-krib-chiish-muddati-container39">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="arizalarni-krib-chiish-muddati-icon22"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="arizalarni-krib-chiish-muddati-container40">
                  <span className="arizalarni-krib-chiish-muddati-text228">
                    A+
                  </span>
                  <span className="arizalarni-krib-chiish-muddati-text229">
                    A-
                  </span>
                </div>
              </div>
              <div
                data-thq="thq-close-menu"
                className="arizalarni-krib-chiish-muddati-close-menu"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="arizalarni-krib-chiish-muddati-icon24"
                >
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="arizalarni-krib-chiish-muddati-links2">
            <div
              data-thq="thq-dropdown"
              className="arizalarni-krib-chiish-muddati-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="arizalarni-krib-chiish-muddati-dropdown-toggle44"
                >
                  <span className="arizalarni-krib-chiish-muddati-text230">
                    Бош сахифа
                  </span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="arizalarni-krib-chiish-muddati-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="arizalarni-krib-chiish-muddati-dropdown-toggle45"
              >
                <span className="arizalarni-krib-chiish-muddati-text231">
                  Умумий
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="arizalarni-krib-chiish-muddati-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="arizalarni-krib-chiish-muddati-icon26"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="arizalarni-krib-chiish-muddati-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle46"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text232">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle47"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text235">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle48"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text236">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle49"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text237">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle50"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text242">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle51"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text243">
                        Тулов Шартлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle52"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text244">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle53"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text247">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="arizalarni-krib-chiish-muddati-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="arizalarni-krib-chiish-muddati-dropdown-toggle54"
              >
                <span className="arizalarni-krib-chiish-muddati-text248">
                  Виза олиш
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="arizalarni-krib-chiish-muddati-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="arizalarni-krib-chiish-muddati-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="arizalarni-krib-chiish-muddati-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle55"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text249">
                        Шенген визаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle56"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text250">
                        Миллий виза
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle57"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text251">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle58"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text252">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle59"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text255">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle60"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text259">
                        Ариза шакллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle61"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text260">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="arizalarni-krib-chiish-muddati-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="arizalarni-krib-chiish-muddati-dropdown-toggle62"
              >
                <span className="arizalarni-krib-chiish-muddati-text261">
                  Кушимча хизматлар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="arizalarni-krib-chiish-muddati-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="arizalarni-krib-chiish-muddati-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="arizalarni-krib-chiish-muddati-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle63"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text262">
                        VIP - хизмати
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle64"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text263">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle65"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text264">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle66"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text265">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle67"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text266">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle68"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text269">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle69"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text272">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle70"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text273">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle71"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text274">
                        Prime Тиме
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="arizalarni-krib-chiish-muddati-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="arizalarni-krib-chiish-muddati-dropdown-toggle72"
                >
                  <span className="arizalarni-krib-chiish-muddati-text275">
                    FAQ
                  </span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="arizalarni-krib-chiish-muddati-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="arizalarni-krib-chiish-muddati-dropdown-toggle73"
              >
                <span className="arizalarni-krib-chiish-muddati-text276">
                  Алокалар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="arizalarni-krib-chiish-muddati-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="arizalarni-krib-chiish-muddati-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="arizalarni-krib-chiish-muddati-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle74"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text277">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle75"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text278">
                        Алока маркази
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle76"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text279">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="arizalarni-krib-chiish-muddati-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="arizalarni-krib-chiish-muddati-dropdown-toggle77"
                    >
                      <span className="arizalarni-krib-chiish-muddati-text280">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="arizalarni-krib-chiish-muddati-container41">
        <div className="arizalarni-krib-chiish-muddati-container42">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="arizalarni-krib-chiish-muddati-image22"
          />
          <span className="arizalarni-krib-chiish-muddati-text283">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="arizalarni-krib-chiish-muddati-container43">
          <div className="arizalarni-krib-chiish-muddati-container44">
            <span className="arizalarni-krib-chiish-muddati-text284">
              Ходимларни бошқариш
            </span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="arizalarni-krib-chiish-muddati-image23"
            />
          </div>
          <div className="arizalarni-krib-chiish-muddati-container45">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="arizalarni-krib-chiish-muddati-navlink83"
            >
              <div className="arizalarni-krib-chiish-muddati-container46">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="arizalarni-krib-chiish-muddati-icon34"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="arizalarni-krib-chiish-muddati-text285">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="arizalarni-krib-chiish-muddati-navlink84"
            >
              <div className="arizalarni-krib-chiish-muddati-container47">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="arizalarni-krib-chiish-muddati-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="arizalarni-krib-chiish-muddati-text286">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="arizalarni-krib-chiish-muddati-navlink85"
            >
              <div className="arizalarni-krib-chiish-muddati-container48">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="arizalarni-krib-chiish-muddati-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="arizalarni-krib-chiish-muddati-text290">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="arizalarni-krib-chiish-muddati-navlink86"
            >
              <div className="arizalarni-krib-chiish-muddati-container49">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="arizalarni-krib-chiish-muddati-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="arizalarni-krib-chiish-muddati-text294">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="arizalarni-krib-chiish-muddati-navlink87"
            >
              <div className="arizalarni-krib-chiish-muddati-container50">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="arizalarni-krib-chiish-muddati-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="arizalarni-krib-chiish-muddati-text298">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="arizalarni-krib-chiish-muddati-container51">
        <div className="arizalarni-krib-chiish-muddati-container52">
          <span className="arizalarni-krib-chiish-muddati-text302">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="arizalarni-krib-chiish-muddati-container53">
            <Link
              to="/visametric-gurukhi"
              className="arizalarni-krib-chiish-muddati-navlink88"
            >
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="arizalarni-krib-chiish-muddati-navlink89"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/w3c-logo2-200h.png"
          className="arizalarni-krib-chiish-muddati-image24"
        />
        <a href="#main" className="arizalarni-krib-chiish-muddati-link">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="arizalarni-krib-chiish-muddati-image25"
          />
        </a>
      </div>
    </div>
  )
}

export default ArizalarniKribChiishMuddati
