import React from 'react'

import { Helmet } from 'react-helmet'

import './visametric-gurukhi.css'

const VisametricGurukhi = (props) => {
  return (
    <div className="visametric-gurukhi-container">
      <Helmet>
        <title>visametric-gurukhi - Visametric</title>
        <meta property="og:title" content="visametric-gurukhi - Visametric" />
      </Helmet>
    </div>
  )
}

export default VisametricGurukhi
