import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './milliy-viza.css'

const MilliyViza = (props) => {
  return (
    <div className="milliy-viza-container10">
      <Helmet>
        <title>milliy-viza - Visametric</title>
        <meta property="og:title" content="milliy-viza - Visametric" />
      </Helmet>
      <div data-thq="thq-burger-menu" className="milliy-viza-burger-menu1">
        <svg viewBox="0 0 1024 1024" className="milliy-viza-icon10">
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div id="main" className="milliy-viza-container11">
        <h1 className="milliy-viza-text100">Миллий виза</h1>
        <div className="milliy-viza-container12">
          <Link to="/uzbekistan-germanyuz" className="milliy-viza-navlink10">
            <div className="milliy-viza-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="milliy-viza-icon12"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="milliy-viza-text101">Бош саҳифа</h1>
            </div>
          </Link>
          <Link to="/uzbekistan-germanyuz" className="milliy-viza-navlink11">
            <div className="milliy-viza-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="milliy-viza-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="milliy-viza-text102">Виза олиш</h1>
            </div>
          </Link>
          <Link to="/uzbekistan-germanyuz" className="milliy-viza-navlink12">
            <div className="milliy-viza-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="milliy-viza-icon16"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="milliy-viza-text103">Миллий виза</h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="milliy-viza-container16">
        <div className="milliy-viza-container17">
          <div className="milliy-viza-container18">
            <span className="milliy-viza-text104">Виза олиш</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="milliy-viza-icon18"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="milliy-viza-image10"
          />
          <div className="milliy-viza-container19">
            <Link to="/shengen-vizasi" className="milliy-viza-navlink13">
              <div className="milliy-viza-container20">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="milliy-viza-image11"
                />
                <span className="milliy-viza-text105">Шенген визаси</span>
              </div>
            </Link>
            <Link to="/milliy-viza" className="milliy-viza-navlink14">
              <div className="milliy-viza-container21">
                <img
                  alt="image"
                  src="/rectangle%2030.svg"
                  className="milliy-viza-image12"
                />
                <span className="milliy-viza-text106">Миллий виза</span>
              </div>
            </Link>
            <Link to="/onlayn-srovnomasi" className="milliy-viza-navlink15">
              <div className="milliy-viza-container22">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="milliy-viza-image13"
                />
                <span className="milliy-viza-text107">Онлайн-сўровнома</span>
              </div>
            </Link>
            <Link to="/kerakli-uzhzhatlar" className="milliy-viza-navlink16">
              <div className="milliy-viza-container23">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="milliy-viza-image14"
                />
                <span className="milliy-viza-text108">Керакли ҳужжатлар</span>
              </div>
            </Link>
            <Link
              to="/uchrashuv-sanasini-va-vatini-belgilash"
              className="milliy-viza-navlink17"
            >
              <div className="milliy-viza-container24">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="milliy-viza-image15"
                />
                <span className="milliy-viza-text109">
                  Учрашув санасини ва вақтини белгилаш
                </span>
              </div>
            </Link>
            <Link to="/ariza-shakllari" className="milliy-viza-navlink18">
              <div className="milliy-viza-container25">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="milliy-viza-image16"
                />
                <span className="milliy-viza-text110">Ариза шакллари</span>
              </div>
            </Link>
            <Link to="/ilovani-kuzatish" className="milliy-viza-navlink19">
              <div className="milliy-viza-container26">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="milliy-viza-image17"
                />
                <span className="milliy-viza-text111">Иловани кузатиш</span>
              </div>
            </Link>
          </div>
        </div>
        <div className="milliy-viza-container27">
          <span className="milliy-viza-text112">
            Миллий виза (90 кундан ортиқ бўлиш)
          </span>
          <span className="milliy-viza-text113">
            <span>
              Германия ҳудудида бир сафарнинг ўзида 90 кундан ортиқ бўлиши учун
              миллий визани расмий равишда сўраш лозим.
            </span>
            <br className="milliy-viza-text115"></br>
            <br className="milliy-viza-text116"></br>
            <span className="milliy-viza-text117">
              Германия виза маркази таълим олиш мақсадида узок муддатли миллий
              виза учун хужжатларни.шунингдек Au-Pair сифатида фаолият юритиш
              учун аризаларни қабул қилишни бошлайди. Германия университетига,
              техник коллежига ёки тайёрлов коллежига қабул қилинганлик
              тўғрисида таклифнома билан талабалик визаси учун VisaMetric
              маркази орқали аризаларни кабул килишни бошлади.
            </span>
          </span>
          <span className="milliy-viza-text118">
            <span>
              Ҳужжатларни миллий визага топшириш мобайнида бармоқ изларини олиш
              жараёнлари амалга оширилади ва ҳар бир аризачи ариза тпшириш
              пайтида шахсан ижтирок этиши даркор. Ҳозирги кунда ҳужжатларни
              топшириш учун руйхатдан утиш
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="milliy-viza-text120">VisaMetric Appointment</span>
            <span>
              {' '}
              сайти оркали онлайн тарзда амалга оширилади.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
          <span className="milliy-viza-text122">
            <span>
              Рўйхатдаги тўлиқ керакли ҳужжатлар ҳақида маълумот олиш
              учун,шунингдек бошқа мақсадлар учун ариза бериш қоидалари билан
              Тошкентдаги Германия элчихонаси сайти орқали танишиб чиқишингиз
              мумкин.
            </span>
            <br></br>
            <br></br>
            <span className="milliy-viza-text126">
              Ариза топширишдан олдин:
            </span>
            <br></br>
            <br></br>
            <span>• </span>
            <span>
              Сафарингиз мақсади бўйича керакли ҳужжатларни тайёрланг.
            </span>
            <br></br>
            <br></br>
            <span>•  </span>
            <span>
              Ҳужжатларни топшириш учун олдиндан рўйхатимизга онлайн таризда
              ёзилишингиз лозим.
            </span>
            <br></br>
            <br></br>
            <span>•  </span>
            <span>
              Миллий визага ҳужжатларни топшириш учун умумий маълумотлар билан
              танишиб чиқинг.
            </span>
            <br></br>
            <br></br>
            <span>•  </span>
            <span>VisaMetric офисига келинг.</span>
            <br></br>
            <br></br>
            <br></br>
            <span className="milliy-viza-text146">
              Ариза топшириш вақтида :
            </span>
            <br></br>
            <br></br>
            <span>•  </span>
            <span>
              Ҳужжатларни қабул қилиш ва бармоқ изларини олиш жараёни VisaMetric
              офисида амалга оширилади,сўнгра топширилган аризаларни кўриб чиқиш
              учун Германиянинг тегишли Дипломатик ваколатхонасига юборилади.
            </span>
            <br></br>
            <span>•  </span>
            <span>
              Ҳужжатларни топшириш вақтида қўшимча хизматларимиздан
              фойдаланишингиз мумкин.
            </span>
            <br></br>
            <br></br>
            <span className="milliy-viza-text156">
              Хужжатларингиз кабул килингандан сунг айрим керакли хужжатлар
              Тошкентдаги Германия элчихонаси томонидан талаб килиниб суралиши
              мумкин.
            </span>
          </span>
        </div>
      </div>
      <div id="header" className="milliy-viza-container28">
        <Link to="/" className="milliy-viza-navlink20">
          <div className="milliy-viza-container29">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="milliy-viza-image18"
            />
            <p className="milliy-viza-text157">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="milliy-viza-container30">
          <div className="milliy-viza-container31">
            <span className="milliy-viza-text160">
              <span>Apply for Visa to</span>
              <span className="milliy-viza-text162"> Germany</span>
            </span>
            <span className="milliy-viza-text163">
              <span className="milliy-viza-text164">in Uzbekistan</span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="milliy-viza-image19"
          />
          <span className="milliy-viza-text195">|</span>
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header data-thq="thq-navbar" className="milliy-viza-navbar-interactive">
        <div data-thq="thq-navbar-nav" className="milliy-viza-desktop-menu">
          <div className="milliy-viza-container32">
            <nav className="milliy-viza-links1">
              <div
                data-thq="thq-dropdown"
                className="milliy-viza-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="milliy-viza-dropdown-toggle10"
                  >
                    <span className="milliy-viza-text196">Бош сахифа</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="milliy-viza-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="milliy-viza-dropdown-toggle11"
                >
                  <span className="milliy-viza-text197">Умумий</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="milliy-viza-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="milliy-viza-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle12"
                      >
                        <span className="milliy-viza-text198 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle13"
                      >
                        <span className="milliy-viza-text201 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle14"
                      >
                        <span className="milliy-viza-text202 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle15"
                      >
                        <span className="milliy-viza-text203 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle16"
                      >
                        <span className="milliy-viza-text208 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle17"
                      >
                        <span className="milliy-viza-text209 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle18"
                      >
                        <span className="milliy-viza-text210 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle19"
                      >
                        <span className="milliy-viza-text214 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="milliy-viza-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="milliy-viza-dropdown-toggle20"
                >
                  <span className="milliy-viza-text215">Виза олиш</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="milliy-viza-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="milliy-viza-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle21"
                      >
                        <span className="milliy-viza-text216">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle22"
                      >
                        <span className="milliy-viza-text217">Миллий виза</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle23"
                      >
                        <span className="milliy-viza-text218">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle24"
                      >
                        <span className="milliy-viza-text219">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle25"
                      >
                        <span className="milliy-viza-text222">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle26"
                      >
                        <span className="milliy-viza-text226">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle27"
                      >
                        <span className="milliy-viza-text227">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="milliy-viza-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="milliy-viza-dropdown-toggle28"
                >
                  <span className="milliy-viza-text228">Кушимча хизматлар</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="milliy-viza-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="milliy-viza-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle29"
                      >
                        <span className="milliy-viza-text229">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle30"
                      >
                        <span className="milliy-viza-text230">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle31"
                      >
                        <span className="milliy-viza-text234">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle32"
                      >
                        <span className="milliy-viza-text237">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle33"
                      >
                        <span className="milliy-viza-text238">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle34"
                      >
                        <span className="milliy-viza-text242">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle35"
                      >
                        <span className="milliy-viza-text243">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="milliy-viza-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="milliy-viza-dropdown-toggle38"
                  >
                    <span className="milliy-viza-text246">FAQ</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="milliy-viza-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="milliy-viza-dropdown-toggle39"
                >
                  <span className="milliy-viza-text247">Алокалар</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="milliy-viza-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="milliy-viza-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle40"
                      >
                        <span className="milliy-viza-text248">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle41"
                      >
                        <span className="milliy-viza-text249">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle42"
                      >
                        <span className="milliy-viza-text250">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="milliy-viza-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="milliy-viza-dropdown-toggle43"
                      >
                        <span className="milliy-viza-text251">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="milliy-viza-container33">
          <input
            type="text"
            placeholder="Кидирмок"
            className="milliy-viza-textinput1 input"
          />
          <div className="milliy-viza-container34">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="milliy-viza-icon20"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="milliy-viza-container35">
            <span className="milliy-viza-text254">A+</span>
            <span className="milliy-viza-text255">A-</span>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="milliy-viza-burger-menu2">
          <svg viewBox="0 0 1024 1024" className="milliy-viza-icon22">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="milliy-viza-mobile-menu">
          <div className="milliy-viza-nav">
            <div className="milliy-viza-container36">
              <div className="milliy-viza-container37">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="milliy-viza-textinput2 input"
                />
                <div className="milliy-viza-container38">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="milliy-viza-icon24"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="milliy-viza-container39">
                  <span className="milliy-viza-text256">A+</span>
                  <span className="milliy-viza-text257">A-</span>
                </div>
              </div>
              <div data-thq="thq-close-menu" className="milliy-viza-close-menu">
                <svg viewBox="0 0 1024 1024" className="milliy-viza-icon26">
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="milliy-viza-links2">
            <div
              data-thq="thq-dropdown"
              className="milliy-viza-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="milliy-viza-dropdown-toggle44"
                >
                  <span className="milliy-viza-text258">Бош сахифа</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="milliy-viza-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="milliy-viza-dropdown-toggle45"
              >
                <span className="milliy-viza-text259">Умумий</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="milliy-viza-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="milliy-viza-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="milliy-viza-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle46"
                    >
                      <span className="milliy-viza-text260">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle47"
                    >
                      <span className="milliy-viza-text263">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle48"
                    >
                      <span className="milliy-viza-text264">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle49"
                    >
                      <span className="milliy-viza-text265">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle50"
                    >
                      <span className="milliy-viza-text270">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle51"
                    >
                      <span className="milliy-viza-text271">
                        Тулов Шартлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle52"
                    >
                      <span className="milliy-viza-text272">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle53"
                    >
                      <span className="milliy-viza-text275">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="milliy-viza-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="milliy-viza-dropdown-toggle54"
              >
                <span className="milliy-viza-text276">Виза олиш</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="milliy-viza-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="milliy-viza-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="milliy-viza-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle55"
                    >
                      <span className="milliy-viza-text277">Шенген визаси</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle56"
                    >
                      <span className="milliy-viza-text278">Миллий виза</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle57"
                    >
                      <span className="milliy-viza-text279">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle58"
                    >
                      <span className="milliy-viza-text280">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle59"
                    >
                      <span className="milliy-viza-text283">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle60"
                    >
                      <span className="milliy-viza-text287">
                        Ариза шакллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle61"
                    >
                      <span className="milliy-viza-text288">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="milliy-viza-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="milliy-viza-dropdown-toggle62"
              >
                <span className="milliy-viza-text289">Кушимча хизматлар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="milliy-viza-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="milliy-viza-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="milliy-viza-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle63"
                    >
                      <span className="milliy-viza-text290">VIP - хизмати</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle64"
                    >
                      <span className="milliy-viza-text291">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle65"
                    >
                      <span className="milliy-viza-text292">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle66"
                    >
                      <span className="milliy-viza-text293">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle67"
                    >
                      <span className="milliy-viza-text294">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle68"
                    >
                      <span className="milliy-viza-text297">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle69"
                    >
                      <span className="milliy-viza-text300">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle70"
                    >
                      <span className="milliy-viza-text301">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle71"
                    >
                      <span className="milliy-viza-text302">Prime Тиме</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="milliy-viza-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="milliy-viza-dropdown-toggle72"
                >
                  <span className="milliy-viza-text303">FAQ</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="milliy-viza-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="milliy-viza-dropdown-toggle73"
              >
                <span className="milliy-viza-text304">Алокалар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="milliy-viza-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="milliy-viza-icon34"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="milliy-viza-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle74"
                    >
                      <span className="milliy-viza-text305">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle75"
                    >
                      <span className="milliy-viza-text306">Алока маркази</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle76"
                    >
                      <span className="milliy-viza-text307">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="milliy-viza-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="milliy-viza-dropdown-toggle77"
                    >
                      <span className="milliy-viza-text308">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="milliy-viza-container40">
        <div className="milliy-viza-container41">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="milliy-viza-image21"
          />
          <span className="milliy-viza-text311">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="milliy-viza-container42">
          <div className="milliy-viza-container43">
            <span className="milliy-viza-text312">Ходимларни бошқариш</span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="milliy-viza-image22"
            />
          </div>
          <div className="milliy-viza-container44">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="milliy-viza-navlink81"
            >
              <div className="milliy-viza-container45">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="milliy-viza-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="milliy-viza-text313">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="milliy-viza-navlink82"
            >
              <div className="milliy-viza-container46">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="milliy-viza-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="milliy-viza-text314">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="milliy-viza-navlink83"
            >
              <div className="milliy-viza-container47">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="milliy-viza-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="milliy-viza-text318">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="milliy-viza-navlink84"
            >
              <div className="milliy-viza-container48">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="milliy-viza-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="milliy-viza-text322">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="milliy-viza-navlink85"
            >
              <div className="milliy-viza-container49">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="milliy-viza-icon44"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="milliy-viza-text326">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="milliy-viza-container50">
        <div className="milliy-viza-container51">
          <span className="milliy-viza-text330">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="milliy-viza-container52">
            <Link to="/visametric-gurukhi" className="milliy-viza-navlink86">
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="milliy-viza-navlink87"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/w3c-logo2-200h.png"
          className="milliy-viza-image23"
        />
        <a href="#main" className="milliy-viza-link">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="milliy-viza-image24"
          />
        </a>
      </div>
    </div>
  )
}

export default MilliyViza
