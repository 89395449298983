import React from 'react'

import { Helmet } from 'react-helmet'

import './masuldorlikni-bosharish.css'

const MasuldorlikniBosharish = (props) => {
  return (
    <div className="masuldorlikni-bosharish-container">
      <Helmet>
        <title>masuldorlikni-bosharish - Visametric</title>
        <meta
          property="og:title"
          content="masuldorlikni-bosharish - Visametric"
        />
      </Helmet>
    </div>
  )
}

export default MasuldorlikniBosharish
