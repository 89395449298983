import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './navbar-interactive.css'

const NavbarInteractive = (props) => {
  return (
    <div className={`navbar-interactive-container ${props.rootClassName} `}>
      <header
        data-thq="thq-navbar"
        className="navbar-interactive-navbar-interactive"
      >
        <Link to="/" className="navbar-interactive-navlink1">
          <img
            alt={props.imageAlt3}
            src={props.imageSrc3}
            className="navbar-interactive-image1"
          />
        </Link>
        <div
          data-thq="thq-navbar-nav"
          className="navbar-interactive-desktop-menu"
        ></div>
        <div
          data-thq="thq-burger-menu"
          className="navbar-interactive-burger-menu"
        >
          <svg viewBox="0 0 1024 1024" className="navbar-interactive-icon1">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div
          data-thq="thq-mobile-menu"
          className="navbar-interactive-mobile-menu"
        >
          <div className="navbar-interactive-nav">
            <div className="navbar-interactive-top">
              <Link to="/" className="navbar-interactive-navlink2">
                <img
                  alt={props.imageAlt5}
                  src={props.imageSrc5}
                  className="navbar-interactive-image2"
                />
              </Link>
              <div
                data-thq="thq-close-menu"
                className="navbar-interactive-close-menu"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="navbar-interactive-icon3"
                >
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav className="navbar-interactive-links">
              <Link to="/" className="navbar-interactive-navlink3">
                {props.text7}
              </Link>
              <span className="navbar-interactive-text1">{props.text8}</span>
              <span className="navbar-interactive-text2">{props.text9}</span>
              <span className="navbar-interactive-text3">{props.text10}</span>
              <span className="navbar-interactive-text4">{props.text11}</span>
              <span className="navbar-interactive-text5">{props.text12}</span>
              <span className="navbar-interactive-text6">{props.text13}</span>
              <a
                href="mailto:excellenceagency20@gmail.com?subject="
                className="navbar-interactive-text7"
              >
                {props.text17}
              </a>
            </nav>
          </div>
          <div className="navbar-interactive-icon-group"></div>
        </div>
      </header>
    </div>
  )
}

NavbarInteractive.defaultProps = {
  text7: 'Startseite',
  imageAlt5: 'logo',
  text8: 'Über uns',
  text11: 'Rezensionen',
  text9: 'Stellenangebote',
  text10: 'Dienstleistungen',
  imageSrc5: '/logo1.svg',
  text17: 'excellenceagency20@gmail.com',
  imageSrc3: '/logomin-200h.png',
  text13: 'eu',
  rootClassName: '',
  text12: 'Kontakte',
  imageAlt3: 'logo',
}

NavbarInteractive.propTypes = {
  text7: PropTypes.string,
  imageAlt5: PropTypes.string,
  text8: PropTypes.string,
  text11: PropTypes.string,
  text9: PropTypes.string,
  text10: PropTypes.string,
  imageSrc5: PropTypes.string,
  text17: PropTypes.string,
  imageSrc3: PropTypes.string,
  text13: PropTypes.string,
  rootClassName: PropTypes.string,
  text12: PropTypes.string,
  imageAlt3: PropTypes.string,
}

export default NavbarInteractive
