import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './tegishli-malumotlar.css'

const TegishliMalumotlar = (props) => {
  return (
    <div className="tegishli-malumotlar-container10">
      <Helmet>
        <title>tegishli-malumotlar - Visametric</title>
        <meta property="og:title" content="tegishli-malumotlar - Visametric" />
      </Helmet>
      <div id="main" className="tegishli-malumotlar-container11">
        <h1 className="tegishli-malumotlar-text100">Тегишли маълумотлар</h1>
        <div className="tegishli-malumotlar-container12">
          <Link
            to="/uzbekistan-germanyuz"
            className="tegishli-malumotlar-navlink10"
          >
            <div className="tegishli-malumotlar-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="tegishli-malumotlar-icon10"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="tegishli-malumotlar-text101">Бош саҳифа</h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="tegishli-malumotlar-navlink11"
          >
            <div className="tegishli-malumotlar-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="tegishli-malumotlar-icon12"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="tegishli-malumotlar-text102">Умумий</h1>
            </div>
          </Link>
          <Link
            to="/uzbekistan-germanyuz"
            className="tegishli-malumotlar-navlink12"
          >
            <div className="tegishli-malumotlar-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="tegishli-malumotlar-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="tegishli-malumotlar-text103">
                Тегишли маълумотлар
              </h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="tegishli-malumotlar-container16">
        <div className="tegishli-malumotlar-container17">
          <div className="tegishli-malumotlar-container18">
            <span className="tegishli-malumotlar-text104">Умумий</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="tegishli-malumotlar-icon16"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="tegishli-malumotlar-image10"
          />
          <div className="tegishli-malumotlar-container19">
            <Link
              to="/submission-collection"
              className="tegishli-malumotlar-navlink13"
            >
              <div className="tegishli-malumotlar-container20">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="tegishli-malumotlar-image11"
                />
                <span className="tegishli-malumotlar-text105">
                  Ҳужжатларни топшириш/Ҳужжатларни олиш
                </span>
              </div>
            </Link>
            <Link
              to="/tegishli-malumotlar"
              className="tegishli-malumotlar-navlink14"
            >
              <div className="tegishli-malumotlar-container21">
                <img
                  alt="image"
                  src="/rectangle%2030.svg"
                  className="tegishli-malumotlar-image12"
                />
                <span className="tegishli-malumotlar-text106">
                  Тегишли маълумотлар
                </span>
              </div>
            </Link>
            <Link
              to="/rasmiy-bayramlar-kuni"
              className="tegishli-malumotlar-navlink15"
            >
              <div className="tegishli-malumotlar-container22">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="tegishli-malumotlar-image13"
                />
                <span className="tegishli-malumotlar-text107">
                  Расмий байрамлар куни
                </span>
              </div>
            </Link>
            <Link
              to="/arizalarni-krib-chiish-muddati"
              className="tegishli-malumotlar-navlink16"
            >
              <div className="tegishli-malumotlar-container23">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="tegishli-malumotlar-image14"
                />
                <span className="tegishli-malumotlar-text108">
                  Аризаларни кўриб чиқиш муддати
                </span>
              </div>
            </Link>
            <Link
              to="/biometrik-malumotlarini"
              className="tegishli-malumotlar-navlink17"
            >
              <div className="tegishli-malumotlar-container24">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="tegishli-malumotlar-image15"
                />
                <span className="tegishli-malumotlar-text109">
                  биометрик маълумотларини
                </span>
              </div>
            </Link>
            <Link
              to="/tlov-shartlari"
              className="tegishli-malumotlar-navlink18"
            >
              <div className="tegishli-malumotlar-container25">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="tegishli-malumotlar-image16"
                />
                <span className="tegishli-malumotlar-text110">
                  Тўлов Шартлари
                </span>
              </div>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="tegishli-malumotlar-navlink19"
            >
              <div className="tegishli-malumotlar-container26">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="tegishli-malumotlar-image17"
                />
                <span className="tegishli-malumotlar-text111">
                  Техника хавфсизлиги кўрсатмалари
                </span>
              </div>
            </Link>
            <Link
              to="/foydali-avolalar-uz"
              className="tegishli-malumotlar-navlink20"
            >
              <div className="tegishli-malumotlar-container27">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="tegishli-malumotlar-image18"
                />
                <span className="tegishli-malumotlar-text112">
                  Фойдали ҳаволалар
                </span>
              </div>
            </Link>
          </div>
        </div>
        <div className="tegishli-malumotlar-container28">
          <div className="tegishli-malumotlar-container29">
            <div className="tegishli-malumotlar-container30">
              <div className="tegishli-malumotlar-container31">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="tegishli-malumotlar-icon18"
                >
                  <path
                    d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m0 16H5V10h14zM9 14H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2zm-8 4H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2z"
                    fill="currentColor"
                  ></path>
                </svg>
                <span className="tegishli-malumotlar-text113">
                  сентйабр 2024
                </span>
              </div>
              <div className="tegishli-malumotlar-container32">
                <span className="tegishli-malumotlar-text114">17</span>
              </div>
            </div>
            <div className="tegishli-malumotlar-container33">
              <span className="tegishli-malumotlar-text115">
                Қўшимча хизматларга оид муҳим янгиланиш!!!
              </span>
              <span className="tegishli-malumotlar-text116">
                <span>
                  Ҳурматли аризачилар! &amp;nbsp; Эслатиб ўтамиз, 16-сентабрдан
                  бошлаб Ўзбекистон бўйлаб курерлик хизматининг паспорт етказиб
                  бериш хизматидан фойда...
                </span>
                <br className="tegishli-malumotlar-text118"></br>
              </span>
              <button
                type="button"
                className="tegishli-malumotlar-button1 button"
              >
                <span className="tegishli-malumotlar-text119">
                  {' '}
                  Давомини о&apos;qинг
                </span>
              </button>
            </div>
          </div>
          <div className="tegishli-malumotlar-container34">
            <div className="tegishli-malumotlar-container35">
              <div className="tegishli-malumotlar-container36">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="tegishli-malumotlar-icon20"
                >
                  <path
                    d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m0 16H5V10h14zM9 14H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2zm-8 4H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2z"
                    fill="currentColor"
                  ></path>
                </svg>
                <span className="tegishli-malumotlar-text120">ийул 2024</span>
              </div>
              <div className="tegishli-malumotlar-container37">
                <span className="tegishli-malumotlar-text121">10</span>
              </div>
            </div>
            <div className="tegishli-malumotlar-container38">
              <span className="tegishli-malumotlar-text122">
                <span>
                  Sizlardan viza hujjatlarini oldindan topshirishingizni
                  so&apos;raymiz.
                </span>
                <br></br>
              </span>
              <span className="tegishli-malumotlar-text125">
                <span>
                  Hurmatli arizachilar, &amp;nbsp; Sizlardan viza hujjatlarini
                  oldindan topshirishingizni so&apos;raymiz. Shengen Shartnomasi
                  davlatlari Vizasi Kodeksiga k...
                </span>
                <br className="tegishli-malumotlar-text127"></br>
              </span>
              <button
                type="button"
                className="tegishli-malumotlar-button2 button"
              >
                <span className="tegishli-malumotlar-text128">
                  {' '}
                  Давомини о&apos;qинг
                </span>
              </button>
            </div>
          </div>
          <div className="tegishli-malumotlar-container39">
            <div className="tegishli-malumotlar-container40">
              <div className="tegishli-malumotlar-container41">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="tegishli-malumotlar-icon22"
                >
                  <path
                    d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m0 16H5V10h14zM9 14H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2zm-8 4H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2z"
                    fill="currentColor"
                  ></path>
                </svg>
                <span className="tegishli-malumotlar-text129">ийул 2024</span>
              </div>
              <div className="tegishli-malumotlar-container42">
                <span className="tegishli-malumotlar-text130">08</span>
              </div>
            </div>
            <div className="tegishli-malumotlar-container43">
              <span className="tegishli-malumotlar-text131">
                <span>Қўшимча хизматларга оид муҳим янгиланиш</span>
                <br></br>
              </span>
              <span className="tegishli-malumotlar-text134">
                <span>
                  Ҳурматли аризачилар! Сизга шуни малум киламизки, тез орада
                  виза учун ариза топшириш жароёнида VIP хизматидан фойдаланиш
                  имконияти мавжуд булади. Ру...
                </span>
                <br className="tegishli-malumotlar-text136"></br>
              </span>
              <button
                type="button"
                className="tegishli-malumotlar-button3 button"
              >
                <span className="tegishli-malumotlar-text137">
                  {' '}
                  Давомини о&apos;qинг
                </span>
              </button>
            </div>
          </div>
          <div className="tegishli-malumotlar-container44">
            <div className="tegishli-malumotlar-container45">
              <div className="tegishli-malumotlar-container46">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="tegishli-malumotlar-icon24"
                >
                  <path
                    d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m0 16H5V10h14zM9 14H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2zm-8 4H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2z"
                    fill="currentColor"
                  ></path>
                </svg>
                <span className="tegishli-malumotlar-text138">
                  <span> Апрел 2024</span>
                  <br></br>
                </span>
              </div>
              <div className="tegishli-malumotlar-container47">
                <span className="tegishli-malumotlar-text141">31</span>
              </div>
            </div>
            <div className="tegishli-malumotlar-container48">
              <span className="tegishli-malumotlar-text142">
                <span>Hurmatli Arizachilar,</span>
                <br></br>
              </span>
              <span className="tegishli-malumotlar-text145">
                <span>
                  2024-yil 11-iyundan boshlab quyidagi o&apos;zgarishlar Shengen
                  vizalari uchun to&apos;lovlarga tatbiq etiladi: • Viza
                  kodeksining 16-moddasi, 1-qismi bo&apos;yicha S...
                </span>
                <br className="tegishli-malumotlar-text147"></br>
              </span>
              <button
                type="button"
                className="tegishli-malumotlar-button4 button"
              >
                <span className="tegishli-malumotlar-text148">
                  {' '}
                  Давомини о&apos;qинг
                </span>
              </button>
            </div>
          </div>
          <div className="tegishli-malumotlar-container49">
            <div className="tegishli-malumotlar-container50">
              <div className="tegishli-malumotlar-container51">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="tegishli-malumotlar-icon26"
                >
                  <path
                    d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m0 16H5V10h14zM9 14H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2zm-8 4H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2z"
                    fill="currentColor"
                  ></path>
                </svg>
                <span className="tegishli-malumotlar-text149">
                  <span>нойабр 2023</span>
                  <br></br>
                </span>
              </div>
              <div className="tegishli-malumotlar-container52">
                <span className="tegishli-malumotlar-text152">03</span>
              </div>
            </div>
            <div className="tegishli-malumotlar-container53">
              <span className="tegishli-malumotlar-text153">
                <span>Qabul qilish haqida muhim ma`lumot!</span>
                <br></br>
              </span>
              <span className="tegishli-malumotlar-text156">
                <span>
                  Hurmatli arizachilar! Toshkent shahrida biometrik
                  ma`lumotlarni qabul qilish (barmoq izlari) 02.11.2023 yildan
                  boshlab yangilanganini haqida ma`lum...
                </span>
                <br className="tegishli-malumotlar-text158"></br>
              </span>
              <button
                type="button"
                className="tegishli-malumotlar-button5 button"
              >
                <span className="tegishli-malumotlar-text159">
                  {' '}
                  Давомини о&apos;qинг
                </span>
              </button>
            </div>
          </div>
          <div className="tegishli-malumotlar-container54">
            <div className="tegishli-malumotlar-container55">
              <div className="tegishli-malumotlar-container56">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="tegishli-malumotlar-icon28"
                >
                  <path
                    d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m0 16H5V10h14zM9 14H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2zm-8 4H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2z"
                    fill="currentColor"
                  ></path>
                </svg>
                <span className="tegishli-malumotlar-text160">Май 2023</span>
              </div>
              <div className="tegishli-malumotlar-container57">
                <span className="tegishli-malumotlar-text161">18</span>
              </div>
            </div>
            <div className="tegishli-malumotlar-container58">
              <span className="tegishli-malumotlar-text162">
                Миллий (Д) визалар малумот
              </span>
              <span className="tegishli-malumotlar-text163">
                <span>
                  Ҳурматли аризачилар. Маълум қиламизки,Висаметрик визага
                  мурожаат қилиш марказига учрашув кунини белгилаш учун имкон
                  қадар визанинг тўғри турини тан...
                </span>
                <br className="tegishli-malumotlar-text165"></br>
              </span>
              <button
                type="button"
                className="tegishli-malumotlar-button6 button"
              >
                <span className="tegishli-malumotlar-text166">
                  {' '}
                  Давомини о&apos;qинг
                </span>
              </button>
            </div>
          </div>
          <div className="tegishli-malumotlar-container59">
            <div className="tegishli-malumotlar-container60">
              <div className="tegishli-malumotlar-container61">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="tegishli-malumotlar-icon30"
                >
                  <path
                    d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m0 16H5V10h14zM9 14H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2zm-8 4H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2z"
                    fill="currentColor"
                  ></path>
                </svg>
                <span className="tegishli-malumotlar-text167">Апрел 2023</span>
              </div>
              <div className="tegishli-malumotlar-container62">
                <span className="tegishli-malumotlar-text168">12</span>
              </div>
            </div>
            <div className="tegishli-malumotlar-container63">
              <span className="tegishli-malumotlar-text169">
                Висаметрик Ташкентдаги ташкилоти Финляндия давлат учун Шенген
                виза аризалаларни қабул қилишни тўхтатади
              </span>
              <span className="tegishli-malumotlar-text170">
                <span>
                  урматли аризачилар, Германия елчихонаси фақат VisaMetric
                  хизмати ҳамкори билан ишлайди. Учинчи томон хизмат кўрсатувчи
                  провайдердан фойдаланмаслик...
                </span>
                <br className="tegishli-malumotlar-text172"></br>
              </span>
              <button
                type="button"
                className="tegishli-malumotlar-button7 button"
              >
                <span className="tegishli-malumotlar-text173">
                  {' '}
                  Давомини о&apos;qинг
                </span>
              </button>
            </div>
          </div>
          <div className="tegishli-malumotlar-container64">
            <div className="tegishli-malumotlar-container65">
              <div className="tegishli-malumotlar-container66">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="tegishli-malumotlar-icon32"
                >
                  <path
                    d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m0 16H5V10h14zM9 14H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2zm-8 4H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2z"
                    fill="currentColor"
                  ></path>
                </svg>
                <span className="tegishli-malumotlar-text174">Апрел 2023</span>
              </div>
              <div className="tegishli-malumotlar-container67">
                <span className="tegishli-malumotlar-text175">03</span>
              </div>
            </div>
            <div className="tegishli-malumotlar-container68">
              <span className="tegishli-malumotlar-text176">
                Муҳим маълумотлар - Ўзбекистон
              </span>
              <span className="tegishli-malumotlar-text177">
                <span>
                  Ҳурматли аризачилар, Сизга шуни маълум қиламизки, 13.04.23
                  санасидан бошлаб Висаметрик Ташкентдаги ташкилоти Финляндия
                  давлат учун Шенген виза ариз...
                </span>
                <br className="tegishli-malumotlar-text179"></br>
              </span>
              <button
                type="button"
                className="tegishli-malumotlar-button8 button"
              >
                <span className="tegishli-malumotlar-text180">
                  {' '}
                  Давомини о&apos;qинг
                </span>
              </button>
            </div>
          </div>
          <div className="tegishli-malumotlar-container69">
            <div className="tegishli-malumotlar-container70">
              <div className="tegishli-malumotlar-container71">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="tegishli-malumotlar-icon34"
                >
                  <path
                    d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m0 16H5V10h14zM9 14H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2zm-8 4H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2z"
                    fill="currentColor"
                  ></path>
                </svg>
                <span className="tegishli-malumotlar-text181">
                  <span> нойабр 2022</span>
                  <br></br>
                </span>
              </div>
              <div className="tegishli-malumotlar-container72">
                <span className="tegishli-malumotlar-text184">01</span>
              </div>
            </div>
            <div className="tegishli-malumotlar-container73">
              <span className="tegishli-malumotlar-text185">
                <span>
                  Au-Pair sifatida faoliyat uchun milliy viza (90 kundan ortiq
                  qolish)
                </span>
                <br></br>
              </span>
              <span className="tegishli-malumotlar-text188">
                <span>
                  Milliy viza Hurmatli arizachilsar, Sizga shuni ma’lum
                  qilamizki, 15.11.2022 dan boshlab Germaniyaning Toshkent
                  shahridagi viza olish markazi Au-Pai...
                </span>
                <br className="tegishli-malumotlar-text190"></br>
              </span>
              <button
                type="button"
                className="tegishli-malumotlar-button9 button"
              >
                <span className="tegishli-malumotlar-text191">
                  {' '}
                  Давомини о&apos;qинг
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="header" className="tegishli-malumotlar-container74">
        <Link to="/" className="tegishli-malumotlar-navlink21">
          <div className="tegishli-malumotlar-container75">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="tegishli-malumotlar-image19"
            />
            <p className="tegishli-malumotlar-text192">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="tegishli-malumotlar-container76">
          <div className="tegishli-malumotlar-container77">
            <span className="tegishli-malumotlar-text195">
              <span>Apply for Visa to</span>
              <span className="tegishli-malumotlar-text197"> Germany</span>
            </span>
            <span className="tegishli-malumotlar-text198">
              <span className="tegishli-malumotlar-text199">in Uzbekistan</span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="tegishli-malumotlar-image20"
          />
          <span className="tegishli-malumotlar-text230">|</span>
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header
        data-thq="thq-navbar"
        className="tegishli-malumotlar-navbar-interactive"
      >
        <div
          data-thq="thq-navbar-nav"
          className="tegishli-malumotlar-desktop-menu"
        >
          <div className="tegishli-malumotlar-container78">
            <nav className="tegishli-malumotlar-links1">
              <div
                data-thq="thq-dropdown"
                className="tegishli-malumotlar-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="tegishli-malumotlar-dropdown-toggle10"
                  >
                    <span className="tegishli-malumotlar-text231">
                      Бош сахифа
                    </span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="tegishli-malumotlar-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="tegishli-malumotlar-dropdown-toggle11"
                >
                  <span className="tegishli-malumotlar-text232">Умумий</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="tegishli-malumotlar-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="tegishli-malumotlar-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle12"
                      >
                        <span className="tegishli-malumotlar-text233 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle13"
                      >
                        <span className="tegishli-malumotlar-text236 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle14"
                      >
                        <span className="tegishli-malumotlar-text237 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle15"
                      >
                        <span className="tegishli-malumotlar-text238 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle16"
                      >
                        <span className="tegishli-malumotlar-text243 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle17"
                      >
                        <span className="tegishli-malumotlar-text244 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle18"
                      >
                        <span className="tegishli-malumotlar-text245 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle19"
                      >
                        <span className="tegishli-malumotlar-text249 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="tegishli-malumotlar-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="tegishli-malumotlar-dropdown-toggle20"
                >
                  <span className="tegishli-malumotlar-text250">Виза олиш</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="tegishli-malumotlar-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="tegishli-malumotlar-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle21"
                      >
                        <span className="tegishli-malumotlar-text251">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle22"
                      >
                        <span className="tegishli-malumotlar-text252">
                          Миллий виза
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle23"
                      >
                        <span className="tegishli-malumotlar-text253">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle24"
                      >
                        <span className="tegishli-malumotlar-text254">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle25"
                      >
                        <span className="tegishli-malumotlar-text257">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle26"
                      >
                        <span className="tegishli-malumotlar-text261">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle27"
                      >
                        <span className="tegishli-malumotlar-text262">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="tegishli-malumotlar-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="tegishli-malumotlar-dropdown-toggle28"
                >
                  <span className="tegishli-malumotlar-text263">
                    Кушимча хизматлар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="tegishli-malumotlar-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="tegishli-malumotlar-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle29"
                      >
                        <span className="tegishli-malumotlar-text264">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle30"
                      >
                        <span className="tegishli-malumotlar-text265">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle31"
                      >
                        <span className="tegishli-malumotlar-text269">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle32"
                      >
                        <span className="tegishli-malumotlar-text272">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle33"
                      >
                        <span className="tegishli-malumotlar-text273">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle34"
                      >
                        <span className="tegishli-malumotlar-text277">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle35"
                      >
                        <span className="tegishli-malumotlar-text278">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="tegishli-malumotlar-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="tegishli-malumotlar-dropdown-toggle38"
                  >
                    <span className="tegishli-malumotlar-text281">FAQ</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="tegishli-malumotlar-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="tegishli-malumotlar-dropdown-toggle39"
                >
                  <span className="tegishli-malumotlar-text282">Алокалар</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="tegishli-malumotlar-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="tegishli-malumotlar-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle40"
                      >
                        <span className="tegishli-malumotlar-text283">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle41"
                      >
                        <span className="tegishli-malumotlar-text284">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle42"
                      >
                        <span className="tegishli-malumotlar-text285">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="tegishli-malumotlar-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="tegishli-malumotlar-dropdown-toggle43"
                      >
                        <span className="tegishli-malumotlar-text286">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="tegishli-malumotlar-container79">
          <input
            type="text"
            placeholder="Кидирмок"
            className="tegishli-malumotlar-textinput1 input"
          />
          <div className="tegishli-malumotlar-container80">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="tegishli-malumotlar-icon36"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="tegishli-malumotlar-container81">
            <span className="tegishli-malumotlar-text289">A+</span>
            <span className="tegishli-malumotlar-text290">A-</span>
          </div>
        </div>
        <div
          data-thq="thq-burger-menu"
          className="tegishli-malumotlar-burger-menu"
        >
          <svg viewBox="0 0 1024 1024" className="tegishli-malumotlar-icon38">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div
          data-thq="thq-mobile-menu"
          className="tegishli-malumotlar-mobile-menu"
        >
          <div className="tegishli-malumotlar-nav">
            <div className="tegishli-malumotlar-container82">
              <div className="tegishli-malumotlar-container83">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="tegishli-malumotlar-textinput2 input"
                />
                <div className="tegishli-malumotlar-container84">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="tegishli-malumotlar-icon40"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="tegishli-malumotlar-container85">
                  <span className="tegishli-malumotlar-text291">A+</span>
                  <span className="tegishli-malumotlar-text292">A-</span>
                </div>
              </div>
              <div
                data-thq="thq-close-menu"
                className="tegishli-malumotlar-close-menu"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="tegishli-malumotlar-icon42"
                >
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="tegishli-malumotlar-links2">
            <div
              data-thq="thq-dropdown"
              className="tegishli-malumotlar-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="tegishli-malumotlar-dropdown-toggle44"
                >
                  <span className="tegishli-malumotlar-text293">
                    Бош сахифа
                  </span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="tegishli-malumotlar-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="tegishli-malumotlar-dropdown-toggle45"
              >
                <span className="tegishli-malumotlar-text294">Умумий</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="tegishli-malumotlar-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="tegishli-malumotlar-icon44"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="tegishli-malumotlar-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle46"
                    >
                      <span className="tegishli-malumotlar-text295">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle47"
                    >
                      <span className="tegishli-malumotlar-text298">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle48"
                    >
                      <span className="tegishli-malumotlar-text299">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle49"
                    >
                      <span className="tegishli-malumotlar-text300">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle50"
                    >
                      <span className="tegishli-malumotlar-text305">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle51"
                    >
                      <span className="tegishli-malumotlar-text306">
                        Тулов Шартлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle52"
                    >
                      <span className="tegishli-malumotlar-text307">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle53"
                    >
                      <span className="tegishli-malumotlar-text310">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="tegishli-malumotlar-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="tegishli-malumotlar-dropdown-toggle54"
              >
                <span className="tegishli-malumotlar-text311">Виза олиш</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="tegishli-malumotlar-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="tegishli-malumotlar-icon46"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="tegishli-malumotlar-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle55"
                    >
                      <span className="tegishli-malumotlar-text312">
                        Шенген визаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle56"
                    >
                      <span className="tegishli-malumotlar-text313">
                        Миллий виза
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle57"
                    >
                      <span className="tegishli-malumotlar-text314">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle58"
                    >
                      <span className="tegishli-malumotlar-text315">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle59"
                    >
                      <span className="tegishli-malumotlar-text318">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle60"
                    >
                      <span className="tegishli-malumotlar-text322">
                        Ариза шакллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle61"
                    >
                      <span className="tegishli-malumotlar-text323">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="tegishli-malumotlar-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="tegishli-malumotlar-dropdown-toggle62"
              >
                <span className="tegishli-malumotlar-text324">
                  Кушимча хизматлар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="tegishli-malumotlar-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="tegishli-malumotlar-icon48"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="tegishli-malumotlar-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle63"
                    >
                      <span className="tegishli-malumotlar-text325">
                        VIP - хизмати
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle64"
                    >
                      <span className="tegishli-malumotlar-text326">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle65"
                    >
                      <span className="tegishli-malumotlar-text327">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle66"
                    >
                      <span className="tegishli-malumotlar-text328">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle67"
                    >
                      <span className="tegishli-malumotlar-text329">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle68"
                    >
                      <span className="tegishli-malumotlar-text332">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle69"
                    >
                      <span className="tegishli-malumotlar-text335">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle70"
                    >
                      <span className="tegishli-malumotlar-text336">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle71"
                    >
                      <span className="tegishli-malumotlar-text337">
                        Prime Тиме
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="tegishli-malumotlar-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="tegishli-malumotlar-dropdown-toggle72"
                >
                  <span className="tegishli-malumotlar-text338">FAQ</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="tegishli-malumotlar-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="tegishli-malumotlar-dropdown-toggle73"
              >
                <span className="tegishli-malumotlar-text339">Алокалар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="tegishli-malumotlar-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="tegishli-malumotlar-icon50"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="tegishli-malumotlar-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle74"
                    >
                      <span className="tegishli-malumotlar-text340">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle75"
                    >
                      <span className="tegishli-malumotlar-text341">
                        Алока маркази
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle76"
                    >
                      <span className="tegishli-malumotlar-text342">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="tegishli-malumotlar-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="tegishli-malumotlar-dropdown-toggle77"
                    >
                      <span className="tegishli-malumotlar-text343">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="tegishli-malumotlar-container86">
        <div className="tegishli-malumotlar-container87">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="tegishli-malumotlar-image22"
          />
          <span className="tegishli-malumotlar-text346">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="tegishli-malumotlar-container88">
          <div className="tegishli-malumotlar-container89">
            <span className="tegishli-malumotlar-text347">
              Ходимларни бошқариш
            </span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="tegishli-malumotlar-image23"
            />
          </div>
          <div className="tegishli-malumotlar-container90">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="tegishli-malumotlar-navlink82"
            >
              <div className="tegishli-malumotlar-container91">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="tegishli-malumotlar-icon52"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="tegishli-malumotlar-text348">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="tegishli-malumotlar-navlink83"
            >
              <div className="tegishli-malumotlar-container92">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="tegishli-malumotlar-icon54"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="tegishli-malumotlar-text349">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="tegishli-malumotlar-navlink84"
            >
              <div className="tegishli-malumotlar-container93">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="tegishli-malumotlar-icon56"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="tegishli-malumotlar-text353">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="tegishli-malumotlar-navlink85"
            >
              <div className="tegishli-malumotlar-container94">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="tegishli-malumotlar-icon58"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="tegishli-malumotlar-text357">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="tegishli-malumotlar-navlink86"
            >
              <div className="tegishli-malumotlar-container95">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="tegishli-malumotlar-icon60"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="tegishli-malumotlar-text361">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="tegishli-malumotlar-container96">
        <div className="tegishli-malumotlar-container97">
          <span className="tegishli-malumotlar-text365">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="tegishli-malumotlar-container98">
            <Link
              to="/visametric-gurukhi"
              className="tegishli-malumotlar-navlink87"
            >
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="tegishli-malumotlar-navlink88"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/w3c-logo2-200h.png"
          className="tegishli-malumotlar-image24"
        />
        <a href="#main" className="tegishli-malumotlar-link">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="tegishli-malumotlar-image25"
          />
        </a>
      </div>
    </div>
  )
}

export default TegishliMalumotlar
