import React from 'react'
import { Link } from 'react-router-dom'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import './office.css'

const Office = (props) => {
  return (
    <div className="office-container10">
      <Helmet>
        <title>office - Visametric</title>
        <meta property="og:title" content="office - Visametric" />
      </Helmet>
      <div data-thq="thq-burger-menu" className="office-burger-menu1">
        <svg viewBox="0 0 1024 1024" className="office-icon10">
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div id="main" className="office-container11">
        <h1 className="office-text100">VisaMetric филиаллари</h1>
        <div className="office-container12">
          <Link to="/uzbekistan-germanyuz" className="office-navlink10">
            <div className="office-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="office-icon12"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="office-text101">Бош саҳифа</h1>
            </div>
          </Link>
          <Link to="/uzbekistan-germanyuz" className="office-navlink11">
            <div className="office-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="office-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="office-text102">Алоқалар</h1>
            </div>
          </Link>
          <Link to="/uzbekistan-germanyuz" className="office-navlink12">
            <div className="office-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="office-icon16"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="office-text103">VisaMetric филиаллари</h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="office-container16">
        <div className="office-container17">
          <div className="office-container18">
            <span className="office-text104">Алоқалар</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="office-icon18"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="office-image10"
          />
          <div className="office-container19">
            <Link to="/office" className="office-navlink13">
              <div className="office-container20">
                <img
                  alt="image"
                  src="/rectangle%2030.svg"
                  className="office-image11"
                />
                <span className="office-text105">VisaMetric филиаллари</span>
              </div>
            </Link>
            <Link to="/callcenter" className="office-navlink14">
              <div className="office-container21">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="office-image12"
                />
                <span className="office-text106">Алоқа маркази</span>
              </div>
            </Link>
            <Link to="/contact" className="office-navlink15">
              <div className="office-container22">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="office-image13"
                />
                <span className="office-text107">Муштарий Анкетаси</span>
              </div>
            </Link>
            <Link to="/contact-form" className="office-navlink16">
              <div className="office-container23">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="office-image14"
                />
                <span className="office-text108">Қайта алока шакли</span>
              </div>
            </Link>
          </div>
        </div>
        <div className="office-container24">
          <a
            href="https:// https://ais.usvisa-info.com/en-uz/niv/information/contact_us"
            target="_blank"
            rel="noreferrer noopener"
            className="office-link1"
          >
            <span>
              Бу ердан сиз офиснинг иш вақтлари ва манзиллари ҳақида батафсил
              маълумотни топасиз.
            </span>
            <br className="office-text110"></br>
            <br className="office-text111"></br>
            <span>
              Bizning qo‘ng‘iroqlar markazimiz faqat Germaniyaga viza arizalari
              bo‘yicha ma&apos;lumot beradi. AQSh uchun viza arizalari
              bo&apos;yicha maslahatlar uchun quyidagi havolada keltirilgan
              aloqa ma&apos;lumotlaridan foydalaning:
            </span>
            <span className="office-text113">
              {' '}
              https://ais.usvisa-info.com/en-uz/niv/information/contact_us
            </span>
            <br className="office-text114"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </a>
          <div className="office-container25">
            <div className="office-container26">
              <Script
                html={`<div class="panel-body">

                                                    <h6 style="font-weight: 600; background-color: #DDDDDD; padding: 5px !important;">Тошкент</h6>
                                                    <hr>
                                                    <p style="margin-top: 15px;">
                                                            Сизга филиалларимизда ариза  топшириш учун веб сайтимизга учрашув вақтини киритишингизни маслаҳат берамиз. Бизнинг “VisaMetric”  филиалларидаги қўшимча хизматлар:  биометрик сурат, нусха кўчириш.  Қўшимча хизматлар хақида тўлиқ маьлумот олиш учун “қўшимча маьлумотлар” саҳифасига киринг. 
                                                    </p>

                                                                                                            <p class="col-md-12" style="margin-bottom: 10px;"><span class="col-md-4"><strong>Телефон</strong></span>
                                                            <span class="col-md-8">+998 (78) 120-09-93 </span></p>
                                                                                                                                                                <p class="col-md-12" style="margin-bottom: 10px;"><span class="col-md-4"><strong>Факс</strong> </span><span class="col-md-8">   info.uzbekistan@visametric.com</span></p>
                                                                                                                                                                <p class="col-md-12" style="margin-bottom: 10px;"><span class="col-md-4"><strong>Манзил</strong></span>
                                                            <span class="col-md-8">  100001 Toshkent, Shahrisabz proezd, 5A, “Seul Plaza” biznes markazi, 5 qavat</span></p>
                                                                                                                                                                <p class="col-md-12" style="margin-bottom: 10px;">
                                                            <span class="col-md-4"><strong>Аризани қабул қилиш куни</strong></span>
                                                            <span class="col-md-8">
														
														Душ-Жум 9:00-16:00														</span>
                                                        </p>
                                                                                                                                                                <p class="col-md-12" style="margin-bottom: 10px;"><span class="col-md-4"><strong>Хужжатни тарқатиш куни</strong></span>
                                                            <span class="col-md-8">
													
													Душ-Жум 9:00-18:00													</span>
                                                        </p>
                                                                                                                                                                <p class="col-md-12" style="margin-bottom: 10px;"> <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1498.5796469698626!2d69.28143597162824!3d41.30539814178826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8bbddca3f8b5%3A0xdbfbb7e48305c36f!2z0JLQuNC30L7QstGL0Lkg0YbQtdC90YLRgCDQk9C10YDQvNCw0L3QuNC4IFZpc2FNZXRyaWM!5e0!3m2!1sru!2sru!4v1681373764512!5m2!1sru!2sru" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></p>
                                                                                                    </div>`}
              ></Script>
            </div>
          </div>
        </div>
      </div>
      <div id="header" className="office-container27">
        <Link to="/" className="office-navlink17">
          <div className="office-container28">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="office-image15"
            />
            <p className="office-text116">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="office-container29">
          <div className="office-container30">
            <span className="office-text119">
              <span>Apply for Visa to</span>
              <span className="office-text121"> Germany</span>
            </span>
            <span className="office-text122">
              <span className="office-text123">in Uzbekistan</span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="office-image16"
          />
          <span className="office-text154">|</span>
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header data-thq="thq-navbar" className="office-navbar-interactive">
        <div data-thq="thq-navbar-nav" className="office-desktop-menu">
          <div className="office-container31">
            <nav className="office-links1">
              <div
                data-thq="thq-dropdown"
                className="office-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="office-dropdown-toggle10"
                  >
                    <span className="office-text155">Бош сахифа</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="office-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="office-dropdown-toggle11"
                >
                  <span className="office-text156">Умумий</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="office-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="office-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle12"
                      >
                        <span className="office-text157 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle13"
                      >
                        <span className="office-text160 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle14"
                      >
                        <span className="office-text161 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle15"
                      >
                        <span className="office-text162 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle16"
                      >
                        <span className="office-text167 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle17"
                      >
                        <span className="office-text168 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle18"
                      >
                        <span className="office-text169 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle19"
                      >
                        <span className="office-text173 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="office-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="office-dropdown-toggle20"
                >
                  <span className="office-text174">Виза олиш</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="office-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="office-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle21"
                      >
                        <span className="office-text175">Шенген визаси</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle22"
                      >
                        <span className="office-text176">Миллий виза</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle23"
                      >
                        <span className="office-text177">Онлайн-суровнома</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle24"
                      >
                        <span className="office-text178">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle25"
                      >
                        <span className="office-text181">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle26"
                      >
                        <span className="office-text185">Ариза шакллари</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle27"
                      >
                        <span className="office-text186">Иловани кузатиш</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="office-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="office-dropdown-toggle28"
                >
                  <span className="office-text187">Кушимча хизматлар</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="office-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="office-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle29"
                      >
                        <span className="office-text188">VIP - хизмати</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle30"
                      >
                        <span className="office-text189">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle31"
                      >
                        <span className="office-text193">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle32"
                      >
                        <span className="office-text196">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle33"
                      >
                        <span className="office-text197">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle34"
                      >
                        <span className="office-text201">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle35"
                      >
                        <span className="office-text202">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="office-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="office-dropdown-toggle38"
                  >
                    <span className="office-text205">FAQ</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="office-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="office-dropdown-toggle39"
                >
                  <span className="office-text206">Алокалар</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="office-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="office-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle40"
                      >
                        <span className="office-text207">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle41"
                      >
                        <span className="office-text208">Алока маркази</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle42"
                      >
                        <span className="office-text209">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="office-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="office-dropdown-toggle43"
                      >
                        <span className="office-text210">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="office-container32">
          <input
            type="text"
            placeholder="Кидирмок"
            className="office-textinput1 input"
          />
          <div className="office-container33">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="office-icon20"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="office-container34">
            <span className="office-text213">A+</span>
            <span className="office-text214">A-</span>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="office-burger-menu2">
          <svg viewBox="0 0 1024 1024" className="office-icon22">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="office-mobile-menu">
          <div className="office-nav">
            <div className="office-container35">
              <div className="office-container36">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="office-textinput2 input"
                />
                <div className="office-container37">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="office-icon24"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="office-container38">
                  <span className="office-text215">A+</span>
                  <span className="office-text216">A-</span>
                </div>
              </div>
              <div data-thq="thq-close-menu" className="office-close-menu">
                <svg viewBox="0 0 1024 1024" className="office-icon26">
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="office-links2">
            <div
              data-thq="thq-dropdown"
              className="office-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="office-dropdown-toggle44"
                >
                  <span className="office-text217">Бош сахифа</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="office-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="office-dropdown-toggle45"
              >
                <span className="office-text218">Умумий</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="office-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="office-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="office-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle46"
                    >
                      <span className="office-text219">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle47"
                    >
                      <span className="office-text222">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle48"
                    >
                      <span className="office-text223">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle49"
                    >
                      <span className="office-text224">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle50"
                    >
                      <span className="office-text229">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle51"
                    >
                      <span className="office-text230">Тулов Шартлари</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle52"
                    >
                      <span className="office-text231">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle53"
                    >
                      <span className="office-text234">Фойдали хаволалар</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="office-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="office-dropdown-toggle54"
              >
                <span className="office-text235">Виза олиш</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="office-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="office-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="office-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle55"
                    >
                      <span className="office-text236">Шенген визаси</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle56"
                    >
                      <span className="office-text237">Миллий виза</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle57"
                    >
                      <span className="office-text238">Онлайн-суровнома</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle58"
                    >
                      <span className="office-text239">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle59"
                    >
                      <span className="office-text242">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle60"
                    >
                      <span className="office-text246">Ариза шакллари</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle61"
                    >
                      <span className="office-text247">Иловани кузатиш</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="office-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="office-dropdown-toggle62"
              >
                <span className="office-text248">Кушимча хизматлар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="office-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="office-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="office-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle63"
                    >
                      <span className="office-text249">VIP - хизмати</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle64"
                    >
                      <span className="office-text250">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle65"
                    >
                      <span className="office-text251">Биометрик фотосура</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle66"
                    >
                      <span className="office-text252">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle67"
                    >
                      <span className="office-text253">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle68"
                    >
                      <span className="office-text256">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle69"
                    >
                      <span className="office-text259">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle70"
                    >
                      <span className="office-text260">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle71"
                    >
                      <span className="office-text261">Prime Тиме</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="office-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="office-dropdown-toggle72"
                >
                  <span className="office-text262">FAQ</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="office-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="office-dropdown-toggle73"
              >
                <span className="office-text263">Алокалар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="office-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="office-icon34"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="office-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle74"
                    >
                      <span className="office-text264">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle75"
                    >
                      <span className="office-text265">Алока маркази</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle76"
                    >
                      <span className="office-text266">Муштарий Анкетаси</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="office-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="office-dropdown-toggle77"
                    >
                      <span className="office-text267">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="office-container39">
        <div className="office-container40">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="office-image18"
          />
          <span className="office-text270">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="office-container41">
          <div className="office-container42">
            <span className="office-text271">Ходимларни бошқариш</span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="office-image19"
            />
          </div>
          <div className="office-container43">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="office-navlink78"
            >
              <div className="office-container44">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="office-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="office-text272">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link to="/talim-va-malaka-oshirish" className="office-navlink79">
              <div className="office-container45">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="office-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="office-text273">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link to="/masuldorlikni-bosharish" className="office-navlink80">
              <div className="office-container46">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="office-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="office-text277">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="office-navlink81"
            >
              <div className="office-container47">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="office-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="office-text281">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link to="/ishga-zhoylashish-zharaeni" className="office-navlink82">
              <div className="office-container48">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="office-icon44"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="office-text285">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="office-container49">
        <div className="office-container50">
          <span className="office-text289">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="office-container51">
            <Link to="/visametric-gurukhi" className="office-navlink83">
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="office-navlink84"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img alt="image" src="/w3c-logo2-200h.png" className="office-image20" />
        <a href="#main" className="office-link2">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="office-image21"
          />
        </a>
      </div>
    </div>
  )
}

export default Office
