import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './uzbekistan-germanyuz.css';

const UzbekistanGermanyuz = () => {
  const history = useHistory();
  const [dob, setDob] = useState('');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);


  const handleDobChange = (e) => {
    let inputValue = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
    if (inputValue.length > 8) inputValue = inputValue.slice(0, 8); // Limit to 8 characters

    // Format to DD-MM-YYYY
    if (inputValue.length >= 5) {
      inputValue = `${inputValue.slice(0, 2)}-${inputValue.slice(2, 4)}-${inputValue.slice(4)}`;
    } else if (inputValue.length >= 3) {
      inputValue = `${inputValue.slice(0, 2)}-${inputValue.slice(2)}`;
    }

    setDob(inputValue);
  };


  return (
    <div className="uzbekistan-germanyuz-container10">
      <Helmet>
        <title>UzbekistanGermanyuz - Visametric</title>
        <meta property="og:title" content="UzbekistanGermanyuz - Visametric" />
      </Helmet>
      <div id="header" className="uzbekistan-germanyuz-container11">
        <Link to="/" className="uzbekistan-germanyuz-navlink10">
          <div className="uzbekistan-germanyuz-container12">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="uzbekistan-germanyuz-image10"
            />
            <p className="uzbekistan-germanyuz-text100">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="uzbekistan-germanyuz-container13">
          <div className="uzbekistan-germanyuz-container14">
            <span className="uzbekistan-germanyuz-text103">
              <span>Apply for Visa to</span>
              <span className="uzbekistan-germanyuz-text105"> Germany</span>
            </span>
            <span className="uzbekistan-germanyuz-text106">
              <span className="uzbekistan-germanyuz-text107">
                in Uzbekistan
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="uzbekistan-germanyuz-image11"
            />
            <span className="uzbekistan-germanyuz-text138">|</span>
            <div className="language-selector">
              <div className="uzbekistan-germanyuz-elfsight-widget">
        <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
      </div>
</div>
        </div>
      </div>
      <div
        data-thq="thq-burger-menu"
        className="uzbekistan-germanyuz-burger-menu1"
      >
        <svg viewBox="0 0 1024 1024" className="uzbekistan-germanyuz-icon10">
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div id="main" className="uzbekistan-germanyuz-container15">
        <Link
          to="/kerakli-uzhzhatlar"
          className="uzbekistan-germanyuz-navlink11"
        >
          <img
            alt="image"
            src="/3-step_01_uz_uz-200h.png"
            className="uzbekistan-germanyuz-image13"
          />
        </Link>
        <Link
          to="/uchrashuv-sanasini-va-vatini-belgilash"
          className="uzbekistan-germanyuz-navlink12"
        >
          <img
            alt="image"
            src="/3-step_02_uz_uz-200h.png"
            className="uzbekistan-germanyuz-image14"
          />
        </Link>
        <Link
          to="/arizalarni-krib-chiish-muddati"
          className="uzbekistan-germanyuz-navlink13"
        >
          <img
            alt="image"
            src="/3-step_03_uz_uz-200h.png"
            className="uzbekistan-germanyuz-image15"
          />
        </Link>
        <Link to="/shengen-vizasi" className="uzbekistan-germanyuz-navlink14">
          <img
            alt="image"
            src="/schengen-vize_uz-300h.png"
            className="uzbekistan-germanyuz-image16"
          />
        </Link>
      </div>
      <div className="uzbekistan-germanyuz-container16">
        <Link
          to="/kerakli-uzhzhatlar"
          className="uzbekistan-germanyuz-navlink15"
        >
          <div className="uzbekistan-germanyuz-container17">
            <div className="uzbekistan-germanyuz-container18">
              <img
                alt="image"
                src="/union.svg"
                className="uzbekistan-germanyuz-image17"
              />
            </div>
            <div className="uzbekistan-germanyuz-container19">
              <span className="uzbekistan-germanyuz-text139">
                Талаб қилинган ҳужжатлар
              </span>
              <span className="uzbekistan-germanyuz-text140">
                Керакли ҳужжатларни белгиланган кунгача тайёрлаб қўйишингиз
                лозим
              </span>
            </div>
          </div>
        </Link>
        <Link
          to="/uchrashuv-sanasini-va-vatini-belgilash"
          className="uzbekistan-germanyuz-navlink16"
        >
          <div className="uzbekistan-germanyuz-container20">
            <div className="uzbekistan-germanyuz-container21">
              <img
                alt="image"
                src="/vector.svg"
                className="uzbekistan-germanyuz-image18"
              />
            </div>
            <div className="uzbekistan-germanyuz-container22">
              <span className="uzbekistan-germanyuz-text141">
                Қабул кунини белгилаш
              </span>
              <span className="uzbekistan-germanyuz-text142">
                Қабул кунини онлайн тарзда белгиланг ва тўловни олдинан амалга
                оширинг. Белгиланган кун ва вақтда Visametric офисига келинг.
              </span>
            </div>
          </div>
        </Link>
        <div className="uzbekistan-germanyuz-container23">
          <div className="uzbekistan-germanyuz-container24">
            <span className="uzbekistan-germanyuz-text143">Янгиликлар</span>
          </div>
          <div className="uzbekistan-germanyuz-container25">
            <span className="uzbekistan-germanyuz-text144">
              <span>Муҳим маълумотлар - Ўзбекистон ...</span>
              <br></br>
              <span>
                Au-Pair sifatida faoliyat uchun milliy viza (90 kundan ortiq
                qolish)
              </span>
            </span>
            <Link
              to="/tegishli-malumotlar"
              className="uzbekistan-germanyuz-navlink17 button"
            >
              <svg width="8" height="8" viewBox="0 0 24 24">
                <path
                  d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                  fill="currentColor"
                  clip-rule="evenodd"
                  fill-rule="evenodd"
                ></path>
              </svg>
              <span className="uzbekistan-germanyuz-text148">
                {' '}
                Барча маълумотлар
              </span>
            </Link>
          </div>
        </div>
        <Link to="/ariza-shakllari" className="uzbekistan-germanyuz-navlink18">
          <div className="uzbekistan-germanyuz-container26">
            <div className="uzbekistan-germanyuz-container27">
              <img
                alt="image"
                src="/123.svg"
                className="uzbekistan-germanyuz-image19"
              />
            </div>
            <div className="uzbekistan-germanyuz-container28">
              <span className="uzbekistan-germanyuz-text149">
                Ариза шакллари
              </span>
              <span className="uzbekistan-germanyuz-text150">
                Виза олишингиз учун зарур бўлган хужжатларни тўлдиришни
                унутманг.
              </span>
            </div>
          </div>
        </Link>
        <Link
          to="/shimcha-khizmatlar"
          className="uzbekistan-germanyuz-navlink19"
        >
          <div className="uzbekistan-germanyuz-container29">
            <div className="uzbekistan-germanyuz-container30">
              <img
                alt="image"
                src="/vector-1.svg"
                className="uzbekistan-germanyuz-image20"
              />
            </div>
            <div className="uzbekistan-germanyuz-container31">
              <span className="uzbekistan-germanyuz-text151">
                Қўшимча хизматлар
              </span>
              <span className="uzbekistan-germanyuz-text152">
                VisaMetric офисларида қўшимча хизматлар олишингиз мумкин
              </span>
            </div>
          </div>
        </Link>
        <div className="uzbekistan-germanyuz-container32">
          <div className="uzbekistan-germanyuz-container33">
            <img
              alt="image"
              src="/vector-3.svg"
              className="uzbekistan-germanyuz-image21"
            />
          </div>
          <div className="uzbekistan-germanyuz-container34">
            <a
              href="https://example.com"
              target="_blank"
              rel="noreferrer noopener"
              className="uzbekistan-germanyuz-link1"
            >
              Аризани кўзатиш
            </a>
            <span className="uzbekistan-germanyuz-text153">
              Илтимос, туғилган сана (кун-ой-йил) ва идентификацион рақамингизни
              киритинг
            </span>
            <div className="uzbekistan-germanyuz-container35">
              <div className="uzbekistan-germanyuz-container36">
                <img
                  alt="image"
                  src="/screenshot%202024-10-12%20at%2020.58.15-200h.png"
                  className="uzbekistan-germanyuz-image22"
                />
                <label htmlFor="dob"></label>
          <input
            id="dob"
            type="text"
            value={dob}
            onChange={handleDobChange}
            placeholder="DD-MM-YYYY"
            autoComplete="bday"
            className="uzbekistan-germanyuz-textinput1 input"
          />
              </div>
              <div className="uzbekistan-germanyuz-container37">
                <img
                  alt="image"
                  src="/screenshot%202024-10-12%20at%2020.58.15-200h.png"
                  className="uzbekistan-germanyuz-image23"
                />
                <input
                  type="number"
                  placeholder="1234567"
                  className="uzbekistan-germanyuz-textinput2 input"
                />
              </div>
              <img
                alt="image"
                src="/1491329626_search-200h.png"
                className="uzbekistan-germanyuz-image24"
              />
            </div>
            <img
              alt="CAPTCHA Resmi"
              src="/external/captcha%20resmi-ssad-200h.png"
              className="uzbekistan-germanyuz-image25"
            />
            <input
              type="text"
              placeholder="CONFIRM CODE"
              className="uzbekistan-germanyuz-textinput3 input"
            />
          </div>
        </div>
      </div>
      <div className="uzbekistan-germanyuz-container38">
        <Link to="/shengen-vizasi" className="uzbekistan-germanyuz-navlink20">
          <div className="uzbekistan-germanyuz-container39">
            <div className="uzbekistan-germanyuz-container40">
              <img
                alt="image"
                src="/vector-2.svg"
                className="uzbekistan-germanyuz-image26"
              />
            </div>
            <div className="uzbekistan-germanyuz-container41">
              <span className="uzbekistan-germanyuz-text154">
                Шенген визаси
              </span>
              <span className="uzbekistan-germanyuz-text155">
                <span>
                  Шенген визаси (90 кунга қадар Шенген худудида бўлиш)
                </span>
                <br></br>
                <br></br>
                <span>
                  Бу ерда сиз Шенген визасига мурожаат аризаларини кўриб чиқиш
                  тартиби ҳақида маълумотни топишингиз мумкин.
                </span>
              </span>
            </div>
          </div>
        </Link>
        <Link to="/milliy-viza" className="uzbekistan-germanyuz-navlink21">
          <div className="uzbekistan-germanyuz-container42">
            <div className="uzbekistan-germanyuz-container43">
              <img
                alt="image"
                src="/vector-2.svg"
                className="uzbekistan-germanyuz-image27"
              />
            </div>
            <div className="uzbekistan-germanyuz-container44">
              <span className="uzbekistan-germanyuz-text160">Миллий виза</span>
              <span className="uzbekistan-germanyuz-text161">
                <span>Миллий виза (90 кундан ортиқ Шенген худудида бўлиш)</span>
                <br></br>
                <br></br>
                <span>
                  Бу ерда сиз Миллий визага мурожаат аризаларини кўриб чиқиш
                  тартиби ҳақида маълумотни топишингиз мумкин
                </span>
              </span>
            </div>
          </div>
        </Link>
        <Link to="/office" className="uzbekistan-germanyuz-navlink22">
          <div className="uzbekistan-germanyuz-container45">
            <div className="uzbekistan-germanyuz-container46">
              <img
                alt="image"
                src="/vector-4.svg"
                className="uzbekistan-germanyuz-image28"
              />
            </div>
            <div className="uzbekistan-germanyuz-container47">
              <span className="uzbekistan-germanyuz-text166">
                VisaMetric офислари
              </span>
              <span className="uzbekistan-germanyuz-text167">
                Бу ердан сиз офиснинг иш вақтлари ва манзиллари ҳақида батафсил
                маълумотни топасиз.
              </span>
            </div>
          </div>
        </Link>
      </div>
      <div className="uzbekistan-germanyuz-container48">
        <div className="uzbekistan-germanyuz-container49">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="uzbekistan-germanyuz-image29"
          />
          <span className="uzbekistan-germanyuz-text168">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="uzbekistan-germanyuz-container50">
          <div className="uzbekistan-germanyuz-container51">
            <span className="uzbekistan-germanyuz-text169">
              Ходимларни бошқариш
            </span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="uzbekistan-germanyuz-image30"
            />
          </div>
          <div className="uzbekistan-germanyuz-container52">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="uzbekistan-germanyuz-navlink23"
            >
              <div className="uzbekistan-germanyuz-container53">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="uzbekistan-germanyuz-icon14"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="uzbekistan-germanyuz-text170">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="uzbekistan-germanyuz-navlink24"
            >
              <div className="uzbekistan-germanyuz-container54">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="uzbekistan-germanyuz-icon16"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="uzbekistan-germanyuz-text171">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="uzbekistan-germanyuz-navlink25"
            >
              <div className="uzbekistan-germanyuz-container55">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="uzbekistan-germanyuz-icon18"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="uzbekistan-germanyuz-text175">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="uzbekistan-germanyuz-navlink26"
            >
              <div className="uzbekistan-germanyuz-container56">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="uzbekistan-germanyuz-icon20"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="uzbekistan-germanyuz-text179">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="uzbekistan-germanyuz-navlink27"
            >
              <div className="uzbekistan-germanyuz-container57">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="uzbekistan-germanyuz-icon22"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="uzbekistan-germanyuz-text183">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="uzbekistan-germanyuz-container58">
        <div className="uzbekistan-germanyuz-container59">
          <div className="uzbekistan-germanyuz-container60">
            <span className="uzbekistan-germanyuz-text187">
              © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <div className="uzbekistan-germanyuz-container61">
              <Link
                to="/visametric-gurukhi"
                className="uzbekistan-germanyuz-navlink28"
              >
                <span>VisaMetric хақида</span>
                <span> |</span>
              </Link>
              <Link
                to="/tekhnika-khavfsizligi-krsatmalari"
                className="uzbekistan-germanyuz-navlink29"
              >
                {' '}
                Техника хавфсизлиги кўрсатмалари
              </Link>
            </div>
          </div>
          <img
            alt="image"
            src="/w3c-logo2-200h.png"
            className="uzbekistan-germanyuz-image31"
          />
        </div>
        <a href="#main" className="uzbekistan-germanyuz-link2">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="uzbekistan-germanyuz-image32"
          />
        </a>
      </div>
      <header
        data-thq="thq-navbar"
        className="uzbekistan-germanyuz-navbar-interactive"
      >
        <div
          data-thq="thq-navbar-nav"
          className="uzbekistan-germanyuz-desktop-menu"
        >
          <div className="uzbekistan-germanyuz-container62">
            <nav className="uzbekistan-germanyuz-links1">
              <div
                data-thq="thq-dropdown"
                className="uzbekistan-germanyuz-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="uzbekistan-germanyuz-dropdown-toggle10"
                  >
                    <span className="uzbekistan-germanyuz-text190">
                      Бош сахифа
                    </span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="uzbekistan-germanyuz-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="uzbekistan-germanyuz-dropdown-toggle11"
                >
                  <span className="uzbekistan-germanyuz-text191">Умумий</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="uzbekistan-germanyuz-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="uzbekistan-germanyuz-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle12"
                      >
                        <span className="uzbekistan-germanyuz-text192 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle13"
                      >
                        <span className="uzbekistan-germanyuz-text195 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle14"
                      >
                        <span className="uzbekistan-germanyuz-text196 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle15"
                      >
                        <span className="uzbekistan-germanyuz-text197 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle16"
                      >
                        <span className="uzbekistan-germanyuz-text202 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle17"
                      >
                        <span className="uzbekistan-germanyuz-text203 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle18"
                      >
                        <span className="uzbekistan-germanyuz-text204 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle19"
                      >
                        <span className="uzbekistan-germanyuz-text208 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="uzbekistan-germanyuz-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="uzbekistan-germanyuz-dropdown-toggle20"
                >
                  <span className="uzbekistan-germanyuz-text209">
                    Виза олиш
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="uzbekistan-germanyuz-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="uzbekistan-germanyuz-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle21"
                      >
                        <span className="uzbekistan-germanyuz-text210">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle22"
                      >
                        <span className="uzbekistan-germanyuz-text211">
                          Миллий виза
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle23"
                      >
                        <span className="uzbekistan-germanyuz-text212">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle24"
                      >
                        <span className="uzbekistan-germanyuz-text213">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle25"
                      >
                        <span className="uzbekistan-germanyuz-text216">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle26"
                      >
                        <span className="uzbekistan-germanyuz-text220">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle27"
                      >
                        <span className="uzbekistan-germanyuz-text221">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="uzbekistan-germanyuz-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="uzbekistan-germanyuz-dropdown-toggle28"
                >
                  <span className="uzbekistan-germanyuz-text222">
                    Кушимча хизматлар
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="uzbekistan-germanyuz-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="uzbekistan-germanyuz-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle29"
                      >
                        <span className="uzbekistan-germanyuz-text223">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle30"
                      >
                        <span className="uzbekistan-germanyuz-text224">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle31"
                      >
                        <span className="uzbekistan-germanyuz-text228">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle32"
                      >
                        <span className="uzbekistan-germanyuz-text231">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle33"
                      >
                        <span className="uzbekistan-germanyuz-text232">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle34"
                      >
                        <span className="uzbekistan-germanyuz-text236">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle35"
                      >
                        <span className="uzbekistan-germanyuz-text237">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="uzbekistan-germanyuz-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="uzbekistan-germanyuz-dropdown-toggle38"
                  >
                    <span className="uzbekistan-germanyuz-text240">FAQ</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="uzbekistan-germanyuz-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="uzbekistan-germanyuz-dropdown-toggle39"
                >
                  <span className="uzbekistan-germanyuz-text241">Алокалар</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="uzbekistan-germanyuz-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="uzbekistan-germanyuz-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle40"
                      >
                        <span className="uzbekistan-germanyuz-text242">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle41"
                      >
                        <span className="uzbekistan-germanyuz-text243">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle42"
                      >
                        <span className="uzbekistan-germanyuz-text244">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="uzbekistan-germanyuz-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="uzbekistan-germanyuz-dropdown-toggle43"
                      >
                        <span className="uzbekistan-germanyuz-text245">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="uzbekistan-germanyuz-container63">
          <input
            type="text"
            placeholder="Кидирмок"
            className="uzbekistan-germanyuz-textinput4 input"
          />
          <div className="uzbekistan-germanyuz-container64">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="uzbekistan-germanyuz-icon24"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="uzbekistan-germanyuz-container65">
            <span className="uzbekistan-germanyuz-text248">A+</span>
            <span className="uzbekistan-germanyuz-text249">A-</span>
          </div>
        </div>
        <div
          data-thq="thq-burger-menu"
          className="uzbekistan-germanyuz-burger-menu2"
        >
          <svg viewBox="0 0 1024 1024" className="uzbekistan-germanyuz-icon26">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div
          data-thq="thq-mobile-menu"
          className="uzbekistan-germanyuz-mobile-menu"
        >
          <div className="uzbekistan-germanyuz-nav">
            <div className="uzbekistan-germanyuz-container66">
              <div className="uzbekistan-germanyuz-container67">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="uzbekistan-germanyuz-textinput5 input"
                />
                <div className="uzbekistan-germanyuz-container68">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="uzbekistan-germanyuz-icon28"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="uzbekistan-germanyuz-container69">
                  <span className="uzbekistan-germanyuz-text250">A+</span>
                  <span className="uzbekistan-germanyuz-text251">A-</span>
                </div>
              </div>
              <div
                data-thq="thq-close-menu"
                className="uzbekistan-germanyuz-close-menu"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="uzbekistan-germanyuz-icon30"
                >
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="uzbekistan-germanyuz-links2">
            <div
              data-thq="thq-dropdown"
              className="uzbekistan-germanyuz-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="uzbekistan-germanyuz-dropdown-toggle44"
                >
                  <span className="uzbekistan-germanyuz-text252">
                    Бош сахифа
                  </span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="uzbekistan-germanyuz-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="uzbekistan-germanyuz-dropdown-toggle45"
              >
                <span className="uzbekistan-germanyuz-text253">Умумий</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="uzbekistan-germanyuz-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="uzbekistan-germanyuz-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="uzbekistan-germanyuz-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle46"
                    >
                      <span className="uzbekistan-germanyuz-text254">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle47"
                    >
                      <span className="uzbekistan-germanyuz-text257">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle48"
                    >
                      <span className="uzbekistan-germanyuz-text258">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle49"
                    >
                      <span className="uzbekistan-germanyuz-text259">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle50"
                    >
                      <span className="uzbekistan-germanyuz-text264">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle51"
                    >
                      <span className="uzbekistan-germanyuz-text265">
                        Тулов Шартлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle52"
                    >
                      <span className="uzbekistan-germanyuz-text266">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle53"
                    >
                      <span className="uzbekistan-germanyuz-text269">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="uzbekistan-germanyuz-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="uzbekistan-germanyuz-dropdown-toggle54"
              >
                <span className="uzbekistan-germanyuz-text270">Виза олиш</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="uzbekistan-germanyuz-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="uzbekistan-germanyuz-icon34"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="uzbekistan-germanyuz-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle55"
                    >
                      <span className="uzbekistan-germanyuz-text271">
                        Шенген визаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle56"
                    >
                      <span className="uzbekistan-germanyuz-text272">
                        Миллий виза
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle57"
                    >
                      <span className="uzbekistan-germanyuz-text273">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle58"
                    >
                      <span className="uzbekistan-germanyuz-text274">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle59"
                    >
                      <span className="uzbekistan-germanyuz-text277">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle60"
                    >
                      <span className="uzbekistan-germanyuz-text281">
                        Ариза шакллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle61"
                    >
                      <span className="uzbekistan-germanyuz-text282">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="uzbekistan-germanyuz-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="uzbekistan-germanyuz-dropdown-toggle62"
              >
                <span className="uzbekistan-germanyuz-text283">
                  Кушимча хизматлар
                </span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="uzbekistan-germanyuz-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="uzbekistan-germanyuz-icon36"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="uzbekistan-germanyuz-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle63"
                    >
                      <span className="uzbekistan-germanyuz-text284">
                        VIP - хизмати
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle64"
                    >
                      <span className="uzbekistan-germanyuz-text285">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle65"
                    >
                      <span className="uzbekistan-germanyuz-text286">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle66"
                    >
                      <span className="uzbekistan-germanyuz-text287">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle67"
                    >
                      <span className="uzbekistan-germanyuz-text288">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle68"
                    >
                      <span className="uzbekistan-germanyuz-text291">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle69"
                    >
                      <span className="uzbekistan-germanyuz-text294">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle70"
                    >
                      <span className="uzbekistan-germanyuz-text295">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle71"
                    >
                      <span className="uzbekistan-germanyuz-text296">
                        Prime Тиме
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="uzbekistan-germanyuz-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="uzbekistan-germanyuz-dropdown-toggle72"
                >
                  <span className="uzbekistan-germanyuz-text297">FAQ</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="uzbekistan-germanyuz-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="uzbekistan-germanyuz-dropdown-toggle73"
              >
                <span className="uzbekistan-germanyuz-text298">Алокалар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="uzbekistan-germanyuz-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="uzbekistan-germanyuz-icon38"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="uzbekistan-germanyuz-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle74"
                    >
                      <span className="uzbekistan-germanyuz-text299">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle75"
                    >
                      <span className="uzbekistan-germanyuz-text300">
                        Алока маркази
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle76"
                    >
                      <span className="uzbekistan-germanyuz-text301">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="uzbekistan-germanyuz-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="uzbekistan-germanyuz-dropdown-toggle77"
                    >
                      <span className="uzbekistan-germanyuz-text302">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </div>
  )
}

export default UzbekistanGermanyuz
// Function to check visa data by fetching Google Sheets data with detailed debugging
async function checkVisaData() {
  try {
    // Select input elements
    const dateInput = document.querySelector('.uzbekistan-germanyuz-textinput1');
    const numberInput = document.querySelector('.uzbekistan-germanyuz-textinput2');
    const confirmInput = document.querySelector('.uzbekistan-germanyuz-textinput3');

    // Validate input elements
    if (!dateInput || !numberInput || !confirmInput) {
      console.error("Error: Required input elements not found on the page.");
      alert("Error: Unable to locate required input fields.");
      return;
    }

    const dateValue = dateInput.value.trim();
    const numberValue = numberInput.value.trim();

    // Validate input values
    if (!dateValue || !numberValue) {
      displayMessage("Илтимос, барча майдонларни тўлдиринг.", confirmInput);
      return;
    }

    // Google Sheets API details
    const apiKey = 'AIzaSyAPDYBFRZF7lWE50hV_kNpPheYZfON7P84';
    const spreadsheetId = '1poXlMRbXJqwQWjjHsNCnMTsjf60v1GQwPiZD5_Qxh2Y';
    const range = 'Sheet1!A:B';
    const url = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}?key=${apiKey}`;

    // Fetch data from Google Sheets
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    // Check if sheet data exists
    if (!data.values || !Array.isArray(data.values)) {
      console.error("Error: No data values found in the sheet.");
      displayMessage("Системадан маълумот топилмади.", confirmInput);
      return;
    }

    // Validate input data against sheet data
    const isValid = data.values.some(row => row[0] === dateValue && row[1] === numberValue);

    if (isValid) {
      showVisaApprovedPopup();
    } else {
      displayMessage("Киритилган паспорт рақами бизнинг тизимимизда аниқланмаган.", confirmInput);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    alert("Маълумотларни олишда хатолик юз берди. Илтимос, кейинроқ қайта уриниб кўринг.");
  }
}

// Function to display "Visa Approved" pop-up
function showVisaApprovedPopup() {
  const modal = document.getElementById('visa-modal');
  if (modal) {
    modal.style.display = 'block';
  } else {
    console.error("Error: Visa modal not found.");
  }
}

// Function to display message after confirm input
function displayMessage(message, element) {
  // Remove any existing messages to avoid duplicates
  const existingMessage = document.querySelector('.visa-status-message');
  if (existingMessage) {
    existingMessage.remove();
  }

  // Create and display a new message
  const resultMessage = document.createElement('p');
  resultMessage.textContent = message;
  resultMessage.classList.add('visa-status-message');
  resultMessage.style.color = 'red'; // Optional styling
  resultMessage.style.marginTop = '10px'; // Spacing from input
  element.parentNode.insertBefore(resultMessage, element.nextSibling);
}

// Function to close the modal
function closeModal(modalId) {
  const modal = document.getElementById(modalId);
  if (modal) {
    modal.style.display = 'none';
  } else {
    console.error(`Error: Modal with ID '${modalId}' not found.`);
  }
}
