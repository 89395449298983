import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './faq.css'

const Faq = (props) => {
  return (
    <div className="faq-container10">
      <Helmet>
        <title>faq - Visametric</title>
        <meta property="og:title" content="faq - Visametric" />
      </Helmet>
      <div data-thq="thq-burger-menu" className="faq-burger-menu1">
        <svg viewBox="0 0 1024 1024" className="faq-icon10">
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div id="main" className="faq-container11">
        <h1 className="faq-text100">FAQ</h1>
        <div className="faq-container12">
          <Link to="/uzbekistan-germanyuz" className="faq-navlink10">
            <div className="faq-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="faq-icon12"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="faq-text101">Бош саҳифа</h1>
            </div>
          </Link>
          <Link to="/uzbekistan-germanyuz" className="faq-navlink11">
            <div className="faq-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="faq-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="faq-text102">FAQ</h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="faq-container15">
        <div className="faq-container16">
          <div className="faq-container17">
            <span className="faq-text103">FAQ</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="faq-icon16"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img alt="image" src="/rectangle%20271.svg" className="faq-image10" />
          <div className="faq-container18">
            <Link to="/umumiy-malumot" className="faq-navlink12">
              <div className="faq-container19">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="faq-image11"
                />
                <span className="faq-text104">Умумий маълумот</span>
              </div>
            </Link>
            <Link to="/ariza-oldin" className="faq-navlink13">
              <div className="faq-container20">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="faq-image12"
                />
                <span className="faq-text105">Ариза олдин</span>
              </div>
            </Link>
            <Link to="/ariza-topshirish-zharaeni" className="faq-navlink14">
              <div className="faq-container21">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="faq-image13"
                />
                <span className="faq-text106">Ариза топшириш жараёни</span>
              </div>
            </Link>
            <Link to="/uzhzhatlar" className="faq-navlink15">
              <div className="faq-container22">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="faq-image14"
                />
                <span className="faq-text107">Ҳужжатлар</span>
              </div>
            </Link>
            <Link to="/shimcha-khizmatlar" className="faq-navlink16">
              <div className="faq-container23">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="faq-image15"
                />
                <span className="faq-text108">Қўшимча хизматлар</span>
              </div>
            </Link>
            <Link
              to="/ariza-topshirishdan-snggi-olat"
              className="faq-navlink17"
            >
              <div className="faq-container24">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="faq-image16"
                />
                <span className="faq-text109">
                  Ариза топширишдан сўнгги ҳолат
                </span>
              </div>
            </Link>
          </div>
        </div>
        <div className="faq-container25">
          <Link to="/umumiy-malumot" className="faq-navlink18">
            <div className="faq-container26">
              <div className="faq-container27">
                <img alt="image" src="/affirm.svg" className="faq-image17" />
              </div>
              <div className="faq-container28">
                <span className="faq-text110">Умумий маълумот</span>
                <span className="faq-text111">
                  Дипломатик ваколатхоналар, алоқалар, филиаллар, дам олиш
                  кунлари ва хорижий паспортлар тўғрисида маълумотлар.
                </span>
              </div>
            </div>
          </Link>
          <Link to="/ariza-oldin" className="faq-navlink19">
            <div className="faq-container29">
              <div className="faq-container30">
                <img alt="image" src="/affirm.svg" className="faq-image18" />
              </div>
              <div className="faq-container31">
                <span className="faq-text112">Ариза олдин</span>
                <span className="faq-text113">
                  ыловадан олдин бажариладиган харакатлар хаqида ма&apos;лумот
                  олинг.
                </span>
              </div>
            </div>
          </Link>
          <Link to="/ariza-topshirish-zharaeni" className="faq-navlink20">
            <div className="faq-container32">
              <div className="faq-container33">
                <img alt="image" src="/affirm.svg" className="faq-image19" />
              </div>
              <div className="faq-container34">
                <span className="faq-text114">Ариза топшириш жараёни</span>
                <span className="faq-text115">
                  Санани белгилаш ва уни рад этиш, аризани кўзатиш, нархлар ва
                  бармоқ излари тўғрисида маълумот.
                </span>
              </div>
            </div>
          </Link>
          <Link to="/uzhzhatlar" className="faq-navlink21">
            <div className="faq-container35">
              <div className="faq-container36">
                <img alt="image" src="/affirm.svg" className="faq-image20" />
              </div>
              <div className="faq-container37">
                <span className="faq-text116">Ҳужжатлар</span>
                <span className="faq-text117">
                  Ариза топшириш учун зарур барча ҳужжатлар тўғрисида
                  маълумотлар. Саналар, Шенген визаси, Миллий визалар кўриб
                  чиқилиши ва қайта ишлаб чиқиш вақти.
                </span>
              </div>
            </div>
          </Link>
          <Link to="/shimcha-khizmatlar" className="faq-navlink22">
            <div className="faq-container38">
              <div className="faq-container39">
                <img alt="image" src="/affirm.svg" className="faq-image21" />
              </div>
              <div className="faq-container40">
                <span className="faq-text118">Қўшимча хизматлар</span>
                <span className="faq-text119">
                  Саёҳатнинг тиббий суғурталаниши, паспортдан чиқариб ташлаш,
                  етказиб бериш хизмати ва биометрик фотосуратлар.
                </span>
              </div>
            </div>
          </Link>
          <Link to="/ariza-topshirishdan-snggi-olat" className="faq-navlink23">
            <div className="faq-container41">
              <div className="faq-container42">
                <img alt="image" src="/affirm.svg" className="faq-image22" />
              </div>
              <div className="faq-container43">
                <span className="faq-text120">
                  Ариза топширишдан сўнгги ҳолат
                </span>
                <span className="faq-text121">
                  Ариза кўзатуви, ариза бекор қилиниши, апелляция
                  огоҳлантирилиши ва нотўғри визалар тўғрисида маълумот.
                </span>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div id="header" className="faq-container44">
        <Link to="/" className="faq-navlink24">
          <div className="faq-container45">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="faq-image23"
            />
            <p className="faq-text122">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="faq-container46">
          <div className="faq-container47">
            <span className="faq-text125">
              <span>Apply for Visa to</span>
              <span className="faq-text127"> Germany</span>
            </span>
            <span className="faq-text128">
              <span className="faq-text129">in Uzbekistan</span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="faq-image24"
          />
          <span className="faq-text160">|</span>
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header data-thq="thq-navbar" className="faq-navbar-interactive">
        <div data-thq="thq-navbar-nav" className="faq-desktop-menu">
          <div className="faq-container48">
            <nav className="faq-links1">
              <div
                data-thq="thq-dropdown"
                className="faq-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="faq-dropdown-toggle10"
                  >
                    <span className="faq-text161">Бош сахифа</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="faq-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="faq-dropdown-toggle11"
                >
                  <span className="faq-text162">Умумий</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="faq-dropdown-arrow1"
                  ></div>
                </div>
                <ul data-thq="thq-dropdown-list" className="faq-dropdown-list1">
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle12"
                      >
                        <span className="faq-text163 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle13"
                      >
                        <span className="faq-text166 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle14"
                      >
                        <span className="faq-text167 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle15"
                      >
                        <span className="faq-text168 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle16"
                      >
                        <span className="faq-text173 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle17"
                      >
                        <span className="faq-text174 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle18"
                      >
                        <span className="faq-text175 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle19"
                      >
                        <span className="faq-text179 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="faq-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="faq-dropdown-toggle20"
                >
                  <span className="faq-text180">Виза олиш</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="faq-dropdown-arrow2"
                  ></div>
                </div>
                <ul data-thq="thq-dropdown-list" className="faq-dropdown-list2">
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle21"
                      >
                        <span className="faq-text181">Шенген визаси</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle22"
                      >
                        <span className="faq-text182">Миллий виза</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle23"
                      >
                        <span className="faq-text183">Онлайн-суровнома</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle24"
                      >
                        <span className="faq-text184">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle25"
                      >
                        <span className="faq-text187">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle26"
                      >
                        <span className="faq-text191">Ариза шакллари</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle27"
                      >
                        <span className="faq-text192">Иловани кузатиш</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="faq-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="faq-dropdown-toggle28"
                >
                  <span className="faq-text193">Кушимча хизматлар</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="faq-dropdown-arrow3"
                  ></div>
                </div>
                <ul data-thq="thq-dropdown-list" className="faq-dropdown-list3">
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle29"
                      >
                        <span className="faq-text194">VIP - хизмати</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle30"
                      >
                        <span className="faq-text195">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle31"
                      >
                        <span className="faq-text199">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle32"
                      >
                        <span className="faq-text202">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle33"
                      >
                        <span className="faq-text203">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle34"
                      >
                        <span className="faq-text207">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle35"
                      >
                        <span className="faq-text208">Хужжатлар таржимаси</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="faq-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="faq-dropdown-toggle38"
                  >
                    <span className="faq-text211">FAQ</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="faq-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="faq-dropdown-toggle39"
                >
                  <span className="faq-text212">Алокалар</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="faq-dropdown-arrow4"
                  ></div>
                </div>
                <ul data-thq="thq-dropdown-list" className="faq-dropdown-list4">
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle40"
                      >
                        <span className="faq-text213">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle41"
                      >
                        <span className="faq-text214">Алока маркази</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle42"
                      >
                        <span className="faq-text215">Муштарий Анкетаси</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="faq-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="faq-dropdown-toggle43"
                      >
                        <span className="faq-text216">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="faq-container49">
          <input
            type="text"
            placeholder="Кидирмок"
            className="faq-textinput1 input"
          />
          <div className="faq-container50">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="faq-icon18"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="faq-container51">
            <span className="faq-text219">A+</span>
            <span className="faq-text220">A-</span>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="faq-burger-menu2">
          <svg viewBox="0 0 1024 1024" className="faq-icon20">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="faq-mobile-menu">
          <div className="faq-nav">
            <div className="faq-container52">
              <div className="faq-container53">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="faq-textinput2 input"
                />
                <div className="faq-container54">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="faq-icon22"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="faq-container55">
                  <span className="faq-text221">A+</span>
                  <span className="faq-text222">A-</span>
                </div>
              </div>
              <div data-thq="thq-close-menu" className="faq-close-menu">
                <svg viewBox="0 0 1024 1024" className="faq-icon24">
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="faq-links2">
            <div
              data-thq="thq-dropdown"
              className="faq-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="faq-dropdown-toggle44"
                >
                  <span className="faq-text223">Бош сахифа</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="faq-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="faq-dropdown-toggle45"
              >
                <span className="faq-text224">Умумий</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="faq-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="faq-icon26"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul data-thq="thq-dropdown-list" className="faq-dropdown-list5">
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle46"
                    >
                      <span className="faq-text225">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle47"
                    >
                      <span className="faq-text228">Тегишли маълумотлар</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle48"
                    >
                      <span className="faq-text229">Расмий байрамлар куни</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle49"
                    >
                      <span className="faq-text230">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle50"
                    >
                      <span className="faq-text235">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle51"
                    >
                      <span className="faq-text236">Тулов Шартлари</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle52"
                    >
                      <span className="faq-text237">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle53"
                    >
                      <span className="faq-text240">Фойдали хаволалар</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="faq-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="faq-dropdown-toggle54"
              >
                <span className="faq-text241">Виза олиш</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="faq-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="faq-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul data-thq="thq-dropdown-list" className="faq-dropdown-list6">
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle55"
                    >
                      <span className="faq-text242">Шенген визаси</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle56"
                    >
                      <span className="faq-text243">Миллий виза</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle57"
                    >
                      <span className="faq-text244">Онлайн-суровнома</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle58"
                    >
                      <span className="faq-text245">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle59"
                    >
                      <span className="faq-text248">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle60"
                    >
                      <span className="faq-text252">Ариза шакллари</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle61"
                    >
                      <span className="faq-text253">Иловани кузатиш</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="faq-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="faq-dropdown-toggle62"
              >
                <span className="faq-text254">Кушимча хизматлар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="faq-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="faq-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul data-thq="thq-dropdown-list" className="faq-dropdown-list7">
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle63"
                    >
                      <span className="faq-text255">VIP - хизмати</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle64"
                    >
                      <span className="faq-text256">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle65"
                    >
                      <span className="faq-text257">Биометрик фотосура</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle66"
                    >
                      <span className="faq-text258">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle67"
                    >
                      <span className="faq-text259">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle68"
                    >
                      <span className="faq-text262">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle69"
                    >
                      <span className="faq-text265">Хужжатлар таржимаси</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle70"
                    >
                      <span className="faq-text266">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle71"
                    >
                      <span className="faq-text267">Prime Тиме</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="faq-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="faq-dropdown-toggle72"
                >
                  <span className="faq-text268">FAQ</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="faq-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="faq-dropdown-toggle73"
              >
                <span className="faq-text269">Алокалар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="faq-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="faq-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul data-thq="thq-dropdown-list" className="faq-dropdown-list8">
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle74"
                    >
                      <span className="faq-text270">VisaMetric филиаллари</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle75"
                    >
                      <span className="faq-text271">Алока маркази</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle76"
                    >
                      <span className="faq-text272">Муштарий Анкетаси</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="faq-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="faq-dropdown-toggle77"
                    >
                      <span className="faq-text273">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="faq-container56">
        <div className="faq-container57">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="faq-image26"
          />
          <span className="faq-text276">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="faq-container58">
          <div className="faq-container59">
            <span className="faq-text277">Ходимларни бошқариш</span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="faq-image27"
            />
          </div>
          <div className="faq-container60">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="faq-navlink85"
            >
              <div className="faq-container61">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="faq-icon34"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="faq-text278">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link to="/talim-va-malaka-oshirish" className="faq-navlink86">
              <div className="faq-container62">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="faq-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="faq-text279">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link to="/masuldorlikni-bosharish" className="faq-navlink87">
              <div className="faq-container63">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="faq-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="faq-text283">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link to="/ish-taminoti-byicha-srovnoma" className="faq-navlink88">
              <div className="faq-container64">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="faq-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="faq-text287">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link to="/ishga-zhoylashish-zharaeni" className="faq-navlink89">
              <div className="faq-container65">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="faq-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="faq-text291">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="faq-container66">
        <div className="faq-container67">
          <span className="faq-text295">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="faq-container68">
            <Link to="/visametric-gurukhi" className="faq-navlink90">
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="faq-navlink91"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img alt="image" src="/w3c-logo2-200h.png" className="faq-image28" />
        <a href="#main" className="faq-link">
          <img alt="image" src="/icon-totop-200h.png" className="faq-image29" />
        </a>
      </div>
    </div>
  )
}

export default Faq
