import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './ariza-oldin.css';

const ArizaOldin = (props) => {
  const history = useHistory();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleLanguageChange = (event) => {
    const selectedValue = event.target.value;

    if (selectedValue === "Option 1") {
      history.push('/uzbekistan-germanyuz');
    } else if (selectedValue === "Option 2") {
      history.push('/uzbekistan-germanyuz-en');
    } else if (selectedValue === "Option 3") {
      history.push('/uzbekistan-germanyuz-ru');
    }
  };

  return (
    <div className="ariza-oldin-container10">
      <Helmet>
        <title>ariza-oldin - Visametric</title>
        <meta property="og:title" content="ariza-oldin - Visametric" />
      </Helmet>
      <div data-thq="thq-burger-menu" className="ariza-oldin-burger-menu1">
        <svg viewBox="0 0 1024 1024" className="ariza-oldin-icon10">
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div id="main" className="ariza-oldin-container11">
        <h1 className="ariza-oldin-text100">Ариза олдин</h1>
        <div className="ariza-oldin-container12">
          <Link to="/uzbekistan-germanyuz" className="ariza-oldin-navlink10">
            <div className="ariza-oldin-container13">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="ariza-oldin-icon12"
              >
                <path
                  d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1"
                  fill="currentColor"
                ></path>
              </svg>
              <h1 className="ariza-oldin-text101">Бош саҳифа</h1>
            </div>
          </Link>
          <Link to="/uzbekistan-germanyuz" className="ariza-oldin-navlink11">
            <div className="ariza-oldin-container14">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="ariza-oldin-icon14"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="ariza-oldin-text102">FAQ</h1>
            </div>
          </Link>
          <Link to="/uzbekistan-germanyuz" className="ariza-oldin-navlink12">
            <div className="ariza-oldin-container15">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="ariza-oldin-icon16"
              >
                <path
                  d="m9 5l7 7l-7 7"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 className="ariza-oldin-text103">Ариза олдин</h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="ariza-oldin-container16">
        <div className="ariza-oldin-container17">
          <div className="ariza-oldin-container18">
            <span className="ariza-oldin-text104">FAQ</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="ariza-oldin-icon18"
            >
              <path
                d="m9 5l7 7l-7 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <img
            alt="image"
            src="/rectangle%20271.svg"
            className="ariza-oldin-image10"
          />
          <div className="ariza-oldin-container19">
            <Link to="/umumiy-malumot" className="ariza-oldin-navlink13">
              <div className="ariza-oldin-container20">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-oldin-image11"
                />
                <span className="ariza-oldin-text105">Умумий маълумот</span>
              </div>
            </Link>
            <Link to="/ariza-oldin" className="ariza-oldin-navlink14">
              <div className="ariza-oldin-container21">
                <img
                  alt="image"
                  src="/rectangle%2030.svg"
                  className="ariza-oldin-image12"
                />
                <span className="ariza-oldin-text106">Ариза олдин</span>
              </div>
            </Link>
            <Link
              to="/ariza-topshirish-zharaeni"
              className="ariza-oldin-navlink15"
            >
              <div className="ariza-oldin-container22">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-oldin-image13"
                />
                <span className="ariza-oldin-text107">
                  Ариза топшириш жараёни
                </span>
              </div>
            </Link>
            <Link to="/uzhzhatlar" className="ariza-oldin-navlink16">
              <div className="ariza-oldin-container23">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-oldin-image14"
                />
                <span className="ariza-oldin-text108">Ҳужжатлар</span>
              </div>
            </Link>
            <Link to="/shimcha-khizmatlar" className="ariza-oldin-navlink17">
              <div className="ariza-oldin-container24">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-oldin-image15"
                />
                <span className="ariza-oldin-text109">Қўшимча хизматлар</span>
              </div>
            </Link>
            <Link
              to="/ariza-topshirishdan-snggi-olat"
              className="ariza-oldin-navlink18"
            >
              <div className="ariza-oldin-container25">
                <img
                  alt="image"
                  src="/rectangle%2031.svg"
                  className="ariza-oldin-image16"
                />
                <span className="ariza-oldin-text110">
                  Ариза топширишдан сўнгги ҳолат
                </span>
              </div>
            </Link>
          </div>
        </div>
        <div className="ariza-oldin-container26">
          <span className="ariza-oldin-text111">
            <span>1. Узбекистон / FAQ / Ариза олдин</span>
            <br className="ariza-oldin-text113"></br>
          </span>
          <span className="ariza-oldin-text114">
            <span className="ariza-oldin-text115">Мажбурият аризаси нима?</span>
            <br className="ariza-oldin-text116"></br>
            <br className="ariza-oldin-text117"></br>
            <span>
              Мажбурият аризаси, учинчи давлат фуқароси яшаш харажатлари
              хавфсизлиги учун қўлланилади ва маъмурий жараёнида исботлаш
              имконини беради ва доимий яшовчининг картаси Турар жой қонунининг
              5-моддаси 1-бандига биноан тўлдирилади.
            </span>
            <br className="ariza-oldin-text119"></br>
            <span>
              Мажбурият хатини қабул қилганидан сўнг қабул қилувчи ариза
              берувчининг яшаши ва виза олиш ҳаражатларини ўз бўйнига олади.
              Хатни олувчи жисмоний ва юридик шахс бўлиши мумкин.
            </span>
            <br className="ariza-oldin-text121"></br>
            <br className="ariza-oldin-text122"></br>
            <br className="ariza-oldin-text123"></br>
            <span className="ariza-oldin-text124">
              «90 кун/6 ойлик мажбурият хати олдим, лекин икки йиллик виза учун
              ариза бермоқчиман» Бунинг иложи борми?
            </span>
            <br className="ariza-oldin-text125"></br>
            <br className="ariza-oldin-text126"></br>
            <span>
              Ҳа, фақат биринчи 90 кун мажбурият аризасига асосан тиббий суғурта
              килинади. Зарурий виза учун молиявий имкониятингизни исботлашингиз
              керак. Шунингдек, ўз молиявий имкониятларингизни текширишингиз
              лозим, айниқса, сўнгги 3-6-ой бўйича маълумотномада кўрсатилган
              ойлик даромадингиз ва хорижда кўпроқ вақт истикомат килиш
              имкониятингизни исботлашингиз керак.
            </span>
            <br className="ariza-oldin-text128"></br>
            <br className="ariza-oldin-text129"></br>
            <br className="ariza-oldin-text130"></br>
            <span className="ariza-oldin-text131">
              Мажбуриятларни қабул қилиш аризаси билан Мултивиза олиш учун ариза
              бера оламанми?
            </span>
            <br className="ariza-oldin-text132"></br>
            <br className="ariza-oldin-text133"></br>
            <span>
              Ҳа. Қабул қилувчи давлатингиз чет эллик шахс режалаштираётган
              қолиш вақтини (бир йилдан 4 йилгача) ва мажбурият аризасини сўраши
              лозим.
            </span>
            <br className="ariza-oldin-text135"></br>
            <span>
              Бу ҳолатда Германия ваколатхонаси бутун яшаш вақти учун молиявий
              кафолатга эга бўлади.
            </span>
            <br className="ariza-oldin-text137"></br>
            <span>
              Чет элликлар ваколатли органи фақат қисқа давр учун мажбуриятларни
              бажариш мажбуриятини олган бўлса, сиз (ариза топширувчи) қўшимча
              тарзда молиявий аҳвол кафолатини тақдим этишингиз керак. Бу сўнгги
              3-6-ойлик банк ҳисоб рақами баённомаси сизнинг ҳар ойлик
              даромадингизни кўрсатади ва чет элда кўпроқ муддатга қолиш
              имкониятингизни тасдиқлайди.
            </span>
            <br className="ariza-oldin-text139"></br>
            <br className="ariza-oldin-text140"></br>
            <br className="ariza-oldin-text141"></br>
            <span className="ariza-oldin-text142">
              Бизнинг компаниямиз мижозларни бир неча маротаба чорлаган ва бу
              сизга аъён. Нима учун биз ҳар сафар виза олиш аризамизда молиявий
              ҳолатимизга тегишли барча ҳужжатларни тақдим этамиз?
            </span>
            <br className="ariza-oldin-text143"></br>
            <br className="ariza-oldin-text144"></br>
            <span>
              Мавжуд қоидаларга асосан элчихона қабул қилувчи томон тўғрисидаги
              маълумотларни сақлаб турмайди. Биринчи мижоз таклиф этишга
              жўнатилган ҳужжатлар элчихонада виза аризаси билан бирга сақланиб,
              компаниялар томонидан тартибга солинмайди. Шунинг учун ҳар бир
              мижоз учун алоҳида ҳужжатлар тақдим этишингиз керак.
            </span>
            <br className="ariza-oldin-text146"></br>
            <span>Элчихона тушунганлигингиз учун сиздан миннатдор.</span>
            <br className="ariza-oldin-text148"></br>
            <br className="ariza-oldin-text149"></br>
            <br className="ariza-oldin-text150"></br>
            <span className="ariza-oldin-text151">
              Мажбурият аризасида фамилиямни ёзишда хато қилсам, нима қилишим
              керак?
            </span>
            <br className="ariza-oldin-text152"></br>
            <br className="ariza-oldin-text153"></br>
            <span>
              Таклиф этилган шахс аниқ бўлган тақдирда, Мажбурият аризасида
              кўрсатилган фамилия ва паспорт чегарадан ўтишга рад этилишга олиб
              келмайди.
            </span>
            <br className="ariza-oldin-text155"></br>
            <br className="ariza-oldin-text156"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-oldin-text158"></br>
            <span className="ariza-oldin-text159">
              Норасмий таклифнома нима ва у қандай кўринишга эга?
            </span>
            <br className="ariza-oldin-text160"></br>
            <br className="ariza-oldin-text161"></br>
            <span>
              Оила аъзоларингизни кўриб келишингиз учун бериладиган виза учун
              қайси фуқарони кўриб келишингиз томонидан берилган норасмий
              таклифномани тақдим этишингиз керак.
            </span>
            <br className="ariza-oldin-text163"></br>
            <span>
              Норасмий таклифнома немис тилида бўлиши керак ва унда саёхат
              мазмуни ва вақти ёзилган бўлиши керак. Норасмий таклифнома қўлда
              ёзилган ёки компьютерда таҳрир қилинган бўлиши мумкин. У шахсан
              таклиф қилувчи томонидан ёзилган бўлиши керак. Имзоланган булиши
              зарур эмас.
            </span>
            <br className="ariza-oldin-text165"></br>
            <br className="ariza-oldin-text166"></br>
            <br className="ariza-oldin-text167"></br>
            <span className="ariza-oldin-text168">
              Ариза шаклини қаердан олсам бўлади ва у қанча туради?
            </span>
            <br className="ariza-oldin-text169"></br>
            <br className="ariza-oldin-text170"></br>
            <span>
              Ариза шаклини бизнинг марказнинг қабулхонасидан олишингиз мумкин.
              Шунингдек, сиз уни бизнинг веб саҳифамиздан юклаб олишингиз
              мумкин. Аризаларнинг барча шакллари бепул.
            </span>
            <br className="ariza-oldin-text172"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-oldin-text174"></br>
            <br className="ariza-oldin-text175"></br>
            <span className="ariza-oldin-text176">
              Вояга етмаган ариза топширувчилар
            </span>
            <br className="ariza-oldin-text177"></br>
            <span className="ariza-oldin-text178">
              Ёлғиз она(ота)/ажрашган/бева она/ота сифатида нима қилишим керак?
            </span>
            <br className="ariza-oldin-text179"></br>
            <br className="ariza-oldin-text180"></br>
            <span>
              Илтимос, ҳомийлик тўғрисидаги далилларни ариза топшириш вақтида
              кўрсатиб ўтинг.
            </span>
            <br className="ariza-oldin-text182"></br>
            <br className="ariza-oldin-text183"></br>
            <br className="ariza-oldin-text184"></br>
            <span className="ariza-oldin-text185">
              Вояга етмаганларга розилик.
            </span>
            <br className="ariza-oldin-text186"></br>
            <br className="ariza-oldin-text187"></br>
            <span>
              Агар вояга етмаган бола ёлғиз ёки фақат бир васий (одатда ота она)
              билан бирга саёҳат қилаётган бўлса, визага ариза топшириш учун
              иккала ота-онанинг розилиги ёки болани кузатиб келмаётган
              ота-онадан бирининг розилиги керак бўлади. Ушбу мактуб нотариал
              тасдиқланган бўлиши шарт
            </span>
            <br className="ariza-oldin-text189"></br>
            <span>
              Хатда ота-онанинг бола саёҳатига розилиги кўрсатиб ўтилади.
              Шунингдек, бу саёҳат даври ва мақсадини белгилаб беради. Қоидага
              кўра, саёҳат даврида болани кузатиб келувчи шахс учун ҳам амал
              қилади.
            </span>
            <br className="ariza-oldin-text191"></br>
            <span>
              Таъкидлаш жоизки, розилик мактуби сўралаётган саёҳат ҳақиқати
              сабабли ота-она хуқуқларини ҳимоя қилади.
            </span>
            <br className="ariza-oldin-text193"></br>
            <span>
              Шунинг учун ҳам элчихона фақатгина визага мурожаат этувчи виза
              олиш учун берилган аризани 6 ойдан кўп булмаган муддатда кўриб
              чиқади. Розилик тўғрисидаги хат 6 ойдан ортиқ вақтни қамраб олса,
              визага ариза топшириш пайтида бу қабул қилинмайди. Бу вазиятда,
              илтимос, янги нотариал тасдиқланган розилик хатини тақдим қилинг.
            </span>
            <br className="ariza-oldin-text195"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-oldin-text197"></br>
            <br className="ariza-oldin-text198"></br>
            <span className="ariza-oldin-text199">
              Розилик хатлари, хизмат сафарлари
            </span>
            <br className="ariza-oldin-text200"></br>
            <span className="ariza-oldin-text201">
              Ёзма розилик хатисиз виза ола биламанми?
            </span>
            <br className="ariza-oldin-text202"></br>
            <br className="ariza-oldin-text203"></br>
            <span>
              Розилик хатисиз хам виза олишингиз мумкин. Кейин сиз саёҳатингизга
              қайтишингизга қодирлигингизни исботлашингиз керак. Исбот турлари:
            </span>
            <br className="ariza-oldin-text205"></br>
            <br className="ariza-oldin-text206"></br>
            <span>- банк ҳисоб рақами ёки ҳисоб рақами баённомаси.</span>
            <br className="ariza-oldin-text208"></br>
            <br className="ariza-oldin-text209"></br>
            <span>ЁКИ</span>
            <br className="ariza-oldin-text211"></br>
            <br className="ariza-oldin-text212"></br>
            <span>- кредит карталари ва кредит карталари баённомаси.</span>
            <br className="ariza-oldin-text214"></br>
            <br className="ariza-oldin-text215"></br>
            <span>
              Бундан ташқари, тиббий суғуртани шахсан тўлашингизга тўғри келади.
            </span>
            <br className="ariza-oldin-text217"></br>
            <br className="ariza-oldin-text218"></br>
            <br className="ariza-oldin-text219"></br>
            <span className="ariza-oldin-text220">
              Меҳмоним учун мажбуриятлар расмий аризасини каерда тайёрлашим
              мумкин ва бу қанча туради?
            </span>
            <br className="ariza-oldin-text221"></br>
            <br className="ariza-oldin-text222"></br>
            <span>
              Сиз Германиядаги яшаш жойингиздаги мажбуриятлар аризасини Чет эл
              фуқаролари бўйича Федерал офисига тақдим қилишингиз мумкин.
            </span>
            <br className="ariza-oldin-text224"></br>
            <span>
              Сиз у ерда тўлов қобилиятингизни исботлашингиз керак. Бундан
              ташқари, сиз меҳмоннинг исми фамилияси, туғилган куни, манзили ва
              паспорт рақамини билишингиз керак. Мажбуриятлар аризасини
              топширишда 29 евро миқдорида божхона тўловини амалга оширишингиз
              керак.
            </span>
            <br className="ariza-oldin-text226"></br>
            <br className="ariza-oldin-text227"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-oldin-text229"></br>
            <span className="ariza-oldin-text230">
              Мажбуриятлар аризасининг амал қилиш муддати қанча вақт?
            </span>
            <br className="ariza-oldin-text231"></br>
            <br className="ariza-oldin-text232"></br>
            <span>
              Одатда элчихона Мажбуриятлар аризасини виза берилган муддатдан
              бошлаб олти ой муддатгача кўриб чиқади.&quot;
            </span>
            <br className="ariza-oldin-text234"></br>
            <br className="ariza-oldin-text235"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-oldin-text237"></br>
            <span className="ariza-oldin-text238">
              Биз компанияни ифодалаймиз ва бизнес шерикларимизнинг
              мажбуриятларини ўз зиммамизга олишни хохлаймиз, буни қандай амалга
              оширамиз?
            </span>
            <br className="ariza-oldin-text239"></br>
            <br className="ariza-oldin-text240"></br>
            <span>
              Илтимос, таклифингизда қуйидагини ёзинг «Турар жой қонунининг
              66-68-параграфларига асосан харажатларни қоплаш мажбуриятини …..»
            </span>
            <br className="ariza-oldin-text242"></br>
            <span>
              Компаниянгиз тўлов қобилиятини исботи сифатида, илтимос, хўжалик
              субъектларининг маълумотномасини юборинг. Таклифнома компания
              ташқи ишлар юридик вакиллиги хўжалик юритиш субъектлар реестри
              гувоҳномасига эга шахс томонидан имзоланишига ишонч ҳосил қилинг.
              Илтимос, имзоловчининг шахсни тасдиқловчи гувоҳномаси ёки паспорти
              нусхасини тақдим этинг.
            </span>
            <br className="ariza-oldin-text244"></br>
            <span>
              Таклифни хўжалик юритувчи субъектлар томонидан рўйхатга олиш
              ҳуқуқига эга бўлмаган шахс имзолаган тақдирда, ваколатли шахс ушбу
              ваколатни унга тақдим этиши керак. Илтимос, талаб қилинган
              рухсатномани ваколатли шахсларнинг шахсни тасдиқлаш картаси ёки
              паспорт нусхаси билан бирга юборинг.
            </span>
            <br className="ariza-oldin-text246"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-oldin-text248"></br>
            <br className="ariza-oldin-text249"></br>
            <span className="ariza-oldin-text250">
              Мен чет эл фукаросиман, лекин Узбекистонда истикомат киламан.
              Узбекистондаги Visametric офисларига ариза топширишим мумкинми?
            </span>
            <br className="ariza-oldin-text251"></br>
            <br className="ariza-oldin-text252"></br>
            <span>
              Ҳа — сизнинг фукаролигингиз аcосий омил эмас, балки доимий яшаш
              жойингиз. Агар Cиз Узбекистонда конуний асосда яшасангиз ва буни
              яшаш жойдан руйхатдан утган булсангиз VisaMetric сизга ҳизмат
              курсата олади.
            </span>
            <br className="ariza-oldin-text254"></br>
            <br className="ariza-oldin-text255"></br>
            <br className="ariza-oldin-text256"></br>
            <span className="ariza-oldin-text257">
              Мен Узбекистон фукаросиман, лекин бошка давлатда яшайман, бироз
              вакт Узбекистонда коламан ва Германияга бориш учун ариза топширсам
              буладими?
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-oldin-text258"></br>
            <br className="ariza-oldin-text259"></br>
            <span>
              Йўқ - чунки доимий яшаш жойингиз Узбекистонда эмас, шунинг учун
              Узбекистондаги VisaMetric офисларига мурожаат кила олмайсиз.
            </span>
            <br className="ariza-oldin-text261"></br>
            <span>
              Аризангизни Cиз истикомат калаётган давлатдаги Германия
              элчихонасига топширишингиз мумкин.
            </span>
            <br className="ariza-oldin-text263"></br>
            <br className="ariza-oldin-text264"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-oldin-text266"></br>
            <span className="ariza-oldin-text267">
              Саёхатим давомида Шенген зонаси давлатларида бўлмоқчиман. Германия
              элчихонасига ариза бера олманми?
            </span>
            <br className="ariza-oldin-text268"></br>
            <br className="ariza-oldin-text269"></br>
            <span>
              Агар Германияда ўтказадиган вақтингиз Шенген зонасининг қолган
              давлатларникидан кўпроқ бўлса, Германия элчихонасига мурожаат
              қилсангиз бўлади. Бир нечта давлатда маълум бир вақтда қолсангиз,
              виза учун биринчи кирган давлатингиз элчихонасига мурожаат
              қилишингиз керак.
            </span>
            <br className="ariza-oldin-text271"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-oldin-text273"></br>
            <br className="ariza-oldin-text274"></br>
            <span className="ariza-oldin-text275">
              Качон ариза беришим мумкин?
            </span>
            <br className="ariza-oldin-text276"></br>
            <br className="ariza-oldin-text277"></br>
            <span>
              Ариза топшириш сайтимизда сиз томондан белгиланган вактда
              факатгина кабулга ёзилган куннгизда амалги оширилади.
            </span>
            <br className="ariza-oldin-text279"></br>
            <span>
              Санани белгилаш электрон шаклда кабул системамиз оркали амалги
              оширилади. Илтимос, белгиланган кун ва соатдан 15 минут илгари
              келишингиз суралади.
            </span>
            <br className="ariza-oldin-text281"></br>
            <br className="ariza-oldin-text282"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-oldin-text284"></br>
            <span className="ariza-oldin-text285">
              Ариза беришим учун нималарни олишим керак?
            </span>
            <br className="ariza-oldin-text286"></br>
            <br className="ariza-oldin-text287"></br>
            <span>
              Саёҳатингиз мақсади кўрсатилган йўриқномада келтирилган ҳамма
              хужжатларни олишингиз керак. Фақат тўлиқ йиғилган ҳужжатларгина
              қабул қилинишини ёдда тутинг. Кейинчалик ҳужжат қўшишнинг иложи
              йўқ.
            </span>
            <br className="ariza-oldin-text289"></br>
            <br className="ariza-oldin-text290"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-oldin-text292"></br>
            <span className="ariza-oldin-text293">
              Элчихонада шахсан бўлишим керакми?
            </span>
            <br className="ariza-oldin-text294"></br>
            <br className="ariza-oldin-text295"></br>
            <span>
              Шенген визаси: Европа иттифоқи виза информацион системаси 2015 йил
              23 июнда Озарбайжонда ишга тушиши мумкин. Бу ҳар бир ариза
              жўнатувчи ўз бармоқ изларини шу санадан бошлаб Шенген визасига
              ариза учун тайёрлаши зарурлигини англатади.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-oldin-text297"></br>
            <span>
              Биринчи марта ариза берувчилар агар қуйидаги истисноларга мос
              келмаган ҳолда бармоқ изларини топшириш жараёнида шахсан
              қатнашишади.
            </span>
            <br className="ariza-oldin-text299"></br>
            <span>
              Шенген визасини олиш учун сўнгги 59 ой ичида бармоқ изларини
              топширган бўлсангиз, шахсан қатнашишингиз зарур эмас. Қайси Шенген
              зонаси давлати учун бармоқ изларини топширган бўлсангиз ҳам фарқи
              йўқ. Бокудаги Франция элчихонасига 2012 йил 23 июнигача
              топширилган бармоқ излари Виза ахборот тизимида сақданиб қолмаган.
              Бармоқ изларининг қайта топширилиши нимага зарур. Ариза
              топширилишидан сўнгги 24 ойгача муддатда С категорияли шенген
              визаларни олиб, қонуний ишлатган ариза топширувчилар шахсан
              қатнашишлари зарур эмас.
            </span>
            <br className="ariza-oldin-text301"></br>
            <span>
              Ёки aриза топшириш давридан олдин сўнгги 24 ой давомида бир йиллик
              ёки мулти Шенген визасини олиб уни қонуний ишлатган.
            </span>
            <br className="ariza-oldin-text303"></br>
            <span>
              Олдинги визалар қабул қилиниши ва қонуний ишлатилганлиги тегишли
              паспорт нусхаларида тасдиқланган бўлиши шарт.
            </span>
            <br className="ariza-oldin-text305"></br>
            <span>
              Агар никохланган жуфтлик бирга саёҳат қилаётган бўлса, жуфтликдан
              бири сўнгги 59 ой ичида Шенген вакиллигига ўз бармоқ изларини
              топширган ҳолатда, фақат бир кишининг аризаси билан
              қаноатлантирилади.
            </span>
            <br className="ariza-oldin-text307"></br>
            <span>
              Худди шу ҳолат 18 ёшгача бўлган болалар, ҳамда, Шенген
              ваколатхонасига 12 ёшгача бўлган болалар бармоқ излари сўнгги 59
              ой ичида топширилган бўлса амал қилади.
            </span>
            <br className="ariza-oldin-text309"></br>
            <br className="ariza-oldin-text310"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-oldin-text312"></br>
            <span className="ariza-oldin-text313">
              Агар элчихонада шахсан иштироким зарур бўлса, даврни банд қилишда
              нималарга эътибор қаратишим зарур?
            </span>
            <br className="ariza-oldin-text314"></br>
            <br className="ariza-oldin-text315"></br>
            <span>
              Шахсан сухбатда қатнашмасангиз ҳам, даврни банд қилиш зарур.
              Даврни VisaMetric маркази қабул хонасига ариза этиб берувчи шахс
              номига эмас, ариза топширувчи шахс номига банд этинг. Бир нечта
              бандловчи учун алоҳида давр банд қилиш керак VisaMetric га бир
              нечта ариза топширилиб, айнан бир давр банд этилган бўлса, фақат
              битта ариза қаноатлантирилади.
            </span>
            <br className="ariza-oldin-text317"></br>
            <span>
              Аризани сизнинг номингиздан топширадиган шахс элчихонада
              бериладиган саволларга жавоб бериш лаёқатига эга бўлиши керак.
              Саёҳат мақсади тўғрисида бериладиган хамма саволларга жавоб бериши
              учун унга барча маълумотларни беринг.
            </span>
            <br className="ariza-oldin-text319"></br>
            <br className="ariza-oldin-text320"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-oldin-text322"></br>
            <span className="ariza-oldin-text323">
              Ҳужжатлар тўплами тўлиқ бўлмаган тақдирда ҳам қабул қилинадими?
            </span>
            <br className="ariza-oldin-text324"></br>
            <br className="ariza-oldin-text325"></br>
            <span>
              VisaMetric маркази қабулхонасига тўлиқ бўлмаган ҳужжатлар тўплами
              билан борсангиз, вакилларимиз бу ҳақида тушунтириб беришади.
            </span>
            <br className="ariza-oldin-text327"></br>
            <span>Кейин сизда қуйидаги имкониятлар пайдо бўлади:</span>
            <br className="ariza-oldin-text329"></br>
            <br className="ariza-oldin-text330"></br>
            <span>
              - Ҳужжатлар сизга қайтарилади ва сиз бизнинг веб саҳифамизда янги
              учрашув вақтини белгилайсиз;
            </span>
            <br className="ariza-oldin-text332"></br>
            <br className="ariza-oldin-text333"></br>
            <span>
              - Сиз тўлиқ бўлмаган хужжат топширгансиз. Лекин, етишмаган
              ҳужжатлар виза олишда рад жавоби олишингиз мумкин. Аризани
              жўнатишда тегишли огоҳлантиришни имзолашингиз керак
            </span>
            <br className="ariza-oldin-text335"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-oldin-text337"></br>
            <br className="ariza-oldin-text338"></br>
            <span className="ariza-oldin-text339">
              Ариза тўпламида муайян ҳужжат йўқ Ҳужжатларни шу ҳолатда жўнатасам
              бўладими ёки аризам рад этиладими?
            </span>
            <br className="ariza-oldin-text340"></br>
            <br className="ariza-oldin-text341"></br>
            <span>
              Доим тўлиқ ҳужжатлар тўпламини жўнатинг. Қарор қандай бўлишини
              олдиндан айтиб бўлмайди, етишмаётган ҳужжатларга қарамасдан ижобий
              жавоб бўлиши ҳам мумкин.
            </span>
            <br className="ariza-oldin-text343"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="ariza-oldin-text345"></br>
            <br className="ariza-oldin-text346"></br>
            <span className="ariza-oldin-text347">
              Вояга етмаганларнинг чет эл паспорти: нималарга эътибор қаратишим
              зарур?
            </span>
            <br className="ariza-oldin-text348"></br>
            <br className="ariza-oldin-text349"></br>
            <span>
              Вояга етмаган шахс номидан ариза у паспортга эга бўлса ёки у ота
              оналик ҳуқуқига эга шахснинг паспортида рўйхатдан ўтган бўлсагина
              жўнатишингиз мумкин. Агар Вояга етмаган шахс бобо-бувиларининг,
              қон қариндошлари ёки ота-оналарининг паспортида рўйхатга ўтган
              бўлса, виза аризаси қаноатлантирилмайди. Вояга етмаган шахс бир
              ўзи ёки ота-она ҳуқуқига эга бўлмаган шахсларсиз саёҳат қилаётган
              бўлса, алоҳида паспорт керак бўлади.
            </span>
            <br className="ariza-oldin-text351"></br>
            <br className="ariza-oldin-text352"></br>
            <br className="ariza-oldin-text353"></br>
            <span className="ariza-oldin-text354">
              Вояга етмаган шахс томонидан ариза топширилишида нималарга эътибор
              беришим керак?
            </span>
            <br className="ariza-oldin-text355"></br>
            <br className="ariza-oldin-text356"></br>
            <span>
              Вояга етмаган шахслар учун ҳам ариза шакли тўлдирилади. Ота-она
              ҳуқуқига эга барча шахслар томонидан имзоланиши зарур. Ота-она
              ҳуқуқига эга шахс имзолашга имкони бўлмаса, Шенген давлатлари
              зонасига саёҳат учун нотариал тасдиқланган розилик тақдим қилиши
              шарт
            </span>
            <br className="ariza-oldin-text358"></br>
            <span>16 ёшга тўлган ариза берувчи шаклни шахсан тўлдиради.</span>
            <br className="ariza-oldin-text360"></br>
            <span>
              Вояга етмаганлар анкетасининг тўлдирилган намунаси билан танишинг,
              мархамат.
            </span>
            <br className="ariza-oldin-text362"></br>
            <br className="ariza-oldin-text363"></br>
            <br className="ariza-oldin-text364"></br>
            <span className="ariza-oldin-text365">
              Йиллик виза олиб, жорий ярим йилликнинг 50 кунини бизнес бўйича
              Германияда ўтказдим. Дам олиш даврининг 14 кунини Испанияда
              ўтказмоқчиман. Буни бажарса/уддаласа бўладими?
            </span>
            <br className="ariza-oldin-text366"></br>
            <br className="ariza-oldin-text367"></br>
            <span>
              Шенген визаси бутун Шенген ҳудуди давлатлари учун яроқли. Лекин
              Сиз вақтингизни виза берилган Шенген давлатида сарфлаётганингизни
              эътиборга олинг Қолган вақт исталган Шенген давлатларида
              сарфланиши мумкин.
            </span>
            <br className="ariza-oldin-text369"></br>
            <br className="ariza-oldin-text370"></br>
            <br className="ariza-oldin-text371"></br>
            <span className="ariza-oldin-text372">
              15 кунлик Шенген визасини олдим ва мен Германияда қоламан. Бу ерда
              узоқроқ муддатга қолсам бўладими? Бунинг учун нима қилишим зарур?
            </span>
            <br className="ariza-oldin-text373"></br>
            <br className="ariza-oldin-text374"></br>
            <span>
              Зудлик билан тегишли чет элликлар билан ишлаш органи га мурожаат
              қилиб виза муддатини оширишни сўранг. Муддати ўтган резидентлик
              карточкаси – Германияда яшаш ҳуқуқини суистеъмол қилишдир ва
              натижада жарима, ҳатто киришга таъқиққа айланиши мумкин.
            </span>
            <br className="ariza-oldin-text376"></br>
            <span>
              Бундан ташқари, сизнинг визангиз Германия элчихонаси томонидан
              берилмаган ёки Шенген давлати ҳудудида яшамаётган бўлсангиз,
              тегишли чет эл ҳокимиятига мурожаат қилишингиз зарур.
            </span>
            <br className="ariza-oldin-text378"></br>
            <br className="ariza-oldin-text379"></br>
            <br className="ariza-oldin-text380"></br>
            <span className="ariza-oldin-text381">
              Яқин вақтгача вояга етмаган болам яқин орагача паспортимда
              рўйхатдан ўтган эди. Унинг визаси менинг паспортимда рўйхатдан
              ўтган эди. Ҳозирда унинг ўз паспорти бор. Визага нима бўлади?
            </span>
            <br className="ariza-oldin-text382"></br>
            <br className="ariza-oldin-text383"></br>
            <span>
              Визани паспортингиздан боланинг янги паспортига ўтказишингиз
              мумкин. Илтимос, эски ҳамда янги паспортларингиз билан виза
              ахборот бўлимига юборинг. Ўтказиш бепул бўлади.
            </span>
          </span>
        </div>
      </div>
      <div id="header" className="ariza-oldin-container27">
        <Link to="/" className="ariza-oldin-navlink19">
          <div className="ariza-oldin-container28">
            <img
              alt="logo"
              src="/external/logomin-200h-200h-200h-200h.png"
              className="ariza-oldin-image17"
            />
            <p className="ariza-oldin-text385">
              <span>
                VisaMetric - Визани расмийлаштириш учун ариза топширишда
                хизматлар кўрсатадиган Ўзбекистондаги Германия элчихонасининг
                ягона аккредитатсия қилинган виза марказидир.
              </span>
              <br></br>
            </p>
          </div>
        </Link>
        <div className="ariza-oldin-container29">
          <div className="ariza-oldin-container30">
            <span className="ariza-oldin-text388">
              <span>Apply for Visa to</span>
              <span className="ariza-oldin-text390"> Germany</span>
            </span>
            <span className="ariza-oldin-text391">
              <span className="ariza-oldin-text392">in Uzbekistan</span>
              <span>
                <span>
                  <span>
                    <span>
                      in
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/external/germany-200h-200h-200h-200h.png"
            className="ariza-oldin-image18"
          />
          <div className="elfsight-app-5b7bb311-5565-427e-b3c0-d5e8f1dcb7ae" data-elfsight-app-lazy></div>
        </div>
      </div>
      <header data-thq="thq-navbar" className="ariza-oldin-navbar-interactive">
        <div data-thq="thq-navbar-nav" className="ariza-oldin-desktop-menu">
          <div className="ariza-oldin-container31">
            <nav className="ariza-oldin-links1">
              <div
                data-thq="thq-dropdown"
                className="ariza-oldin-thq-dropdown10 list-item"
              >
                <Link to="/uzbekistan-germanyuz">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="ariza-oldin-dropdown-toggle10"
                  >
                    <span className="ariza-oldin-text424">Бош сахифа</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ariza-oldin-thq-dropdown11 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-oldin-dropdown-toggle11"
                >
                  <span className="ariza-oldin-text425">Умумий</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="ariza-oldin-dropdown-arrow1"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="ariza-oldin-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown10 list-item"
                  >
                    <Link to="/submission-collection">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle12"
                      >
                        <span className="ariza-oldin-text426 Content">
                          <span>Хужжатларни топшириш/</span>
                          <span>Хужжатларни олиш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown11 list-item"
                  >
                    <Link to="/tegishli-malumotlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle13"
                      >
                        <span className="ariza-oldin-text429 Content">
                          Тегишли маълумотлар
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown12 list-item"
                  >
                    <Link to="/rasmiy-bayramlar-kuni">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle14"
                      >
                        <span className="ariza-oldin-text430 Content">
                          Расмий байрамлар куни
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown13 list-item"
                  >
                    <Link to="/arizalarni-krib-chiish-muddati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle15"
                      >
                        <span className="ariza-oldin-text431 Content">
                          <span>Аризаларни куриб чикиш</span>
                          <span> </span>
                          <span>муддати</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown14 list-item"
                  >
                    <Link to="/biometrik-malumotlarini">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle16"
                      >
                        <span className="ariza-oldin-text436 Content">
                          биометрик маълумотларини
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown15 list-item"
                  >
                    <Link to="/tlov-shartlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle17"
                      >
                        <span className="ariza-oldin-text437 Content">
                          Тулов Шартлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown16 list-item"
                  >
                    <Link to="/tekhnika-khavfsizligi-krsatmalari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle18"
                      >
                        <span className="ariza-oldin-text438 Content">
                          <span>Техника хавфсизлиги</span>
                          <br></br>
                          <span>курсатмалари</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown17 list-item"
                  >
                    <Link to="/foydali-avolalar-uz">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle19"
                      >
                        <span className="ariza-oldin-text442 Content">
                          Фойдали хаволалар
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ariza-oldin-thq-dropdown12 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-oldin-dropdown-toggle20"
                >
                  <span className="ariza-oldin-text443">Виза олиш</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="ariza-oldin-dropdown-arrow2"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="ariza-oldin-dropdown-list2"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown18 list-item"
                  >
                    <Link to="/shengen-vizasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle21"
                      >
                        <span className="ariza-oldin-text444">
                          Шенген визаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown19 list-item"
                  >
                    <Link to="/milliy-viza">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle22"
                      >
                        <span className="ariza-oldin-text445">Миллий виза</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown20 list-item"
                  >
                    <Link to="/onlayn-srovnomasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle23"
                      >
                        <span className="ariza-oldin-text446">
                          Онлайн-суровнома
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown21 list-item"
                  >
                    <Link to="/kerakli-uzhzhatlar">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle24"
                      >
                        <span className="ariza-oldin-text447">
                          <span>Керакли хужжатлар</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown22 list-item"
                  >
                    <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle25"
                      >
                        <span className="ariza-oldin-text450">
                          <span>Учрашув санасини ва вактини</span>
                          <br className="Content"></br>
                          <span>белгилаш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown23 list-item"
                  >
                    <Link to="/ariza-shakllari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle26"
                      >
                        <span className="ariza-oldin-text454">
                          Ариза шакллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown24 list-item"
                  >
                    <Link to="/ilovani-kuzatish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle27"
                      >
                        <span className="ariza-oldin-text455">
                          Иловани кузатиш
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ariza-oldin-thq-dropdown13 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-oldin-dropdown-toggle28"
                >
                  <span className="ariza-oldin-text456">Кушимча хизматлар</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="ariza-oldin-dropdown-arrow3"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="ariza-oldin-dropdown-list3"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown25 list-item"
                  >
                    <Link to="/vip-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle29"
                      >
                        <span className="ariza-oldin-text457">
                          VIP - хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown26 list-item"
                  >
                    <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle30"
                      >
                        <span className="ariza-oldin-text458">
                          <span>Ариза топшириш учун суровнома</span>
                          <br className="Content"></br>
                          <span>Tnanpnu</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown27 list-item"
                  >
                    <Link to="/biometrik-fotosura">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle31"
                      >
                        <span className="ariza-oldin-text462">
                          <span>Биометрик фотосура</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown28 list-item"
                  >
                    <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle32"
                      >
                        <span className="ariza-oldin-text465">
                          Фото нусха, факс, чоп этиш хизматлари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown29 list-item"
                  >
                    <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle33"
                      >
                        <span className="ariza-oldin-text466">
                          <span>Курьерлик хизмати оркали</span>
                          <br className="Content"></br>
                          <span>етказиб бериш</span>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown30 list-item"
                  >
                    <Link to="/khabardor-ilish-khizmati">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle34"
                      >
                        <span className="ariza-oldin-text470">
                          Хабардор килиш хизмати
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown31 list-item"
                  >
                    <Link to="/khuzhzhatlar-tarzhimasi">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle35"
                      >
                        <span className="ariza-oldin-text471">
                          Хужжатлар таржимаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown32 list-item"
                  >
                    <Link to="/khalaro-tibbiy-suurta">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle36"
                      >
                        <span>Халкаро тиббий сугурта</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown33 list-item"
                  >
                    <Link to="/prime-time">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle37"
                      >
                        <span>Prime Тиме</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ariza-oldin-thq-dropdown14 list-item"
              >
                <Link to="/faq">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="ariza-oldin-dropdown-toggle38"
                  >
                    <span className="ariza-oldin-text474">FAQ</span>
                  </div>
                </Link>
              </div>
              <div
                data-thq="thq-dropdown"
                className="ariza-oldin-thq-dropdown15 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-oldin-dropdown-toggle39"
                >
                  <span className="ariza-oldin-text475">Алокалар</span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="ariza-oldin-dropdown-arrow4"
                  ></div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="ariza-oldin-dropdown-list4"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown34 list-item"
                  >
                    <Link to="/office">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle40"
                      >
                        <span className="ariza-oldin-text476">
                          VisaMetric филиаллари
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown35 list-item"
                  >
                    <Link to="/callcenter">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle41"
                      >
                        <span className="ariza-oldin-text477">
                          Алока маркази
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown36 list-item"
                  >
                    <Link to="/contact">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle42"
                      >
                        <span className="ariza-oldin-text478">
                          Муштарий Анкетаси
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="ariza-oldin-dropdown37 list-item"
                  >
                    <Link to="/contact-form">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="ariza-oldin-dropdown-toggle43"
                      >
                        <span className="ariza-oldin-text479">
                          <span>Кайта алока шакли</span>
                          <br className="Content"></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="ariza-oldin-container32">
          <input
            type="text"
            placeholder="Кидирмок"
            className="ariza-oldin-textinput1 input"
          />
          <div className="ariza-oldin-container33">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="ariza-oldin-icon20"
            >
              <path
                d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="ariza-oldin-container34">
            <span className="ariza-oldin-text482">A+</span>
            <span className="ariza-oldin-text483">A-</span>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="ariza-oldin-burger-menu2">
          <svg viewBox="0 0 1024 1024" className="ariza-oldin-icon22">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="ariza-oldin-mobile-menu">
          <div className="ariza-oldin-nav">
            <div className="ariza-oldin-container35">
              <div className="ariza-oldin-container36">
                <input
                  type="text"
                  placeholder="Кидирмок"
                  className="ariza-oldin-textinput2 input"
                />
                <div className="ariza-oldin-container37">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="ariza-oldin-icon24"
                  >
                    <path
                      d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div className="ariza-oldin-container38">
                  <span className="ariza-oldin-text484">A+</span>
                  <span className="ariza-oldin-text485">A-</span>
                </div>
              </div>
              <div data-thq="thq-close-menu" className="ariza-oldin-close-menu">
                <svg viewBox="0 0 1024 1024" className="ariza-oldin-icon26">
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="ariza-oldin-links2">
            <div
              data-thq="thq-dropdown"
              className="ariza-oldin-thq-dropdown16 list-item"
            >
              <Link to="/uzbekistan-germanyuz">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-oldin-dropdown-toggle44"
                >
                  <span className="ariza-oldin-text486">Бош сахифа</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ariza-oldin-thq-dropdown17 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="ariza-oldin-dropdown-toggle45"
              >
                <span className="ariza-oldin-text487">Умумий</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="ariza-oldin-dropdown-arrow5"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="ariza-oldin-icon28"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="ariza-oldin-dropdown-list5"
              >
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown38 list-item"
                >
                  <Link to="/submission-collection">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle46"
                    >
                      <span className="ariza-oldin-text488">
                        <span>Хужжатларни топшириш/</span>
                        <span>Хужжатларни олиш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown39 list-item"
                >
                  <Link to="/tegishli-malumotlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle47"
                    >
                      <span className="ariza-oldin-text491">
                        Тегишли маълумотлар
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown40 list-item"
                >
                  <Link to="/rasmiy-bayramlar-kuni">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle48"
                    >
                      <span className="ariza-oldin-text492">
                        Расмий байрамлар куни
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown41 list-item"
                >
                  <Link to="/arizalarni-krib-chiish-muddati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle49"
                    >
                      <span className="ariza-oldin-text493">
                        <span>Аризаларни куриб чикиш</span>
                        <span> </span>
                        <span>муддати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown42 list-item"
                >
                  <Link to="/biometrik-malumotlarini">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle50"
                    >
                      <span className="ariza-oldin-text498">
                        биометрик маълумотларини
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown43 list-item"
                >
                  <Link to="/tlov-shartlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle51"
                    >
                      <span className="ariza-oldin-text499">
                        Тулов Шартлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown44 list-item"
                >
                  <Link to="/tekhnika-khavfsizligi-krsatmalari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle52"
                    >
                      <span className="ariza-oldin-text500">
                        <span>Техника хавфсизлиги </span>
                        <span>курсатмалари</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown45 list-item"
                >
                  <Link to="/foydali-avolalar-uz">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle53"
                    >
                      <span className="ariza-oldin-text503">
                        Фойдали хаволалар
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ariza-oldin-thq-dropdown18 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="ariza-oldin-dropdown-toggle54"
              >
                <span className="ariza-oldin-text504">Виза олиш</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="ariza-oldin-dropdown-arrow6"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="ariza-oldin-icon30"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="ariza-oldin-dropdown-list6"
              >
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown46 list-item"
                >
                  <Link to="/shengen-vizasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle55"
                    >
                      <span className="ariza-oldin-text505">Шенген визаси</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown47 list-item"
                >
                  <Link to="/milliy-viza">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle56"
                    >
                      <span className="ariza-oldin-text506">Миллий виза</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown48 list-item"
                >
                  <Link to="/onlayn-srovnomasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle57"
                    >
                      <span className="ariza-oldin-text507">
                        Онлайн-суровнома
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown49 list-item"
                >
                  <Link to="/kerakli-uzhzhatlar">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle58"
                    >
                      <span className="ariza-oldin-text508">
                        <span>Керакли хужжатлар</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown50 list-item"
                >
                  <Link to="/uchrashuv-sanasini-va-vatini-belgilash">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle59"
                    >
                      <span className="ariza-oldin-text511">
                        <span>Учрашув санасини ва вактини</span>
                        <br></br>
                        <span>белгилаш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown51 list-item"
                >
                  <Link to="/ariza-shakllari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle60"
                    >
                      <span className="ariza-oldin-text515">
                        Ариза шакллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown52 list-item"
                >
                  <Link to="/ilovani-kuzatish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle61"
                    >
                      <span className="ariza-oldin-text516">
                        Иловани кузатиш
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ariza-oldin-thq-dropdown19 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="ariza-oldin-dropdown-toggle62"
              >
                <span className="ariza-oldin-text517">Кушимча хизматлар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="ariza-oldin-dropdown-arrow7"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="ariza-oldin-icon32"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="ariza-oldin-dropdown-list7"
              >
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown53 list-item"
                >
                  <Link to="/vip-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle63"
                    >
                      <span className="ariza-oldin-text518">VIP - хизмати</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown54 list-item"
                >
                  <Link to="/ariza-topshirish-uchun-srovnoma-tldirish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle64"
                    >
                      <span className="ariza-oldin-text519">
                        Ариза топшириш учун сўровнома тўлдириш
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown55 list-item"
                >
                  <Link to="/biometrik-fotosura">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle65"
                    >
                      <span className="ariza-oldin-text520">
                        Биометрик фотосура
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown56 list-item"
                >
                  <Link to="/foto-nuskha-faks-chop-etish-khizmatlari">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle66"
                    >
                      <span className="ariza-oldin-text521">
                        Фото нусха, факс, чоп этиш хизматлари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown57 list-item"
                >
                  <Link to="/kurerlik-khizmati-orali-etkazib-berish">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle67"
                    >
                      <span className="ariza-oldin-text522">
                        <span>Курьерлик хизмати оркали </span>
                        <span>етказиб бериш</span>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown58 list-item"
                >
                  <Link to="/khabardor-ilish-khizmati">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle68"
                    >
                      <span className="ariza-oldin-text525">
                        <span>Хабардор килиш хизмати</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown59 list-item"
                >
                  <Link to="/khuzhzhatlar-tarzhimasi">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle69"
                    >
                      <span className="ariza-oldin-text528">
                        Хужжатлар таржимаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown60 list-item"
                >
                  <Link to="/khalaro-tibbiy-suurta">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle70"
                    >
                      <span className="ariza-oldin-text529">
                        Халқаро тиббий суғурта
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown61 list-item"
                >
                  <Link to="/prime-time">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle71"
                    >
                      <span className="ariza-oldin-text530">Prime Тиме</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ariza-oldin-thq-dropdown20 list-item"
            >
              <Link to="/faq">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="ariza-oldin-dropdown-toggle72"
                >
                  <span className="ariza-oldin-text531">FAQ</span>
                </div>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="ariza-oldin-thq-dropdown21 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="ariza-oldin-dropdown-toggle73"
              >
                <span className="ariza-oldin-text532">Алокалар</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="ariza-oldin-dropdown-arrow8"
                ></div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="ariza-oldin-icon34"
                >
                  <path
                    d="m19 9l-7 7l-7-7"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="ariza-oldin-dropdown-list8"
              >
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown62 list-item"
                >
                  <Link to="/office">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle74"
                    >
                      <span className="ariza-oldin-text533">
                        VisaMetric филиаллари
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown63 list-item"
                >
                  <Link to="/callcenter">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle75"
                    >
                      <span className="ariza-oldin-text534">Алока маркази</span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown64 list-item"
                >
                  <Link to="/contact">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle76"
                    >
                      <span className="ariza-oldin-text535">
                        Муштарий Анкетаси
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="ariza-oldin-dropdown65 list-item"
                >
                  <Link to="/contact-form">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="ariza-oldin-dropdown-toggle77"
                    >
                      <span className="ariza-oldin-text536">
                        <span>Кайта алока шакли</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="ariza-oldin-container39">
        <div className="ariza-oldin-container40">
          <img
            alt="image"
            src="/visametric-logowhite-200h.png"
            className="ariza-oldin-image20"
          />
          <span className="ariza-oldin-text539">
            VisaMetric - Визани расмийлаштириш учун ариза топширишда хизматлар
            кўрсатадиган Ўзбекистондаги Германия элчихонасининг ягона
            аккредитатсия қилинган виза марказидир.
          </span>
        </div>
        <div className="ariza-oldin-container41">
          <div className="ariza-oldin-container42">
            <span className="ariza-oldin-text540">Ходимларни бошқариш</span>
            <img
              alt="image"
              src="/rectangle%2027.svg"
              className="ariza-oldin-image21"
            />
          </div>
          <div className="ariza-oldin-container43">
            <Link
              to="/khodimlar-bulimi-konun-koidalari"
              className="ariza-oldin-navlink80"
            >
              <div className="ariza-oldin-container44">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ariza-oldin-icon36"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ariza-oldin-text541">
                  Ходимлар булими конун коидалари
                </span>
              </div>
            </Link>
            <Link
              to="/talim-va-malaka-oshirish"
              className="ariza-oldin-navlink81"
            >
              <div className="ariza-oldin-container45">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ariza-oldin-icon38"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ariza-oldin-text542">
                  <span>Таълим ва малака</span>
                  <br></br>
                  <span>ошириш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/masuldorlikni-bosharish"
              className="ariza-oldin-navlink82"
            >
              <div className="ariza-oldin-container46">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ariza-oldin-icon40"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ariza-oldin-text546">
                  <span>Махсулдорликни</span>
                  <br></br>
                  <span>бошкариш</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ish-taminoti-byicha-srovnoma"
              className="ariza-oldin-navlink83"
            >
              <div className="ariza-oldin-container47">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ariza-oldin-icon42"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ariza-oldin-text550">
                  <span>Иш таъминоти буйича</span>
                  <br></br>
                  <span>суровнома</span>
                </span>
              </div>
            </Link>
            <Link
              to="/ishga-zhoylashish-zharaeni"
              className="ariza-oldin-navlink84"
            >
              <div className="ariza-oldin-container48">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  className="ariza-oldin-icon44"
                >
                  <path
                    d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                    fill="currentColor"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="ariza-oldin-text554">
                  <span>Ишга жойлашиш</span>
                  <br></br>
                  <span>жараёни</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="ariza-oldin-container49">
        <div className="ariza-oldin-container50">
          <span className="ariza-oldin-text558">
            © Copyright 2023 by VisaMetric. Барча хуқуқлар химояланган |
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <div className="ariza-oldin-container51">
            <Link to="/visametric-gurukhi" className="ariza-oldin-navlink85">
              <span>VisaMetric хақида</span>
              <span> |</span>
            </Link>
            <Link
              to="/tekhnika-khavfsizligi-krsatmalari"
              className="ariza-oldin-navlink86"
            >
              {' '}
              Техника хавфсизлиги кўрсатмалари
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/w3c-logo2-200h.png"
          className="ariza-oldin-image22"
        />
        <a href="#main" className="ariza-oldin-link">
          <img
            alt="image"
            src="/icon-totop-200h.png"
            className="ariza-oldin-image23"
          />
        </a>
      </div>
    </div>
  )
}

export default ArizaOldin
