import React from 'react'

import { Helmet } from 'react-helmet'

import './ishga-zhoylashish-zharaeni.css'

const IshgaZhoylashishZharaeni = (props) => {
  return (
    <div className="ishga-zhoylashish-zharaeni-container">
      <Helmet>
        <title>ishga-zhoylashish-zharaeni - Visametric</title>
        <meta
          property="og:title"
          content="ishga-zhoylashish-zharaeni - Visametric"
        />
      </Helmet>
    </div>
  )
}

export default IshgaZhoylashishZharaeni
